import React, { Component } from "react"
import Panel, { Menu, MenuItem } from "../Panel"
import { Subscribe } from "unstated"
import { UserContainer } from "./UserContainer"
import { Route, Link, Switch } from "react-router-dom"
import Overview from "./Overview"
import Auth0 from "./Auth0"
import Organizations from "./Organizations"
import Roles from "./Roles"

class User extends Component {
  render = () => {
    return (
      <Subscribe to={[ UserContainer ]}>
        {userStore => (
          <Panel
            title={
              userStore.state.isFetching
                ? `Loading user...`
                : userStore.state.details
                ? `User: ${ userStore.state.details.displayName }`
                : `User`
            }
            onClose={() =>
              this.props.history.push(
                this.props.returnTo ||
                  this.props.location.pathname
                    .slice(
                      0,
                      this.props.location.pathname.indexOf(
                        `/${ this.props.match.params.userId }`
                      )
                    )
                    .concat(
                      this.props.location.search
                        ? this.props.location.search
                        : ``
                    )
              )
            }
            menu={
              <Menu>
                <MenuItem
                  active={this.props.location.pathname === this.props.match.url}
                >
                  <Link to={this.props.match.url + this.props.location.search}>
                    Overview
                  </Link>
                </MenuItem>
                <MenuItem
                  active={
                    this.props.location.pathname ===
                    this.props.match.url + `/organizations`
                  }
                >
                  <Link
                    to={
                      this.props.match.url +
                      `/organizations` +
                      this.props.location.search
                    }
                  >
                    Organizations
                  </Link>
                </MenuItem>
                <MenuItem
                  active={
                    this.props.location.pathname ===
                    this.props.match.url + `/roles`
                  }
                >
                  <Link
                    to={
                      this.props.match.url +
                      `/roles` +
                      this.props.location.search
                    }
                  >
                    Roles
                  </Link>
                </MenuItem>
                <MenuItem
                  active={
                    this.props.location.pathname ===
                    this.props.match.url + `/auth0`
                  }
                >
                  <Link
                    to={
                      this.props.match.url +
                      `/auth0` +
                      this.props.location.search
                    }
                  >
                    Auth0
                  </Link>
                </MenuItem>
              </Menu>
            }
          >
            <Switch>
              <Route
                path={this.props.match.path + `/organizations`}
                component={Organizations}
              />
              <Route
                path={this.props.match.path + `/roles`}
                component={Roles}
              />
              <Route
                path={this.props.match.path + `/auth0`}
                component={Auth0}
              />
              <Route path={this.props.match.path} component={Overview} />
            </Switch>
          </Panel>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.props.userStore.fetchDetails(this.props.match.params.userId)
    }, 0)
  }

  componentWillReceiveProps = nextProps => {
    this.props.match.params.userId !== nextProps.match.params.userId &&
      this.props.userStore.fetchDetails(nextProps.match.params.userId)
  }

  componentWillUnmount = () => {
    this.props.userStore.resetState()
  }
}

export default props => (
  <Subscribe to={[ UserContainer ]}>
    {userStore => <User userStore={userStore} {...props} />}
  </Subscribe>
)
