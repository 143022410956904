import React, { Component } from "react"
import { Route } from "react-router-dom"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { Form, Dropdown } from "semantic-ui-react"
import OrganizationContainer from "../Organizations/OrganizationContainer"
import Organization from "../Organizations/Organization"
import CreateSong from "./CreateSong"
// import Genre from "../Genres/Genre"
import Category from "../Categories/Category"
import Select from "../Select"
import get from "lodash.get"
import isEqual from "deep-equal"
import { root } from "../services/Globals"
import dispatchEvent from "../helpers/dispatch-event"
import FocusElement from "../FocusElement"
import FileInput from "../FileInput"
import { push, replace } from "../history"
import Button from "../TextButton"

class CreateArrangement extends Component {
  constructor(props) {
    super(props)

    this.arrangerSelectRef = React.createRef()
    this.publisherSelectRef = React.createRef()
    this.songSelectRef = React.createRef()
    this.categorySelectRef = React.createRef()

    this.initialState = {
      loading: false,
      loadingMessage: undefined,
      draft: {
        name: get(this.props, `location.state.name`),
        description: undefined,
        composition: `Unknown`,
        songId: undefined,
        arrangerId: undefined,
        genresId: [],
        ice: undefined,
        iswc: undefined,
        pdf: undefined,
      },
      compositions: {
        loading: false,
        data: [],
      },
    }

    this.state = {
      ...this.initialState,
    }
  }

  render = () => {
    return (
      <React.Fragment>
        <Panel
          title="New arrangement"
          onClose={() => push(this.props.returnTo || `/arrangements`)}
        >
          <FocusElement>
            {({ elementRef, focusElement }) => (
              <React.Fragment>
                <CommandBar>
                  <Command
                    save
                    disabled={
                      isEqual(this.initialState.data, this.state.draft) ||
                      this.state.loading
                    }
                    onClick={this.save}
                    ref={elementRef}
                  />
                </CommandBar>
                <Content
                  loading={this.state.loading}
                  loadingText="Saving arrangement"
                >
                  <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
                    <Form.Input
                      required
                      label="Name"
                      name="name"
                      value={this.state.draft.name || ``}
                      onChange={this.handleChange}
                    />

                    <Form.TextArea
                      label="Description"
                      name="description"
                      value={this.state.draft.description || ``}
                      onChange={this.handleChange}
                    />

                    <Form.Field>
                      <label
                        style={{
                          display: `flex`,
                          justifyContent: `space-between`,
                          userSelect: `none`,
                        }}
                      >
                        Arranger{` `}
                        {this.state.draft.arrangerId && (
                          <Button
                            onClick={event => {
                              event.preventDefault()
                              this.handleChange(undefined, {
                                name: `arrangerId`,
                                value: undefined,
                              })
                            }}
                          >
                            Clear
                          </Button>
                        )}
                      </label>
                      <Select
                        url="/organizations/ou"
                        value={this.state.draft.arrangerId}
                        onChange={item =>
                          this.handleChange(undefined, {
                            name: `arrangerId`,
                            value: item.id,
                          })
                        }
                        fluid
                        allowAdditions
                        onAddItem={({ value }) => {
                          push(this.props.match.url + `/arranger`, {
                            name: value,
                          })
                        }}
                        ref={this.arrangerSelectRef}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label
                        style={{
                          display: `flex`,
                          justifyContent: `space-between`,
                          userSelect: `none`,
                        }}
                      >
                        Distributing publisher{` `}
                        {this.state.draft.publisherTempId && (
                          <Button
                            onClick={event => {
                              event.preventDefault()
                              this.handleChange(undefined, {
                                name: `publisherTempId`,
                                value: undefined,
                              })
                            }}
                          >
                            Clear
                          </Button>
                        )}
                      </label>
                      <Select
                        url="/organizations/ou"
                        value={this.state.draft.publisherTempId}
                        onChange={item =>
                          this.handleChange(undefined, {
                            name: `publisherTempId`,
                            value: item.id,
                          })
                        }
                        fluid
                        allowAdditions
                        onAddItem={({ value }) => {
                          push(this.props.match.url + `/publisher`, {
                            name: value,
                          })
                        }}
                        ref={this.publisherSelectRef}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label
                        style={{
                          display: `flex`,
                          justifyContent: `space-between`,
                          userSelect: `none`,
                        }}
                      >
                        Song{` `}
                        {this.state.draft.songId && (
                          <Button
                            onClick={event => {
                              event.preventDefault()
                              this.handleChange(undefined, {
                                name: `songId`,
                                value: undefined,
                              })
                            }}
                          >
                            Clear
                          </Button>
                        )}
                      </label>
                      <Select
                        url="/songs"
                        value={this.state.draft.songId}
                        onChange={item =>
                          this.handleChange(undefined, {
                            name: `songId`,
                            value: item.id,
                          })
                        }
                        fluid
                        allowAdditions
                        onAddItem={({ value }) => {
                          push(this.props.match.url + `/song`, {
                            name: value,
                          })
                        }}
                        ref={this.songSelectRef}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label>Composition</label>
                      <Dropdown
                        selection
                        deburr
                        search
                        name="composition"
                        loading={this.state.compositions.loading}
                        options={this.state.compositions.data.map(
                          composition => ({
                            value: composition,
                            text: composition,
                          })
                        )}
                        value={this.state.draft.composition}
                        onChange={this.handleChange}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label>Categories</label>
                      <Select
                        url="/genres"
                        value={this.state.draft.genresId.map(id => ({ id }))}
                        onChange={items =>
                          this.handleChange(undefined, {
                            name: `genresId`,
                            value: items.map(({ id }) => id),
                          })
                        }
                        fluid
                        multiple
                        allowAdditions
                        onAddItem={({ value }) => {
                          push(this.props.match.url + `/category`, {
                            name: value,
                          })
                        }}
                        ref={this.categorySelectRef}
                      />
                    </Form.Field>

                    <Form.Input
                      label="ICE"
                      name="ice"
                      value={this.state.draft.ice || ``}
                      onChange={this.handleChange}
                    />

                    <Form.Input
                      label="ISWC"
                      name="iswc"
                      value={this.state.draft.iswc || ``}
                      onChange={this.handleChange}
                    />

                    <Form.Field>
                      <label>PDF</label>
                      <FileInput
                        name="pdf"
                        value={this.state.draft.pdf}
                        icon="file pdf"
                        accept="application/pdf"
                        onChange={this.handleFileChange}
                        onKeyDown={focusElement}
                      />
                    </Form.Field>
                  </Form>
                </Content>
              </React.Fragment>
            )}
          </FocusElement>
        </Panel>
        <Route
          path={this.props.match.url + `/arranger`}
          render={props => (
            <OrganizationContainer onCreate={this.handleArrangerAddition}>
              {organization => <Organization {...props} {...organization} />}
            </OrganizationContainer>
          )}
        />
        <Route
          path={this.props.match.url + `/publisher`}
          render={props => (
            <OrganizationContainer onCreate={this.handlePublisherAddition}>
              {organization => <Organization {...props} {...organization} />}
            </OrganizationContainer>
          )}
        />

        <Route
          path={this.props.match.url + `/song`}
          render={props => (
            // <Song
            //   {...props}
            //   returnTo={this.props.match.url}
            //   onSave={this.handleSongAddition}
            // />
            <CreateSong
              returnTo={this.props.match.url}
              onCreate={this.handleSongAddition}
              {...props}
            />
          )}
        />
        <Route
          path={this.props.match.url + `/category`}
          render={props => (
            // <Genre
            //   {...props}
            //   returnTo={this.props.match.url}
            //   onSave={this.handleCategoryAddition}
            // />
            <Category
              returnTo={this.props.match.url}
              onCreate={this.handleCategoryAddition}
              {...props}
            />
          )}
        />
      </React.Fragment>
    )
  }

  componentDidMount = () => {
    this.fetchCompositions()
  }

  handleFileChange = event => {
    const {
      name,
      files: [ file ],
    } = event.target

    this.setState(previousState => ({
      ...previousState,
      draft: {
        ...previousState.draft,
        [name]: file,
      },
    }))
  }

  handleChange = ({ target } = {}, data) => {
    const { name, value } = data || target
    this.setState(previousState => ({
      ...previousState,
      draft: {
        ...previousState.draft,
        [name]: value,
      },
    }))
  }

  handleArrangerAddition = response => {
    replace(this.props.match.url)
    setTimeout(() => this.arrangerSelectRef.current.handleFocus(), 0)
    this.handleChange(undefined, {
      name: `arrangerId`,
      value: response.data.id,
    })
  }

  handlePublisherAddition = response => {
    replace(this.props.match.url)
    setTimeout(() => this.publisherSelectRef.current.handleFocus(), 0)
    this.handleChange(undefined, {
      name: `publisherTempId`,
      value: response.data.id,
    })
  }

  handleSongAddition = ({ data: { id } }) => {
    replace(this.props.match.url)
    setTimeout(() => this.songSelectRef.current.handleFocus(), 0)
    this.handleChange(undefined, { name: `songId`, value: id })
  }

  handleCategoryAddition = ({ data: { id } }) => {
    replace(this.props.match.url)
    setTimeout(() => this.categorySelectRef.current.handleFocus(), 0)
    this.handleChange(undefined, {
      name: `genresId`,
      value: this.state.draft.genresId.concat(id),
    })
  }

  fetchCompositions = () => {
    this.setState(
      previousState => ({
        ...previousState,
        compositions: {
          loading: true,
          data: [],
        },
      }),
      () => {
        root.wav
          .api({
            url: `/arrangements/composition`,
          })
          .then(({ data }) => {
            this.setState(previousState => ({
              ...previousState,
              compositions: {
                loading: false,
                data,
              },
            }))
          })
          .catch(error => {
            this.setState(previousState => ({
              ...previousState,
              compositions: {
                loading: false,
                data: [],
              },
            }))
          })
      }
    )
  }

  save = () => {
    const { pdf, ...data } = this.state.draft

    this.setState(
      previousState => ({
        ...previousState,
        loading: true,
        loadingMessage: `Creating arrangement`,
      }),
      () => {
        root.wav
          .api({
            url: `/arrangements`,
            method: `post`,
            data: data,
          })
          .then(({ data: { id: arrangementId, version: { id: versionId } } }) =>
            !pdf
              ? { arrangementId }
              : root.wav
                  .api({
                    url: `/arrangements/${ arrangementId }/versions/${ versionId }/upload/pdf`,
                    method: `post`,
                    data: (file => {
                      const data = new FormData()
                      data.append(`file`, file)
                      return data
                    })(pdf),
                    onUploadProgress: ({ total, loaded }) => {
                      this.setState(previousState => ({
                        ...previousState,
                        loadingMessage: `Uploading pdf ${ Math.ceil(
                          (loaded / total) * 100
                        ) }%`,
                      }))
                    },
                  })
                  .then(() => ({ arrangementId }))
          )
          .then(({ arrangementId }) => {
            dispatchEvent(`create:arrangement`)
            replace(`/arrangements/${ arrangementId }`)
          })
          .catch(error => {
            this.setState({ loading: false, loadingMessage: undefined })
          })
      }
    )
  }
}

export default CreateArrangement
