import { Component } from "react"
import { root } from "../../services/Globals"
import isEqual from "deep-equal"

export default class GetArrangements extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `loading`,
      payload: undefined,
      next: undefined,
      search: ``,
    }
    this.state = this.initialState
    this.updateEvent = `update:arrangement`
    this.createEvent = `create:arrangement`
    this.deleteEvent = `delete:arrangement`
  }

  render = () => {
    const {
      reload,
      paginate,
      search,
      state: { mode, payload, next },
      props: { children },
    } = this

    return children({
      mode,
      payload:
        mode !== `error` && payload !== undefined
          ? Object.keys(payload).reduce((acc, id) => [ ...acc, payload[id] ], [])
          : payload,
      reload,
      ...(next ? { paginate } : {}),
      search,
    })
  }

  componentDidMount = () => {
    window.addEventListener(this.updateEvent, this.getData)
    window.addEventListener(this.createEvent, this.getData)
    window.addEventListener(this.deleteEvent, this.getData)
    return this.getData()
  }

  componentWillUnmount = () => {
    window.removeEventListener(this.updateEvent, this.getData)
    window.removeEventListener(this.createEvent, this.getData)
    window.removeEventListener(this.deleteEvent, this.getData)
  }

  getData = () => {
    this.setState(previousState => ({
      ...previousState,
      mode: `loading`,
    }))

    const { limit, offset, sort } = this.props
    const { search } = this.state

    const params = {
      ...(limit ? { limit } : {}),
      ...(offset ? { offset } : {}),
      ...(sort ? { sort } : {}),
      ...(search ? { search } : {}),
    }

    return root.wav
      .api({
        url: `/arrangements`,
        params,
      })
      .then(({ data: { next, items } }) => {
        const itemById = items.reduce(
          (rearranged, item) => ({ ...rearranged, [item.id]: item }),
          {}
        )
        this.setState(previousState => ({
          ...previousState,
          mode: `success`,
          payload:
            previousState.payload !== undefined
              ? Object.keys(itemById).reduce(
                  (acc, id) =>
                    previousState.payload[id] &&
                    isEqual(previousState.payload[id], itemById[id])
                      ? { ...acc, [id]: previousState.payload[id] }
                      : { ...acc, [id]: itemById[id] },
                  {}
                )
              : itemById,
          next,
        }))
      })
      .catch(error => {
        this.setState(previousState => ({
          ...previousState,
          mode: `error`,
          payload: error,
        }))
      })
  }

  paginate = () => {
    this.setState(previousState => ({
      ...previousState,
      mode: `paginating`,
    }))

    return root.wav
      .api(this.state.next)
      .then(({ data: { items, next } }) => {
        this.setState(previousState => ({
          ...previousState,
          mode: `success`,
          payload: {
            ...previousState.payload,
            ...items.reduce(
              (rearranged, item) => ({ ...rearranged, [item.id]: item }),
              {}
            ),
          },
          next,
        }))
      })
      .catch(error => {
        this.setState(previousState => ({
          ...previousState,
          mode: `error`,
          payload: error,
        }))
      })
  }

  reload = event => {
    event && event.preventDefault && event.preventDefault()
    this.setState(() => this.initialState)
    return this.getData()
  }

  search = (query = ``) => {
    this.setState(
      previousState => ({
        ...previousState,
        search: query,
      }),
      () => {
        this.getData()
      }
    )
  }
}
