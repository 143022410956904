import React from "react"
import { Route } from "react-router-dom"
import { push } from "../history"
import { QueryParams } from "../QueryParamsContainer"
import { CollectionProvider } from "../CollectionContainer"
import Artists from "./Artists"
import Artist from "./Artist"

export default props => (
  <QueryParams>
    {queryParams => (
      <CollectionProvider
        url={props.match.url}
        params={{ search: queryParams.params.artistsQuery }}
      >
        {artists => (
          <React.Fragment>
            <Route
              path={props.match.url}
              render={props => <Artists artists={artists} {...props} />}
            />

            <Route
              path="/artists/:artistId"
              render={props => (
                <Artist
                  onCreate={({ data: { id } }) => {
                    push(`/artists/${ id }`)
                    artists.refresh()
                  }}
                  onUpdate={({ data: { id } }) => artists.refresh(id)}
                  onDelete={id => {
                    push(
                      props.match.url.slice(0, props.match.url.lastIndexOf(`/`))
                    )
                    artists.refresh(id)
                  }}
                  {...props}
                />
              )}
            />
          </React.Fragment>
        )}
      </CollectionProvider>
    )}
  </QueryParams>
)
