import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import RevenueTableWrapper from './RevenueTableWrapper'

const ARRANGEMENT_LIMIT = 5;

export default function OrganizationRevenueTable({ revenue, onDownload, isLoadingDownload }) {
  return (
    <RevenueTableWrapper onDownload={onDownload} isLoadingDownload={isLoadingDownload}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Organization</Table.HeaderCell>
            <Table.HeaderCell>Arrangement</Table.HeaderCell>
            <Table.HeaderCell>Total time</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              Collecting society
            </Table.Cell>
            <Table.Cell />
            <Table.Cell>
              <p style={{ fontWeight: "bold" }}>
                {Math.round(revenue.collectingSociety.totalTime)} s
              </p>
            </Table.Cell>
            <Table.Cell>
              <p style={{ fontWeight: "bold" }}>
                {revenue.collectingSociety.revenueRaw.toFixed(2)} £$€
              </p>
            </Table.Cell>
          </Table.Row>

          {revenue.collectingSociety.arrangementIds.slice(0, ARRANGEMENT_LIMIT).map((arrId) => (
            <Table.Row key={`col-soc-${arrId}`}>
              <Table.Cell />

              <Table.Cell>
                <Link to={`arrangements/${arrId}`}>{revenue.collectingSociety.arrangements[arrId].title}</Link>
              </Table.Cell>

              <Table.Cell>
                <p>{Math.round(revenue.collectingSociety.arrangements[arrId].totalTime)} s</p>
              </Table.Cell>

              <Table.Cell>
                <p>{revenue.collectingSociety.arrangements[arrId].collectingSociety.revenueRaw.toFixed(2)} £$€</p>
              </Table.Cell>
            </Table.Row>
          ))}
          {revenue.collectingSociety.arrangementCount > ARRANGEMENT_LIMIT && (
            <Table.Row key="col-soc-more">
              <Table.Cell />

              <Table.Cell>
                {`+${revenue.collectingSociety.arrangementCount - ARRANGEMENT_LIMIT} more`}
              </Table.Cell>

              <Table.Cell>
                ...
              </Table.Cell>

              <Table.Cell>
                ...
              </Table.Cell>
              </Table.Row>
          )}

          {revenue.organizationIds.map((organizationId) => (
            <Fragment key={organizationId}>
              <Table.Row>
                <Table.Cell>
                  <Link to={`organizations/${organizationId}`}>
                    {revenue.organizations[organizationId].name}
                  </Link>
                </Table.Cell>
                <Table.Cell />
                <Table.Cell>
                  <p style={{fontWeight: 'bold'}}>{Math.round(revenue.organizations[organizationId].totalTime)} s</p>
                </Table.Cell>
                <Table.Cell>
                  <p style={{fontWeight: 'bold'}}>{revenue.organizations[organizationId].revenueRaw.toFixed(2)} £$€</p>
                </Table.Cell>
              </Table.Row>

              {revenue.organizations[organizationId].arrangementIds.slice(0, ARRANGEMENT_LIMIT).map((arrId) => (
                <Table.Row key={`${revenue.organizations[organizationId].id}-${arrId}`}>
                  <Table.Cell />

                  <Table.Cell>
                    <Link to={`arrangements/${arrId}`}>{revenue.organizations[organizationId].arrangements[arrId].title}</Link>
                  </Table.Cell>

                  <Table.Cell>
                    <p>{Math.round(revenue.organizations[organizationId].arrangements[arrId].totalTime)} s</p>
                  </Table.Cell>

                  <Table.Cell>
                    <p>
                      {calculateRevenueForOrganizationArrangement(
                        organizationId,
                        revenue.organizations[organizationId].arrangements[arrId]
                      )}{" "}
                      £$€
                    </p>
                  </Table.Cell>
                </Table.Row>
              ))}
            {revenue.organizations[organizationId].arrangementCount > ARRANGEMENT_LIMIT && (
              <Table.Row key={`${organizationId}-more`}>
                <Table.Cell />

                <Table.Cell>
                  {`+${revenue.organizations[organizationId].arrangementCount - ARRANGEMENT_LIMIT} more`}
                </Table.Cell>

                <Table.Cell>
                  ...
                </Table.Cell>

                <Table.Cell>
                  ...
                </Table.Cell>
              </Table.Row>
            )}
            </Fragment>
          ))}
        </Table.Body>
      </Table>
    </RevenueTableWrapper>
  );
}

const calculateRevenueForOrganizationArrangement = (
  organizationId,
  arrangement
) => {
  let sum = 0;

  if (arrangement.contentProvider1?.id === organizationId) {
    sum += arrangement.contentProvider1.revenueRaw;
  }

  if (arrangement.contentProvider2?.id === organizationId) {
    sum += arrangement.contentProvider2.revenueRaw;
  }

  if (arrangement.distributingPublisher?.id === organizationId) {
    sum += arrangement.distributingPublisher.revenueRaw;
  }

  return sum.toFixed(2);
};
