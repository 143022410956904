import React from "react"
import { root } from "./services/Globals"

const { Provider, Consumer } = React.createContext()

class Config extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: undefined,
    }
  }

  render = () =>
    typeof this.props.children !== `function` ? (
      <Provider value={this.state}>{this.props.children}</Provider>
    ) : (
      <Consumer>
        {state => (state.data ? this.props.children(state.data) : null)}
      </Consumer>
    )

  componentDidMount = () => {
    typeof this.props.children !== `function` && this.fetchConfig()
  }

  fetchConfig = () => {
    root.wav
      .api({ url: `/config` })
      .then(({ data }) => {
        this.setState({ data })
      })
      .catch(error => console.error(error))
  }
}

export default Config
