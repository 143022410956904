import React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"
import Panel, { CommandBar, Command, Search, Content } from "../Panel"
import { Checkbox, Dropdown, Icon, Table, Button } from "semantic-ui-react"
import { root } from "../services/Globals"
import get from "lodash.get"
import { PanelsConsumer } from "../Panels"
import { Route } from "react-router-dom"
import ListContainer from "../ListContainer"
import Observer from "react-intersection-observer"
import isEqual from "deep-equal"

// Permission levels available:
// `NoAccess`,
// `Read`,
// `Create`,
// `Edit`,
// `Delete`,
// `Administer`,
// `Play`,
// `FullAccess`,
// `Undefined`,

const PERMISSIONS = {
  NoAccess: `No access`,
  Read: `Read`,
  Create: `Create`,
  Edit: `Edit`,
  Delete: `Delete`,
  Play: `Play`,
  // Player: `Player`,
  Administer: `Administer`,
  FullAccess: `Full access`,
  Share: `Share`,
}

const PERMISSION_TYPES = {
  user: `User`,
  choir: `Choir`,
  role: `Role`,
}

const CheckboxWrapper = styled.div`
  position: relative;

  :hover {
    ::before {
      visibility: visible;
      opacity: 1;
      transition-delay: 1s;
    }
  }

  ::before {
    content: '${ props => (props.content ? props.content : ``) }';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100px;
    height: 30px;
    white-space: nowrap;
    transform: translate(-50%, 100%);
    display: flex;
    visibility: hidden;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    background-color: black;
    border-radius: 3px;
    color: white;
    z-index: 1;
    opacity: 0;
    transition: visibility 0s, opacity 120ms;
    transition-delay: 0s;
  }

  ::after  {
    content: '${ props => (props.content ? props.content : ``) }';
    position: absolute;
    top: -20px;
    left: 4px;
    white-space: nowrap;
    transform-origin: left center;
    transform: rotate(-45deg);
  }
`

const Options = styled.div`
  display: flex;

  ${ CheckboxWrapper } {
    :not(:last-child) {
      margin-right: 20px;
    }
  }
`

const Name = styled.p`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Row = styled.div`
  display: flex;
  margin-bottom: 14px;

  p {
    margin: 0;
  }

  button {
    visibility: hidden;
    border: 1px solid #db2828;
    border-radius: 3px;
    color: #db2828;
    text-transform: uppercase;
    margin-left: 5px;
    font-size: 10px;
    line-height: 10px;
    padding: 2px 7px;
    letter-spacing: 0.02em;
  }

  :hover button {
    visibility: visible;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 44px;
  margin-top: 30px;
  margin-bottom: 36px;

  & > ${ Row }:not(:nth-child(2)) ${ Options } ${ CheckboxWrapper }::after {
    display: none;
  }

  h3 {
    text-transform: capitalize;
  }
`

const PermissionCheckbox = ({
  permissionType,
  permissionLevel,
  onChange,
  ...props
}) => {
  const isActive = permissionLevel.includes(permissionType)

  return (
    <CheckboxWrapper content={PERMISSIONS[permissionType]} {...props}>
      <Checkbox
        checked={isActive}
        onChange={() =>
          onChange(
            isActive
              ? permissionLevel.filter(
                  permission => permission !== permissionType
                )
              : permissionLevel.concat(permissionType)
          )
        }
      />
    </CheckboxWrapper>
  )
}

const AccessControlList = props => (
  <List>
    <h3>{props.type}</h3>
    {get(props, `rows`, []).map(({ id, type, permissionLevel, ...data }) => {
      const onChange = props.onChange && props.onChange.bind(null, type, id)

      return (
        <Row key={id}>
          <Name>
            {get(data, `${ type }.name`) ||
              get(data, `${ type }.displayName`) ||
              get(data, `${ type }.profile.displayName`)}
            <span>
              <button
                onClick={event => {
                  event.preventDefault()
                  onChange(undefined)
                }}
              >
                remove
              </button>
            </span>
          </Name>
          <Options>
            {Object.keys(PERMISSIONS).map(permissionType => (
              <PermissionCheckbox
                key={permissionType}
                permissionType={permissionType}
                permissionLevel={permissionLevel}
                onChange={onChange}
              />
            ))}
          </Options>
        </Row>
      )
    })}
  </List>
)

class Add extends React.Component {
  render = () => (
    <Panel
      extra
      title={`Add: ${ PERMISSION_TYPES[this.props.location.state.type] }`}
      onClose={() => this.props.history.replace(this.props.returnTo)}
    >
      <CommandBar>
        <Command fluid>
          <Search
            loading={this.props.isFetching}
            onChange={query => this.props.filter({ search: query })}
          />
        </Command>
      </CommandBar>
      <Content style={{ padding: 0 }}>
        <Table basic="very" selectable style={{ marginTop: 0 }}>
          <Table.Body>
            {this.props.data.map(entity => (
              <Table.Row
                key={entity.id}
                onClick={() => {
                  this.props.onAdd(this.props.location.state.type, entity)
                }}
                style={{ cursor: `pointer` }}
              >
                <Table.Cell style={{ paddingLeft: `16px` }}>
                  {get(entity, `displayName`) || get(entity, `name`)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {!this.props.isFetching && this.props.next && (
          <Observer
            onChange={inView =>
              inView && !this.props.isFetchingNext && this.props.fetch(true)
            }
            rootMargin="610px"
          >
            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                height: `122px`,
              }}
            >
              <Button
                basic
                content="Load more..."
                onClick={() => this.props.fetch(true)}
                loading={this.props.isFetchingNext}
              />
            </div>
          </Observer>
        )}
        {!this.props.isFetching && !this.props.next && (
          <div
            style={{
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              height: `122px`,
            }}
          >
            <Button disabled basic content="No more content" />
          </div>
        )}
      </Content>
    </Panel>
  )

  componentDidMount = () => {
    this.props.fetch()
  }
}

class AccessControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      acl: {
        current: undefined,
        draft: [],
      },
    }
  }

  render = () => (
    <React.Fragment>
      <CommandBar>
        <Command>
          <span>
            <Icon name="add" />
            <Dropdown text="Add">
              <Dropdown.Menu>
                {Object.entries(PERMISSION_TYPES).map(([ key, value ], index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={event => {
                      this.props.history.replace(
                        this.props.match.url + `/add`,
                        {
                          type: key,
                        }
                      )
                    }}
                  >
                    {value}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </Command>
        <Command
          save
          disabled={
            this.state.isLoading ||
            isEqual(this.state.acl.current, this.state.acl.draft)
          }
          onClick={this.saveAccessControlList}
        />
      </CommandBar>
      <Content loading={this.state.isLoading} loadingText="Loading permissions">
        {this.state.acl.current &&
          (draft => {
            const { Role, Choir, User, ...sections } = Object.entries(
              draft
            ).reduce(
              (sections, [ type, rows ]) =>
                rows.length > 0
                  ? Object.assign(sections, {
                      [type.replace(/^./, match => match.toUpperCase())]: (
                        <AccessControlList
                          key={type}
                          type={type}
                          rows={rows}
                          onChange={this.handleChange}
                        />
                      ),
                    })
                  : sections,
              {}
            )

            return (
              <React.Fragment>
                {!!User && User}
                {!!Role && Role}
                {!!Choir && Choir}
                {Object.values(sections).map(Component => (
                  <Component />
                ))}
              </React.Fragment>
            )
          })(this.state.acl.draft)}
      </Content>
      <PanelsConsumer>
        {panelsElement => (
          <Route
            path={this.props.match.path + `/add`}
            render={props =>
              createPortal(
                <ListContainer
                  key={props.location.state.type}
                  url={
                    props.location.state.type
                      ? `/${ props.location.state.type }s`
                      : undefined
                  }
                >
                  {({ state: { data, ...state }, fetch, filter }) => (
                    <Add
                      key={props.location.state.type}
                      returnTo={this.props.match.url}
                      fetch={fetch}
                      filter={filter}
                      onAdd={this.handleAdd}
                      data={data.filter(
                        ({ id }) =>
                          (
                            this.state.acl.draft[props.location.state.type] ||
                            []
                          ).find(x => x.id === id) === undefined
                      )}
                      {...state}
                      {...props}
                    />
                  )}
                </ListContainer>,
                panelsElement
              )
            }
          />
        )}
      </PanelsConsumer>
    </React.Fragment>
  )

  componentDidMount = () => {
    this.fetchAccessControlList()
  }

  handleAdd = (type, entity) => {
    this.setState(previousState => ({
      ...previousState,
      acl: {
        ...previousState.acl,
        draft: {
          ...previousState.acl.draft,
          [type]: (previousState.acl.draft[type] || [])
            .filter(({ id }) => id !== entity.id)
            .concat({
              id: entity.id,
              type,
              [type]:
                type === `user`
                  ? {
                      ...entity,
                      profile: {
                        displayName: entity.displayName,
                      },
                    }
                  : entity,
              permissionLevel: [],
            }),
        },
      },
    }))
  }

  handleChange = (type, id, permissions) => {
    // permissions === undefined ? remove entity : update entity
    this.setState(previousState => ({
      ...previousState,
      acl: {
        ...previousState.acl,
        draft: {
          ...previousState.acl.draft,
          [type]:
            permissions === undefined
              ? previousState.acl.draft[type].filter(entity => entity.id !== id)
              : previousState.acl.draft[type].map(entry =>
                  entry.id !== id
                    ? entry
                    : {
                        ...entry,
                        permissionLevel: [
                          (previousPermissions, updatedPermissions) =>
                            previousPermissions.includes(`NoAccess`) &&
                            updatedPermissions.length > 1
                              ? updatedPermissions.filter(p => p !== `NoAccess`)
                              : updatedPermissions,
                          (previousPermissions, updatedPermissions) =>
                            updatedPermissions.includes(`NoAccess`) &&
                            !previousPermissions.includes(`NoAccess`)
                              ? [ `NoAccess` ]
                              : updatedPermissions,
                          (previousPermissions, updatedPermissions) =>
                            previousPermissions.includes(`FullAccess`) &&
                            updatedPermissions.length > 1
                              ? updatedPermissions.filter(
                                  p => p !== `FullAccess`
                                )
                              : updatedPermissions,
                          (previousPermissions, updatedPermissions) =>
                            updatedPermissions.includes(`FullAccess`) &&
                            !previousPermissions.includes(`FullAccess`)
                              ? [ `FullAccess` ]
                              : updatedPermissions,
                        ].reduce(
                          (updatedPermissions, test) =>
                            test(entry.permissionLevel, updatedPermissions),
                          permissions
                        ),
                      }
                ),
        },
      },
    }))
  }

  fetchAccessControlList = () => {
    this.setState({ isLoading: true }, () => {
      root.wav
        .api({
          url: `/arrangements/${ this.props.match.params.arrangementId }/acl`,
        })
        .then(({ data }) => {
          const current = data.reduce(
            (types, { permissionLevel, ...entities }) => {
              const { type, data } = Object.entries(entities).reduce(
                (entity, [ type, data ]) => (data ? { type, data } : entity),
                undefined
              )

              return {
                ...types,
                [type]: (types[type] || []).concat({
                  id: data.id,
                  type,
                  [type]: data,
                  permissionLevel: permissionLevel
                    .split(`,`)
                    .map(p => p.trim()),
                }),
              }
            },
            {}
          )

          this.setState({
            isLoading: false,
            acl: {
              current,
              draft: current,
            },
          })
        })
        .catch(error => {
          this.setState(previousState => ({
            isLoading: false,
            acl: { current: undefined, draft: [] },
          }))
        })
    })
  }

  saveAccessControlList = () => {
    const permissionTypes = Object.keys(PERMISSION_TYPES).reduce(
      (base, type) => Object.assign(base, { [`${ type }Id`]: null }),
      {}
    )

    const data = Object.values(this.state.acl.draft).reduce(
      (data, collection) =>
        data.concat(
          collection
            .filter(({ permissionLevel }) => permissionLevel.length > 0)
            .map(({ type, id, permissionLevel }) => ({
              ...permissionTypes,
              [`${ type }Id`]: id,
              permissionLevel: permissionLevel.join(`,`),
            }))
        ),
      []
    )

    this.setState({ isLoading: true }, () => {
      root.wav
        .api({
          url: `/arrangements/${ this.props.match.params.arrangementId }/acl`,
          method: `post`,
          data,
        })
        .then(() => {
          this.setState({ isLoading: false }, this.fetchAccessControlList())
        })
        .catch(error => {
          this.setState({ isLoading: false })
        })
    })
  }
}

export default AccessControl
