import get from "lodash.get"
import queryString from "query-string"
import React, { Component } from "react"
import Observer from "react-intersection-observer"
import { Button, Table } from "semantic-ui-react"
import { Subscribe } from "unstated"
import Panel, { Command, CommandBar, Content, Select } from "../Panel"
import { OrdersContainer } from "./OrdersContainer"

const STATUS = {
  Draft: `Draft`,
  "In progress": `InProgress`,
  Completed: `Completed`,
  Canceled: `Canceled`,
}

class Orders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: {
        status: undefined,
      },
    }
  }

  render = () => {
    return (
      <Subscribe to={[ OrdersContainer ]}>
        {ordersStore => (
          <Panel title="Orders">
            <CommandBar>
              <Command>
                <Select
                  clearable={false}
                  pointing="top right"
                  name="Status"
                  header="Select status"
                  options={Object.entries(STATUS).map(([ key, value ]) => ({
                    value,
                    content: key,
                  }))}
                />
              </Command>
            </CommandBar>
            <Content
              style={{ padding: `0` }}
              loading={ordersStore.state.isFetching}
              loadingText="Loading orders.."
            >
              <Table
                basic="very"
                fixed
                singleLine
                selectable
                style={{ marginTop: 0 }}
              >
                <Table.Body>
                  {this.props.orders.map(item => (
                    <Table.Row
                      key={item.id}
                      active={
                        this.props.location.pathname.indexOf(item.id) !== -1
                      }
                      onClick={() => {
                        this.props.history.push(
                          this.props.match.path +
                            `/${ item.id }` +
                            this.props.location.search
                        )
                      }}
                      style={{ cursor: `pointer` }}
                    >
                      <Table.Cell style={{ paddingLeft: `16px` }}>
                        {get(
                          item,
                          `choir.name`,
                          get(item, `user.displayName`, ``)
                        )}
                      </Table.Cell>
                      <Table.Cell>{item.type}</Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{ paddingRight: `16px` }}
                      >
                        {item.quantity}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {ordersStore.state.next && (
                <Observer
                  onChange={inView =>
                    inView &&
                    !ordersStore.state.isFetching &&
                    ordersStore.fetch()
                  }
                  rootMargin="610px"
                >
                  <div
                    style={{
                      width: `100%`,
                      display: `flex`,
                      justifyContent: `center`,
                      padding: `3em`,
                    }}
                  >
                    <Button
                      basic
                      content="Load more..."
                      onClick={ordersStore.fetch}
                      loading={ordersStore.state.isFetching}
                    />
                  </div>
                </Observer>
              )}
            </Content>
          </Panel>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    this.historyListenerStopListen = this.props.history.listen(location =>
      this.setState(previousState => ({
        ...previousState,
        filters: {
          ...previousState.filters,
          status: get(queryString.parse(location.search), `status`, ``),
        },
      }))
    )

    const { status } = queryString.parse(this.props.location.search)

    if (!status) {
      this.props.history.push(this.props.match.url + `?status=InProgress`)
    } else {
      this.setState(previousState => ({
        ...previousState,
        filters: {
          ...previousState.filters,
          status: status && STATUS[status] ? status : STATUS[`In Progress`],
        },
      }))
    }
  }

  componentDidUpdate = (previousProps, previousState) => {
    previousState.filters.status !== this.state.filters.status &&
      this.props.ordersStore.filter(this.state.filters)
  }

  componentWillUnmount = () => {
    this.historyListenerStopListen && this.historyListenerStopListen()
    this.props.ordersStore.resetState()
  }
}

export default props => (
  <Subscribe to={[ OrdersContainer ]}>
    {ordersStore => (
      <Orders
        ordersStore={ordersStore}
        orders={ordersStore.state.data || []}
        {...props}
      />
    )}
  </Subscribe>
)
