import React from "react"
import GetPlayModes from "../api/containers/GetPlayModes"
import { Loader, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"
import "./Audiotracks.css"
import { CommandBar, Command, Content } from "../Panel"

const PlayModesListItem = ({ playMode, urlBase, history, active }) => (
  <div
    className={`wav-audiotracks-list-row ${
      active ? `wav-audiotracks-list-row-active` : ``
    }`}
  >
    <Link to={`${ urlBase }/${ playMode.id }`} style={{ flexGrow: 1 }}>
      <div className="wav-audiotracks-list-item-name">
        <div>{playMode.playModeType}</div>
      </div>
    </Link>
  </div>
)

const PlayModesList = ({ playModes, location, disabled, ...props }) => (
  <Content disabled={disabled}>
    {playModes.map(playMode => (
      <PlayModesListItem
        key={playMode.id}
        playMode={playMode}
        active={location.pathname.indexOf(playMode.id) > -1}
        {...props}
      />
    ))}
  </Content>
)

export default ({
  arrangementId,
  versionId,
  match,
  history,
  location,
  version: { status },
}) => (
  <GetPlayModes arrangementId={arrangementId} versionId={versionId}>
    {({ mode, payload }) => (
      <React.Fragment>
        {mode === `loading` && (
          <Content>
            <Loader active inline="centered" content="Loading playmodes" />
          </Content>
        )}
        {mode === `success` && (
          <React.Fragment>
            <CommandBar>
              <Command
                onClick={() => {
                  history.push(`${ match.url }/new`)
                }}
                disabled={status !== `Draft`}
              >
                <span>
                  <Icon name="add" />
                  {` `} Add play mode
                </span>
              </Command>
            </CommandBar>
            <PlayModesList
              playModes={payload}
              urlBase={match.url}
              history={history}
              location={location}
              disabled={status !== `Draft`}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )}
  </GetPlayModes>
)
