import styled from "styled-components"

const TextButton = styled.button.attrs({ type: `button`, tabIndex: -1 })`
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  color: #4183c4;
  width: auto;
  outline: none;
`

export default TextButton
