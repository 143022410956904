import React from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import RevenueTableWrapper from './RevenueTableWrapper'

export default function ArrangementRevenueTable({ revenue }) {
  if (!revenue) {
    return null;
  }
  return (
    <RevenueTableWrapper>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Arrangement
            </Table.HeaderCell>
            <Table.HeaderCell>
              Total time
            </Table.HeaderCell>
            <Table.HeaderCell>
              Value
            </Table.HeaderCell>
            <Table.HeaderCell>
              Content provider 1
            </Table.HeaderCell>
            <Table.HeaderCell>
              Content provider 2
            </Table.HeaderCell>
            <Table.HeaderCell>
              Distributing publisher
            </Table.HeaderCell>
            <Table.HeaderCell>
              Collecting society
            </Table.HeaderCell>
            <Table.HeaderCell>
              Public domain
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {revenue.arrangementIds.map((arrId) => (
            <Table.Row key={arrId}>
              <Table.Cell>
                <Link to={`arrangements/${arrId}`}>{revenue.arrangements[arrId].title}</Link>
              </Table.Cell>
              <Table.Cell>
                <p title={secondsToDaysHoursMinutesSeconds(revenue.arrangements[arrId].totalTime)}>{Math.round(revenue.arrangements[arrId].totalTime)} s</p>
              </Table.Cell>
              <Table.Cell>
                <p>{revenue.arrangements[arrId].revenueRaw.toFixed(2)} £$€</p>
              </Table.Cell>
              <Table.Cell>
                {revenue.arrangements[arrId].contentProvider1 && (
                  <>
                    <Link to={`organizations/${revenue.arrangements[arrId].contentProvider1.id}`}>
                      {revenue.arrangements[arrId].contentProvider1?.name}
                    </Link>
                    <p title={`${(100 * revenue.arrangements[arrId].contentProvider1.percentage).toFixed(0)} %`}>{revenue.arrangements[arrId].contentProvider1.revenueRaw.toFixed(2)} £$€</p>
                  </>
                )}
              </Table.Cell>
              <Table.Cell>
                {revenue.arrangements[arrId].contentProvider2 && (
                  <>
                    <Link to={`organizations/${revenue.arrangements[arrId].contentProvider2.id}`}>
                      {revenue.arrangements[arrId].contentProvider2?.name}
                    </Link>
                    <p title={`${(100 * revenue.arrangements[arrId].contentProvider2.percentage).toFixed(0)} %`}>{revenue.arrangements[arrId].contentProvider2.revenueRaw.toFixed(2)} £$€</p>
                  </>
                )}
              </Table.Cell>
              <Table.Cell>
                {revenue.arrangements[arrId].distributingPublisher && (
                  <>
                    <Link to={`organizations/${revenue.arrangements[arrId].distributingPublisher.id}`}>
                      {revenue.arrangements[arrId].distributingPublisher?.name}
                    </Link>
                    <p title={`${(100 * revenue.arrangements[arrId].distributingPublisher.percentage).toFixed(0)} %`}>{revenue.arrangements[arrId].distributingPublisher.revenueRaw.toFixed(2)} £$€</p>
                  </>
                )}
              </Table.Cell>
              <Table.Cell>
                {revenue.arrangements[arrId].collectingSociety && (
                  <p title={`${(100 * revenue.arrangements[arrId].collectingSociety.percentage).toFixed(0)} %`}>{revenue.arrangements[arrId].collectingSociety.revenueRaw.toFixed(2)} £$€</p>
                )}
              </Table.Cell>

              <Table.Cell>
                {revenue.arrangements[arrId].publicDomain && <p style={{ textAlign: "center" }}>✓</p>}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </RevenueTableWrapper>
  );
}

const secondsToDaysHoursMinutesSeconds = (x) => {
  const d = Math.floor(x / (24 * 3600));
  var h = Math.floor((x % (3600 * 24)) / 3600);
  var m = Math.floor((x % 3600) / 60);
  var s = Math.floor((x % 3600) % 60);
  var dDisplay =
    d > 0
      ? d + (d === 1 ? ` day` : ` days`) + (h > 0 || m > 0 || s > 0 ? `, ` : ``)
      : ``;
  var hDisplay =
    h > 0 ? h + (h === 1 ? ` hr` : ` hrs`) + (m > 0 || s > 0 ? `, ` : ``) : ``;
  var mDisplay =
    m > 0 ? m + (m === 1 ? ` min` : ` mins`) + (s > 0 ? `, ` : ``) : ``;
  var sDisplay = s > 0 ? s + (s === 1 ? ` sec` : ` secs`) : ``;
  return dDisplay + hDisplay + mDisplay + sDisplay;
};
