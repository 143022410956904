import React, { Component } from "react"
import { CommandBar, Command, Content } from "../Panel"
import {
  Icon,
  Card,
  Progress,
  Header,
  Loader,
  Message,
  Button,
} from "semantic-ui-react"
import { Link } from "react-router-dom"
import ExecuteVerovio from "../api/containers/ExecuteVerovio"
import RetreiveSyncpoints from "../api/containers/RetreiveSyncpoints"

class Overview extends Component {
  render = () => {
    const {
      match: { url, params },
      jobs,
      history,
      version,
      validation,
    } = this.props

    return (
      <div>
        <CommandBar>
          <Command disabled={version.status !== `Draft`}>
            <Link to={`${ url }/pdf`}>
              <Icon name="file pdf" />
              Upload PDF
            </Link>
          </Command>
          <Command disabled={version.status !== `Draft`}>
            <Link to={`${ url }/musicxml`}>
              <Icon name="file code" />
              Update with MusicXML
            </Link>
          </Command>
          <ExecuteVerovio
            arrangementId={params.arrangementId}
            versionId={params.versionId}
          >
            {({ mode, execute }) => (
              <Command
                disabled={
                  jobs.running.filter(({ type }) => type === `ScoreGenerating`)
                    .length > 0 ||
                  mode === `loading` ||
                  version.status !== `Draft`
                }
              >
                <span
                  onClick={() => {
                    execute().then(() => jobs.refresh())
                  }}
                >
                  <Icon name="video play" />
                  Generate score
                </span>
              </Command>
            )}
          </ExecuteVerovio>
          <RetreiveSyncpoints
            arrangementId={params.arrangementId}
            versionId={params.versionId}
          >
            {({ mode, execute }) => (
              <Command
                disabled={
                  jobs.running.filter(({ type }) => type === `ScoreGenerating`)
                    .length > 0 ||
                  mode === `loading` ||
                  version.status !== `Draft`
                }
              >
                <span
                  onClick={() => {
                    execute().then(() => jobs.refresh())
                  }}
                >
                  <Icon name="refresh" />
                  Sync
                </span>
              </Command>
            )}
          </RetreiveSyncpoints>
        </CommandBar>
        <Content>
          {jobs.running.length > 0 && (
            <Header as="h3" style={{ marginTop: 0 }}>
              Active Jobs
            </Header>
          )}
          {jobs.running.map(
            ({ name, type, id, progress, createdUtc, createdBy }) => (
              <React.Fragment key={id}>
                <Card fluid>
                  <Card.Content>
                    <strong>
                      {Array.prototype.reduce
                        .call(
                          type,
                          (acc, char) =>
                            char.toUpperCase() === char
                              ? acc.concat(` ` + char)
                              : acc.concat(char),
                          ``
                        )
                        .trim()}
                    </strong>
                    <Card.Meta>
                      <small>{new Date(createdUtc).toString()}</small>
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <Progress
                      active
                      size="tiny"
                      value={progress}
                      total={100}
                      style={{ marginBottom: 0 }}
                    />
                  </Card.Content>
                </Card>
              </React.Fragment>
            )
          )}

          <React.Fragment>
            {validation.data &&
              validation.data.errors &&
              validation.data.errors.length > 0 && (
                <Header as="h3" style={{ marginTop: 0 }}>
                  Validation
                </Header>
              )}
            {validation.loading && (
              <Loader active inline="centered" content="Validating" />
            )}
            {validation.data &&
              validation.data.errors &&
              validation.data.errors.length > 0 &&
              validation.data.errors.map((entity, i) => (
                <Message
                  key={i}
                  error
                  content={entity.error}
                  {...(entity.entityType === `Audiotrack`
                    ? {
                        onClick: () =>
                          history.push(url + `/audiotracks/${ entity.entityId }`),
                        style: { cursor: `pointer` },
                      }
                    : {})}
                />
              ))}
            {validation.data &&
              validation.data.errors &&
              validation.data.errors.length > 0 && (
                <div style={{ display: `flex`, justifyContent: `flex-end` }}>
                  <Button
                    onClick={validation.validate}
                    icon="refresh"
                    content="Validate"
                    compact
                  />
                </div>
              )}
          </React.Fragment>
        </Content>
      </div>
    )
  }

  componentDidUpdate = previousProps => {
    previousProps.jobs.running.length !== this.props.jobs.running.length &&
      this.props.validation.validate()
  }
}

export default Overview
