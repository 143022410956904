import React from "react"
import { root } from "../services/Globals"

export default class ReportContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      loadingMessage: ``,
      data: undefined,
    }
  }

  render = () =>
    this.props.children({
      ...this.state,
      save: this.save,
      delete: this.delete,
    })

  componentDidMount = () => {
    this.props.id && this.fetch()
  }

  componentDidUpdate = previousProps => {
    if (previousProps.id !== this.props.id && !this.state.loading) {
      this.props.id
        ? this.fetch()
        : this.setState({
            data: undefined,
          })
    }
  }

  fetch = () => {
    if (this.props.id) {
      this.setState({ loading: true, loadingMessage: `Loading report` }, () => {
        root.wav
          .api({
            url: `/arrangements/feedback/${ this.props.id }`,
          })
          .then(({ data }) => {
            this.setState({
              loading: false,
              loadingMessage: ``,
              data,
            })
          })
          .catch(error => {
            this.setState({
              loading: false,
              loadingMessage: ``,
              data: undefined,
            })
          })
      })
    }
  }

  save = ({ id, status, ...data }) => {
    const loadingMessage = 'Saving report...'

    this.setState(
      {
        loading: true,
        loadingMessage,
      },
      () => {
          root.wav.api({
            url: `/arrangements/feedback/${id}/status`,
            method: 'put',
            data: {
              status
            },
          })
          .then(_ => {
            const repsonseData = {
              data: {
                ...data,
                status
              }
            };
            this.setState(
              {
                loading: false,
                loadingMessage: ``,
                data: repsonseData.data,
              },
              () => {
                  this.props.onUpdate &&
                  this.props.onUpdate(repsonseData)
              }
            )
          })
          .catch(() => {
            this.setState({
              loading: false,
              loadingMessage: ``,
            })
          })
      }
    )
  }
}
