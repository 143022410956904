import React from "react"
import GetVersions from "../api/containers/GetVersions"
import CopyVersion from "../api/containers/CopyVersion"
import { Link } from "react-router-dom"
import { Loader, Icon, Dropdown, Label } from "semantic-ui-react"
import "./Versions.css"
import { CommandBar, Command, Content } from "../Panel"

const VersionsListItem = ({ version, url, arrangementId, history, active }) => (
  <CopyVersion>
    {({ copy, mode }) => (
      <div
        className={`wav-versions-list-row ${
          active ? `wav-versions-list-row-active` : ``
        }`}
      >
        <Link to={url}>
          <div className="wav-versions-list-item">
            <div className="wav-versions-list-item-info">
              <div style={{ display: `flex`, alignItems: `center` }}>
                {new Date(version.updatedUtc).toLocaleString(
                  navigator.language
                )}
                {mode === `loading` && (
                  <Label
                    horizontal
                    color="yellow"
                    size="mini"
                    style={{ marginLeft: `8px` }}
                  >
                    Copying
                  </Label>
                )}
              </div>
              <div className="wav-versions-list-item-status">
                {Array.prototype.reduce
                  .call(
                    version.status,
                    (acc, char) =>
                      char.toUpperCase() === char
                        ? acc.concat(` ` + char)
                        : acc.concat(char),
                    ``
                  )
                  .trim()}
              </div>
            </div>
          </div>
        </Link>
        <div className="wav-versions-list-item-context-menu">
          <Dropdown
            icon={null}
            pointing="top right"
            trigger={<Icon name="ellipsis horizontal" />}
            disabled={mode === `loading`}
          >
            <Dropdown.Menu>
              {[
                {
                  key: `details`,
                  content: `Details`,
                  path: `/details`,
                },
                {
                  key: `score`,
                  content: `Score`,
                  path: `/score`,
                },
                {
                  key: `audiotracks`,
                  content: `Audio tracks`,
                  path: `/audiotracks`,
                },
                {
                  key: `status`,
                  content: `Status`,
                  path: `/status`,
                },
              ].map(options => (
                <Dropdown.Item
                  key={options.key}
                  content={options.content}
                  onClick={() => history.push(url + options.path)}
                />
              ))}
              <Dropdown.Divider />
              <Dropdown.Item
                content={
                  <span>
                    <Icon name="copy" />
                    Copy
                  </span>
                }
                onClick={() => copy({ arrangementId, versionId: version.id })}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    )}
  </CopyVersion>
)

const Versions = ({ match, history, location, arrangementId }) => (
  <GetVersions arrangementId={arrangementId}>
    {({ mode, payload, paginate }) => {
      return (
        <React.Fragment>
          <CommandBar>
            <Command>
              <Link
                to={{
                  pathname: `${ match.url }/musicxml`,
                  state: { arrangementId },
                }}
              >
                <Icon name="file code" />
                Create from MusicXML
              </Link>
            </Command>
          </CommandBar>
          <Content style={{ paddingTop: 0 }}>
            {mode === `loading` && (
              <Loader
                active
                inline="centered"
                content="Loading versions"
                style={{ marginTop: `2rem` }}
              />
            )}
            {mode === `success` &&
              payload.map(version => (
                <VersionsListItem
                  key={version.id}
                  version={version}
                  url={`${ match.url }/${ version.id }`}
                  arrangementId={arrangementId}
                  history={history}
                  active={location.pathname.indexOf(version.id) > -1}
                />
              ))}
          </Content>
        </React.Fragment>
      )
    }}
  </GetVersions>
)

Versions.displayName = `Versions`

export default Versions
