import React from "react"
import SongContainer from "../Songs/SongContainer"
import { push } from "../history"
import Draft from "../Draft"
import get from "lodash.get"
import merge from "lodash.merge"
import Panel from "../Panel"
import Song from "../Songs/Song"
import { Route } from "react-router-dom"
import Artist from "../Artists/Artist"
import Songwriter from "../Songwriters/Songwriter"

export default props => (
  <SongContainer onCreate={props.onCreate}>
    {song => (
      <Draft
        original={merge(
          {},
          {
            name: get(props, `location.state.name` || ``),
          }
        )}
      >
        {draft => (
          <React.Fragment>
            <Panel title="New song" onClose={() => push(props.returnTo)}>
              <Song
                draft={draft.state}
                onChange={draft.handleChange}
                dirty={draft.dirty}
                loading={song.loading}
                loadingMessage={song.loadingMessage}
                save={() => song.save(draft.state)}
                {...props}
              />
            </Panel>

            <Route
              path={`${ props.match.path }/artist`}
              render={props => (
                <Artist
                  {...props}
                  onCreate={({ data }) => {
                    draft.handleChange({
                      target: {
                        name: `knownPerformingArtists`,
                        value: [
                          ...(draft.state.knownPerformingArtists || []),
                          data,
                        ],
                      },
                    })

                    push(
                      props.match.url.slice(0, props.match.url.lastIndexOf(`/`))
                    )
                  }}
                  returnTo={props.match.url.slice(
                    0,
                    props.match.url.lastIndexOf(`/`)
                  )}
                />
              )}
            />
            <Route
              path={`${ props.match.path }/songwriter`}
              render={props => (
                <Songwriter
                  {...props}
                  onCreate={({ data }) => {
                    draft.handleChange({
                      target: {
                        name: `songwriters`,
                        value: [ ...(draft.state.songwriters || []), data ],
                      },
                    })

                    push(
                      props.match.url.slice(0, props.match.url.lastIndexOf(`/`))
                    )
                  }}
                  returnTo={props.match.url.slice(
                    0,
                    props.match.url.lastIndexOf(`/`)
                  )}
                />
              )}
            />
          </React.Fragment>
        )}
      </Draft>
    )}
  </SongContainer>
)
