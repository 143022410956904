import React, { Component } from "react"
import Panel, { CommandBar, Command, Content, Search } from "../Panel"
import GetArrangements from "../api/containers/GetArrangements"
import { Icon, Button } from "semantic-ui-react"
import { Link } from "react-router-dom"
import ArrangementPreview from "./ArrangementPreview"
import "./Arrangements.css"

export default class Arrangements extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchQuery: undefined,
    }

    this.searchInput = React.createRef()
  }
  render = () => {
    const { history, location } = this.props
    return (
      <GetArrangements>
        {({ mode, payload, paginate, search }) => (
          <Panel title="Arrangements">
            <CommandBar>
              <Command fluid tabIndex={-1}>
                <Search
                  onChange={searchQuery => {
                    this.setState({
                      searchQuery,
                    })

                    search(searchQuery)
                  }}
                  innerRef={this.searchInput}
                  placeholder="Search by title..."
                />
              </Command>
              <Command
                onClick={() => history.push(`/arrangements/musicxml`)}
                tabIndex={-1}
              >
                <span>
                  <Icon name="file code" />
                  Create from MusicXML
                </span>
              </Command>
            </CommandBar>
            <Content
              style={{ paddingTop: `1rem` }}
              loading={mode === `loading` && !Array.isArray(payload)}
              loadingText="Loading arrangements..."
            >
              {Array.isArray(payload) &&
                payload.length > 0 &&
                payload.map(item => (
                  <div
                    key={item.id}
                    className={`wav-arrangements-preview-row ${
                      location.pathname.indexOf(item.id) >= 0
                        ? `wav-arrangements-preview-row-active`
                        : ``
                    }`}
                  >
                    <Link to={`/arrangements/${ item.id }`}>
                      <ArrangementPreview data={item} />
                    </Link>
                  </div>
                ))}
              {paginate && (
                <div
                  style={{
                    width: `100%`,
                    padding: `2rem`,
                    display: `flex`,
                    justifyContent: `center`,
                  }}
                >
                  <Button
                    loading={mode === `paginating`}
                    compact
                    basic
                    style={{ margin: `auto` }}
                    content="More"
                    onClick={paginate}
                  />
                </div>
              )}
              {!paginate && !(mode === `loading`) && (
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    minHeight: `122px`,
                  }}
                >
                  <Button
                    basic
                    onClick={() =>
                      this.props.history.push(
                        `/arrangements/new` + this.props.location.search,
                        this.state.searchQuery &&
                          this.state.searchQuery.length > 0
                          ? { name: this.state.searchQuery }
                          : {}
                      )
                    }
                  >
                    {this.state.searchQuery &&
                    this.state.searchQuery.length > 0 ? (
                      <span>
                        Add{` `}
                        <strong>{this.state.searchQuery}</strong>
                      </span>
                    ) : (
                      `Add missing arrangement`
                    )}
                  </Button>
                </div>
              )}
            </Content>
          </Panel>
        )}
      </GetArrangements>
    )
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.searchInput.current.focus()
      this.searchInput.current.inputRef.select()
    }, 0)
  }

  componentDidUpdate = previousProps => {
    if (
      this.props.location.pathname === this.props.match.url &&
      previousProps.location.pathname !== this.props.match.url
    ) {
      setTimeout(() => {
        this.searchInput.current.focus()
        this.searchInput.current.inputRef.select()
      }, 0)
    }
  }
}
