export default (names = {}, fieldName, value) => {
  try {
    const result = names[fieldName]
    return !result
      ? value || fieldName
      : typeof result === `object`
      ? result[value]
      : result
  } catch (error) {
    return value || fieldName
  }
}
