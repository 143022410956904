import React from "react"
import styled, { keyframes } from "styled-components"
import { fromEvent, of, animationFrameScheduler, merge } from "rxjs"
import {
  concatMap,
  takeUntil,
  map,
  distinctUntilChanged,
  withLatestFrom,
  observeOn,
  filter,
  bufferTime,
} from "rxjs/operators"
import { PanelsConsumer } from "./FlexiblePanels"

const Title = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: white;
`

const Close = styled.a`
  width: 1rem;
  height: 1rem;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 1em;
    height: 2px;
    background: white;
    transform: translateY(-50%) rotate(45deg);
  }

  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 1em;
    height: 2px;
    background: white;
    transform: translateY(-50%) rotate(-45deg);
  }
`

const titleBarKeyframes = keyframes`
0% {
  opacity: 0;
}
50% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`

const TitleBar = styled.div`
  width: 100%;
  min-height: 40px;
  background: ${ props =>
    props.active ? `rgb(61, 181, 239)` : `rgba(61, 181, 239, .61)` };
  border-right: 1px solid
    ${ props => (props.active ? `rgb(57, 165, 217)` : `rgba(61, 181, 239, .61)`) };
  border-left: 1px solid
    ${ props => (props.active ? `rgb(61, 181, 239)` : `rgba(61, 181, 239, .61)`) };
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  user-select: none;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    display: ${ props => (props.loading ? `block` : `none`) };
    box-shadow: 0 0 30px
        ${ props =>
          props.active ? `rgb(110, 255, 255)` : `rgba(110, 255, 255, .8)` },
      0 0 18px
        ${ props =>
          props.active ? `rgb(110, 255, 255)` : `rgba(110, 255, 255, .8)` },
      0 0 10px
        ${ props =>
          props.active ? `rgb(110, 255, 255)` : `rgba(110, 255, 255, .8)` },
      0 0 3px
        ${ props =>
          props.active ? `rgb(110, 255, 255)` : `rgba(110, 255, 255, .8)` };
    opacity: 0;
    animation: 1s ${ titleBarKeyframes } linear infinite;
  }
`

const ListItem = styled.li`
  background: ${ props => (props.active ? `rgb(243, 243, 243)` : `transparent`) };
  color: ${ props =>
    props.active ? `rgba(0, 0, 0, 0.85)` : `rgba(0, 0, 0, 0.6)` };
  list-style: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${ props =>
    props.active ? `transparent` : `rgba(0, 0, 0, 0.04)` };
  display: flex;
  align-items: center;
  padding: 13px 4rem 13px 1rem;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;

  :hover {
    background: ${ props =>
      props.active ? `rgb(243, 243, 243)` : `rgb(250, 250, 250)` };
  }

  & > * {
    display: block;
    padding: 13px 4rem 13px 1rem;
    margin: -13px -4rem -13px -1rem;
    color: ${ props =>
      props.active ? `rgba(0, 0, 0, 0.85)` : `rgba(0, 0, 0, 0.6)` };
  }

  & > *:hover {
    color: rgba(0, 0, 0, 0.75);
  }
`

const Menu = styled.ul`
  margin: 0;
  padding: 0;
  background-color: rgb(253, 253, 254);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  user-select: none;
`

Menu.Item = ListItem

const ResizeHandle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 100%;
  background: transparent;
  cursor: ew-resize;
  z-index: 10;
`

const Content = styled.div`
  min-width: 576px;
  width: ${ props => (props.initialWidth ? props.initialWidth : `576px`) };
  max-width: ${ props => props.maxWidth || `auto` };
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`

class ContentContainer extends React.Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef()
    this.resizeHandleRef = React.createRef()
  }

  render = () => (
    <PanelsConsumer>
      {panels => (
        <Content
          ref={this.ref}
          initialWidth={
            this.props.initialWidth === `max`
              ? `${ panels.width }px`
              : this.props.initialWidth
          }
          maxWidth={`${ panels.width }px`}
        >
          <ResizeHandle ref={this.resizeHandleRef} />
          {this.props.children}
        </Content>
      )}
    </PanelsConsumer>
  )

  componentDidMount = () => {
    const options = {
      passive: true,
    }

    const start$ = merge(
      fromEvent(this.resizeHandleRef.current, `mousedown`, options),
      fromEvent(this.resizeHandleRef.current, `touchstart`, options)
    )

    const end$ = merge(
      fromEvent(this.resizeHandleRef.current, `mouseup`, options),
      fromEvent(this.resizeHandleRef.current, `touchend`, options),
      fromEvent(window, `mouseup`, options),
      fromEvent(window, `touchend`, options)
    )

    const move$ = merge(
      fromEvent(window, `mousemove`, options),
      fromEvent(window, `touchmove`, options)
    )

    const resize$ = start$.pipe(
      concatMap(start =>
        move$.pipe(
          map(move =>
            move.pageX < start.pageX
              ? 0 - start.pageX + move.pageX
              : move.pageX - start.pageX
          ),
          distinctUntilChanged(),
          takeUntil(end$),
          withLatestFrom(
            of({
              DOMRect: this.ref.current.getBoundingClientRect(),
              computedStyle: window.getComputedStyle(this.ref.current),
            }).pipe(
              map(({ DOMRect, computedStyle }) => ({
                width: DOMRect.width,
                minWidth: parseFloat(
                  computedStyle.getPropertyValue(`min-width`),
                  10
                ),
                maxWidth: parseFloat(
                  computedStyle.getPropertyValue(`max-width`),
                  10
                ),
              }))
            )
          )
        )
      ),
      map(
        ([
          distance,
          { width, minWidth, maxWidth = Number.POSITIVE_INFINITY },
        ]) => {
          const targetWidth =
            Math.sign(distance) === 1 ? width + distance : distance + width

          return targetWidth < minWidth
            ? minWidth
            : targetWidth > maxWidth
            ? maxWidth
            : targetWidth
        }
      ),
      distinctUntilChanged(),
      observeOn(animationFrameScheduler)
    )

    this.resizeSubscription = resize$.subscribe(width => {
      this.ref.current.style.width = `${ width }px`
    })

    this.userSelectSubscription = merge(
      start$.pipe(map(() => `none`)),
      end$.pipe(map(() => `auto`))
    ).subscribe(value => {
      this.ref.current.style.userSelect = value
    })

    this.doubleClickSubscription = start$
      .pipe(
        bufferTime(800),
        filter(val => val.length >= 2),
        map(() => {
          const computedStyle = window.getComputedStyle(this.ref.current)

          return [ `min-width`, `max-width`, `width` ].map(value =>
            parseFloat(computedStyle.getPropertyValue(value), 10)
          )
        }),
        observeOn(animationFrameScheduler)
      )
      .subscribe(([ minWidth, maxWidth, width ]) => {
        if (maxWidth - 30 < width) {
          this.ref.current.style.width = `${ minWidth }px`
        } else {
          this.ref.current.style.width = `${ maxWidth }px`
        }
      })
  }

  componentWillUnmount = () => {
    this.resizeSubscription.unsubscribe()
    this.userSelectSubscription.unsubscribe()
    this.doubleClickSubscription.unsubscribe()
  }
}

const Body = styled.div`
  background-color: rgb(253, 253, 254);
  border-right: 1px solid rgb(235, 235, 235);
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export { Menu, ContentContainer as Content }

class Panel extends React.Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef()
  }

  render = () => (
    <Container ref={this.ref}>
      <TitleBar active={this.props.active} loading={this.props.loading}>
        <Title>{this.props.title || ``}</Title>
        {this.props.onClose && (
          <Close
            onClick={event => {
              event.preventDefault()
              this.props.onClose(event)
            }}
          />
        )}
      </TitleBar>
      <Body>{this.props.children}</Body>
    </Container>
  )

  componentDidMount = () => {
    this.props.panels.element.scrollLeft =
      this.ref.current.getBoundingClientRect().x -
      this.props.panels.element.getBoundingClientRect().x
  }
}

export default props => (
  <PanelsConsumer>
    {panels => <Panel panels={panels} {...props} />}
  </PanelsConsumer>
)
