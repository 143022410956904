import React from "react"
import GetAudiotracks from "../api/containers/GetAudiotracks"
import { Loader, Dropdown, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"
import "./Audiotracks.css"
import { CommandBar, Command, Content } from "../Panel"

const AudiotracksListItem = ({
  audiotrack,
  urlBase,
  disabled,
  history,
  active,
}) => (
  <div
    className={`wav-audiotracks-list-row ${
      active ? `wav-audiotracks-list-row-active` : ``
    }`}
    style={disabled ? { pointerEvents: `none`, opacity: `.6` } : {}}
  >
    <Link to={`${ urlBase }/${ audiotrack.id }`} style={{ flexGrow: 1 }}>
      <div className="wav-audiotracks-list-item-name">
        {disabled ? (
          <React.Fragment>
            {audiotrack.name} <small>(Encoding...)</small>
          </React.Fragment>
        ) : (
          audiotrack.name
        )}
        <div>{audiotrack.recordingType}</div>
      </div>
    </Link>
    <Dropdown
      icon={null}
      pointing="top right"
      trigger={<Icon name="ellipsis horizontal" />}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          content="Upload audio"
          onClick={() => history.push(`${ urlBase }/${ audiotrack.id }/upload`)}
        />
      </Dropdown.Menu>
    </Dropdown>
  </div>
)

const AudiotracksList = ({
  audiotracks,
  jobs,
  location,
  disabled,
  ...props
}) => (
  <Content disabled={disabled}>
    {audiotracks.map(audiotrack => (
      <AudiotracksListItem
        key={audiotrack.id}
        audiotrack={audiotrack}
        disabled={
          jobs.filter(
            ({ metadata: { audioTrackId } }) => audioTrackId === audiotrack.id
          ).length > 0
        }
        active={location.pathname.indexOf(audiotrack.id) > -1}
        {...props}
      />
    ))}
  </Content>
)

export default ({
  arrangementId,
  versionId,
  match,
  history,
  jobs,
  location,
  version: { status },
}) => (
  <GetAudiotracks arrangementId={arrangementId} versionId={versionId}>
    {({ mode, payload }) => (
      <React.Fragment>
        {mode === `loading` && (
          <Content>
            <Loader active inline="centered" content="Loading audiotracks" />
          </Content>
        )}
        {mode === `success` && (
          <React.Fragment>
            <CommandBar>
              <Command
                onClick={() => {
                  history.push(`${ match.url }/new`)
                }}
                disabled={status !== `Draft`}
              >
                <span>
                  <Icon name="add" />
                  {` `} Add audio track
                </span>
              </Command>
            </CommandBar>
            <AudiotracksList
              audiotracks={payload}
              urlBase={match.url}
              jobs={jobs.running.filter(
                ({ type }) => type === `MediaProcessing`
              )}
              history={history}
              location={location}
              disabled={status !== `Draft`}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )}
  </GetAudiotracks>
)
