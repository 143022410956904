import React, { Component } from "react"
import { fromEvent, timer, Subject, merge, from } from "rxjs"
import { filter, switchMap } from "rxjs/operators"
import { root } from "../../services/Globals"
import { Subscribe } from "unstated"
import { ActivityContainer } from "../../ActivityContainer"

class Jobs extends Component {
  constructor(props) {
    super(props)

    this.refresh$ = new Subject()

    const pageVisible$ = fromEvent(document, `visibilitychange`).pipe(
      filter(event => document.visibilityState === `visible`)
    )

    this.jobs$ = merge(timer(0, 3000), this.refresh$, pageVisible$).pipe(
      filter(() => document.visibilityState === `visible`),
      switchMap(() =>
        from(
          root.wav
            .api({ url: `/jobs`, suppressError: true })
            .then(({ data }) => data)
          // Promise.resolve([
          //   {
          //     name: `Backoffice - ScoreGenerating job`,
          //     type: `ScoreGenerating`,
          //     status: `Running`,
          //     message: null,
          //     progress: 44,
          //     metadata: {
          //       arrangementVersionId: `f91c0073-2039-4f40-bff8-ef2c4a1facaf`
          //     },
          //     createdBy: {
          //       id: `6ba6d518-c2a3-42af-97f4-7315b8b82dac`,
          //       displayName: `roger`
          //     },
          //     id: `c73b0437-17d5-4c71-9086-e7c172aa5d27`,
          //     createdUtc: `2018-01-25T08:58:14.3818191Z`,
          //     updatedUtc: `2018-01-25T08:58:14.3818191Z`
          //   },
          //   {
          //     name: `Backoffice - MediaProcessing job`,
          //     type: `MediaProcessing`,
          //     status: `Running`,
          //     message: null,
          //     progress: 0,
          //     metadata: {
          //       audioTrackId: `b2958394-2f14-4669-a2a1-c61f08739669`,
          //       arrangementVersionId: `f91c0073-2039-4f40-bff8-ef2c4a1facaf`
          //     },
          //     createdBy: {
          //       id: `6ba6d518-c2a3-42af-97f4-7315b8b82dac`,
          //       displayName: `roger`
          //     },
          //     id: `327d5678-e5f5-4777-afe2-2d5ac0879b5f`,
          //     createdUtc: `2018-01-25T09:02:03.9407022Z`,
          //     updatedUtc: `2018-01-25T09:02:03.9407022Z`
          //   }
          // ])
        )
      )
    )

    this.state = {
      running: [],
    }
  }

  render = () => {
    const { state, refresh } = this
    return this.props.children({
      ...state,
      running: this.props.arrangementVersionId
        ? state.running.filter(
            ({ metadata: { arrangementVersionId } }) =>
              arrangementVersionId === this.props.arrangementVersionId
          )
        : state.running,
      refresh,
    })
  }

  componentDidMount = () => {
    this.intervalSubscription = this.jobs$.subscribe(jobs => {
      this.setState({
        running: jobs,
      })
    })
  }

  componentWillUnmount = () => {
    this.intervalSubscription.unsubscribe()
    this.state.running.forEach(job => {
      this.props.activities.remove(job)
    })
  }

  refresh = () => {
    this.refresh$.next()
  }
}

const WrappedJobs = props => (
  <Subscribe to={[ ActivityContainer ]}>
    {activities => <Jobs activities={activities} {...props} />}
  </Subscribe>
)

const withJobs = Component => props => (
  <WrappedJobs>{jobs => <Component {...props} {...{ jobs }} />}</WrappedJobs>
)

export default WrappedJobs

export { withJobs }
