import notifyListeners from "./notify-listeners"
import status from "./status"

const getUtcTime = () => new Date(Date.now()).toISOString()

const generateActivityId = () =>
  `activity-${ Math.random()
    .toString(36)
    .substring(2, 8) }`

const createActivity = (state = {}, activity, listeners = []) => {
  const createdUtc = getUtcTime()

  Object.assign(state, {
    id: generateActivityId(),
    status: status.Created,
    metadata: {
      ...(state.metadata ? state.metadata : {}),
    },
    createdUtc: createdUtc,
    updatedUtc: createdUtc,
  })

  let cancelActivity = undefined

  const activityApi = {
    // ProgressEvent API
    onProgress: ({ loaded, total, lengthComputable }) => {
      state = {
        ...state,
        progress: lengthComputable ? Math.ceil(100 * (loaded / total)) : null,
        updatedUtc: getUtcTime(),
      }

      notifyListeners(listeners, state)
    },

    cancel: cancel => {
      cancelActivity = cancel
    },

    resolve: data => {
      state = {
        ...state,
        status: status.Completed,
        ...(data ? { data } : {}),
        metadata: {
          ...state.metadata,
          ...(data || {}).metadata,
        },
        updatedUtc: getUtcTime(),
      }

      notifyListeners(listeners, state)

      return Promise.resolve(data)
    },

    reject: error => {
      state = {
        ...state,
        status: status.CompletedWithError,
        data: error,
        updatedUtc: getUtcTime(),
      }

      notifyListeners(listeners, state)

      listeners = null

      return Promise.reject(error)
    },
  }

  let promise = undefined

  return {
    id: state.id,

    start: () => {
      try {
        if (!promise) {
          promise = activity(activityApi)

          state = {
            ...state,
            status: status.Running,
          }
        }

        return promise
      } catch (error) {
        state = {
          ...state,
          status: status.Error,
        }

        return Promise.reject(error)
      }
    },

    getState: () => state,

    subscribe: listener => {
      listeners.push(listener)
      listener(state)
      return () => {
        listeners = listeners.filter(l => l !== listener)
        return listener
      }
    },

    cancel: () => {
      if (cancelActivity) {
        cancelActivity()

        state = {
          ...state,
          status: status.Canceled,
          updatedUtc: getUtcTime(),
        }

        notifyListeners(listeners, state)

        listeners = null

        return true
      } else {
        return false
      }
    },
  }
}

export default createActivity
