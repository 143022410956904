import React from "react"
import { Dropdown, Flag } from "semantic-ui-react"
import availableLocales from "./available-locales"

export default props => (
  <Dropdown
    selection
    options={availableLocales.map(locale => ({
      text: locale,
      value: locale,
      content: (
        <span>
          <Flag name={locale.slice(locale.indexOf(`-`) + 1).toLowerCase()} />
          {locale}
        </span>
      ),
    }))}
    {...props}
  />
)
