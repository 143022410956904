import React from "react"
import { root } from "./services/Globals"
import { Subscribe } from "unstated"
import { ServiceWorkerContainer } from "./ServiceWorkerContainer"

export default () => (
  <Subscribe to={[ ServiceWorkerContainer ]}>
    {({ state: { updateAvailable } }) => {
      if (updateAvailable) {
        const updateNotification = new root.wav.Notification({
          text: `<h4 class="ui header"><div class="content">Application update available, reload to use.</div></h4>`,
          buttons: [
            root.wav.Notification.button(
              `Reload now`,
              `ui compact button`,
              () => {
                window.location.reload()
              }
            ),
            root.wav.Notification.button(`Close`, `ui compact button`, () => {
              updateNotification.close()
            }),
          ],
          theme: `semanticui`,
          modal: true,
        }).show()
      }
      return null
    }}
  </Subscribe>
)
