import React, { Component } from "react"
import Auth0 from "auth0-lock"
import propTypes from "prop-types"

class Lock extends Component {
  constructor(props) {
    super(props)

    props.clientID && sessionStorage.setItem(`auth0.clientID`, props.clientID)
    props.domain && sessionStorage.setItem(`auth0.domain`, props.domain)

    const clientID = sessionStorage[`auth0.clientID`]
    const domain = sessionStorage[`auth0.domain`]

    this.lock = new Auth0(clientID, domain, {
      ...(this.props.options || {}),
    })

    const previousAuthResult =
      (localStorage[`auth0.authResult`] &&
        JSON.parse(localStorage[`auth0.authResult`])) ||
      undefined

    previousAuthResult &&
      Date.now() > previousAuthResult.idTokenPayload.exp * 1000 &&
      this.logout()

    this.state = {
      authResult: previousAuthResult,
      isAuthenticated: !!previousAuthResult,
      lock: this.lock,
      show: this.show,
      logout: this.logout,
    }
  }

  getChildContext = () => {
    return {
      lock: this.state,
    }
  }

  render = () => {
    return typeof this.props.children === `function`
      ? this.props.children(this.state)
      : this.props.children
  }

  componentDidMount = () => {
    this.lock.on(`authenticated`, authResult => {
      localStorage.setItem(
        `auth0.authResult`,
        JSON.stringify({
          ...authResult,
        })
      )

      this.lock.hide()

      this.setState(previousState => ({
        ...previousState,
        isAuthenticated: true,
        authResult: {
          ...authResult,
        },
      }))
    })
  }

  show = (options = {}) => {
    this.lock.show(options)
    return null
  }

  logout = (options = {}) => {
    Object.keys(localStorage).forEach(key => {
      ;/auth0/g.test(key) && localStorage.removeItem(key)
    })
    this.lock.logout({ returnTo: window.location.origin, ...options })
  }
}

Lock.childContextTypes = {
  lock: propTypes.object,
}

export default Lock

const ConnectLock = (props, context) => props.children(context.lock)

ConnectLock.contextTypes = {
  lock: propTypes.object,
}

export const withLock = Component => props => (
  <ConnectLock>{lock => <Component {...props} lock={lock} />}</ConnectLock>
)

export class UserInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userInfo:
        sessionStorage[`auth0.userInfo`] &&
        JSON.parse(sessionStorage[`auth0.userInfo`]),
      error: undefined,
    }
  }

  render = () => {
    return this.props.children(this.state)
  }

  componentDidMount = () => {
    if (!this.state.userInfo) {
      const {
        lock,
        authResult: { accessToken },
      } = this.props

      lock.getUserInfo(accessToken, (error, userInfo) => {
        if (!error) {
          sessionStorage.setItem(`auth0.userInfo`, JSON.stringify(userInfo))
          this.setState({
            userInfo,
            error: undefined,
          })
        } else {
          this.setState({
            userInfo: undefined,
            error,
          })
        }
      })
    }
  }
}
