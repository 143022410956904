import { Component } from "react"
import dispatchEvent from "../../helpers/dispatch-event"
import { root } from "../../services/Globals"

const noop = () => {}

export default class PutPlayMode extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `idle`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
      update,
    } = this

    return this.props.children({
      mode,
      payload,
      update,
    })
  }

  update = options => {
    const {
      data: { id, ...playMode },
      arrangementId,
      versionId,
      onSuccess = noop,
      onError = noop,
      onLoading = noop,
    } = options || this.props

    this.setState(
      previousState => ({
        ...previousState,
        mode: `loading`,
      }),
      onLoading
    )

    return root.wav
      .api({
        method: `put`,
        url: `/arrangements/${ arrangementId }/versions/${ versionId }/playmodes/${ id }`,
        data: {
          ...Object.keys(playMode).reduce(
            (data, key) =>
              playMode[key] ? { ...data, [key]: playMode[key] } : data,
            {}
          ),
        },
      })
      .then(({ data }) => {
        onSuccess(data)
        // dispatchEvent(`update:arrangement`)
        dispatchEvent(`update:${ arrangementId }:playModes`)
        dispatchEvent(`update:${ versionId }:playModes`)
        dispatchEvent(`update:${ id }`)
        this.setState(previousState => ({
          ...previousState,
          mode: `success`,
          payload: data,
        }))
      })
      .catch(error => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `error`,
            payload: error,
          }),
          () => onError(error)
        )
      })
  }
}
