import React, { Component } from "react"
import { Content } from "../Panel"
import { Subscribe } from "unstated"
import { UserContainer } from "./UserContainer"
import JSONTree from "react-json-tree"

class Auth0 extends Component {
  render = () => {
    return (
      <Subscribe to={[ UserContainer ]}>
        {userStore => (
          <Content loading={userStore.state.isFetching}>
            <JSONTree
              data={userStore.state.auth0}
              theme={{
                scheme: `chalk`,
                author: `chris kempson (http://chriskempson.com)`,
                base00: `#151515`,
                base01: `#202020`,
                base02: `#303030`,
                base03: `#505050`,
                base04: `#b0b0b0`,
                base05: `#d0d0d0`,
                base06: `#e0e0e0`,
                base07: `#f5f5f5`,
                base08: `#fb9fb1`,
                base09: `#eda987`,
                base0A: `#ddb26f`,
                base0B: `#acc267`,
                base0C: `#12cfc0`,
                base0D: `#6fc2ef`,
                base0E: `#e1a3ee`,
                base0F: `#deaf8f`,
              }}
            />
          </Content>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    this.props.userStore.fetchAuth0(this.props.match.params.userId)
  }
}

export default props => (
  <Subscribe to={[ UserContainer ]}>
    {userStore => <Auth0 userStore={userStore} {...props} />}
  </Subscribe>
)
