import React from "react"
import styled from "styled-components"
import { Subscribe } from "unstated"
import { SidebarContainer } from "./Sidebar/SidebarContainer"
import { fromEvent, merge, of } from "rxjs"
import { bufferTime } from "rxjs/operators"

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: rgb(245, 246, 250);
`

const Panels = styled.div`
  display: flex;
  align-content: stretch;
`

const { Provider, Consumer } = React.createContext()

class PanelsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.panelsRef = React.createRef()

    this.state = {
      width: undefined,
    }
  }

  render = () => (
    <Container ref={this.panelsRef}>
      <Provider
        value={{
          element: this.panelsRef.current,
          ...this.state,
        }}
      >
        <Subscribe to={[ SidebarContainer ]}>
          {sidebar => (
            <Panels
              style={{ paddingRight: `${ sidebar.state.metrics.width }px` }}
            >
              {this.state.width && this.props.children}
            </Panels>
          )}
        </Subscribe>
      </Provider>
    </Container>
  )

  componentDidMount = () => {
    this.updateWidth()

    this.windowResizeSubscription = merge(
      of(),
      fromEvent(window, `resize`, { passive: true }).pipe(bufferTime(125))
    ).subscribe(this.updateWidth)
  }

  componentWillUnmount = () => {
    this.windowResizeSubscription.unsubscribe()
  }

  updateWidth = () => {
    this.setState({
      width: this.panelsRef.current.getBoundingClientRect().width,
    })
  }
}

export { Consumer as PanelsConsumer }

export default PanelsContainer
