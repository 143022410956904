import { Component } from "react"
import root from "window-or-global"

export { root }

export default class Globals extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      result: undefined,
    }

    if (props.namespace) {
      root[props.namespace] = {}
      this.root = root[props.namespace]
    } else {
      this.root = root
    }

    this.promise = Promise.all(
      (props.attach || []).map(work => work(this.root))
    )
  }

  render = () => {
    const { children } = this.props
    return typeof children === `function`
      ? children(this.state)
      : this.state.result
      ? children
      : null
  }

  componentDidMount = () => {
    Promise.resolve(this.promise)
      .then(result => {
        this.setState({
          error: undefined,
          result,
        })
      })
      .catch(error => {
        this.setState({
          error,
          result: undefined,
        })
      })
  }
}
