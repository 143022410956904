import { Container } from "unstated"
import { root } from "../services/Globals"
import { Subject, from } from "rxjs"
import { tap, map, switchMap, debounceTime } from "rxjs/operators"

const initialState = {
  isFetching: false,
  data: [],
  filters: {
    search: undefined,
    limit: 15,
    sort: undefined,
  },
  next: undefined,
}

class UsersContainer extends Container {
  constructor() {
    super()
    this.state = { ...initialState }

    this.fetch$ = new Subject().pipe(
      tap(() => this.setState({ isFetching: true })),
      map(() => ({ ...this.state })),
      map(state =>
        state.next
          ? {
              url: state.next,
            }
          : {
              url: `/users`,
              params: {
                ...Object.keys(state.filters).reduce(
                  (params, filterName) =>
                    state.filters[filterName]
                      ? {
                          ...params,
                          [filterName]: state.filters[filterName],
                        }
                      : params,
                  {}
                ),
              },
            }
      ),
      switchMap(configuration => from(root.wav.api(configuration))),
      map(({ data }) => data)
    )

    this.fetch$.subscribe(data => {
      this.setState({
        isFetching: false,
        data: this.state.next
          ? [ ...this.state.data, ...data.items ]
          : data.items,
        next: data.next ? data.next : undefined,
      })
    })

    this.search$ = new Subject().pipe(debounceTime(200))

    this.search$.subscribe(() => this.fetch$.next())
  }

  fetch = () => this.fetch$.next()

  filter = filters => {
    this.setState({
      ...initialState,
      filters: {
        ...this.state.filters,
        ...filters,
      },
    })
    this.search$.next()
  }

  removeUser = id => {
    this.setState({
      ...this.state,
      data: [ ...this.state.data.filter(user => user.id !== id) ],
    })
  }

  resetState = () => {
    this.setState({ ...initialState })
  }
}

export { UsersContainer }
