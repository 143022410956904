import React from "react"
import { Content, CommandBar, Command } from "../Panel"
import { Form, Header } from "semantic-ui-react"
import PutVersion from "../api/containers/PutVersion"
import isEqual from "deep-equal"

export default class Notes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: undefined,
    }
  }

  render = () => {
    return (
      <PutVersion
        arrangementId={this.props.arrangementId}
        data={{
          ...this.props.version,
          notes: this.state.notes,
        }}
      >
        {version => (
          <React.Fragment>
            <CommandBar>
              <Command
                save
                disabled={
                  isEqual(this.state.notes, this.props.version.notes) ||
                  version.mode === `loading`
                }
                onClick={() => version.update()}
              />
            </CommandBar>
            <Content loading={this.props.loading || version.mode === `loading`}>
              <Header style={{ marginTop: 0 }}>Notes</Header>
              <Form>
                <Form.TextArea
                  name="notes"
                  value={this.state.notes || ``}
                  onChange={this.handleChange}
                  autoHeight
                  rows={10}
                />
              </Form>
            </Content>
          </React.Fragment>
        )}
      </PutVersion>
    )
  }

  componentDidMount = () => {
    this.setState({
      notes: this.props.version.notes,
    })
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ notes: value })
  }
}
