import React from "react"
import MissingImageRect from "./images/MissingImageRect.svg"
import NoImageRect from "./images/NoImageRect.svg"

export default ({ src, alt, width, style = {}, ...props }) => {
  const attributes = {
    src: `${ src || NoImageRect }${ width ? `?width=${ width }` : `` }`,
    alt: alt || ``,
    style: {
      ...(width ? { width: `${ width }px` } : {}),
      ...(!src ? { height: `${ width }px` } : {}),
      ...style,
    },
    onError: event => {
      event.target.src = MissingImageRect
    },
    ...props,
  }

  return <img alt={attributes.alt} {...attributes} />
}
