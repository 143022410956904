import Noty from "noty"
import "../../node_modules/noty/lib/noty.css"
import "../../node_modules/noty/lib/themes/semanticui.css"

export default root =>
  new Promise((resolve, reject) => {
    try {
      root.Notification = Noty
      resolve(root.Notification)
    } catch (error) {
      reject(error)
    }
  })
