import React from "react"
import "./ArrangementPreview.css"
import Img from "../Img"

export default ({
  data: { version, image, title },
  onClick,
  hideDescription,
}) => (
  <div className="wav-arrangement" onClick={onClick}>
    <Img className="wav-arrangement-image"src={image && `${ image.url }?width=95`} />
    <div className="wav-arrangement-info">
      <div className="wav-arrangement-title">
        {title}
      </div>
      {!hideDescription && (
        <div className="wav-arrangement-description">
          <div className="wav-arrangement-description-status">
            {(version && version.status) || '-'}
          </div>
        </div>
      )}
    </div>
  </div>
)
