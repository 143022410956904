import { Container } from "unstated"
import { root } from "../services/Globals"
import { Subject, from } from "rxjs"
import { tap, map, switchMap, debounceTime } from "rxjs/operators"
import isEqual from "deep-equal"
import queryString from "query-string"
import get from "lodash.get"

const initialState = {
  isFetching: false,
  data: [],
  filters: {
    status: `InProgress`,
    limit: 50,
  },
  next: undefined,
}

class OrdersContainer extends Container {
  constructor() {
    super()
    this.state = {
      ...initialState,
      filters: {
        ...initialState.filters,
        status: get(
          queryString.parse(window.location.search),
          `status`,
          `InProgress`
        ),
      },
    }

    this.fetch$ = new Subject().pipe(
      tap(() => this.setState({ isFetching: true })),
      map(() => ({ ...this.state })),
      map(state =>
        state.next
          ? {
              url: state.next,
            }
          : {
              url: `/orders`,
              params: {
                ...Object.keys(state.filters).reduce(
                  (params, filterName) =>
                    state.filters[filterName]
                      ? {
                          ...params,
                          [filterName]: state.filters[filterName],
                        }
                      : params,
                  {}
                ),
              },
            }
      ),
      switchMap(configuration => from(root.wav.api(configuration)))
    )

    this.fetch$.subscribe(
      ({ data }) => {
        this.setState(previousState => ({
          ...previousState,
          isFetching: false,
          data: previousState.next
            ? [ ...previousState.data, ...data.items ]
            : data.items,
          next: data.next ? data.next : undefined,
        }))
      },
      error => process.env.NODE_ENV === `development` && console.error(error)
    )

    this.search$ = new Subject().pipe(debounceTime(200))

    this.search$.subscribe(() => this.fetch$.next())

    // this.fetch$.next()
  }

  fetch = () => {
    process.env.NODE_ENV === `development` &&
      console.log(`OrdersContainer:fetch`)

    this.fetch$.next()
  }

  filter = filters => {
    this.setState({
      ...initialState,
      filters: {
        ...this.state.filters,
        ...filters,
      },
    })
    this.search$.next()
  }

  removeCampaignFromOrders = id => {
    this.setState({
      ...this.state,
      data: [ ...this.state.data.filter(order => order.id !== id) ],
    })
  }

  updateCampaignInOrders = ({ id, ...data }) => {
    const newState = {
      ...this.state,
      data: this.state.data.map(order =>
        order.id === id ? { id, ...data } : order
      ),
    }

    if (isEqual(this.state, newState)) {
      this.fetch()
    } else {
      this.setState(newState)
    }
  }

  resetState = () => {
    this.setState({ ...initialState })
  }
}

export { OrdersContainer }
