// Created  = 1,
// Running = 2,
// Completed = 4,
// CompletedWithError = 8

export default {
  Created: `Created`,
  Running: `Running`,
  Completed: `Completed`,
  CompletedWithError: `CompletedWithError`,
  Canceled: `Canceled`,
  Error: `Error`,
}
