import React, { Component } from "react"
import { root } from "../../services/Globals"

export default class Validate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: undefined,
      loading: false,
    }
  }

  render = () => {
    return this.props.children({
      ...this.state,
      validate: this.validate,
    })
  }

  componentDidMount = () => {
    this.validate()
  }

  componentWillReceiveProps = nextProps => {
    ;(nextProps.arrangementId !== this.props.arrangementId ||
      nextProps.versionId !== this.props.versionId) &&
      this.validate()
  }

  validate = () => {
    this.setState(
      {
        data: undefined,
        loading: true,
      },
      () => {
        root.wav
          .api({
            method: `post`,
            url: `/arrangements/${ this.props.arrangementId }/versions/${
              this.props.versionId
            }/validate`,
          })
          .then(({ data }) => {
            this.setState({ data, loading: false })
          })
      }
    )
  }
}

export const withValidation = Component => props => (
  <Validate
    arrangementId={props.match.params.arrangementId}
    versionId={props.match.params.versionId}
  >
    {validation => <Component {...props} validation={validation} />}
  </Validate>
)
