import React from "react"
import styled from "styled-components"
import { Subscribe } from "unstated"
import { SidebarContainer } from "./SidebarContainer"

const Sidebar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  height: 100%;
  transform: translateX(${ props => (props.toggled ? `0%` : `100%`) });
  transition: transform ease-out 120ms;
  background: rgb(33, 33, 34);
  color: white;
  will-change: transform;
  width: 356px;
  overflow-y: scroll;

  /* Layering shadow */

  /* ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 2rem rgb(33, 33, 34);
    opacity: ${ props => (props.toggled ? 1 : 0) };
    transition: opacity 120ms;
    will-change: opacity;
  } */

  h1, h2 {
    font-family: inherit;
    font-size: 16px;
    line-height: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 400;
    letter-spacing: 0.006em;
    
    margin-bottom: 0;
    user-select: none;
  }
  
  h2 {
    font-family: inherit;
    font-size: 12px;
    line-height: 39px;
    font-weight: 400;
    letter-spacing: 0.02em;
    margin-left: 1.618rem;
    margin-right: 1.618rem;
    text-transform: uppercase;
  }
`

export default props => (
  <Subscribe to={[ SidebarContainer ]}>
    {sidebar => (
      <Sidebar toggled={sidebar.state.toggled} ref={sidebar.setPanelRef}>
        {(({ component: Component }) => Component && <Component {...props} />)(
          sidebar.state
        )}
      </Sidebar>
    )}
  </Subscribe>
)
