import React from "react"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { Form } from "semantic-ui-react"
import ImageAsset from "../ImageAsset"
import FocusElement from "../FocusElement"
import get from "lodash.get"
import SongwriterContainer from "./SongwriterContainer"
import Draft from "../Draft"
import merge from "lodash.merge"
import { push } from "../history"

export default ({ onCreate, onUpdate, onDelete, ...props }) => (
  <SongwriterContainer
    id={
      props.match.params.songwriterId !== `new` &&
      props.match.params.songwriterId
    }
    onCreate={onCreate}
    onUpdate={onUpdate}
    onDelete={onDelete}
  >
    {songwriter => (
      <Draft
        key={get(songwriter, `data.id`)}
        original={merge(
          {},
          {
            name: get(props, `location.state.name` || ``),
          },
          songwriter.data
        )}
      >
        {draft => (
          <Panel
            title={
              songwriter.loading
                ? `Loading songwriter...`
                : get(
                    songwriter,
                    `data.name`,
                    `Add: ${ get(draft, `state.name`, ``) }`
                  )
            }
            onClose={() => push(props.returnTo || `/songwriters`)}
          >
            <FocusElement>
              {({ elementRef, focusElement }) => (
                <React.Fragment>
                  <CommandBar>
                    {get(draft, `state.id`) && (
                      <Command.Delete onClick={songwriter.delete} />
                    )}
                    <Command
                      save
                      ref={elementRef}
                      disabled={
                        (get(draft, `state.id`) && !draft.dirty) ||
                        !get(draft, `state.name`)
                      }
                      onClick={() => songwriter.save(draft.state)}
                    />
                  </CommandBar>
                  <Content
                    loading={songwriter.loading}
                    loadingText={songwriter.loadingMessage}
                  >
                    <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
                      <Form.Input
                        required
                        name="name"
                        label="Name"
                        value={get(draft, `state.name`, ``)}
                        onChange={draft.handleChange}
                      />

                      <Form.TextArea
                        name="description"
                        label="Description"
                        value={get(draft, `state.description`, ``)}
                        onChange={draft.handleChange}
                        onKeyDown={focusElement}
                      />

                      <Form.Field>
                        <label>Image</label>
                        <ImageAsset
                          name="image"
                          initialValue={get(draft, `state.image.url`)}
                          onChange={draft.handleChange}
                        />
                      </Form.Field>

                      {get(draft, `state.id`) && (
                        <React.Fragment>
                          <Form.Field>
                            <label>Created</label>
                            <p>
                              {draft.state.createdUtc &&
                                new Date(draft.state.createdUtc).toLocaleString(
                                  navigator.language
                                )}
                            </p>
                          </Form.Field>
                          <Form.Field>
                            <label>Updated</label>
                            <p>
                              {draft.state.updatedUtc &&
                                new Date(draft.state.updatedUtc).toLocaleString(
                                  navigator.language
                                )}
                            </p>
                          </Form.Field>
                        </React.Fragment>
                      )}
                    </Form>
                  </Content>
                </React.Fragment>
              )}
            </FocusElement>
          </Panel>
        )}
      </Draft>
    )}
  </SongwriterContainer>
)
