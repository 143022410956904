import { Container } from "unstated"

class ServiceWorkerContainer extends Container {
  constructor(trigger) {
    super()
    this.state = {
      updateAvailable: false,
    }
    trigger.listen(() => this.setState({ updateAvailable: true }))
  }
}

export { ServiceWorkerContainer }
