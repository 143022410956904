import React, { Component } from "react"
import PropTypes from "prop-types"
import { root } from "./services/Globals"
import { Subject, merge, of, from } from "rxjs"
import {
  map,
  distinctUntilChanged,
  debounceTime,
  tap,
  switchMap,
} from "rxjs/operators"
import { Dropdown } from "semantic-ui-react"
import Img from "./Img"
import equal from "deep-equal"

const Item = ({ imageUrl, name }) => (
  <div style={{ display: `flex`, alignItems: `center` }}>
    <div style={{ marginRight: `1rem`, width: `45px` }}>
      <Img src={imageUrl} alt={name} width={45} />
    </div>
    <div>{name}</div>
  </div>
)

Item.displayName = `Item`

class Select extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false

    const search$ = new Subject().pipe(
      map(value => value.trim()),
      distinctUntilChanged(),
      debounceTime(100)
    )

    this.fetch$ = merge(of(``), search$).pipe(
      tap(() =>
        this.setState(previousState => ({
          ...previousState,
          loading: true,
          error: false,
        }))
      ),
      switchMap(search =>
        from(
          root.wav
            .api({
              url: this.props.url,
              ...(search.length > 0
                ? {
                    params: {
                      search,
                    },
                  }
                : {}),
            })
            .then(({ data }) => data)
        )
      ),
      tap(() =>
        this.setState(previousState => ({
          loading: false,
        }))
      )
    )

    this.search = (query = ``) => {
      search$.next(query)
    }

    this.elementRef = props.forwardedRef || React.createRef()

    this.state = this.getInitialState()
  }

  render = () => {
    const {
      state: { items, selectedItems, error, next, ...state },
      props: {
        onChange,
        onAddItem,
        value: propsValue,
        itemMapper,
        itemFilter,
        forwardedRef,
        ...props
      },
      handleSearchChange,
      handleOnChange,
      handleOnAddItem,
    } = this

    const options = [
      ...items
        .filter(itemFilter ? itemFilter : () => true)
        .map(itemMapper ? itemMapper : item => item)
        .map(item => ({
          key: item.id,
          text: item.name,
          value: item.id,
          ...(item.image
            ? {
                content: (
                  <Item
                    imageUrl={item.image && item.image.url}
                    name={item.name}
                  />
                ),
              }
            : {}),
        }))
        .concat(
          next
            ? {
                key: `next`,
                text: `Too many to display, start typing to initiate a search...`,
                disabled: true,
              }
            : []
        ),
    ]

    const value = Array.isArray(selectedItems)
      ? selectedItems.map(({ id }) => id)
      : selectedItems.id

    return (
      <Dropdown
        selection
        search
        deburr
        openOnFocus={false}
        selectOnBlur={false}
        selectOnNavigation={false}
        value={value}
        options={options}
        onSearchChange={handleSearchChange}
        onChange={handleOnChange}
        onAddItem={handleOnAddItem}
        error={!!error}
        {...state}
        {...props}
        ref={this.elementRef}
      />
    )
  }

  getInitialState = () => ({
    loading: false,
    searchQuery: ``,
    selectedItems: this.props.multiple
      ? this.props.value || []
      : this.props.value || { id: `` },
    items: [],
    error: false,
    next: false,
  })

  componentDidMount = () => {
    this._isMounted = true

    this.searchSubscription = this.fetch$.subscribe({
      next: ({ items, next }) =>
        this.setState(previousState => ({
          ...previousState,
          items: Object.values(
            [ ...items, ...previousState.items ].reduce(
              (all, item) => ({ ...all, [item.id]: item }),
              {}
            )
          ),
          next,
        })),
      error: error =>
        this.setState(previousState => ({
          ...previousState,
          loading: false,
          error: !!error,
        })),
    })

    this.props.value &&
      this.setState(
        previousState => ({
          ...previousState,
          selectedItems: this.props.multiple
            ? this.props.value || []
            : { id: this.props.value } || { id: `` },
          loading: true,
        }),
        () => {
          Promise.all(
            []
              .concat(this.state.selectedItems)
              .map(({ id }) => root.wav.api({ url: this.props.url + `/${ id }` }))
          )
            .then(responses => responses.map(({ data }) => data))
            .then(items => {
              this.setState(previousState => ({
                ...previousState,
                items: Object.values(
                  [ ...previousState.items, ...items ].reduce(
                    (all, item) => ({ ...all, [item.id]: item }),
                    {}
                  )
                ),
                loading: false,
              }))
            })
        }
      )
  }

  componentWillUnmount = () => {
    this._isMounted = false

    this.searchSubscription.unsubscribe()
  }

  componentDidUpdate = previousProps => {
    // if (nextProps.value && nextProps.value.length > 0) {
    if (
      !equal(previousProps.value, this.props.value) &&
      this.props.value &&
      this.props.value.length
    ) {
      this.setState(
        previousState => ({
          ...previousState,
          selectedItems: this.props.multiple
            ? this.props.value || []
            : { id: this.props.value } || { id: `` },
          loading: true,
        }),
        () => {
          Promise.all(
            []
              .concat(this.state.selectedItems)
              .map(({ id }) => root.wav.api({ url: this.props.url + `/${ id }` }))
          )
            .then(responses => responses.map(({ data }) => data))
            .then(items => {
              this.setState(previousState => ({
                ...previousState,
                items: Object.values(
                  [ ...previousState.items, ...items ].reduce(
                    (all, item) => ({ ...all, [item.id]: item }),
                    {}
                  )
                ),
                loading: false,
              }))
            })
        }
      )
    } else if (!!previousProps.value && this.props.value === undefined) {
      this.setState({
        ...this.getInitialState(),
      })
    }

    // } else {
    //   this.setState(previousState => ({
    //     ...this.getInitialState(),
    //     selectedItems: nextProps.multiple ? [] : { id: `` },
    //     items: previousState.items,
    //   }))
    // }
  }

  handleSearchChange = (event, { searchQuery }) => {
    this.setState(
      previousState => ({
        ...previousState,
        searchQuery,
      }),
      () => {
        this.search(searchQuery)
      }
    )
  }

  handleOnChange = (event, { value }) => {
    const selectedItems = Array.isArray(value)
      ? value.reduce((all, v) => {
          const item = this.state.items.filter(({ id }) => id === v)[0]
          return item ? all.concat(item) : all
        }, [])
      : this.state.items.filter(({ id }) => id === value)[0]

    selectedItems && this.props.onChange && this.props.onChange(selectedItems)

    this.setState(previousState => ({
      ...previousState,
      searchQuery: ``,
      selectedItems: selectedItems || previousState.selectedItems,
    }))
  }

  handleOnAddItem = (event, data) => {
    this.props.onAddItem && this.props.onAddItem(data)
    !this.props.multiple &&
      this.setState(previousState => ({
        ...previousState,
        selectedItems: ``,
      }))
    this.elementRef.current.close()
  }
}

Select.propTypes = { url: PropTypes.string.isRequired }

export default React.forwardRef((props, ref) => (
  <Select {...props} forwardedRef={ref} />
))
