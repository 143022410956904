import React from "react"
import styled from "styled-components"
import { Button, Popup, Checkbox } from "semantic-ui-react"
import { FIELDS } from "./constants"
import getFriendlyName from "./get-friendly-name"

const CheckboxContainer = styled.div`
  > div:not(:last-child) {
    margin-bottom: 0.4rem;
  }
`

export default ({ selected, selectedFields, update, ...props }) => (
  <Popup
    on="click"
    position="bottom left"
    trigger={<Button icon="columns" content="Columns" />}
    content={
      <CheckboxContainer>
        {Object.keys(FIELDS)
          .filter(
            name => !name.match(/Id$/) && name !== `id` && name !== `type`
          )
          .map(name => {
            const checked =
              selected === undefined ? true : (selected || ``).includes(name)

            return (
              <div
                key={name}
                onClick={event => {
                  const next = checked
                    ? selectedFields
                        .filter(fieldName => fieldName !== name)
                        .filter(
                          fieldName =>
                            !(
                              name.match(/.*Name$/) &&
                              fieldName ===
                                name.slice(0, name.indexOf(`Name`)) + `Id`
                            )
                        )
                    : selectedFields.concat(name)

                  update({
                    select: next
                      .reduce(
                        (acc, name) => {
                          return acc.concat(
                            !name.match(/.*Name$/)
                              ? name
                              : !next.includes(
                                  name.slice(0, name.indexOf(`Name`)) + `Id`
                                )
                              ? [
                                  name,
                                  name.slice(0, name.indexOf(`Name`)) + `Id`,
                                ]
                              : name
                          )
                        },
                        next.includes(`name`) && !next.includes(`arrangementId`)
                          ? [ `arrangementId` ]
                          : []
                      )
                      .join(`,`),
                    skip: 0,
                  })
                }}
              >
                <Checkbox
                  checked={checked}
                  label={getFriendlyName(FIELDS, name)}
                />
              </div>
            )
          })}
      </CheckboxContainer>
    }
  />
)
