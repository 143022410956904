import React, { Component } from "react"
import Panel, {
  Content,
  CommandBar,
  Command,
  PersistentSearch as Search,
} from "../Panel"
import { Item, Button, Label } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { UsersContainer } from "./UsersContainer"
import queryString from "query-string"
import Observer from "react-intersection-observer"

class Users extends Component {
  constructor(props) {
    super(props)
    this.searchInput = undefined
    this.state = {
      filters: {
        search: ``,
      },
    }
  }

  render = () => {
    return (
      <Subscribe to={[ UsersContainer ]}>
        {usersStore => (
          <Panel title="Users">
            <CommandBar>
              <Command fluid>
                <Search
                  value={this.state.filters.search}
                  onChange={this.handleSearch}
                  innerRef={element => (this.searchInput = element)}
                />
              </Command>
            </CommandBar>
            <Content
              style={{ padding: 0, margin: 0 }}
              loading={usersStore.state.isFetching}
              loadingText="Loading users.."
            >
              <Item.Group relaxed style={{ margin: 0 }}>
                {usersStore.state.data.map(item => (
                  <Item
                    key={item.id}
                    style={{
                      margin: 0,
                      marginBottom: `-1.5em`,
                      padding: `1.5em`,
                      minHeight: `122px`,
                      cursor: `pointer`,
                      background:
                        this.props.location.pathname.indexOf(item.id) !== -1
                          ? `rgb(240,240,240)`
                          : `transparent`,
                    }}
                    onClick={() => {
                      this.props.history.push(
                        this.props.match.path +
                          `/${ item.id }` +
                          this.props.location.search
                      )
                    }}
                  >
                    {item.profileImage ? (
                      <Item.Image
                        size="tiny"
                        src={item.profileImage.url + `?width=80`}
                        style={{
                          marginTop: `auto`,
                          marginBottom: `auto`,
                        }}
                      />
                    ) : (
                      <div
                        className="ui tiny image"
                        style={{
                          width: `80px`,
                          height: `80px`,
                          border: `dashed 3px rgb(140,140,140)`,
                        }}
                      />
                    )}
                    <Item.Content verticalAlign="middle">
                      <Item.Header>{item.displayName}</Item.Header>
                      <Item.Meta>
                        {new Date(item.createdUtc).toLocaleString(
                          navigator.language
                        )}
                      </Item.Meta>

                      <Item.Extra>
                        <Label
                          size="mini"
                          color={item.active ? `green` : ``}
                          content={item.active ? `Active` : `Inactive`}
                        />
                      </Item.Extra>
                    </Item.Content>
                  </Item>
                ))}
              </Item.Group>
              {usersStore.state.next && (
                <Observer
                  onChange={inView =>
                    inView && !usersStore.state.isFetching && usersStore.fetch()
                  }
                  rootMargin="610px"
                >
                  <div
                    style={{
                      width: `100%`,
                      display: `flex`,
                      justifyContent: `center`,
                      padding: `3em`,
                    }}
                  >
                    <Button
                      basic
                      content="Load more..."
                      onClick={usersStore.fetch}
                      loading={usersStore.state.isFetching}
                    />
                  </div>
                </Observer>
              )}
            </Content>
          </Panel>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    const { search } = queryString.parse(this.props.location.search)

    setTimeout(() => {
      search ? this.handleSearch(search) : this.props.usersStore.fetch()
    }, 0)

    this.searchInput.focus()
  }

  componentWillUnmount = () => {
    this.props.usersStore.resetState()
  }

  handleSearch = query => {
    this.setState(
      previousState => ({
        ...previousState,
        filters: {
          ...previousState.filters,
          search: query,
        },
      }),
      () => {
        this.props.usersStore.filter(this.state.filters)
      }
    )
  }
}

export default props => (
  <Subscribe to={[ UsersContainer ]}>
    {usersStore => <Users usersStore={usersStore} {...props} />}
  </Subscribe>
)
