import { Component } from "react"
import { root } from "../../services/Globals"

const noop = () => {}

export default class RetreiveSyncpoints extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `idle`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
      execute,
    } = this

    return this.props.children({
      mode,
      payload,
      execute,
    })
  }

  execute = () => {
    const {
      versionId,
      arrangementId,
      onSuccess = noop,
      onError = noop,
      onLoading = noop,
    } = this.props

    this.setState(
      previousState => ({
        ...previousState,
        mode: `loading`,
      }),
      onLoading
    )

    return root.wav
      .api({
        method: `post`,
        url: `/arrangements/${ arrangementId }/versions/${ versionId }/retrievesyncpoints`,
      })
      .then(response => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `success`,
            payload: response,
          }),
          () => onSuccess(response)
        )
      })
      .catch(error => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `error`,
            payload: error,
          }),
          () => onError(error)
        )
      })
  }
}
