import { Component } from "react"

export default class Configuration extends Component {
  constructor(props) {
    super(props)

    this.namespace =
      props.namespace ||
      props.url.slice(
        props.url.lastIndexOf(`/`) === -1 ? 0 : props.url.lastIndexOf(`/`) + 1
      )

    this.adapter = props.adapter || localStorage

    const savedConfiguration = this.adapter.getItem(this.namespace)

    const parsedConfiguration =
      savedConfiguration && JSON.parse(savedConfiguration)

    const hasExpired = parsedConfiguration
      ? Date.now() > parsedConfiguration.expires
      : undefined

    this.expires = this.props.expires || 60000 // milliseconds

    this.state = {
      error: undefined,
      configuration: !hasExpired ? parsedConfiguration : undefined,
    }
  }

  store = configuration => {
    configuration.expires = Date.now() + (configuration.expires || this.expires)

    this.adapter.setItem(this.namespace, JSON.stringify(configuration))
  }

  componentDidMount = () => {
    if (!this.state.configuration) {
      fetch(this.props.url)
        .then(response =>
          response.ok ? response.json() : Promise.reject(response)
        )
        .then(configuration => {
          this.store(configuration)
          this.setState({ configuration, error: undefined })
        })
        .catch(error => {
          this.setState(() => ({ error }))
        })
    }
  }

  render = () => {
    return this.props.children(this.state)
  }
}
