import React from "react"
import { CommandBar, Command, Search, Content } from "../Panel"
import { Table, Button, Image, Icon } from "semantic-ui-react"
import { push } from "../history"
import Observer from "intersection-observer"
import sizes from "../helpers/semantic-ui-sizes"
import { QueryParams } from "../QueryParamsContainer"

export default props => (
  <QueryParams>
    {queryParams => (
      <React.Fragment>
        <CommandBar>
          <Command fluid>
            <Search
              value={queryParams.params.playlistQuery}
              onChange={playlistQuery => queryParams.update({ playlistQuery })}
            />
          </Command>
        </CommandBar>
        <Content
          style={{ padding: 0 }}
          loading={props.arrangements.isFetching}
          loadingText="Loading playlist content"
        >
          <Table basic="very" style={{ marginTop: 0 }}>
            <Table.Body>
              {props.arrangements.data.map(item => (
                <Table.Row
                  key={item.id}
                  disabled={props.activeArrangementId === item.id}
                >
                  <Table.Cell
                    textAlign="left"
                    style={{
                      paddingLeft: `16px`,
                      width: `1%`,
                      whiteSpace: `nowrap`,
                    }}
                  >
                    {item.image ? (
                      <Image
                        size="mini"
                        src={item.image.url + `?width=${ sizes.mini }`}
                        style={{
                          marginTop: `auto`,
                          marginBottom: `auto`,
                        }}
                      />
                    ) : (
                      <div
                        className="ui tiny image"
                        style={{
                          width: `${ sizes.mini }px`,
                          height: `${ sizes.mini }px`,
                          border: `dashed 2px rgb(140,140,140)`,
                        }}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell style={{ paddingLeft: `16px` }}>
                    <div
                      style={{
                        minHeight: `40px`,
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `center`,
                      }}
                    >
                      <span>
                        {item.name}
                        {item.composition === `Unknown`
                          ? ``
                          : ` - ` + item.composition}
                      </span>
                    </div>
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    style={{
                      paddingRight: `16px`,
                      width: `1%`,
                      whiteSpace: `nowrap`,
                    }}
                  >
                    <Icon
                      name="remove circle"
                      color="red"
                      disabled={props.activeArrangementId === item.id}
                      onClick={() => props.removeArrangement(item.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {props.arrangements.next && (
            <Observer
              onChange={inView =>
                inView &&
                !props.arrangements.isFetching &&
                props.arrangements.fetch(true)
              }
              rootMargin="610px"
            >
              <div
                style={{
                  width: `100%`,
                  display: `flex`,
                  justifyContent: `center`,
                  padding: `3em`,
                }}
              >
                <Button
                  basic
                  content="Load more..."
                  onClick={() => props.arrangements.fetch(true)}
                  loading={props.arrangements.isFetching}
                />
              </div>
            </Observer>
          )}
          {!props.arrangements.next && !props.arrangements.isFetching && (
            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                minHeight: `122px`,
              }}
            >
              <Button basic onClick={() => push(`${ props.match.url }/add`)}>
                Add
              </Button>
            </div>
          )}
        </Content>
      </React.Fragment>
    )}
  </QueryParams>
)
