import React from "react"
import { root } from "./services/Globals"
import { Subject, from } from "rxjs"
import { debounceTime, switchMap } from "rxjs/operators"
import merge from "lodash.merge"
import isEqual from "deep-equal"

const initialState = {
  isFetching: false,
  isFetchingNext: false,
  data: [],
  params: {
    search: undefined,
    limit: 50,
    sort: undefined,
  },
  next: undefined,
}

const { Provider, Consumer } = React.createContext()

class CollectionProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
      ...(props.params
        ? { params: merge({}, initialState.params, props.params) }
        : {}),
    }

    this.fetch$ = new Subject().pipe(
      debounceTime(200),
      switchMap(configuration => from(root.wav.api(configuration)))
    )
  }

  render = () =>
    typeof this.props.children === `function` ? (
      this.props.children({
        ...this.state,
        fetch: this.fetch,
        filter: this.filter,
        refresh: this.refresh,
      })
    ) : (
      <Provider
        value={{
          ...this.state,
          fetch: this.fetch,
          refresh: this.refresh,
        }}
      >
        {this.props.children}
      </Provider>
    )

  componentDidMount = () => {
    this.subscription = this.fetch$.subscribe(
      ({ data, config }) => {
        this.setState(previousState => ({
          ...previousState,
          isFetching: false,
          isFetchingNext: false,
          data: this.state.isFetchingNext
            ? [
                ...this.state.data,
                ...(Array.isArray(data.items) ? data.items : data),
              ]
            : Array.isArray(data.items)
            ? data.items
            : data,
          next: data.next ? data.next : undefined,
          previousRequest: {
            url: config.url,
            params: config.params,
          },
        }))
      },
      error => {
        console.error(error)
        this.setState(previousState => ({
          ...previousState,
          isFetching: false,
        }))
      }
    )

    this.fetch()
  }

  componentDidUpdate = previousProps => {
    if (previousProps.url !== this.props.url && this.props.url) {
      this.fetch()
    } else if (!isEqual(previousProps.params, this.props.params)) {
      this.filter(merge({}, initialState.params, this.props.params))
    }
  }

  componentWillUnmount = () => {
    this.subscription.unsubscribe()
  }

  refresh = id => {
    if (!id || (id && this.state.data.find(entity => entity.id === id))) {
      this.setState({ isFetching: true }, () => {
        this.fetch$.next(this.state.previousRequest)
      })
    }
  }

  fetch = next => {
    this.props.url &&
      this.setState(
        previousState => ({
          ...previousState,
          [next ? `isFetchingNext` : `isFetching`]: true,
        }),
        () =>
          this.fetch$.next(
            next
              ? { url: this.state.next }
              : {
                  url: this.props.url,
                  params: this.getParams(),
                }
          )
      )
  }

  delete = id => {
    this.setState(
      {
        isFetching: true,
      },
      () => {
        root.wav
          .api({
            url: this.props.url + `/${ id }`,
            method: `delete`,
          })
          .then(() => {
            this.setState({ isFetching: false }, () => {
              this.refresh(id)
            })
          })
          .catch(() => {
            this.setState({ isFetching: false })
          })
      }
    )
  }

  filter = params => {
    this.setState(
      previousState => ({
        ...initialState,
        params: {
          ...previousState.params,
          ...params,
        },
      }),
      () => this.fetch()
    )
  }

  getParams = (extra = {}) => {
    return {
      ...extra,
      ...Object.keys(this.state.params).reduce(
        (params, filterName) =>
          this.state.params[filterName]
            ? {
                ...params,
                [filterName]: this.state.params[filterName],
              }
            : params,
        {}
      ),
    }
  }
}

export { CollectionProvider, Consumer as Collection }
