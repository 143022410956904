import React, { Component } from "react"
import { Content } from "../Panel"
import Select from "../Select"
import { Form, Table, Header, Label } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { UserContainer } from "./UserContainer"

class Roles extends Component {
  render = () => {
    return (
      <Subscribe to={[ UserContainer ]}>
        {userStore => (
          <Content loading={userStore.state.isFetching}>
            <Header as="h3">
              Roles
            </Header>
            <Form autoComplete="off">
              <Form.Field>
                <label
                  style={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    userSelect: `none`,
                  }}
                >
                  Edit roles
                </label>
                <Select
                  url="/roles"
                  value={userStore.state.roles}
                  onChange={roles => {
                    userStore.updateRoles(roles)
                  }}
                  fluid
                  multiple
                />
              </Form.Field>
            </Form>
          </Content>
        )}
      </Subscribe>
    )
  }
}

export default props => (
  <Subscribe to={[ UserContainer ]}>
    {userStore => <Roles userStore={userStore} {...props} />}
  </Subscribe>
)
