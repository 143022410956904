import React from "react"
import history from "./history"
import queryString from "query-string"
import merge from "lodash.merge"

const { Provider, Consumer } = React.createContext()

class QueryParamsProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      params: queryString.parse(history.location.search),
    }
  }

  render = () => (
    <Provider value={{ ...this.state, update: this.update }}>
      {this.props.children}
    </Provider>
  )

  componentDidMount = () => {
    this.stopListening = history.listen(location => {
      this.setState({
        params: queryString.parse(location.search),
      })
    })
  }

  componentWillUnmount = () => {
    this.stopListening()
  }

  update = params => {
    const search = queryString.stringify(
      Object.entries(
        merge({}, queryString.parse(history.location.search), params)
      ).reduce(
        (params, [ key, value ]) =>
          !value || (typeof value === `string` && !value.length)
            ? params
            : Object.assign(params, { [key]: value }),
        {}
      )
    )

    history.replace(
      history.location.pathname + (search.length ? `?${ search }` : ``),
      history.location.state
    )
  }
}

export { QueryParamsProvider, Consumer as QueryParams }
