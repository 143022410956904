import React from "react"
import styled from "styled-components"
import { Icon } from "semantic-ui-react"

const Filter = styled.span`
  display: flex;
  align-items: center;

  i {
    padding-left: 4px;
    visibility: hidden;
    pointer-events: none;
    cursor: pointer;
  }

  &:hover i {
    visibility: ${ props => (props.active ? `hidden` : `visible`) };
    pointer-events: auto;
  }
`

const Filterable = ({ children, onClick, active, ...props }) =>
  children ? (
    <Filter active={active} {...props}>
      {children}
      <Icon.Group onClick={onClick}>
        <Icon name="filter" />
        <Icon
          corner
          {...(active
            ? {
                name: `delete`,
                color: `red`,
              }
            : {
                name: `add`,
                color: `green`,
              })}
        />
      </Icon.Group>
    </Filter>
  ) : null

export default Filterable
