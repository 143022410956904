import React, { Component } from "react"
import { Content, CommandBar, Command, Search } from "../Panel"
import { Button, Table } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { ChoirContainer } from "./ChoirContainer"
import { LicensesContainer } from "./LicensesContainer"
import { LicenseContainer } from "./LicenseContainer"
import Observer from "react-intersection-observer"
import get from "lodash.get"

class Licenses extends Component {
  constructor(props) {
    super(props)
    this.searchInput = undefined
    this.state = {
      search: ``,
    }
  }
  render = () => {
    return (
      <Subscribe to={[ LicensesContainer, LicenseContainer ]}>
        {(licenses, license) => (
          <React.Fragment>
            <CommandBar>
              <Command fluid>
                <Search
                  value={this.state.search}
                  onChange={this.handleSearch}
                  loading={licenses.state.isFetching}
                  innerRef={element => (this.searchInput = element)}
                />
              </Command>
              <Command
                // disabled={license.state.isSaving}
                disabled={true}
                onClick={license.assignAll}
              >
                Assign all
              </Command>
            </CommandBar>
            <Content
              style={{
                padding: 0,
              }}
            >
              <Table basic="very" selectable style={{ marginTop: 0 }}>
                <Table.Body>
                  {licenses.state.data.map(item => (
                    <Table.Row
                      key={item.id}
                      active={
                        this.props.location.pathname.indexOf(item.id) !== -1
                      }
                      onClick={() => {
                        this.props.history.push(
                          this.props.match.url.slice(
                            0,
                            this.props.match.url.indexOf(`/licenses`) + 9
                          ) +
                            `/${ item.id }` +
                            this.props.location.search,
                          {
                            license: item,
                          }
                        )
                      }}
                      style={{ cursor: `pointer` }}
                      warning={!!item.subscription}
                    >
                      <Table.Cell style={{ paddingLeft: `16px` }}>
                        {get(item, `plan.name`)}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{ paddingRight: `16px` }}
                      >
                        {get(item, `subscription.user.displayName`)}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {licenses.state.next && (
                <Observer
                  onChange={inView =>
                    inView && !licenses.state.isFetching && licenses.fetch()
                  }
                  rootMargin="610px"
                >
                  <div
                    style={{
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      height: `122px`,
                    }}
                  >
                    <Button
                      basic
                      content="Load more..."
                      onClick={licenses.fetch}
                      loading={licenses.state.isFetching}
                    />
                  </div>
                </Observer>
              )}
              {!licenses.state.next && (
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    height: `122px`,
                  }}
                >
                  <Button
                    disabled
                    basic
                    content="No more content"
                    // onClick={licenses.fetch}
                    // loading={licenses.state.isFetching}
                  />
                </div>
              )}
            </Content>
          </React.Fragment>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.props.licenseStore.fetch(this.props.match.params.choirId)
    }, 0)
    this.searchInput.focus()
  }

  componentDidUpdate = previousProps => {
    previousProps.match.params.choirId !== this.props.match.params.choirId &&
      this.props.licenseStore.fetch(this.props.match.params.choirId)

    this.props.match.params.licenseId &&
      !this.props.location.state.license &&
      this.props.history.replace(this.props.location.pathname, {
        license: this.props.licenseStore.state.data
          .filter(license => license.id === this.props.match.params.licenseId)
          .pop(),
      })
  }

  componentWillUnmount = () => {
    this.props.licenseStore.resetState()
  }

  handleSearch = query => {
    this.setState({ search: query }, () => {
      this.props.licenseStore.filter({
        search: query,
        // sort: `plan.name`
      })
    })
  }
}

export default props => (
  <Subscribe to={[ ChoirContainer, LicensesContainer ]}>
    {(choirStore, licenseStore) => (
      <Licenses
        choirStore={choirStore}
        licenseStore={licenseStore}
        {...props}
      />
    )}
  </Subscribe>
)
