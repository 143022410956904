import React, { Component } from "react"
import { Content, CommandBar, Command } from "../Panel"
import { Form, Header } from "semantic-ui-react"
import ImageAsset from "../ImageAsset"
import { root } from "../services/Globals"
import uploadAsset from "../api/upload-asset"
import { Subscribe } from "unstated"
import { ChoirsContainer } from "./ChoirsContainer"
import { ChoirContainer } from "./ChoirContainer"
import isEqual from "deep-equal"

class UpdateChoir extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      isSaving: false,
      progress: undefined,
    }
    this.state = { ...this.initialState }
  }

  render = () => {
    return (
      <React.Fragment>
        <CommandBar>
          <Command.Save
            disabled={
              this.state.isSaving ||
              isEqual(this.state, {
                ...this.state,
                ...this.props.choirStore.state.data,
              })
            }
            onClick={this.handleSave}
          />
        </CommandBar>
        <Content
          loading={
            this.state.isSaving || this.props.choirStore.state.isFetching
          }
          loadingText={
            this.state.progress
              ? `Uploading image ${ this.state.progress }%`
              : this.state.isSaving
              ? `Updating choir`
              : `Loading...`
          }
        >
          <Form autoComplete="off" style={{ paddingBottom: `2rem` }}>
            <Form.Input
              label="Name"
              name="name"
              value={this.state.name || ``}
              onChange={this.handleBasicsChange}
              required
            />
            <Form.TextArea
              label="Description"
              name="description"
              value={this.state.description || ``}
              onChange={this.handleBasicsChange}
              required
            />

            <Form.Field>
              <label>Image</label>
              <ImageAsset
                name="image"
                {...(this.state.image
                  ? { initialValue: this.state.image.url }
                  : {})}
                onChange={this.handleBasicsChange}
              />
            </Form.Field>
            <Header as="h3">Place</Header>
            <Form.Input
              label="Name"
              name="place.name"
              value={(this.state.place && this.state.place.name) || ``}
              onChange={this.handlePlaceChange}
              required
            />
            <div style={{ display: `flex` }}>
              <div style={{ marginRight: `8px`, flexGrow: 1 }}>
                <Form.Input
                  type="number"
                  label="Latitude"
                  name="place.latitude"
                  value={(this.state.place && this.state.place.latitude) || 0}
                  onChange={this.handlePlaceChange}
                  required
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                <Form.Input
                  type="number"
                  label="Longitude"
                  name="place.longitude"
                  value={(this.state.place && this.state.place.longitude) || 0}
                  onChange={this.handlePlaceChange}
                  required
                />
              </div>
            </div>
          </Form>
        </Content>
      </React.Fragment>
    )
  }

  componentDidMount = () => {
    this.props.choirStore.state.data.id !== this.props.match.params.choirId
      ? this.props.choirStore
          .fetchChoir(this.props.match.params.choirId)
          .then(data => this.setState({ ...data }))
      : this.setState({ ...this.props.choirStore.state.data })
  }

  handleBasicsChange = ({ target: { name, value } }) => {
    this.setState(previousState => ({
      ...previousState,
      [name]: value,
    }))
  }

  handlePlaceChange = ({ target }) => {
    const name = target.name.split(`.`)[1]
    const value = target.value
    this.setState(previousState => ({
      ...previousState,
      place: {
        ...previousState.place,
        [name]: value,
      },
    }))
  }

  handleSettingsChange = (event, { name, value, checked }) => {
    this.setState(previousState => ({
      ...previousState,
      settings: {
        ...previousState.settings,
        [name]: value || checked,
      },
    }))
  }

  handleUploadProgress = ({ total, loaded }) => {
    this.setState(previousState => ({
      ...previousState,
      progress:
        total === loaded ? undefined : Math.ceil((loaded / total) * 100),
    }))
  }

  handleSave = () => {
    this.setState(
      previousState => ({
        ...previousState,
        isSaving: true,
      }),
      () => {
        const { id, name, description, image, place, settings } = this.state

        Promise.resolve(
          image && image.id
            ? image.id
            : image
            ? uploadAsset(image, this.handleUploadProgress).then(({ id }) => id)
            : undefined
        )
          .then(imageId =>
            root.wav.api({
              url: `/choirs/${ id }`,
              method: `put`,
              data: {
                name,
                description,
                ...(imageId ? { imageId } : {}),
                ...(place.name && place.latitude && place.longitude
                  ? { place }
                  : {}),
                settings,
              },
            })
          )
          .then(({ data: { id } }) => {
            this.props.choirsStore.updateId(id)
            Promise.all([
              this.props.choirStore.fetchDetails(id),
              this.props.choirStore.fetchChoir(id),
            ]).then(data => {
              this.setState(
                {
                  isSaving: false,
                  progress: undefined,
                },
                () => {
                  this.props.history.replace(
                    `/choirs/${ id }${
                      this.props.location.search
                        ? this.props.location.search
                        : ``
                    }`
                  )
                }
              )
            })
          })
          .catch(error => {
            console.error(error)
            this.setState(previousState => ({
              ...previousState,
              isSaving: false,
              progress: undefined,
            }))
          })
      }
    )
  }
}

export default props => (
  <Subscribe to={[ ChoirsContainer, ChoirContainer ]}>
    {(choirsStore, choirStore) => (
      <UpdateChoir
        choirsStore={choirsStore}
        choirStore={choirStore}
        {...props}
      />
    )}
  </Subscribe>
)
