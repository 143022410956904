import React from "react"
import { Content, CommandBar, Command } from "../Panel"
import { Form, Header } from "semantic-ui-react"
import PutArrangement from "../api/containers/PutArrangement"
import isEqual from "deep-equal"

export default class Notes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: undefined,
    }
  }

  render = () => {
    return (
      <PutArrangement
        data={{
          ...this.props.arrangement,
          notes: this.state.notes,
        }}
      >
        {arrangement => (
          <React.Fragment>
            <CommandBar>
              <Command
                save
                disabled={
                  isEqual(this.state.notes, this.props.arrangement.notes) ||
                  arrangement.mode === `loading`
                }
                onClick={() => arrangement.update()}
              />
            </CommandBar>
            <Content
              loading={this.props.loading || arrangement.mode === `loading`}
            >
              <Header style={{ marginTop: 0 }}>Notes</Header>
              <Form>
                <Form.TextArea
                  name="notes"
                  value={this.state.notes || ``}
                  onChange={this.handleChange}
                  autoHeight
                  rows={10}
                />
              </Form>
            </Content>
          </React.Fragment>
        )}
      </PutArrangement>
    )
  }

  componentDidMount = () => {
    this.setState({
      notes: this.props.arrangement.notes,
    })
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ notes: value })
  }
}
