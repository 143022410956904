import React from "react"
import styled from "styled-components"
import { CommandBar, Command, Content } from "../Panel"
import { Table as SUITable } from "semantic-ui-react"
import { push } from "../history"
import get from "lodash.get"
import { CONTRACT_TYPES } from "./constants"
import Button from "../TextButton"

const Bar = styled.div`
  height: 1rem;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.1);
  position: relative;
  overflow: hidden;

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: ${ props => (props.start ? `${ props.start }%` : 0) };
    height: 100%;
    width: ${ props => (props.share ? `${ props.share }%` : 0) };
    background: rgba(34, 36, 38, 0.1);
  }
`

const Name = styled.div`
  margin-bottom: 0.2rem;
`

const Percentage = styled.div``

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Holder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
`

const Table = styled(SUITable).attrs({
  definition: true,
})`
  cursor: pointer;
  border-color: ${ props =>
    props.selected ? `#85b7d9 !important` : `rgba(34, 36, 38, 0.15)` };

  margin-bottom: 2rem !important;

  :hover {
    border-color: ${ props =>
      props.selected
        ? `#85b7d9 !important`
        : `rgba(34, 36, 38, 0.3) !important` };
  }
`

export default props => (
  <React.Fragment>
    <CommandBar>
      <Command>
        <Button onClick={() => push(props.match.url + `/new`)}>Add</Button>
      </Command>
    </CommandBar>
    <Content>
      {get(props, `contracts.data`, []).map((contract, index) => (
        <Table
          key={contract.id}
          onClick={() => push(props.match.url + `/${ contract.id }`)}
          selected={props.location.pathname.includes(contract.id)}
        >
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing singleLine>
                Kickback
              </Table.Cell>
              <Table.Cell textAlign="right" style={{ fontWeight: 700 }}>
                {get(contract, `kickback`, 0)} %
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing singleLine>
                Region
              </Table.Cell>
              <Table.Cell textAlign="right">
                {get(contract, `region.name`)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing singleLine>
                Type
              </Table.Cell>
              <Table.Cell textAlign="right">
                {get(CONTRACT_TYPES, get(contract, `contractType`))}
              </Table.Cell>
            </Table.Row>
            {get(contract, `contractHolders`, [])
              .sort((a, b) => b.kickback - a.kickback)
              .reduce(
                (acc, { kickback, holder }, index, arr) =>
                  acc.concat(
                    <Table.Row key={holder.id}>
                      <Table.Cell colSpan={2} style={{ background: `none` }}>
                        <Holder>
                          <SpaceBetween>
                            <Name>{get(holder, `name`)}</Name>
                            <Percentage>{kickback || 0} %</Percentage>
                          </SpaceBetween>
                          <Bar
                            start={
                              index > 0
                                ? arr
                                    .slice(0, index)
                                    .reduce(
                                      (acc, { kickback }) => acc + kickback,
                                      0
                                    )
                                : 0
                            }
                            share={kickback || 0}
                          />
                        </Holder>
                      </Table.Cell>
                    </Table.Row>
                  ),
                []
              )}
          </Table.Body>
        </Table>
      ))}
    </Content>
  </React.Fragment>
)
