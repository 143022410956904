import React from "react"
import styled from "styled-components"
import { Route, Switch, Redirect } from "react-router-dom"
import Sidebar from "./Sidebar/Sidebar"
import Navigation from "./Navigation/Navigation"
import Artists from "./Artists/Root"
import Reports from "./Reports/Root"
import Categories from "./Categories/Root"
import Organizations from "./Organizations/Root"
import Songs from "./Songs/Root"
import Songwriters from "./Songwriters/Root"
import Arrangements from "./Arrangements/Arrangements"
import Panels from "./Panels"
import Arrangement from "./Arrangements/Arrangement"
import MusicXml from "./Arrangements/MusicXml"
import UploadPdf from "./Arrangements/UploadPdf"
import CreateArrangement from "./Arrangements/CreateArrangement"
import Version from "./Arrangements/Version"
import Audiotrack from "./Arrangements/Audiotrack"
import Upload from "./Arrangements/Upload"
import Entries from "./Content/Entries"
import Entry from "./Content/Entry"
import Users from "./Users/Users"
import User from "./Users/User"
import Choirs from "./Choirs/Choirs"
import Choir from "./Choirs/Choir"
import License from "./Choirs/License"
import Member from "./Choirs/Member"
import CreateChoir from "./Choirs/Create"
import UpdateNotifier from "./UpdateNotifier.js"
import Plans from "./Plans/Plans"
import Plan from "./Plans/Plan"
import Campaigns from "./Campaigns/Campaigns"
import Campaign from "./Campaigns/Campaign"
import Orders from "./Orders/Orders"
import Order from "./Orders/Order"
import CreateInvoice from "./Orders/CreateInvoice"
import Playlists from "./Playlists/Root"
import Catalog from "./Catalog/Catalog"
import Revenue from "./Revenue/Root"
import PlayMode from "./Arrangements/PlayMode"

const Rows = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Main = styled.main`
  display: flex;
  align-content: stretch;
  flex-grow: 1;
  position: relative;
`

const Application = () => (
  <Rows>
    <Navigation.Top />
    <Main>
      <Navigation.Left />

      <Switch>
        <Route path="/catalog" component={Catalog} />
        <Route path="/revenue" component={Revenue} />

        <Panels>
          <Route path="/callback" render={() => <Redirect to="/" />} />
          <Route path="/arrangements" component={Arrangements} />
          <Switch>
            <Route path={`/arrangements/musicxml`} component={MusicXml} />
            <Route path={`/arrangements/new`} component={CreateArrangement} />
            <Route
              path={`/arrangements/:arrangementId`}
              component={Arrangement}
            />
          </Switch>
          <Switch>
            <Route
              path={`/arrangements/:arrangementId/versions/musicxml`}
              component={MusicXml}
            />
            <Route
              path={`/arrangements/:arrangementId/versions/:versionId`}
              component={Version}
            />
          </Switch>
          <Route
            exact
            path={`/arrangements/:arrangementId/versions/:versionId/audiotracks/:audioId`}
            component={Audiotrack}
          />
          <Route
            exact
            path={`/arrangements/:arrangementId/versions/:versionId/audiotracks/:audioId/upload`}
            component={Upload}
          />
          <Route
            exact
            path={`/arrangements/:arrangementId/versions/:versionId/playmodes/:playModeId`}
            component={PlayMode}
          />
          <Route
            path="/arrangements/:arrangementId/versions/:versionId/musicxml"
            component={MusicXml}
          />
          <Route
            path="/arrangements/:arrangementId/versions/:versionId/pdf"
            component={UploadPdf}
          />
          <Route path="/artists" component={Artists} />
          <Route path="/reports" component={Reports} />

          <Route path="/categories" component={Categories} />

          <Route path="/organizations" component={Organizations} />
          <Route path="/songs" component={Songs} />
          <Route path="/songwriters" component={Songwriters} />

          <Route path="/users" component={Users} />
          <Route path="/users/:userId" component={User} />
        </Panels>
      </Switch>
      <Route path="/" component={Sidebar} />
    </Main>
    <Route path="/" component={UpdateNotifier} />
  </Rows>
)

Application.displayName = `Application`

export default Application
