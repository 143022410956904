import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Form, Radio, Select } from "semantic-ui-react";
import {
  exportRevenue,
  readClients,
  readRevenuePerArrangement,
  readRevenuePerOrganization,
} from "../api/revenue-api";
import Panel from "../Panel";
import RevenueOverview from "./RevenueOverview";
import ArrangementRevenueTable from "./ArrangementRevenueTable";
import OrganizationRevenueTable from "./OrganizationRevenueTable";
import UserRevenueTable from "./UserRevenueTable";

export default function Root() {
  const [source, setSource] = useState("user");
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [revenue, setRevenue] = useState("");
  const [mode, setMode] = useState("organization");
  const [clientOptions, setClientOptions] = useState([]);
  const [clientUserId, setClientUserId] = useState();
  const [includeOtherUsersInWav, setIncludeOtherUsersInWav] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const [arrangementRevenue, setArrangementRevenue] = useState();
  const [organizationRevenue, setOrganizationRevenue] = useState();

  const disableButton = useMemo(
    () => !startDate || !endDate || !revenue || isLoadingTable,
    [endDate, isLoadingTable, revenue, startDate]
  );

  useEffect(() => {
    readClients().then((res) => {
      const options = res.map((client) => ({
        text: client.displayName,
        value: client.id,
        key: client.id,
      }));
      setClientOptions(options);
    })
  }, []);

  const handleDownloadData = useCallback(() => {
    if (mode === 'organization' && organizationRevenue) {
      setIsLoadingDownload(true);
      exportRevenue({ source, startDate, endDate, revenue, clientUserId, includeOtherUsersInWav }).then((res) => {
        const clientName = clientOptions.find((c) => c.value === clientUserId)
          ?.text;
        const data = new Blob([res], { type: "text/csv" });
        const csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute(
          "download",
          `${source}_${startDate}_${endDate}_${revenue}_${clientName?.replace(' ', '') ?? "AllClients"}${includeOtherUsersInWav ? '_OtherUsersInWav' : ''}.csv`
        );
        tempLink.click();
      })
      .finally(() => {
        setIsLoadingDownload(false);
      });
    }
  }, [
    clientUserId,
    clientOptions,
    endDate,
    mode,
    organizationRevenue,
    revenue,
    startDate,
    source,
    includeOtherUsersInWav,
  ]);

  const handleSubmit = useCallback(() => {
    setIsLoadingTable(true);
    if (mode === 'organization') {
      readRevenuePerOrganization({
        source,
        startDate,
        endDate,
        revenue,
        clientUserId,
        includeOtherUsersInWav
      }).then((res) => {
        setOrganizationRevenue(res);
      }).finally(() => {
        setIsLoadingTable(false)
      });
    } else {
      readRevenuePerArrangement({
        source,
        startDate,
        endDate,
        revenue,
        clientUserId,
        includeOtherUsersInWav
      }).then((res) => {
          setArrangementRevenue(res);
      }).finally(() => {
        setIsLoadingTable(false);
      });
    }
  }, [clientUserId, endDate, mode, revenue, startDate, source, includeOtherUsersInWav]);

  return (
    <>
      <Panel attached style={{ width: "100%" }} title="Revenue">
        <div style={{ display: "flex" }}>
          <div
            style={{
              padding: "1rem",
              paddingRight: 0,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "1rem",
              maxWidth: "20rem",
            }}
          >
            <Form>
              <label
                style={{
                  display: "block",
                  margin: "0 0 0.28571429rem 0",
                  color: "rgba(0,0,0,.87)",
                  fontSize: ".92857143em",
                  fontWeight: 700,
                  textTransform: "none",
                }}
              >
                Source (aggregated on day basis)
              </label>
              <Form.Field>
                <Radio
                  label="User statistics"
                  name="source"
                  checked={source === 'user'}
                  onChange={() => setSource('user')}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Arrangement statistics"
                  name="source"
                  checked={source === 'arrangement'}
                  onChange={() => setSource('arrangement')}
                />
              </Form.Field>
            </Form>
            <Form>
              <Form.Input
                value={startDate}
                onChange={(_, v) => setStartDate(v.value)}
                required
                type="date"
                label="Start date"
                name="startDate"
              />
              <Form.Input
                value={endDate}
                onChange={(_, v) => setEndDate(v.value)}
                required
                type="date"
                label="End date"
                name="endDate"
              />
              <Form.Input
                value={revenue}
                onChange={(_, v) => setRevenue(v.value)}
                required
                label="Revenue (1234 = 12.34 £$€)"
                name="revenue"
              />
            </Form>
            <div>
              <label
                style={{
                  display: "block",
                  margin: "0 0 0.28571429rem 0",
                  color: "rgba(0,0,0,.87)",
                  fontSize: ".92857143em",
                  fontWeight: 700,
                  textTransform: "none",
                }}
              >
                Client
              </label>
              <Select
                style={{ width: "100%" }}
                clearable
                options={clientOptions}
                onChange={(_, v) => setClientUserId(v.value)}
              />
            </div>
            <Form>
              <Form.Field>
                <Checkbox
                  label="Include Other users in WAV"
                  name="includeOtherUsersInWAV"
                  checked={includeOtherUsersInWav}
                  onChange={() => setIncludeOtherUsersInWav(!includeOtherUsersInWav)}
                />
              </Form.Field>
            </Form>
            <Form>
              <label
                style={{
                  display: "block",
                  margin: "0 0 0.28571429rem 0",
                  color: "rgba(0,0,0,.87)",
                  fontSize: ".92857143em",
                  fontWeight: 700,
                  textTransform: "none",
                }}
              >
                View
              </label>
              <Form.Field>
                <Radio
                  label="Organization"
                  name="mode"
                  checked={mode === 'organization'}
                  onChange={() => setMode('organization')}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Arrangement"
                  name="mode"
                  checked={mode === 'arrangement'}
                  onChange={() => setMode('arrangement')}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="User"
                  name="mode"
                  checked={mode === 'user'}
                  onChange={() => setMode('user')}
                />
              </Form.Field>
            </Form>
            <Button
              loading={isLoadingTable}
              disabled={disableButton}
              onClick={() => handleSubmit()}
            >
              Calculate
            </Button>
            <RevenueOverview
              revenue={mode === 'organization' ? organizationRevenue : arrangementRevenue}
            />
          </div>
          {mode === 'arrangement' && arrangementRevenue && (
            <ArrangementRevenueTable revenue={arrangementRevenue} />
          )}
          {mode === 'organization' && organizationRevenue && (
            <OrganizationRevenueTable
              onDownload={handleDownloadData}
              isLoadingDownload={isLoadingDownload}
              revenue={organizationRevenue}
            />
          )}
          {mode === 'user' && arrangementRevenue && !!arrangementRevenue.userCount && (
            <UserRevenueTable revenue={arrangementRevenue} clientOptions={clientOptions} />
          )}
        </div>
      </Panel>
    </>
  );
}
