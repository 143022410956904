import React from "react"
import { Table, Label } from "semantic-ui-react"
import Image from "./Image"
import { Link } from "react-router-dom"
import Filterable from "./Filterable"
import getFriendlyName from "./get-friendly-name"
import { FRIENDLY_NAMES, STATUS } from "./constants"
import List from "./List"
import Details from "./Details"
import prettyMs from "pretty-ms"

const fieldComponents = {
  duration: ({ item: { duration } }) => (
    <Table.Cell collapsing>{duration && prettyMs(duration)}</Table.Cell>
  ),
  imageUrl: ({ item: { imageUrl }, assetUrl }) => (
    <Table.Cell collapsing>
      <Image
        image={
          imageUrl
            ? {
                url: assetUrl.replace(/^\//, ``) + imageUrl,
              }
            : undefined
        }
      />
    </Table.Cell>
  ),
  name: ({ item: { name, arrangementId } }) => (
    <Table.Cell>
      <Link to={`/arrangements/${ arrangementId }`}>{name}</Link>
    </Table.Cell>
  ),
  catalog: ({ item: { catalog }, toggleFilter, currentFilter }) => (
    <Table.Cell>
      <Filterable
        onClick={toggleFilter(`entity`, `catalog`, catalog)}
        active={currentFilter.includes(catalog)}
      >
        {getFriendlyName(FRIENDLY_NAMES, `catalog`, catalog)}
      </Filterable>
    </Table.Cell>
  ),
  status: ({ item: { status }, toggleFilter, currentFilter }) => (
    <Table.Cell>
      <Filterable
        onClick={toggleFilter(`entity`, `status`, status)}
        active={currentFilter.includes(status)}
      >
        <Label
          size="tiny"
          color={
            {
              Draft: `grey`,
              ReadyForPublish: `yellow`,
              Published: `green`,
              PreviouslyPublished: `orange`,
            }[status]
          }
        >
          <span
            style={{
              textTransform: `uppercase`,
            }}
          >
            {getFriendlyName(STATUS, status)}
          </span>
        </Label>
      </Filterable>
    </Table.Cell>
  ),
  arrangerName: ({
    item: { arrangerName, arrangerId },
    toggleFilter,
    currentFilter,
  }) => (
    <Table.Cell style={{ minWidth: `150px` }}>
      <List>
        {arrangerName.map((name, index) => (
          <List.Item key={arrangerId[index]}>
            <Filterable
              onClick={toggleFilter(
                `collection`,
                `arrangerId`,
                arrangerId[index]
              )}
              active={currentFilter.includes(arrangerId[index])}
            >
              <Link to={`/organizations/ou/${ arrangerId[index] }`}>{name}</Link>
            </Filterable>
          </List.Item>
        ))}
      </List>
    </Table.Cell>
  ),
  songName: ({ item: { songName, songId }, toggleFilter, currentFilter }) => (
    <Table.Cell style={{ minWidth: `150px` }}>
      <Filterable
        onClick={toggleFilter(`entity`, `songId`, songId)}
        active={currentFilter.includes(songId)}
      >
        <Link to={`/songs/${ songId }`}>{songName}</Link>
      </Filterable>
    </Table.Cell>
  ),
  knownPerformingArtistName: ({
    item: { knownPerformingArtistName, knownPerformingArtistId },
    toggleFilter,
    currentFilter,
  }) => (
    <Table.Cell style={{ minWidth: `150px` }}>
      <List>
        {knownPerformingArtistName.map((name, index) => (
          <List.Item key={knownPerformingArtistId[index]}>
            <Filterable
              onClick={toggleFilter(
                `collection`,
                `knownPerformingArtistId`,
                knownPerformingArtistId[index]
              )}
              active={currentFilter.includes(knownPerformingArtistId[index])}
            >
              <Link to={`/artists/${ knownPerformingArtistId[index] }`}>
                {name}
              </Link>
            </Filterable>
          </List.Item>
        ))}
      </List>
    </Table.Cell>
  ),
  songwriterName: ({
    item: { songwriterName, songwriterId },
    toggleFilter,
    currentFilter,
  }) => (
    <Table.Cell style={{ minWidth: `150px` }}>
      <List>
        {songwriterName.map((name, index) => (
          <List.Item key={songwriterId[index]}>
            <Filterable
              onClick={toggleFilter(
                `collection`,
                `songwriterId`,
                songwriterId[index]
              )}
              active={currentFilter.includes(songwriterId[index])}
            >
              <Link to={`/songwriter/${ songwriterId[index] }`}>{name}</Link>
            </Filterable>
          </List.Item>
        ))}
      </List>
    </Table.Cell>
  ),
  categoryName: ({
    item: { categoryName, categoryId },
    toggleFilter,
    currentFilter,
  }) => (
    <Table.Cell>
      <List>
        {categoryName.map((name, index) => (
          <List.Item key={categoryId[index]}>
            <Filterable
              onClick={toggleFilter(
                `collection`,
                `categoryId`,
                categoryId[index]
              )}
              active={currentFilter.includes(categoryId[index])}
            >
              <Link to={`/categories/${ categoryId[index] }`}>{name}</Link>
            </Filterable>
          </List.Item>
        ))}
      </List>
    </Table.Cell>
  ),
  composition: ({ item: { composition }, toggleFilter, currentFilter }) => (
    <Table.Cell collapsing textAlign="center">
      <Filterable
        onClick={toggleFilter(`entity`, `composition`, composition)}
        active={currentFilter.includes(composition)}
      >
        {composition ? composition : ``}
      </Filterable>
    </Table.Cell>
  ),
  playMode: ({ item: { playMode }, toggleFilter, currentFilter }) => (
    <Table.Cell>
      {playMode === 1 && playMode[0]}
      {playMode.length > 1 && (
        <Details>
          <Details.Summary>{playMode.length}</Details.Summary>
          {playMode.map((name, index) => (
            <Filterable
              key={index}
              onClick={toggleFilter(`collection`, `playMode`, playMode[index])}
              active={currentFilter.includes(playMode[index])}
            >
              {getFriendlyName(FRIENDLY_NAMES, `playMode`, name)}
              {playMode.length > index + 1 && <br />}
            </Filterable>
          ))}
        </Details>
      )}
    </Table.Cell>
  ),
  createdUtc: ({ item: { createdUtc } }) => (
    <Table.Cell collapsing>
      {new Date(createdUtc).toLocaleString(navigator.language)}
    </Table.Cell>
  ),

  updatedUtc: ({ item: { updatedUtc } }) => (
    <Table.Cell collapsing>
      {new Date(updatedUtc).toLocaleString(navigator.language)}
    </Table.Cell>
  ),
}

export default ({ type, ...props }) =>
  fieldComponents[type] && (props.item[type] || type === `imageUrl`) ? (
    fieldComponents[type](props)
  ) : (
    <Table.Cell>{props.item[type]}</Table.Cell>
  )
