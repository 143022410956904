import { Component } from "react"
import dispatchEvent from "../../helpers/dispatch-event"
import { root } from "../../services/Globals"

const noop = () => {}

export default class PutScore extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `idle`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
      update,
    } = this

    return this.props.children({
      mode,
      payload,
      update,
    })
  }

  update = () => {
    const {
      data: score,
      arrangementId,
      versionId,
      onSuccess = noop,
      onError = noop,
      onLoading = noop,
    } = this.props

    this.setState(
      previousState => ({
        ...previousState,
        mode: `loading`,
      }),
      onLoading
    )

    return root.wav
      .api({
        method: `put`,
        url: `/arrangements/${ arrangementId }/versions/${ versionId }/score`,
        data: {
          ...Object.keys(score).reduce(
            (data, key) => (score[key] ? { ...data, [key]: score[key] } : data),
            {}
          ),
        },
      })
      .then(({ data }) => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `idle`,
            payload: data,
          }),
          () => {
            onSuccess(data)
            dispatchEvent(`update:arrangement`)
            dispatchEvent(`update:${ arrangementId }`)
            dispatchEvent(`update:${ versionId }`)
          }
        )
      })
      .catch(error => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `error`,
            payload: error,
          }),
          () => onError(error)
        )
      })
  }
}
