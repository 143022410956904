import React, { Component } from "react"
import Panel, {
  Content,
  CommandBar,
  Command,
  PersistentSearch as Search,
} from "../Panel"
import { Item, Button } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { ChoirsContainer } from "./ChoirsContainer"
import queryString from "query-string"
import Observer from "react-intersection-observer"

class Choirs extends Component {
  constructor(props) {
    super(props)
    this.searchInput = undefined
    this.state = {
      filters: {
        search: ``,
      },
    }
  }

  render = () => {
    return (
      <Subscribe to={[ ChoirsContainer ]}>
        {choirs => (
          <Panel title="Choirs">
            <CommandBar>
              <Command fluid>
                <Search
                  value={this.state.filters.search}
                  onChange={this.handleSearch}
                  innerRef={element => (this.searchInput = element)}
                />
              </Command>
            </CommandBar>
            <Content
              style={{ padding: 0, margin: 0 }}
              loading={choirs.state.isFetching}
              loadingText="Loading choirs..."
            >
              <Item.Group relaxed style={{ margin: 0 }}>
                {choirs.state.data.map(item => (
                  <Item
                    key={item.id}
                    style={{
                      margin: 0,
                      marginBottom: `-1.5em`,
                      padding: `1.5em`,
                      minHeight: `122px`,
                      cursor: `pointer`,
                      background:
                        this.props.location.pathname.indexOf(item.id) !== -1
                          ? `rgb(240,240,240)`
                          : `transparent`,
                    }}
                    onClick={() => {
                      this.props.history.push(
                        this.props.match.path +
                          `/${ item.id }` +
                          this.props.location.search
                      )
                    }}
                  >
                    {item.image ? (
                      <Item.Image
                        size="tiny"
                        src={item.image.url + `?width=80`}
                        style={{
                          marginTop: `auto`,
                          marginBottom: `auto`,
                        }}
                      />
                    ) : (
                      <div
                        className="ui tiny image"
                        style={{
                          width: `80px`,
                          height: `80px`,
                          border: `dashed 3px rgb(140,140,140)`,
                        }}
                      />
                    )}
                    <Item.Content verticalAlign="middle">
                      <Item.Header>{item.name}</Item.Header>
                      <Item.Meta>
                        {new Date(item.createdUtc).toLocaleString(
                          navigator.language
                        )}
                      </Item.Meta>
                      {item.place && <Item.Extra>{item.place.name}</Item.Extra>}
                    </Item.Content>
                  </Item>
                ))}
              </Item.Group>
              {choirs.state.next && (
                <Observer
                  onChange={inView =>
                    inView && !choirs.state.isFetching && choirs.fetch()
                  }
                  rootMargin="610px"
                >
                  <div
                    style={{
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      minHeight: `122px`,
                    }}
                  >
                    <Button
                      basic
                      content="Load more..."
                      onClick={choirs.fetch}
                      loading={choirs.state.isFetching}
                    />
                  </div>
                </Observer>
              )}
              {!choirs.state.next && (
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    minHeight: `122px`,
                  }}
                >
                  <Button
                    basic
                    onClick={() =>
                      this.props.history.push(
                        `/choirs/new` + this.props.location.search,
                        choirs.state.filters.search &&
                          choirs.state.filters.search.length > 0
                          ? { name: choirs.state.filters.search }
                          : {}
                      )
                    }
                  >
                    {choirs.state.filters.search &&
                    choirs.state.filters.search.length > 0 ? (
                      <span>
                        Add{` `}
                        <strong>{choirs.state.filters.search}</strong>
                      </span>
                    ) : (
                      `Add missing choir`
                    )}
                  </Button>
                </div>
              )}
            </Content>
          </Panel>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    const { search } = queryString.parse(this.props.location.search)
    setTimeout(
      () => (search ? this.handleSearch(search) : this.props.choirs.fetch()),
      0
    )

    this.searchInput.focus()
  }

  componentWillUnmount = () => {
    this.props.choirs.resetState()
  }

  handleSearch = query => {
    this.setState(
      previousState => ({
        ...previousState,
        filters: {
          ...previousState.filters,
          search: query,
          sort: `name`,
        },
      }),
      () => {
        this.props.choirs.filter(this.state.filters)
      }
    )
  }
}

export default props => (
  <Subscribe to={[ ChoirsContainer ]}>
    {choirs => <Choirs choirs={choirs} {...props} />}
  </Subscribe>
)
