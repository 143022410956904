import { Container } from "unstated"
import { root } from "../services/Globals"
import uploadAsset from "../api/upload-asset"

const initialState = {
  isFetching: false,
  isRemoving: false,
  isSaving: false,
  details: {},
  data: {},
}

const choir = (() => {
  const details = id =>
    root.wav.api({ url: `/choirs/${ id }/details` }).then(({ data }) => data)

  const read = id =>
    root.wav.api({ url: `/choirs/${ id }` }).then(({ data }) => data)

  const update = (
    { id, name, description, image, place, settings },
    onUploadProgress
  ) =>
    (image
      ? image.id
        ? Promise.resolve(image.id)
        : uploadAsset(image, onUploadProgress).then(({ id }) => id)
      : Promise.resolve(undefined)
    ).then(imageId =>
      root.wav
        .api({
          url: `/choirs/${ id }`,
          method: `put`,
          data: {
            name,
            description,
            ...(imageId ? { imageId } : {}),
            place,
            settings,
          },
        })
        .then(({ data }) => data)
    )

  const deleteChoir = id =>
    root.wav.api({
      url: `/choirs/${ id }`,
      method: `delete`,
    })

  return {
    read: Object.assign(read, {
      details,
    }),
    update,
    delete: deleteChoir,
  }
})()

class ChoirContainer extends Container {
  state = {
    ...initialState,
  }

  fetchChoir = async (id = this.state.details.id) => {
    this.setState({
      isFetching: true,
    })

    try {
      const data = await choir.read(id)

      this.setState({
        isFetching: false,
        data,
      })

      return data
    } catch (error) {
      console.error(error)

      this.setState({
        isFetching: false,
      })
    }
  }

  fetchDetails = async id => {
    this.setState({
      isFetching: true,
    })

    try {
      const data = await choir.read.details(id)

      this.setState({
        isFetching: false,
        details: data,
      })

      return data
    } catch (error) {
      console.error(error)

      this.setState({
        isFetching: false,
      })
    }
  }

  toggleDisableFeedPost = async () => {
    const data = {
      ...this.state.data,
      settings: {
        ...this.state.data.settings,
        disableFeedPost: !this.state.data.settings.disableFeedPost,
      },
    }

    this.setState({
      isSaving: true,
    })

    try {
      const updatedData = await choir.update(data)
      this.setState({
        isSaving: false,
        data: updatedData,
      })
      return updatedData
    } catch (error) {
      this.setState({
        isSaving: false,
      })
    }
  }

  toggleHideFromListings = async () => {
    const data = {
      ...this.state.data,
      settings: {
        ...this.state.data.settings,
        hideFromListings: !this.state.data.settings.hideFromListings,
      },
    }

    this.setState({
      isSaving: true,
    })

    try {
      const updatedData = await choir.update(data)
      this.setState({
        isSaving: false,
        data: updatedData,
      })
      return updatedData
    } catch (error) {
      this.setState({
        isSaving: false,
      })
    }
  }

  toggleAssignLicence = async () => {
    const data = {
      ...this.state.data,
      settings: {
        ...this.state.data.settings,
        assignLicence: !this.state.data.settings.assignLicence,
      },
    }

    this.setState({
      isSaving: true,
    })

    try {
      const updatedData = await choir.update(data)
      this.setState({
        isSaving: false,
        data: updatedData,
      })
      return updatedData
    } catch (error) {
      this.setState({
        isSaving: false,
      })
    }
  }

  changeAccess = async access => {
    if (access !== this.state.data.settings.access) {
      const data = {
        ...this.state.data,
        settings: {
          ...this.state.data.settings,
          access,
        },
      }

      this.setState({
        isSaving: true,
      })

      try {
        const updatedData = await choir.update(data)
        this.setState({
          isSaving: false,
          data: updatedData,
        })
        return updatedData
      } catch (error) {
        this.setState({
          isSaving: false,
        })
      }
    } else {
      return this.state.data
    }
  }

  remove = async () => {
    this.setState({
      isRemoving: true,
    })

    try {
      const id = this.state.details.id
      await choir.delete(id)
      this.setState({
        ...initialState,
      })
      return id
    } catch (error) {
      this.setState({
        isRemoving: false,
      })
    }
  }

  resetState = () => {
    this.setState({ ...initialState })
  }
}

export { ChoirContainer }
