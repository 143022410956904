import React, { Component } from "react"
import get from "lodash.get"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { Form, Dropdown } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { MembersContainer } from "./MembersContainer"
import { LicenseContainer } from "./LicenseContainer"
import { LicensesContainer } from "./LicensesContainer"
import Img from "../Img"
import { Link } from "react-router-dom"
import concatEntityIfMissing from "../helpers/concat-entity-if-missing"
import Button from "../TextButton"

const Item = ({ imageUrl, name }) => (
  <div style={{ display: `flex`, alignItems: `center` }}>
    <div style={{ marginRight: `1rem`, width: `45px` }}>
      <Img src={imageUrl} alt={name} width={45} />
    </div>
    <div>{name}</div>
  </div>
)

class MembersDropdown extends Component {
  render = () => {
    const { members, fetchMembers, ...props } = this.props
    return <Dropdown selection search deburr options={members} {...props} />
  }

  componentDidMount = () => {
    setTimeout(() => this.props.fetchMembers(), 0)
  }
}

class License extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedMember: get(
        this.props,
        `location.state.license.subscription.user.id`,
        undefined
      ),
    }
  }

  render = () => {
    return (
      <Subscribe to={[ LicenseContainer, LicensesContainer ]}>
        {({ state: { isSaving }, assignLicenseToUser }, { updateLicenses }) => (
          <Panel
            title={`License: ${ get(
              this.props,
              `location.state.license.plan.name`,
              ``
            ) }`}
            onClose={() =>
              this.props.history.push(
                this.props.returnTo ||
                  this.props.match.url.slice(
                    0,
                    this.props.match.url.indexOf(`/licenses`) + 9
                  )
              )
            }
          >
            <CommandBar>
              <Command
                disabled={isSaving}
                onClick={() =>
                  assignLicenseToUser({
                    licenseId: this.props.match.params.licenseId,
                    userId: this.state.selectedMember,
                  }).then(({ data }) => {
                    this.props.history.replace(this.props.location.pathname, {
                      license: data,
                    })
                    updateLicenses(data)
                  })
                }
              >
                Assign license
              </Command>
            </CommandBar>
            <Content loading={isSaving} loadingText="Assigning license">
              <Form>
                <Form.Field>
                  <label>Plan</label>
                  <Link
                    to={`/plans/${ get(
                      this.props,
                      `location.state.license.plan.id`
                    ) }`}
                  >
                    {get(this.props, `location.state.license.plan.name`)}
                  </Link>
                </Form.Field>
                <Form.Field>
                  <label
                    style={{ display: `flex`, justifyContent: `space-between` }}
                  >
                    License holder{` `}
                    <Button
                      onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        this.setState({ selectedMember: undefined })
                      }}
                    >
                      Clear selection
                    </Button>
                  </label>
                  <Subscribe to={[ MembersContainer ]}>
                    {({ state, ...actions }) => (
                      <MembersDropdown
                        value={this.state.selectedMember}
                        fetchMembers={() =>
                          actions.fetch(this.props.match.params.choirId)
                        }
                        members={concatEntityIfMissing({
                          entities: state.data.map(({ user }) => user),
                          entity: get(
                            this.props,
                            `location.state.license.subscription.user`
                          ),
                        })
                          .map(user => ({
                            key: user.id,
                            text: user.displayName,
                            value: user.id,
                            ...(user.profileImage
                              ? {
                                  content: (
                                    <Item
                                      imageUrl={
                                        user.profileImage &&
                                        user.profileImage.url
                                      }
                                      name={user.displayName}
                                    />
                                  ),
                                }
                              : {}),
                          }))
                          .concat(
                            state.next
                              ? {
                                  key: `next`,
                                  text: `Too many to display, start typing to initiate a search...`,
                                  disabled: true,
                                }
                              : []
                          )}
                        onSearchChange={(event, { searchQuery }) =>
                          actions.filter({ search: searchQuery })
                        }
                        onChange={(event, { value }) =>
                          this.setState({ selectedMember: value })
                        }
                        loading={state.isFetching}
                      />
                    )}
                  </Subscribe>
                </Form.Field>

                {get(this.props, `location.state.license.subscription`) && (
                  <React.Fragment>
                    <Form.Field>
                      <label>Subscription start time</label>
                      <p>
                        {get(
                          this.props,
                          `location.state.license.subscription.subscriptionStartTimeUtc`
                        )
                          ? new Date(
                              get(
                                this.props,
                                `location.state.license.subscription.subscriptionStartTimeUtc`
                              )
                            ).toLocaleString(navigator.language)
                          : `-`}
                      </p>
                    </Form.Field>
                    <Form.Field>
                      <label>Subscription end time</label>
                      <p>
                        {get(
                          this.props,
                          `location.state.license.subscription.subscriptionEndTimeUtc`
                        )
                          ? new Date(
                              get(
                                this.props,
                                `location.state.license.subscription.subscriptionEndTimeUtc`
                              )
                            ).toLocaleString(navigator.language)
                          : `-`}
                      </p>
                    </Form.Field>
                  </React.Fragment>
                )}
              </Form>
            </Content>
          </Panel>
        )}
      </Subscribe>
    )
  }
}

export default License
