import React, { Component } from "react"
import { Content } from "../Panel"
import { Step, Segment, Button, Icon, Message } from "semantic-ui-react"
import { root } from "../services/Globals"
import dispatchEvent from "../helpers/dispatch-event"
import { withValidation } from "../api/containers/Validate"

// ['Draft', 'ReadyForPublish', 'Published', 'PreviouslyPublished']

const statuses = {
  draft: `Draft`,
  preview: `ReadyForPublish`,
  published: `Published`,
  unpublished: `PreviouslyPublished`,
}

const statusActionNames = {
  Draft: `Draft`,
  ReadyForPublish: `Preview`,
  Published: `Publish`,
  PreviouslyPublished: `Unpublish`,
}

class Status extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: undefined,
      loading: true,
    }
  }

  render = () => {
    const { status, loading } = this.state

    const jobs = this.props.jobs.running.length > 0

    const validation = this.props.validation
    const isValid =
      validation.data &&
      validation.data.errors &&
      validation.data.errors.length === 0

    const nextPossibleStatus =
      (this.props.validation &&
        this.props.validation.data &&
        this.props.validation.data.nextPossibleStatus) ||
      []

    return (
      <Content loading={loading || validation.loading}>
        {status && (
          <div>
            <Step.Group size="mini" fluid attached="top">
              <Step
                {...(status === statuses.draft
                  ? { active: true }
                  : { disabled: true })}
              >
                <Step.Content>
                  <Step.Title>Draft</Step.Title>
                </Step.Content>
              </Step>

              <Step
                {...(status === statuses.preview
                  ? { active: true }
                  : { disabled: true })}
              >
                <Step.Content>
                  <Step.Title>Preview</Step.Title>
                </Step.Content>
              </Step>

              <Step
                {...(status === statuses.published
                  ? { active: true }
                  : { disabled: true })}
              >
                <Step.Content>
                  <Step.Title>Published</Step.Title>
                </Step.Content>
              </Step>

              <Step
                {...(status === statuses.unpublished
                  ? { active: true }
                  : { disabled: true })}
              >
                <Step.Content>
                  <Step.Title>Unpublished</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>
            <Segment attached disabled={jobs || !isValid}>
              <div
                style={{
                  ...(status === statuses.draft
                    ? { display: `block` }
                    : { display: `none` }),
                }}
              >
                <strong>Draft</strong>
                <p>Make all major edits while at this stage.</p>
              </div>
              <div
                style={{
                  ...(status === statuses.preview
                    ? { display: `block` }
                    : { display: `none` }),
                }}
              >
                <strong>Preview</strong> (Ready For Publish)
                <p>
                  The version is available in a limited preview.{` `}
                  <strong>
                    No more edits can be made once it is published
                  </strong>
                  .
                </p>
              </div>
              <div
                style={{
                  ...(status === statuses.published
                    ? { display: `block` }
                    : { display: `none` }),
                }}
              >
                <strong>Published</strong>
                <p>The version is available for all users of the app.</p>
              </div>
              <div
                style={{
                  ...(status === statuses.unpublished
                    ? { display: `block` }
                    : { display: `none` }),
                }}
              >
                <strong>Unpublished</strong> (Previously Published)
                <p>
                  The version was previously published but is no longer
                  available in the app.
                </p>
              </div>
            </Segment>
            <Segment
              attached="bottom"
              textAlign="right"
              disabled={jobs || !isValid}
              style={jobs || !isValid ? { pointerEvents: `none` } : {}}
            >
              {nextPossibleStatus.map(status => (
                <Button
                  key={status}
                  compact
                  onClick={() => this.confirmStatusChange(status)}
                >
                  {statusActionNames[status]}
                </Button>
              ))}
            </Segment>
          </div>
        )}
        {jobs && (
          <Message info>
            <span>
              <Icon name="info circle" />
              Status can't be changed while jobs are running.
            </span>
          </Message>
        )}
        {!isValid && (
          <Message error>
            <span>
              <Icon name="warning circle" />
              Status can't be changed if there are validation errors.
            </span>
          </Message>
        )}
      </Content>
    )
  }

  componentDidMount = () => {
    return this.getCurrentStatus()
  }

  getCurrentStatus = () => {
    return root.wav
      .api(
        `/arrangements/${ this.props.match.params.arrangementId }/versions/${
          this.props.match.params.versionId
        }`
      )
      .then(({ data: { status } }) => {
        this.setState({
          status,
          loading: false,
        })
      })
  }

  confirmStatusChange = status => {
    const confirm = new root.wav.Notification({
      text: `<h4 class="ui header"><div class="content">Change status?</div></h4>`,
      buttons: [
        root.wav.Notification.button(`Yes`, `ui compact button`, () => {
          this.changeStatus(status)
          confirm.close()
        }),
        root.wav.Notification.button(`No`, `ui compact button`, () => {
          confirm.close()
        }),
      ],
      theme: `semanticui`,
      modal: true,
    }).show()
  }

  changeStatus = status => {
    return this.setState(
      previousState => ({
        ...previousState,
        loading: true,
      }),
      () => {
        return root.wav
          .api({
            method: `post`,
            url: this.props.match.url,
            data: { ...{ status } },
          })
          .then(() => {
            this.setState(
              {
                status,
                loading: false,
              },
              () => {
                dispatchEvent(`update:arrangement`)
                dispatchEvent(`update:${ this.props.match.params.arrangementId }`)
                this.props.validation.validate()
              }
            )
          })
          .catch(error => {
            this.setState(previousState => ({
              ...previousState,
              loading: false,
            }))
          })
      }
    )
  }
}

export default withValidation(Status)
