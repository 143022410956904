import React from "react"
import styled from "styled-components"
import { Icon } from "semantic-ui-react"
import getFriendlyName from "./get-friendly-name"
import { FRIENDLY_NAMES, FIELDS } from "./constants"

const FilterList = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li:not(:first-child) {
    margin-left: 1rem;
  }
`

FilterList.Item = styled.li`
  padding: 0.5rem 1rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  display: flex;
  align-items: center;

  h5 {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  i {
    padding-left: 2rem;
    padding-right: 0.5rem;
    cursor: pointer;
  }
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

export default ({ toggleFilter, currentFilters, searchResults, ...props }) => {
  return (
    <React.Fragment>
      <h4
        style={{
          marginTop: `1rem`,
          marginBottom: `-6px`,
        }}
      >
        Filters:
      </h4>
      <Filters>
        <FilterList>
          {currentFilters.length === 0 && (
            <FilterList.Item
              style={{
                height: `53px`,
                width: `86px`,
                justifyContent: `center`,
              }}
            >
              <p>None</p>
            </FilterList.Item>
          )}

          {currentFilters.length > 0 &&
            currentFilters
              .filter(x => x !== `and`)
              .filter(x => x !== `or`)
              .map((filter, index) => {
                const [ fieldName ] = filter.match(/^\w*(?=\/|\s)/)
                const entity = filter.match(/'(.*)'/)[1]

                let entityValue = entity

                if (fieldName.match(/Id$/)) {
                  const found = (searchResults || []).find(item => {
                    const found = item[fieldName]
                    return Array.isArray(found)
                      ? found.find(id => id === entity)
                      : found
                  })

                  if (found) {
                    if (Array.isArray(found[fieldName])) {
                      const index = found[fieldName].indexOf(entity)

                      entityValue =
                        found[
                          fieldName.slice(0, fieldName.indexOf(`Id`)) + `Name`
                        ][index]
                    } else {
                      entityValue =
                        found[
                          fieldName.slice(0, fieldName.indexOf(`Id`)) + `Name`
                        ]
                    }
                  }
                }

                return (
                  <FilterList.Item key={index}>
                    <div>
                      <h5>{getFriendlyName(FIELDS, fieldName)}</h5>
                      <p>
                        {getFriendlyName(
                          FRIENDLY_NAMES,
                          fieldName,
                          entityValue
                        )}
                      </p>
                    </div>
                    <Icon
                      name="delete"
                      color="red"
                      size="small"
                      onClick={toggleFilter(undefined, undefined, entity)}
                    />
                  </FilterList.Item>
                )
              })}
        </FilterList>
      </Filters>
    </React.Fragment>
  )
}
