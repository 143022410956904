import React from "react"
import { root } from "../services/Globals"
import uploadAsset from "../api/upload-asset"

const initialState = {
  loading: false,
  loadingMessage: ``,
  data: {
    address: {},
  },
}

export default class OrganizationContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...initialState,
    }
  }

  render = () =>
    this.props.children({
      ...this.state,
      save: this.save,
      delete: this.delete,
    })

  componentDidMount = () => {
    this.props.id && this.fetch()
  }

  componentDidUpdate = previousProps => {
    if (previousProps.id !== this.props.id && !this.state.loading) {
      this.props.id
        ? this.fetch()
        : this.setState({
            ...initialState,
          })
    }
  }

  fetch = () => {
    if (this.props.id) {
      this.setState(
        { loading: true, loadingMessage: `Loading organization` },
        () => {
          root.wav
            .api({
              url: `/organizations/ou/${ this.props.id }`,
            })
            .then(({ data }) => {
              this.setState({
                loading: false,
                loadingMessage: ``,
                data,
              })
            })
            .catch(error => {
              this.setState({
                loading: false,
                loadingMessage: ``,
                data: undefined,
              })
            })
        }
      )
    }
  }

  save = ({ id, image, owner, ...data }) => {
    const method = id ? `put` : `post`
    const url = { put: `/organizations/ou/${ id }`, post: `/organizations/ou` }[
      method
    ]
    const loadingMessage =
      { put: `Saving`, post: `Creating` }[method] + ` organization...`

    this.setState(
      {
        loading: true,
        loadingMessage,
      },
      () => {
        Promise.all([
          !image
            ? undefined
            : image.id ||
              uploadAsset(image, ({ loaded, total }) => {
                this.setState({
                  loadingMessage: `Uploading image ${ Math.ceil(
                    (loaded / total) * 100
                  ) }%`,
                })
              }).then(({ id }) => id),
          (owner || {}).id,
        ])
          .then(([ imageId, ownerId ]) => {
            this.setState({ loadingMessage })

            return root.wav.api({
              url,
              method,
              data: {
                imageId,
                ownerId,
                ...data,
              },
            })
          })
          .then(response => {
            this.setState(
              {
                loading: false,
                loadingMessage: ``,
                data: response.data,
              },
              () => {
                method === `post` &&
                  this.props.onCreate &&
                  this.props.onCreate(response)

                method === `put` &&
                  this.props.onUpdate &&
                  this.props.onUpdate(response)

                this.props.onSave && this.props.onSave(response)
              }
            )
          })
          .catch(() => {
            this.setState({
              loading: false,
              loadingMessage: ``,
            })
          })
      }
    )
  }

  delete = () => {
    if (this.props.id) {
      const deleteNotification = new root.wav.Notification({
        text: `<h4 class="ui header"><i aria-hidden="true" class="trash icon"></i><div class="content">You are about to delete this organization permanently, would you like to continue?</div></h4>`,
        buttons: [
          root.wav.Notification.button(`Yes`, `ui compact button`, () => {
            this.setState(
              {
                isLoading: true,
                loadingMessage: `Deleting organization...`,
              },
              () => {
                deleteNotification.close()

                root.wav
                  .api({
                    url: `/organizations/ou/${ this.props.id }`,
                    method: `delete`,
                  })
                  .then(() => {
                    this.setState(
                      {
                        loading: false,
                        loadingMessage: ``,
                        data: undefined,
                      },
                      () => {
                        this.props.onDelete &&
                          this.props.onDelete(this.props.id)
                      }
                    )
                  })
                  .catch(() => {
                    this.setState({
                      loading: false,
                      loadingMessage: ``,
                    })
                  })
              }
            )
          }),
          root.wav.Notification.button(`No`, `ui compact button`, () => {
            deleteNotification.close()
          }),
        ],
        theme: `semanticui`,
        modal: true,
      }).show()
    }
  }
}
