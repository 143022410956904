const FIELDS = {
  imageUrl: `Image`,
  name: `Name`,
  arrangerId: `Arranger`,
  arrangerName: `Arranger`,
  catalog: `Catalog`,
  categoryId: `Category`,
  categoryName: `Category`,
  composition: `Composition`,
  distributingPublisherId: `Distributing publisher`,
  distributingPublisherName: `Distributing publisher`,
  duration: `Duration`,
  ice: `ICE`,
  id: `ID`,
  iswc: `ISWC`,
  knownPerformingArtistId: `Associated artists`,
  knownPerformingArtistName: `Associated artists`,
  ownerId: `Owner`,
  ownerName: `Owner`,
  playlistId: `Playlist`,
  playMode: `Play mode`,
  songId: `Song`,
  songName: `Song`,
  songwriterId: `Songwriter`,
  songwriterName: `Songwriter`,
  status: `Status`,
  type: `Type`,
  createdUtc: `Created`,
  updatedUtc: `Updated`,
}

const STATUS = {
  Draft: `Draft`,
  ReadyForPublish: `Preview`,
  Published: `Published`,
  PreviouslyPublished: `Unpublished`,
}

const FRIENDLY_NAMES = {
  status: STATUS,
  playMode: {
    Unknown: `Unknown`,
    ScoreWithSyncedAudio: `Score with synced audio`,
    ScoreWithAudio: `Score with audio`,
    ScoreWithoutAudio: `Score without audio`,
    LyricWithAudio: `Lyric with audio`,
    LyricWithoutAudio: `Lyric without audio`,
    AudioOnly: `Audio only`,
    EnrichedLyricWithSyncedAudio: `Enriched lyrics with synced audio`,
    EnrichedLyricsWithAudio: `Enriched lyrics with audio`,
    EnrichedLyricsWithoutAudio: `Enriched lyrics without audio`,
  },
  catalog: {
    WeAreVoice: `WAV`,
    MyPlayer: `My Player`,
  },
}

const FIELD_SETTINGS = {
  composition: {
    sortable: true,
  },
  duration: {
    sortable: true,
  },
  playMode: {},
  ownerId: {},
  ownerName: {
    sortable: true,
  },
  songId: {},
  songName: {
    sortable: true,
  },
  knownPerformingArtistId: {},
  knownPerformingArtistName: {},
  categoryId: {},
  categoryName: {},
  arrangerId: {},
  arrangerName: {},
  songwriterId: {},
  songwriterName: {},
  status: {
    sortable: true,
  },
  catalog: {
    sortable: true,
  },
  playlistId: {},
  iswc: {},
  ice: {},
  distributingPublisherId: {},
  distributingPublisherName: {},
  id: {},
  createdUtc: {
    sortable: true,
  },
  updatedUtc: {
    sortable: true,
  },
  type: {},
  name: {
    sortable: true,
  },
  imageUrl: {
    showName: false,
  },
}

export { FIELDS, STATUS, FRIENDLY_NAMES, FIELD_SETTINGS }
