import React from "react"
import Panel, { Content, CommandBar, Command, Search } from "../Panel"
import { QueryParams } from "../QueryParamsContainer"
import { Table, Button } from "semantic-ui-react"
import Observer from "react-intersection-observer"
import { push } from "../history"

const STATUS = {
  Open: 'Open',
  InProgress: 'In progress',
  Closed: 'Closed',
  Ignored: 'Ignored',
}

const Reports = props => (
  <QueryParams>
    {queryParams => (
      <Panel title="Arrangement feedback reports">
        <CommandBar>
          <Command fluid>
            <Search
              value={queryParams.params.reportsQuery || ''}
              onChange={reportsQuery => queryParams.update({ reportsQuery })}
              placeholder="Search by arrangement ID or content provider 1 name..."
            />
          </Command>
        </CommandBar>
        <Content
          style={{ padding: 0 }}
          loading={props.reports.isFetching}
          loadingText="Loading reports"
        >
          <Table basic="very" selectable style={{ marginTop: 0 }}>
            <Table.Body>
              {props.reports.data.map(item => (
                <Table.Row
                  key={item.id}
                  active={props.location.pathname.indexOf(item.id) !== -1}
                  onClick={() => {
                    push(`${ props.match.path }/${ item.id }`)
                  }}
                  style={{
                    cursor: `pointer`,
                    fontWeight: item.status === 'Open' ? 'bold' : 'normal',
                    color: item.status === 'Closed' || item.status === 'Ignored' ? 'grey' : 'black'
                  }}
                >
                  <Table.Cell style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <div
                      style={{
                        minHeight: `40px`,
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `center`,
                      }}
                    >
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}>
                        <span>{item.arrangementTitle || '-'}</span>
                        <span>{STATUS[item.status]}</span>
                      </div>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}>
                        <small>{item.type}</small>
                        <small>{new Date(item.createdUtc).toLocaleString(navigator.language)}</small>
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {props.reports.next && (
            <Observer
              onChange={inView =>
                inView && !props.reports.isFetching && props.reports.fetch(true)
              }
              rootMargin="610px"
            >
              <div
                style={{
                  width: `100%`,
                  display: `flex`,
                  justifyContent: `center`,
                  padding: `3em`,
                }}
              >
                <Button
                  basic
                  content="Load more..."
                  onClick={() => props.reports.fetch(true)}
                  loading={props.reports.isFetchingNext}
                />
              </div>
            </Observer>
          )}
        </Content>
      </Panel>
    )}
  </QueryParams>
)

export default Reports
