import { Component, createRef } from "react"

export default class Focus extends Component {
  constructor(props) {
    super(props)

    this.ref = createRef()
  }

  render = () => {
    return this.props.children({
      elementRef: this.ref,
      focusElement: this.focusElement,
    })
  }

  focusElement = event => {
    event.persist()
    if (event.key === `Tab`) {
      event.preventDefault()
      this.ref.current.focus()
    }
  }
}
