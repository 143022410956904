import React, { Component } from "react"
import Panel, {
  Content,
  CommandBar,
  Command,
  PersistentSearch as Search,
} from "../Panel"
import { Button, Table, Icon } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { PlansContainer } from "./PlansContainer"
import queryString from "query-string"
import Observer from "react-intersection-observer"
import get from "lodash.get"

class Plans extends Component {
  constructor(props) {
    super(props)
    this.searchInput = undefined
    this.state = {
      filters: {
        search: ``,
      },
    }
  }

  render = () => {
    return (
      <Subscribe to={[ PlansContainer ]}>
        {plansStore => (
          <Panel title="Plans">
            <CommandBar>
              <Command fluid>
                <Search
                  value={this.state.filters.search}
                  onChange={this.handleSearch}
                  innerRef={element => (this.searchInput = element)}
                />
              </Command>
            </CommandBar>
            <Content
              style={{ padding: `0` }}
              loading={plansStore.state.isFetching}
              loadingText="Loading plans.."
            >
              <Table basic="very" selectable style={{ marginTop: 0 }}>
                <Table.Body>
                  {this.props.plans.map(item => (
                    <Table.Row
                      key={item.id}
                      active={
                        this.props.location.pathname.indexOf(item.id) !== -1
                      }
                      onClick={() => {
                        this.props.history.push(
                          this.props.match.path +
                            `/${ item.id }` +
                            this.props.location.search
                        )
                      }}
                      style={{ cursor: `pointer` }}
                    >
                      <Table.Cell style={{ paddingLeft: `16px` }}>
                        {item.name}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{
                          width: `1%`,
                          whiteSpace: `nowrap`,
                          paddingRight: 0,
                        }}
                      >
                        <Icon
                          size="small"
                          {...get(
                            {
                              Group: {
                                name: `group`,
                              },
                              Individual: {
                                name: `user`,
                              },
                            },
                            item.type
                          )}
                        />
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{
                          width: `1%`,
                          whiteSpace: `nowrap`,
                          paddingRight: `16px`,
                        }}
                      >
                        <Icon
                          {...get(
                            {
                              Private: {
                                name: `eye slash`,
                                color: `orange`,
                              },
                              Public: {
                                name: `eye`,
                                color: `green`,
                              },
                              Draft: {
                                name: `pencil`,
                                color: `grey`,
                              },
                            },
                            item.status
                          )}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {plansStore.state.next && (
                <Observer
                  onChange={inView =>
                    inView && !plansStore.state.isFetching && plansStore.fetch()
                  }
                  rootMargin="610px"
                >
                  <div
                    style={{
                      width: `100%`,
                      display: `flex`,
                      justifyContent: `center`,
                      padding: `3em`,
                    }}
                  >
                    <Button
                      basic
                      content="Load more..."
                      onClick={plansStore.fetch}
                      loading={plansStore.state.isFetching}
                    />
                  </div>
                </Observer>
              )}
              {!plansStore.state.next && !plansStore.state.isFetching && (
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    minHeight: `122px`,
                  }}
                >
                  <Button
                    basic
                    onClick={() =>
                      this.props.history.push(
                        `/plans/new` + this.props.location.search,
                        plansStore.state.filters.search &&
                          plansStore.state.filters.search.length > 0
                          ? { name: plansStore.state.filters.search }
                          : {}
                      )
                    }
                  >
                    {plansStore.state.filters.search &&
                    plansStore.state.filters.search.length > 0 ? (
                      <span>
                        Add{` `}
                        <strong>{plansStore.state.filters.search}</strong>
                      </span>
                    ) : (
                      `Add missing plan`
                    )}
                  </Button>
                </div>
              )}
            </Content>
          </Panel>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    const { search } = queryString.parse(this.props.location.search)
    search
      ? this.handleSearch(search)
      : !this.props.plansStore.state.isFetching && this.props.plansStore.fetch()
    this.searchInput.focus()
  }

  componentWillUnmount = () => {
    this.props.plansStore.resetState()
  }

  handleSearch = query => {
    this.setState(
      previousState => ({
        ...previousState,
        filters: {
          ...previousState.filters,
          search: query,
        },
      }),
      () => {
        this.props.plansStore.filter(this.state.filters)
      }
    )
  }
}

export default props => (
  <Subscribe to={[ PlansContainer ]}>
    {plansStore => (
      <Plans
        plansStore={plansStore}
        plans={plansStore.state.data || []}
        {...props}
      />
    )}
  </Subscribe>
)
