import { createBrowserHistory } from "history"

const browserHistory = createBrowserHistory()

const updateHistoryStack = (type, url, state, persistQuery = true) =>
  browserHistory[type](
    `${ url }${
      persistQuery && browserHistory.location.search
        ? browserHistory.location.search
        : ``
    }`,
    state
  )

const push = updateHistoryStack.bind(null, `push`)

const replace = updateHistoryStack.bind(null, `replace`)

export { push, replace }

export default browserHistory
