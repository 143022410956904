import React from "react"
import { Route, Switch } from "react-router-dom"
import { push } from "../history"
import { QueryParams } from "../QueryParamsContainer"
import { CollectionProvider } from "../CollectionContainer"
import OrganizationContainer from "./OrganizationContainer"
import Organizations from "./Organizations"
import Organization from "./Organization"

export default props => (
  <QueryParams>
    {queryParams => (
      <CollectionProvider
        url="/organizations/ou"
        params={{ search: queryParams.params.organizationsQuery }}
      >
        {organizations => (
          <React.Fragment>
            <Route
              path="/organizations"
              render={props => (
                <Organizations {...props} organizations={organizations} />
              )}
            />

            <Switch>
              <Route
                path="/organizations/new"
                render={props => (
                  <OrganizationContainer
                    onCreate={({ data: { id } }) => {
                      push(`/organizations/${ id }`)
                      organizations.refresh()
                    }}
                  >
                    {organization => (
                      <Organization {...props} {...organization} />
                    )}
                  </OrganizationContainer>
                )}
              />

              <Route
                path="/organizations/:organizationId"
                render={props => (
                  <OrganizationContainer
                    id={props.match.params.organizationId}
                    onUpdate={({ id }) => organizations.refresh(id)}
                    onDelete={id => {
                      organizations.refresh(id)
                      push(`/organizations`)
                    }}
                  >
                    {organization => (
                      <Route
                        path={props.match.path}
                        render={innerProps => (
                          <Organization {...innerProps} {...organization} />
                        )}
                      />
                    )}
                  </OrganizationContainer>
                )}
              />
            </Switch>
          </React.Fragment>
        )}
      </CollectionProvider>
    )}
  </QueryParams>
)
