import React from "react"
import { Switch, Route, Link } from "react-router-dom"
import Panel, { Menu, MenuItem, CommandBar, Command, Content } from "../Panel"
import { Form, Dropdown, Table, Checkbox } from "semantic-ui-react"
import Img from "../Img"
import { push } from "../history"
import isEqual from "deep-equal"
import { CollectionProvider } from "../CollectionContainer"
import get from "lodash.get"
import ImageAsset from "../ImageAsset"
import Arrangements from "./Arrangements"
import Button from "../TextButton"

class Playlist extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      draft: {
        name: (this.props.location.state || {}).name || ``,
      },
    }
  }

  render = () => {
    const { draft } = this.state

    return (
      <Panel
        title={
          !this.props.match.params.playlistId
            ? `New playlist`
            : this.props.isFetching
            ? `Loading playlist...`
            : draft && draft.name
            ? `Edit playlist: ${ draft.name }`
            : `Playlist`
        }
        onClose={() => push(this.props.returnTo || `/playlists`)}
        menu={
          <Menu>
            <MenuItem
              active={this.props.location.pathname === this.props.match.url}
            >
              <Link to={this.props.match.url + this.props.location.search}>
                Overview
              </Link>
            </MenuItem>
            <MenuItem
              active={
                this.props.location.pathname.indexOf(
                  this.props.match.url + `/arrangements`
                ) > -1
              }
              disabled={!this.props.match.params.playlistId}
            >
              <Link
                to={
                  this.props.match.url +
                  `/arrangements` +
                  this.props.location.search
                }
              >
                Arrangements
              </Link>
            </MenuItem>
          </Menu>
        }
      >
        <Switch>
          <Route
            path={this.props.match.path + `/arrangements`}
            render={innerProps => (
              <Arrangements
                {...innerProps}
                arrangements={this.props.arrangements}
                removeArrangement={this.props.removeArrangement}
                arrangementId={this.props.arrangementId}
              />
            )}
          />
          <Route
            path={this.props.match.path}
            render={props => (
              <React.Fragment>
                <CommandBar>
                  {this.props.match.params.playlistId && (
                    <Command.Delete
                      disabled={this.props.loading}
                      onClick={this.props.delete}
                    />
                  )}
                  <Command.Save
                    disabled={
                      isEqual(this.props.data, draft) || this.props.loading
                    }
                    onClick={() => {
                      this.props.save(draft)
                    }}
                  />
                </CommandBar>
                <Content
                  loading={this.props.loading}
                  loadingText={this.props.loadingMessage}
                >
                  <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
                    <Form.Input
                      label="Name"
                      name="name"
                      value={draft.name || ``}
                      onChange={this.handleChange}
                    />

                    <Form.TextArea
                      label="Description"
                      name="description"
                      value={draft.description || ``}
                      onChange={this.handleChange}
                    />
                    <Form.Field>
                      <label
                        style={{
                          display: `flex`,
                          justifyContent: `space-between`,
                          userSelect: `none`,
                        }}
                      >
                        Owner{` `}
                        {draft.owner && (
                          <Button
                            onClick={event => {
                              event.preventDefault()
                              this.handleOwnerChange()
                            }}
                          >
                            Clear
                          </Button>
                        )}
                      </label>
                      <CollectionProvider url="/users">
                        {users => (
                          <Dropdown
                            selection
                            search
                            deburr
                            loading={users.isFetching}
                            onChange={(event, { value }) =>
                              this.handleOwnerChange(
                                users.data.find(({ id }) => id === value) || {
                                  id: value,
                                }
                              )
                            }
                            onSearchChange={(event, { searchQuery }) =>
                              users.filter({ search: searchQuery })
                            }
                            value={draft.owner ? draft.owner.id : undefined}
                            options={(draft.owner
                              ? [
                                  {
                                    ...draft.owner,
                                    displayName: get(
                                      draft,
                                      `owner.profile.displayName`,
                                      draft.owner.displayName
                                    ),
                                  },
                                ]
                              : []
                            )
                              .concat(
                                users.data.filter(
                                  ({ id }) =>
                                    !draft.owner ||
                                    (draft.owner && draft.owner.id !== id)
                                )
                              )
                              .map(user => ({
                                key: user.id,
                                text: user.displayName,
                                value: user.id,
                                ...(user.profileImage
                                  ? {
                                      content: (
                                        <div
                                          key={user.id}
                                          style={{
                                            display: `flex`,
                                            alignItems: `center`,
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginRight: `1rem`,
                                              width: `45px`,
                                            }}
                                          >
                                            <Img
                                              src={
                                                user.profileImage &&
                                                user.profileImage.url
                                              }
                                              alt={user.displayName}
                                              width={45}
                                            />
                                          </div>
                                          <div>{user.displayName}</div>
                                        </div>
                                      ),
                                    }
                                  : {}),
                              }))
                              .concat(
                                users.next
                                  ? {
                                      key: `next`,
                                      text: `Too many results, refine search...`,
                                      disabled: true,
                                    }
                                  : []
                              )}
                          />
                        )}
                      </CollectionProvider>
                    </Form.Field>
                    <Form.Field>
                      <label>Settings</label>
                      <Table style={{ marginTop: 0 }}>
                        <Table.Body>
                          <Table.Row style={{ lineHeight: 1 }}>
                            <Table.Cell
                              style={{
                                height: `36px`,
                                padding: `0 11px`,
                              }}
                            >
                              Featured
                            </Table.Cell>
                            <Table.Cell
                              textAlign="right"
                              style={{ height: `36px`, padding: `0 11px` }}
                            >
                              <Checkbox
                                name="featured"
                                toggle
                                checked={
                                  this.state.draft && this.state.draft.featured
                                }
                                disabled={this.props.loading}
                                onClick={this.handleCheckbox}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Form.Field>

                    <Form.Field>
                      <label>Image</label>
                      <ImageAsset
                        name="image"
                        onChange={this.handleImageChange}
                        {...(draft.image && draft.image.url
                          ? { initialValue: draft.image.url }
                          : {})}
                      />
                    </Form.Field>

                    {draft.createdUtc && (
                      <Form.Field>
                        <label>Created</label>
                        <p>
                          {new Date(draft.createdUtc).toLocaleString(
                            navigator.language
                          )}
                        </p>
                      </Form.Field>
                    )}

                    {draft.updatedUtc && (
                      <Form.Field>
                        <label>Updated</label>
                        <p>
                          {new Date(draft.updatedUtc).toLocaleString(
                            navigator.language
                          )}
                        </p>
                      </Form.Field>
                    )}
                  </Form>
                </Content>
              </React.Fragment>
            )}
          />
        </Switch>
      </Panel>
    )
  }

  componentDidMount = () => {
    this.props.data &&
      this.setState(previousState => ({
        ...previousState,
        draft: this.props.data,
      }))
  }

  componentDidUpdate = previousProps => {
    !isEqual(previousProps.data, this.props.data) &&
      this.setState(previousState => ({
        ...previousState,
        draft: this.props.data || {},
      }))
  }

  handleOwnerChange = user => {
    this.setState(previousState => ({
      ...previousState,
      draft: {
        ...previousState.draft,
        owner: !!user ? user : null,
      },
    }))
  }

  handleChange = (event, { name, value }) => {
    this.setState(previousState => ({
      ...previousState,
      draft: {
        ...previousState.draft,
        [name]: value,
      },
    }))
  }

  handleImageChange = ({ target: { value } }) => {
    this.setState(previousState => ({
      ...previousState,
      draft: {
        ...previousState.draft,
        image: value,
      },
    }))
  }

  handleCheckbox = (event, { name, checked }) => {
    this.setState(previousState => ({
      ...previousState,
      draft: {
        ...previousState.draft,
        [name]: checked,
      },
    }))
  }
}

export default Playlist
