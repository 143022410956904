import { Container } from "unstated"
import { root } from "../services/Globals"
import { Subject, from } from "rxjs"
import { debounceTime, tap, map, switchMap } from "rxjs/operators"

const initialState = {
  isFetching: false,
  data: [],
  filters: {
    search: undefined,
    limit: 15,
    sort: undefined,
  },
  next: undefined,
}

class ChoirsContainer extends Container {
  constructor() {
    super()
    this.state = { ...initialState }

    this.fetch$ = new Subject()
    this.fetch$
      .pipe(
        debounceTime(200),
        tap(() => this.setState({ isFetching: true })),
        map(() => ({ ...this.state })),
        map(state =>
          state.next
            ? {
                url: state.next,
              }
            : {
                url: `/choirs`,
                params: {
                  ...Object.keys(state.filters).reduce(
                    (params, filterName) =>
                      state.filters[filterName]
                        ? {
                            ...params,
                            [filterName]: state.filters[filterName],
                          }
                        : params,
                    {}
                  ),
                },
              }
        ),
        switchMap(configuration => from(root.wav.api(configuration))),
        map(({ data }) => data)
      )
      .subscribe(data => {
        this.setState({
          isFetching: false,
          data: this.state.next
            ? [ ...this.state.data, ...data.items ]
            : data.items,
          next: data.next ? data.next : undefined,
        })
      })
  }

  fetch = () => this.fetch$.next()

  filter = filters => {
    this.setState({
      ...initialState,
      filters: {
        ...this.state.filters,
        ...filters,
      },
    })

    this.fetch$.next(this.state)
  }

  removeId = id => {
    this.setState({
      ...this.state,
      data: [ ...this.state.data.filter(item => item.id !== id) ],
    })
  }

  updateId = id => {
    return root.wav
      .api({
        url: `/choirs/${ id }`,
      })
      .then(({ data }) => {
        this.setState({
          data:
            this.state.data.filter(item => item.id === data.id).length === 1
              ? this.state.data.map(item => (item.id === data.id ? data : item))
              : [ ...this.state.data, data ],
        })
        return data.id
      })
  }

  resetState = () => {
    this.setState({ ...initialState })
  }
}

export { ChoirsContainer }
