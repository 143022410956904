import { root } from "../services/Globals"

export const getClients = () => {
    return root.wav.api
    .request({
      url: `users/clients`,
      method: 'GET'
    }).then(({ data }) => data) 
}

export const getCatalogInfo = ({ arrangementId }) => {
    return root.wav.api
    .request({
      url: `arrangements/${arrangementId}/catalog`,
      method: 'GET'
    }).then(({ data }) => data) 
}

export const moveToPublicCatalog = ({ arrangementId }) => {
    return root.wav.api
    .request({
      url: `arrangements/${arrangementId}/catalog/public`,
      method: 'post'
    }).then(({ data }) => data) 
}

export const moveToMyTitlesCatalog = ({ arrangementId, ownerClientUserId, userId }) => {
    return root.wav.api
    .request({
      url: `arrangements/${arrangementId}/catalog/user`,
      method: 'post',
      data: {
        ownerClientUserId,
        userId
      }
    }).then(({ data }) => data) 
}