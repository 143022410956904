import React from "react"
import { Container, Header } from "semantic-ui-react"

export default ({ error }) => (
  <div
    style={{
      width: `100vw`,
      height: `100vh`,
      backgroundColor: `coral`,
      color: `white`,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
    }}
  >
    <div>
      <Container text textAlign="center" style={{ marginBottom: `5rem` }}>
        <Header size="large" style={{ color: `white` }}>
          The application encountered an error
        </Header>
      </Container>
      <Container text>
        <Header size="medium" style={{ color: `white` }}>
          Error details
        </Header>
        <pre style={{ whiteSpace: `pre-wrap` }}>
          <code>{JSON.stringify(error, null, 2)}</code>
        </pre>
      </Container>
    </div>
  </div>
)
