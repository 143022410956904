import React from "react"
import { Route } from "react-router-dom"
import { push } from "../history"
import { QueryParams } from "../QueryParamsContainer"
import { CollectionProvider } from "../CollectionContainer"
import Categories from "./Categories"
import Category from "./Category"

export default props => (
  <QueryParams>
    {queryParams => (
      <CollectionProvider
        url="/genres"
        params={{ search: queryParams.params.categoriesQuery }}
      >
        {categories => (
          <React.Fragment>
            <Route
              path={props.match.url}
              render={props => (
                <Categories categories={categories} {...props} />
              )}
            />

            <Route
              path="/categories/:categoryId"
              render={props => (
                <Category
                  onCreate={({ data: { id } }) => {
                    push(`/categories/${ id }`)
                    categories.refresh()
                  }}
                  onUpdate={({ data: { id } }) => categories.refresh(id)}
                  onDelete={id => {
                    push(
                      props.match.url.slice(0, props.match.url.lastIndexOf(`/`))
                    )
                    categories.refresh(id)
                  }}
                  {...props}
                />
              )}
            />
          </React.Fragment>
        )}
      </CollectionProvider>
    )}
  </QueryParams>
)
