import React, { Component } from "react"
import { Content, CommandBar, Command, Search } from "../Panel"
import { Item, Button } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { ChoirContainer } from "./ChoirContainer"
import { MembersContainer } from "./MembersContainer"
import { LicensesContainer } from "./LicensesContainer"
import Observer from "react-intersection-observer"
// import get from "lodash.get"

class Members extends Component {
  constructor(props) {
    super(props)
    this.searchInput = undefined
    this.state = {
      search: ``,
    }
  }

  render = () => {
    return (
      <Subscribe to={[ MembersContainer ]}>
        {members => (
          <React.Fragment>
            <CommandBar>
              <Command fluid>
                <Search
                  value={this.state.search}
                  onChange={this.handleSearch}
                  loading={members.state.isFetching}
                  innerRef={element => (this.searchInput = element)}
                />
              </Command>
            </CommandBar>
            <Content
              style={{
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: `2rem`,
              }}
            >
              <Item.Group relaxed style={{ margin: 0 }}>
                {members.state.data.map(item => (
                  <Item
                    key={item.user.id}
                    style={{
                      margin: 0,
                      marginBottom: `-1.5em`,
                      padding: `1.5em`,
                      minHeight: `122px`,
                      background:
                        this.props.location.pathname.indexOf(item.user.id) !==
                        -1
                          ? `rgb(240,240,240)`
                          : `transparent`,
                      cursor: `pointer`,
                    }}
                    onClick={() =>
                      this.props.history.push(
                        `${ this.props.match.url }/${ item.user.id }`,
                        {
                          member: item,
                          // license: this.props.licensesStore.state.data.find(
                          //   license =>
                          //     get(license, `subscription.user.id`) ===
                          //     item.user.id
                          // )
                        }
                      )
                    }
                  >
                    {item.user.profileImage ? (
                      <Item.Image
                        size="tiny"
                        src={item.user.profileImage.url + `?width=80`}
                        style={{
                          marginTop: `auto`,
                          marginBottom: `auto`,
                        }}
                      />
                    ) : (
                      <div
                        className="ui tiny image"
                        style={{
                          width: `80px`,
                          height: `80px`,
                          border: `dashed 3px rgb(140,140,140)`,
                        }}
                      />
                    )}
                    <Item.Content verticalAlign="middle">
                      <Item.Header>{item.user.displayName}</Item.Header>
                      <Item.Meta>
                        {new Date(item.memberSinceUtc).toLocaleString(
                          navigator.language
                        )}
                      </Item.Meta>
                      <Item.Extra>{item.memberType}</Item.Extra>
                    </Item.Content>
                  </Item>
                ))}
              </Item.Group>
              {members.state.next && (
                <Observer
                  onChange={inView =>
                    inView && !members.state.isFetching && members.fetch()
                  }
                  rootMargin="610px"
                >
                  <div
                    style={{
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      height: `122px`,
                    }}
                  >
                    <Button
                      basic
                      content="Load more..."
                      onClick={members.fetch}
                      loading={members.state.isFetching}
                    />
                  </div>
                </Observer>
              )}
            </Content>
          </React.Fragment>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    const choirId = this.props.match.params.choirId

    setTimeout(() => {
      this.props.membersStore.fetch(choirId)
      this.props.licensesStore.fetch(choirId)
    }, 0)
    this.searchInput.focus()
  }

  componentWillUnmount = () => {
    this.props.membersStore.resetState()
  }

  handleSearch = query => {
    this.setState({ search: query }, () => {
      this.props.membersStore.filter({
        search: query,
        sort: `user.displayName`,
      })
    })
  }
}

export default props => (
  <Subscribe to={[ ChoirContainer, MembersContainer, LicensesContainer ]}>
    {(choirStore, membersStore, licensesStore) => (
      <Members
        choirStore={choirStore}
        membersStore={membersStore}
        licensesStore={licensesStore}
        {...props}
      />
    )}
  </Subscribe>
)
