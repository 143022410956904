import React, { Component } from "react"
import { Content } from "../Panel"
import { Table, Checkbox, Dropdown } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { ChoirContainer } from "./ChoirContainer"

class Settings extends Component {
  render = () => {
    return (
      <Subscribe to={[ ChoirContainer ]}>
        {choirStore => (
          <Content loading={choirStore.state.isFetching}>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Disable feed post</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Checkbox
                      name="disableFeedPost"
                      toggle
                      checked={
                        choirStore.state.data &&
                        choirStore.state.data.settings &&
                        choirStore.state.data.settings.disableFeedPost
                      }
                      disabled={choirStore.state.isSaving}
                      onClick={choirStore.toggleDisableFeedPost}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Hide from listings</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Checkbox
                      name="hideFromListings"
                      toggle
                      checked={
                        choirStore.state.data &&
                        choirStore.state.data.settings &&
                        choirStore.state.data.settings.hideFromListings
                      }
                      disabled={choirStore.state.isSaving}
                      onClick={choirStore.toggleHideFromListings}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Assign licence</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Checkbox
                      name="assignLicence"
                      toggle
                      checked={
                        choirStore.state.data &&
                        choirStore.state.data.settings &&
                        choirStore.state.data.settings.assignLicence
                      }
                      disabled={choirStore.state.isSaving}
                      onClick={choirStore.toggleAssignLicence}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Access</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      name="access"
                      options={[
                        {
                          text: `Private`,
                          value: `Private`,
                        },
                        {
                          text: `Public`,
                          value: `Public`,
                        },
                      ]}
                      value={
                        choirStore.state.data &&
                        choirStore.state.data.settings &&
                        choirStore.state.data.settings.access
                      }
                      disabled={choirStore.state.isSaving}
                      onChange={(event, { value }) =>
                        choirStore.changeAccess(value)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Content>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    !this.props.choirId &&
      this.props.fetchChoir(this.props.match.params.choirId)
  }

  componentWillReceiveProps = nextProps => {
    !nextProps.store.isFetching &&
      (nextProps.store.data.id &&
        nextProps.store.data.id !== nextProps.match.params.choirId) &&
      nextProps.fetchChoir(nextProps.match.params.id)
  }
}

export default props => (
  <Subscribe to={[ ChoirContainer ]}>
    {choirStore => (
      <Settings
        {...props}
        fetchChoir={choirStore.fetchChoir}
        store={choirStore.state}
      />
    )}
  </Subscribe>
)
