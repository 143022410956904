import React from "react"
import { root } from "./services/Globals"
import { Subject, from } from "rxjs"
import { debounceTime, map, switchMap } from "rxjs/operators"

const initialState = {
  isFetching: false,
  isFetchingNext: false,
  data: [],
  filters: {
    search: undefined,
    limit: 30,
    sort: undefined,
  },
  next: undefined,
}

class ListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }

    this.fetch$ = new Subject().pipe(
      debounceTime(200),
      map(() =>
        this.state.isFetchingNext && this.state.next
          ? {
              url: this.state.next,
            }
          : {
              url: props.url,
              params: {
                ...Object.keys(this.state.filters).reduce(
                  (params, filterName) =>
                    this.state.filters[filterName]
                      ? {
                          ...params,
                          [filterName]: this.state.filters[filterName],
                        }
                      : params,
                  {}
                ),
              },
            }
      ),
      switchMap(configuration => from(root.wav.api(configuration))),
      map(({ data }) => data)
    )
  }

  render = () =>
    this.props.children({
      state: this.state,
      fetch: this.fetch,
      filter: this.filter,
    })

  componentDidMount = () => {
    this.subscription = this.fetch$.subscribe(
      data => {
        this.setState(previousState => ({
          ...previousState,
          isFetching: false,
          isFetchingNext: false,
          data: this.state.isFetchingNext
            ? [ ...this.state.data, ...data.items ]
            : data.items,
          next: data.next ? data.next : undefined,
        }))
      },
      error => {
        console.error(error)
        this.setState(previousState => ({
          ...previousState,
          isFetching: false,
        }))
      }
    )
  }

  componentWillUnmount = () => {
    this.subscription.unsubscribe()
  }

  fetch = next => {
    this.props.url &&
      this.setState(
        previousState => ({
          ...previousState,
          [next ? `isFetchingNext` : `isFetching`]: true,
        }),
        () => this.fetch$.next()
      )
  }

  filter = filters => {
    this.setState(
      previousState => ({
        ...initialState,
        filters: {
          ...previousState.filters,
          ...filters,
        },
      }),
      () => this.fetch()
    )
  }
}

export default ListContainer
