import React from "react"

export default ({ fill = `#fff`, size = 1, unit = `rem`, ...props }) => (
  <div style={{ width: size * 1.04 + unit, height: size + unit }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 130 125"
      {...props}
    >
      <path
        fill="#fff"
        d="M65 97.54a37.51 37.51 0 0 0 15.13-71.83A13.8 13.8 0 0 1 93.87 1.79a65 65 0 1 1-57.75 0 13.8 13.8 0 0 1 13.75 23.92A37.51 37.51 0 0 0 65 97.54z"
      />
    </svg>
  </div>
)
