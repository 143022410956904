import React from "react"
import styled from "styled-components"
import { Input as SUIInput, Button as SUIButton } from "semantic-ui-react"

const FileInput = styled.input.attrs({ type: `file` })`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const Input = styled(SUIInput)`
  input {
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`

const Button = styled(SUIButton)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin: 0 !important;
`

const Clear = styled(SUIButton).attrs({
  icon: `trash alternate outline`,
  color: `red`,
})`
  border-radius: 0 !important;
  margin: 0 !important;
`

const Container = styled.div`
  display: flex;
`

export default class FileInputComponent extends React.Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  render = () => {
    const {
      name,
      accept = `*`,
      icon = `file outline`,
      iconPosition = `left`,
      action = `Browse`,
      placeholder = `Select a file...`,
      onKeyDown,
      onChange,
      value,
    } = this.props

    return (
      <Container onKeyDown={onKeyDown}>
        <FileInput
          name={name}
          accept={accept}
          tabIndex={-1}
          ref={this.inputRef}
          onChange={event => {
            onChange && onChange(event)
            this.inputRef.current.value = ``
          }}
        />
        <Input
          icon={icon}
          iconPosition={iconPosition}
          placeholder={placeholder}
          value={
            typeof value === `string`
              ? value
              : value && value.name
              ? value.name
              : ``
          }
          onKeyDown={event => {
            if (event.key === ` ` || event.key === `Enter`) {
              event.preventDefault()
              event.stopPropagation()
              this.openFileDialog()
            }

            onKeyDown && onKeyDown(event)
          }}
          onClick={this.openFileDialog}
        />
        {value && (
          <Clear
            onClick={event => {
              event.preventDefault()
              onChange && onChange({ name, target: this.inputRef.current })
            }}
          />
        )}
        <Button type="button" tabIndex={-1} onClick={this.openFileDialog}>
          {action}
        </Button>
      </Container>
    )
  }

  openFileDialog = event => {
    this.inputRef.current.click()
  }
}
