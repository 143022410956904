import React from "react"
import { CommandBar, Command, Content } from "../Panel"
import { Form, Image } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { ChoirContainer } from "./ChoirContainer"
import { ChoirsContainer } from "./ChoirsContainer"
import { root } from "../services/Globals"

export default props => (
  <Subscribe to={[ ChoirContainer, ChoirsContainer ]}>
    {(choirStore, choirsStore) => (
      <React.Fragment>
        <CommandBar>
          <Command.Delete
            onClick={() => {
              const deleteNotification = new root.wav.Notification({
                text: `<h4 class="ui header"><i aria-hidden="true" class="trash icon"></i><div class="content">You are about to delete this choir permanently, do you want to continue?</div></h4>`,
                buttons: [
                  root.wav.Notification.button(
                    `Yes`,
                    `ui compact button`,
                    () => {
                      choirStore.remove().then(removedId => {
                        choirsStore.removeId(removedId)
                        props.history.push(
                          props.returnTo ||
                            `/choirs${
                              props.location.search ? props.location.search : ``
                            }`
                        )
                        deleteNotification.close()
                      })
                    }
                  ),
                  root.wav.Notification.button(
                    `No`,
                    `ui compact button`,
                    () => {
                      deleteNotification.close()
                    }
                  ),
                ],
                theme: `semanticui`,
                modal: true,
              }).show()
            }}
          />
        </CommandBar>
        <Content loading={choirStore.state.isFetching}>
          <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
            <Form.Field>
              <label>Name</label>
              <p>{choirStore.state.details.name}</p>
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <p>{choirStore.state.details.description}</p>
            </Form.Field>
            <Form.Field>
              <label>Members</label>
              <p>{choirStore.state.details.memberCount}</p>
            </Form.Field>
            {choirStore.state.details.image && (
              <Form.Field>
                <label>Image</label>
                <Image
                  src={choirStore.state.details.image.url + `?width=300`}
                  size="medium"
                />
              </Form.Field>
            )}
            {choirStore.state.details.place && (
              <Form.Field>
                <label>Place</label>
                <a
                  href={`https://www.google.com/maps/?q=${
                    choirStore.state.details.place.latitude
                  },${ choirStore.state.details.place.longitude }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {choirStore.state.details.place.name}
                </a>
              </Form.Field>
            )}
            <Form.Field>
              <label>Created</label>
              <p>
                {choirStore.state.details.createdUtc &&
                  new Date(choirStore.state.details.createdUtc).toLocaleString(
                    navigator.language
                  )}
              </p>
            </Form.Field>
            <Form.Field>
              <label>Updated</label>
              <p>
                {choirStore.state.details.updatedUtc &&
                  new Date(choirStore.state.details.updatedUtc).toLocaleString(
                    navigator.language
                  )}
              </p>
            </Form.Field>
            {choirsStore.state.data.externalChoirId && (
              <Form.Field>
                <label>External Choir ID</label>
                <p>{choirStore.state.details.externalChoirId}</p>
              </Form.Field>
            )}
          </Form>
        </Content>
      </React.Fragment>
    )}
  </Subscribe>
)
