import React from "react"
import { Route, Switch, matchPath } from "react-router-dom"
import { push } from "../history"
import { QueryParams } from "../QueryParamsContainer"
import { CollectionProvider } from "../CollectionContainer"
import Panel, { Menu, MenuItem } from "../Panel"
import Songs from "./Songs"
import Song from "./Song"
import SongContainer from "./SongContainer"
import Contracts from "./Contracts"
import Contract from "./Contract"
import ContractContainer from "./ContractContainer"
import Artist from "../Artists/Artist"
import Songwriter from "../Songwriters/Songwriter"
import Draft from "../Draft"
import merge from "lodash.merge"
import get from "lodash.get"
import OrganizationContainer from "../Organizations/OrganizationContainer"
import Organization from "../Organizations/Organization"
import Button from "../TextButton"

export default props => (
  <QueryParams>
    {queryParams => (
      <CollectionProvider
        url={props.match.url}
        params={{ search: queryParams.params.songsQuery }}
      >
        {songs => (
          <React.Fragment>
            <Route
              path={props.match.url}
              render={props => <Songs songs={songs} {...props} />}
            />

            <Route
              path="/songs/:songId"
              render={props => (
                <SongContainer
                  id={
                    props.match.params.songId !== `new` &&
                    props.match.params.songId
                  }
                  onCreate={({ data: { id } }) => {
                    push(`/songs/${ id }`)
                    songs.refresh()
                  }}
                  onUpdate={({ data: { id } }) => songs.refresh(id)}
                  onDelete={id => {
                    push(
                      props.match.url.slice(0, props.match.url.lastIndexOf(`/`))
                    )
                    songs.refresh(id)
                  }}
                >
                  {song => (
                    <Draft
                      key={get(song, `data.id`)}
                      original={merge(
                        {},
                        {
                          name: get(props, `location.state.name` || ``),
                        },
                        song.data
                      )}
                    >
                      {songDraft => (
                        <CollectionProvider
                          url={
                            props.location.pathname.includes(
                              props.match.url + `/contracts`
                            ) && props.match.url + `/contracts`
                          }
                        >
                          {contracts => (
                            <ContractContainer
                              songId={get(song, `data.id`)}
                              contractId={
                                [
                                  get(
                                    matchPath(
                                      props.location.pathname,
                                      props.match.path +
                                        `/contracts/:contractId`
                                    ),
                                    `params.contractId`
                                  ),
                                ].filter(id => id !== `new`)[0]
                              }
                              onCreate={({ data: { id } }) => {
                                push(`${ props.match.url }/contracts/${ id }`)
                                contracts.refresh()
                              }}
                              onUpdate={({ data: { id } }) =>
                                contracts.refresh(id)
                              }
                            >
                              {contract => (
                                <Draft
                                  key={get(contract, `data.id`)}
                                  original={contract.data}
                                >
                                  {contractDraft => (
                                    <React.Fragment>
                                      <Panel
                                        title={
                                          song.loading
                                            ? `Loading song...`
                                            : get(
                                                song,
                                                `data.name`,
                                                `Add: ${ get(
                                                  songDraft,
                                                  `state.name`,
                                                  ``
                                                ) }`
                                              )
                                        }
                                        onClose={() => push(`/songs`)}
                                        menu={
                                          get(song, `data.id`) && (
                                            <Menu>
                                              <MenuItem
                                                active={
                                                  !props.location.pathname.includes(
                                                    props.match.url +
                                                      `/contracts`
                                                  )
                                                }
                                              >
                                                <Button
                                                  onClick={() =>
                                                    push(props.match.url)
                                                  }
                                                >
                                                  Details
                                                </Button>
                                              </MenuItem>
                                              <MenuItem
                                                active={props.location.pathname.includes(
                                                  props.match.url + `/contracts`
                                                )}
                                              >
                                                <Button
                                                  onClick={() =>
                                                    push(
                                                      props.match.url +
                                                        `/contracts`
                                                    )
                                                  }
                                                >
                                                  Contracts
                                                </Button>
                                              </MenuItem>
                                            </Menu>
                                          )
                                        }
                                      >
                                        <Switch>
                                          <Route
                                            path={
                                              props.match.path + `/contracts`
                                            }
                                            render={props => (
                                              <Contracts
                                                contracts={contracts}
                                                {...props}
                                              />
                                            )}
                                          />
                                          <Route
                                            path={props.match.path}
                                            render={props => (
                                              <Song
                                                draft={songDraft.state}
                                                onChange={
                                                  songDraft.handleChange
                                                }
                                                dirty={songDraft.dirty}
                                                loading={song.loading}
                                                loadingMessage={
                                                  song.loadingMessage
                                                }
                                                save={() =>
                                                  song.save(songDraft.state)
                                                }
                                                delete={song.delete}
                                                {...props}
                                              />
                                            )}
                                          />
                                        </Switch>
                                      </Panel>
                                      <Route
                                        path={`${
                                          props.match.path
                                        }/contracts/:contractId`}
                                        render={props => (
                                          <React.Fragment>
                                            <Contract
                                              draft={contractDraft.state}
                                              onChange={
                                                contractDraft.handleChange
                                              }
                                              dirty={contractDraft.dirty}
                                              loading={contract.loading}
                                              loadingMessage={
                                                contract.loadingMessage
                                              }
                                              save={() =>
                                                contract.save(
                                                  contractDraft.state
                                                )
                                              }
                                              {...props}
                                            />
                                            <Route
                                              path={
                                                props.match.path +
                                                `/organization`
                                              }
                                              render={props => (
                                                <OrganizationContainer
                                                  onCreate={response => {
                                                    contractDraft.handleChange({
                                                      target: {
                                                        name: `contractHolders`,
                                                        value: get(
                                                          contractDraft,
                                                          `state.contractHolders`,
                                                          []
                                                        ).concat({
                                                          kickback: 0,
                                                          holder: response.data,
                                                        }),
                                                      },
                                                    })

                                                    push(
                                                      props.match.url.slice(
                                                        0,
                                                        props.match.url.lastIndexOf(
                                                          `/`
                                                        )
                                                      )
                                                    )
                                                  }}
                                                >
                                                  {organization => (
                                                    <Organization
                                                      returnTo={props.match.url.slice(
                                                        0,
                                                        props.match.url.lastIndexOf(
                                                          `/`
                                                        )
                                                      )}
                                                      {...organization}
                                                      {...props}
                                                    />
                                                  )}
                                                </OrganizationContainer>
                                              )}
                                            />
                                          </React.Fragment>
                                        )}
                                      />
                                      <Route
                                        path={`${ props.match.path }/artist`}
                                        render={props => (
                                          <Artist
                                            {...props}
                                            onCreate={({ data }) => {
                                              songDraft.handleChange({
                                                target: {
                                                  name: `knownPerformingArtists`,
                                                  value: [
                                                    ...songDraft.state
                                                      .knownPerformingArtists,
                                                    data,
                                                  ],
                                                },
                                              })

                                              push(
                                                props.match.url.slice(
                                                  0,
                                                  props.match.url.lastIndexOf(
                                                    `/`
                                                  )
                                                )
                                              )
                                            }}
                                            returnTo={props.match.url.slice(
                                              0,
                                              props.match.url.lastIndexOf(`/`)
                                            )}
                                          />
                                        )}
                                      />
                                      <Route
                                        path={`${ props.match.path }/songwriter`}
                                        render={props => (
                                          <Songwriter
                                            {...props}
                                            onCreate={({ data }) => {
                                              songDraft.handleChange({
                                                target: {
                                                  name: `songwriters`,
                                                  value: [
                                                    ...songDraft.state
                                                      .songwriters,
                                                    data,
                                                  ],
                                                },
                                              })

                                              push(
                                                props.match.url.slice(
                                                  0,
                                                  props.match.url.lastIndexOf(
                                                    `/`
                                                  )
                                                )
                                              )
                                            }}
                                            returnTo={props.match.url.slice(
                                              0,
                                              props.match.url.lastIndexOf(`/`)
                                            )}
                                          />
                                        )}
                                      />
                                    </React.Fragment>
                                  )}
                                </Draft>
                              )}
                            </ContractContainer>
                          )}
                        </CollectionProvider>
                      )}
                    </Draft>
                  )}
                </SongContainer>
              )}
            />
          </React.Fragment>
        )}
      </CollectionProvider>
    )}
  </QueryParams>
)
