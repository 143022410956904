import React from "react"
import { root } from "../services/Globals"
import uploadAsset from "../api/upload-asset"

export default class PlaylistContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      loadingMessage: ``,
      activeArrangementId: undefined,
      data: undefined,
    }
  }

  render = () =>
    this.props.children({
      ...this.state,
      save: this.save,
      delete: this.delete,
      addArrangement: this.addArrangement,
      removeArrangement: this.removeArrangement,
    })

  componentDidMount = () => {
    this.props.id && this.fetch()
  }

  componentDidUpdate = previousProps => {
    if (previousProps.id !== this.props.id && !this.state.loading) {
      this.props.id
        ? this.fetch()
        : this.setState({
            data: undefined,
          })
    }
  }

  fetch = () => {
    if (this.props.id) {
      this.setState(
        { loading: true, loadingMessage: `Loading playlist` },
        () => {
          root.wav
            .api({
              url: `/playlists/${ this.props.id }`,
            })
            .then(({ data }) => {
              this.setState({
                loading: false,
                loadingMessage: ``,
                data,
              })
            })
            .catch(error => {
              this.setState({
                loading: false,
                loadingMessage: ``,
                data: undefined,
              })
            })
        }
      )
    }
  }

  save = ({ id, image, owner, ...data }) => {
    const method = id ? `put` : `post`
    const url = { put: `/playlists/${ id }`, post: `/playlists` }[method]
    const loadingMessage =
      { put: `Saving`, post: `Creating` }[method] + ` playlist...`

    this.setState(
      {
        loading: true,
        loadingMessage,
      },
      () => {
        Promise.all([
          !image
            ? undefined
            : image.id ||
              uploadAsset(image, ({ loaded, total }) => {
                this.setState({
                  loadingMessage: `Uploading image ${ Math.ceil(
                    (loaded / total) * 100
                  ) }%`,
                })
              }).then(({ id }) => id),
          (owner || {}).id,
        ])
          .then(([ imageId, ownerId ]) => {
            this.setState({ loadingMessage })

            return root.wav.api({
              url,
              method,
              data: Object.entries({
                ...data,
                imageId,
                ownerId,
              }).reduce(
                (data, [ key, value ]) =>
                  !!value
                    ? Object.assign(data, {
                        [key]: value,
                      })
                    : data,
                {}
              ),
            })
          })
          .then(response => {
            this.setState(
              {
                loading: false,
                loadingMessage: ``,
                data: response.data,
              },
              () => {
                method === `post` &&
                  this.props.onCreate &&
                  this.props.onCreate(response)
                method === `put` &&
                  this.props.onUpdate &&
                  this.props.onUpdate(response)
              }
            )
          })
          .catch(() => {
            this.setState({
              loading: false,
              loadingMessage: ``,
            })
          })
      }
    )
  }

  delete = () => {
    if (this.props.id) {
      const deleteNotification = new root.wav.Notification({
        text: `<h4 class="ui header"><i aria-hidden="true" class="trash icon"></i><div class="content">You are about to delete this playlist permanently, would you like to continue?</div></h4>`,
        buttons: [
          root.wav.Notification.button(`Yes`, `ui compact button`, () => {
            this.setState(
              {
                isLoading: true,
                loadingMessage: `Deleting playlist...`,
              },
              () => {
                deleteNotification.close()

                root.wav
                  .api({
                    url: `/playlists/${ this.props.id }`,
                    method: `delete`,
                  })
                  .then(() => {
                    this.setState(
                      {
                        loading: false,
                        loadingMessage: ``,
                        data: undefined,
                      },
                      () => {
                        this.props.onDelete &&
                          this.props.onDelete(this.props.id)
                      }
                    )
                  })
                  .catch(() => {
                    this.setState({
                      loading: false,
                      loadingMessage: ``,
                    })
                  })
              }
            )
          }),
          root.wav.Notification.button(`No`, `ui compact button`, () => {
            deleteNotification.close()
          }),
        ],
        theme: `semanticui`,
        modal: true,
      }).show()
    }
  }

  addArrangement = arrangementId => {
    if (this.props.id) {
      this.setState(
        {
          loading: true,
          loadingMessage: `Adding arrangement...`,
          activeArrangementId: arrangementId,
        },
        () => {
          root.wav
            .api({
              url: `/playlists/${ this.props.id }/arrangements`,
              method: `post`,
              data: {
                arrangementId,
              },
            })
            .then(response => {
              this.setState(
                {
                  loading: false,
                  loadingMessage: ``,
                  activeArrangementId: undefined,
                },
                () => {
                  this.props.onArrangementAdded &&
                    this.props.onArrangementAdded(response)
                }
              )
            })
            .catch(error => {
              this.setState({
                loading: false,
                loadingMessage: ``,
                activeArrangementId: undefined,
              })
            })
        }
      )
    }
  }

  removeArrangement = arrangementId => {
    if (this.props.id) {
      this.setState(
        {
          loading: true,
          loadingMessage: `Removing arrangement...`,
          activeArrangementId: arrangementId,
        },
        () => {
          root.wav
            .api({
              url: `/playlists/${ this.props.id }/arrangements/${ arrangementId }`,
              method: `delete`,
            })
            .then(response => {
              this.setState(
                {
                  loading: false,
                  loadingMessage: ``,
                  activeArrangementId: undefined,
                },
                () => {
                  this.props.onArrangementRemoved &&
                    this.props.onArrangementRemoved(response)
                }
              )
            })
            .catch(error => {
              this.setState({
                loading: false,
                loadingMessage: ``,
                activeArrangementId: undefined,
              })
            })
        }
      )
    }
  }
}
