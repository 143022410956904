import React from "react"
import { Route } from "react-router-dom"
import { push } from "../history"
import { QueryParams } from "../QueryParamsContainer"
import { CollectionProvider } from "../CollectionContainer"
import Songwriters from "./Songwriters"
import Songwriter from "./Songwriter"

export default props => (
  <QueryParams>
    {queryParams => (
      <CollectionProvider
        url="/songwriters"
        params={{ search: queryParams.params.songwritersQuery }}
      >
        {songwriters => (
          <React.Fragment>
            <Route
              path={props.match.url}
              render={props => (
                <Songwriters songwriters={songwriters} {...props} />
              )}
            />

            <Route
              path="/songwriters/:songwriterId"
              render={props => (
                <Songwriter
                  onCreate={({ data: { id } }) => {
                    push(`/songwriters/${ id }`)
                    songwriters.refresh()
                  }}
                  onUpdate={({ data: { id } }) => songwriters.refresh(id)}
                  onDelete={id => {
                    push(
                      props.match.url.slice(0, props.match.url.lastIndexOf(`/`))
                    )
                    songwriters.refresh(id)
                  }}
                  {...props}
                />
              )}
            />
          </React.Fragment>
        )}
      </CollectionProvider>
    )}
  </QueryParams>
)
