import React, { Component } from "react"
import Panel, {
  Content,
  CommandBar,
  Command,
  PersistentSearch as Search,
} from "../Panel"
import { Button, Table } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { CampaignsContainer } from "./CampaignsContainer"
import queryString from "query-string"
import Observer from "react-intersection-observer"
import get from "lodash.get"

class Campaigns extends Component {
  constructor(props) {
    super(props)
    this.searchInput = undefined
    this.state = {
      filters: {
        search: ``,
      },
    }
  }

  render = () => {
    return (
      <Subscribe to={[ CampaignsContainer ]}>
        {campaignsStore => (
          <Panel title="Campaigns">
            <CommandBar>
              <Command fluid>
                <Search
                  value={this.state.filters.search}
                  onChange={this.handleSearch}
                  innerRef={element => (this.searchInput = element)}
                />
              </Command>
            </CommandBar>
            <Content
              style={{ padding: `0` }}
              loading={campaignsStore.state.isFetching}
              loadingText="Loading campaigns.."
            >
              <Table
                basic="very"
                // fixed
                // singleLine
                selectable
                style={{ marginTop: 0 }}
              >
                <Table.Body>
                  {this.props.campaigns.map(item => (
                    <Table.Row
                      key={item.id}
                      active={
                        this.props.location.pathname.indexOf(item.id) !== -1
                      }
                      onClick={() => {
                        this.props.history.push(
                          this.props.match.path +
                            `/${ item.id }` +
                            this.props.location.search
                        )
                      }}
                      style={{ cursor: `pointer` }}
                    >
                      <Table.Cell style={{ paddingLeft: `16px` }}>
                        <div
                          style={{
                            minHeight: `40px`,
                            display: `flex`,
                            flexDirection: `column`,
                            justifyContent: `center`,
                          }}
                        >
                          <span>{item.name}</span>

                          <small>{get(item, `choir.name`)}</small>
                        </div>
                      </Table.Cell>
                      {/* <Table.Cell>{item.description}</Table.Cell> */}
                      <Table.Cell
                        textAlign="right"
                        style={{
                          paddingRight: `16px`,
                          width: `1%`,
                          whiteSpace: `nowrap`,
                        }}
                      >
                        <div
                          style={{
                            width: `1em`,
                            height: `1em`,
                            borderRadius: `50%`,
                            backgroundColor: get(
                              {
                                Active: `#21ba45`,
                                InActive: `#fbbd08`,
                                Draft: `#767676`,
                              },
                              item.status
                            ),
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {campaignsStore.state.next && (
                <Observer
                  onChange={inView =>
                    inView &&
                    !campaignsStore.state.isFetching &&
                    campaignsStore.fetch()
                  }
                  rootMargin="610px"
                >
                  <div
                    style={{
                      width: `100%`,
                      display: `flex`,
                      justifyContent: `center`,
                      padding: `3em`,
                    }}
                  >
                    <Button
                      basic
                      content="Load more..."
                      onClick={campaignsStore.fetch}
                      loading={campaignsStore.state.isFetching}
                    />
                  </div>
                </Observer>
              )}
              {!campaignsStore.state.next && !campaignsStore.state.isFetching && (
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    minHeight: `122px`,
                  }}
                >
                  <Button
                    basic
                    onClick={() =>
                      this.props.history.push(
                        `/campaigns/new` + this.props.location.search,
                        campaignsStore.state.filters.search &&
                          campaignsStore.state.filters.search.length > 0
                          ? { name: campaignsStore.state.filters.search }
                          : {}
                      )
                    }
                  >
                    {campaignsStore.state.filters.search &&
                    campaignsStore.state.filters.search.length > 0 ? (
                      <span>
                        Add{` `}
                        <strong>{campaignsStore.state.filters.search}</strong>
                      </span>
                    ) : (
                      `Add missing campaign`
                    )}
                  </Button>
                </div>
              )}
            </Content>
          </Panel>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    const { search } = queryString.parse(this.props.location.search)
    search
      ? this.handleSearch(search)
      : !this.props.campaignsStore.state.isFetching &&
        this.props.campaignsStore.fetch()
    this.searchInput.focus()
  }

  componentWillUnmount = () => {
    this.props.campaignsStore.resetState()
  }

  handleSearch = query => {
    this.setState(
      previousState => ({
        ...previousState,
        filters: {
          ...previousState.filters,
          search: query,
        },
      }),
      () => {
        this.props.campaignsStore.filter(this.state.filters)
      }
    )
  }
}

export default props => (
  <Subscribe to={[ CampaignsContainer ]}>
    {campaignsStore => (
      <Campaigns
        campaignsStore={campaignsStore}
        campaigns={campaignsStore.state.data || []}
        {...props}
      />
    )}
  </Subscribe>
)
