import React from "react"
import Panel, { Content, CommandBar, Command, Filter, Select } from "../Panel"
import Items from "../Items"
import { Label, Card, Icon, Flag } from "semantic-ui-react"
import availableLocales from "./available-locales"

const Entries = ({ match, history, location }) => (
  <Panel title="Entries">
    <CommandBar>
      <Command fluid>
        <Filter name="Tags" icon="tags" />
      </Command>
      <Command>
        <Select
          icon="globe"
          name="Locale"
          header="Select locale"
          options={availableLocales.map(locale => ({
            value: locale,
            content: (
              <span>
                <Flag
                  name={locale.slice(locale.indexOf(`-`) + 1).toLowerCase()}
                />
                {locale}
              </span>
            ),
          }))}
        />
      </Command>
      <Command.New onClick={() => history.push(`${ match.url }/create`)} />
    </CommandBar>
    <Content>
      <Items
        renderItem={(item, reload) => (
          <div
            key={item.id}
            onClick={event => history.push(match.url + `/${ item.id }`)}
            style={{
              paddingBottom: `2rem`,
              cursor: `pointer`,
            }}
          >
            <Card
              fluid
              color={location.pathname.indexOf(item.id) > -1 ? `blue` : null}
            >
              <Card.Content>
                <div
                  style={{ display: `flex`, justifyContent: `space-between` }}
                >
                  <span>
                    <strong>{item.name}</strong>
                  </span>
                  <span>
                    <Flag
                      name={item.locale
                        .slice(item.locale.indexOf(`-`) + 1)
                        .toLowerCase()}
                    />
                  </span>
                </div>
                <Card.Meta>{item.title}</Card.Meta>
              </Card.Content>
              {item.tags.length > 0 && (
                <Card.Content extra>
                  <Icon name="tags" />
                  {item.tags.map((tag, index) => (
                    <Label key={`${ item.id }-tag-${ index }`}>{tag}</Label>
                  ))}
                </Card.Content>
              )}
            </Card>
          </div>
        )}
      />
    </Content>
  </Panel>
)

Entries.displayName = `Entries`

export default Entries
