import { Component } from "react"
import dispatchEvent from "../../helpers/dispatch-event"
import { root } from "../../services/Globals"

const noop = () => {}

export default class PutVersion extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `idle`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
      update,
    } = this

    return this.props.children({
      mode,
      payload,
      update,
    })
  }

  update = (data = {}) => {
    const { id, image, duration, bpm, musicXmlSourceUrl, notes } = {
      ...this.props.data,
      ...data,
    }

    const {
      arrangementId,
      versionId = id,
      onSuccess = noop,
      onError = noop,
      onLoading = noop,
    } = this.props

    this.setState(
      previousState => ({
        ...previousState,
        mode: `loading`,
      }),
      onLoading
    )

    return root.wav
      .api({
        method: `put`,
        url: `/arrangements/${ arrangementId }/versions/${ versionId }`,
        data: {
          ...(duration ? { duration: parseInt(duration, 10) } : {}),
          ...(musicXmlSourceUrl ? { musicXmlSourceUrl } : {}),
          ...(bpm ? { bpm: parseInt(bpm, 10) } : {}),
          ...(notes ? { notes } : {}),
          ...(image ? { imageId: image.id } : {}),
        },
      })
      .then(({ data }) => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `idle`,
            payload: data,
          }),
          () => {
            onSuccess(data)
            dispatchEvent(`update:${ versionId }`)
            dispatchEvent(`update:${ arrangementId }`)
            dispatchEvent(`update:arrangement`)
          }
        )
      })
      .catch(error => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `error`,
            payload: error,
          }),
          () => onError(error)
        )
      })
  }
}
