import React, { Component } from "react"
import GetAudiotrack from "../api/containers/GetAudiotrack"
import Panel, { CommandBar, Command, Content } from "../Panel"
import { Loader, Form, Dropdown, Icon } from "semantic-ui-react"
import PutAudiotrack from "../api/containers/PutAudiotrack"
import isEqual from "deep-equal"
import DeleteAudiotrack from "../api/containers/DeleteAudiotrack"
import { root } from "../services/Globals"
import equal from "deep-equal"
import PostAudiotracks from "../api/containers/PostAudiotracks"
import GetRecordingTypes from "../api/containers/GetRecordingTypes"
import GetAudiotypes from "../api/containers/GetAudiotypes"
import { parseFloat } from "window-or-global"

class Audiotrack extends Component {
  constructor(props) {
    super(props)
    this.initialState = props.audiotrack
      ? {
          id: props.audiotrack.id,
          type: props.audiotrack.type,
          recordingType: props.audiotrack.recordingType,
          name: props.audiotrack.name,
          sourceUrl: props.audiotrack.sourceUrl,
          downloadUrl: props.audiotrack.downloadUrl,
          size: props.audiotrack.size,
          duration: props.audiotrack.duration,
          sortOrder: props.audiotrack.sortOrder,
          audioLevel: props.audiotrack.audioLevel,
        }
      : {
          id: undefined,
          type: undefined,
          recordingType: `Audio`,
          name: undefined,
          sourceUrl: undefined,
          downloadUrl: undefined,
          size: undefined,
          duration: undefined,
          sortOrder: undefined,
          audioLevel: undefined,
        }
    this.state = this.initialState
  }

  render = () => {
    const {
      handleChange,
      handleDecimalChange,
      state,
      initialState,
      props: { arrangementId, versionId, history, match },
    } = this

    const unchanged = isEqual(state, initialState)

    return (
      <Form>
        <CommandBar>
          {state.id && (
            <React.Fragment>
              <DeleteAudiotrack
                arrangementId={arrangementId}
                versionId={versionId}
                audioId={state.id}
              >
                {audiotrack => (
                  <Command
                    disabled={audiotrack.mode === `loading`}
                    onClick={() => {
                      const deleteNotification = new root.wav.Notification({
                        text: `<h4 class="ui header"><i aria-hidden="true" class="trash icon"></i><div class="content">You are about to delete this audiotrack permanently, do you want to continue?</div></h4>`,
                        buttons: [
                          root.wav.Notification.button(
                            `Yes`,
                            `ui compact button`,
                            () => {
                              audiotrack.delete().then(() => {
                                deleteNotification.close()
                              })
                            }
                          ),
                          root.wav.Notification.button(
                            `No`,
                            `ui compact button`,
                            () => {
                              deleteNotification.close()
                            }
                          ),
                        ],
                        theme: `semanticui`,
                        modal: true,
                        callbacks: {
                          afterClose: () => {
                            history.push(
                              `/arrangements/${ arrangementId }/versions/${ versionId }/audiotracks`
                            )
                          },
                        },
                      }).show()
                    }}
                  >
                    <span>
                      <Icon name="trash" />
                      {audiotrack.mode === `error`
                        ? `Retry`
                        : audiotrack.mode === `idle`
                        ? `Delete`
                        : `Deleting`}
                    </span>
                  </Command>
                )}
              </DeleteAudiotrack>
              <PutAudiotrack
                data={state}
                arrangementId={arrangementId}
                versionId={versionId}
              >
                {audiotrack => (
                  <Command
                    disabled={unchanged || audiotrack.mode === `loading`}
                    onClick={() => audiotrack.update()}
                  >
                    <span>
                      {/* <Icon name="cloud upload" /> */}
                      {audiotrack.mode === `error`
                        ? `Retry`
                        : audiotrack.mode === `loading`
                        ? `Saving...`
                        : `Save`}
                    </span>
                  </Command>
                )}
              </PutAudiotrack>
            </React.Fragment>
          )}
          {!state.id && (
            <PostAudiotracks>
              {audiotracks => (
                <PutAudiotrack>
                  {audiotrack => (
                    <Command
                      disabled={
                        unchanged ||
                        audiotracks.mode === `loading` ||
                        audiotrack.mode === `loading`
                      }
                      onClick={() => {
                        audiotracks
                          .create({
                            arrangementId,
                            versionId,
                            data: {
                              type: state.type,
                              recordingType: state.recordingType,
                              name: state.name,
                            },
                          })
                          .then(({ data }) => {
                            audiotrack.update({
                              arrangementId,
                              versionId,
                              data: {
                                ...state,
                                id: data.id,
                              },
                            })
                          })
                          .then(() => {
                            history.push(
                              match.url.slice(
                                0,
                                match.url.indexOf(`audiotracks`) +
                                  `audiotracks`.length
                              )
                            )
                          })
                      }}
                    >
                      <span>
                        {/* <Icon name="cloud upload" /> */}
                        {` `}Save
                      </span>
                    </Command>
                  )}
                </PutAudiotrack>
              )}
            </PostAudiotracks>
          )}
        </CommandBar>
        <Content>
          <GetAudiotypes>
            {({ mode, payload }) => (
              <Form.Field required>
                <label>Audiotype</label>
                <Dropdown
                  name="type"
                  options={
                    mode === `success`
                      ? payload.map(({ name, value }) => ({
                          key: name,
                          text: name,
                          value: name,
                        }))
                      : []
                  }
                  items={mode === `success` ? payload : []}
                  loading={mode === `loading`}
                  error={mode === `error`}
                  value={state.type}
                  fluid
                  selection
                  search
                  onChange={handleChange}
                />
              </Form.Field>
            )}
          </GetAudiotypes>
          <GetRecordingTypes>
            {({ mode, payload }) => (
              <Form.Field required>
                <label>Recording Type</label>
                <Dropdown
                  name="recordingType"
                  options={
                    mode === `success`
                      ? payload.map(({ name, value }) => ({
                          key: name,
                          text: name,
                          value: name,
                        }))
                      : []
                  }
                  items={mode === `success` ? payload : []}
                  loading={mode === `loading`}
                  error={mode === `error`}
                  value={state.recordingType}
                  fluid
                  selection
                  search
                  onChange={handleChange}
                />
              </Form.Field>
            )}
          </GetRecordingTypes>
          <Form.Field
            control="input"
            type="text"
            label="Name"
            name="name"
            value={state.name || ``}
            onChange={handleChange}
          />
          <Form.Field
            control="input"
            type="text"
            label="Source URL"
            name="sourceUrl"
            value={state.sourceUrl || ``}
            onChange={handleChange}
          />
          <Form.Field
            control="input"
            type="text"
            label="Download URL"
            name="downloadUrl"
            value={state.downloadUrl || ``}
            onChange={handleChange}
          />
          <Form.Field
            control="input"
            type="number"
            label="Size"
            name="size"
            value={state.size || 0}
            onChange={handleChange}
          />
          <Form.Field
            control="input"
            type="number"
            label="Duration"
            name="duration"
            value={state.duration || 0}
            onChange={handleChange}
          />
          <Form.Field
            control="input"
            type="number"
            label="Sort order"
            name="sortOrder"
            value={state.sortOrder || 0}
            onChange={handleChange}
          />
          <Form.Field
            control="input"
            type="number"
            step="0.1"
            min="0"
            max="1"
            label="Audio Level"
            name="audioLevel"
            value={state.audioLevel}
            onChange={handleDecimalChange}
          />
        </Content>
      </Form>
    )
  }

  handleChange = (event, data) => {
    const { name, value, type } =
      data || (event && event.target) || event.target.type
    this.setState(previousState => ({
      ...previousState,
      [name]: type === `number` ? parseInt(value) : value,
      // [name]: value,
    }))
  }

  handleDecimalChange = (event, data) => {
    const { name, value, type } =
      data || (event && event.target) || event.target.type
    const limitedValue = value > 1 ? 1 : value < 0 ? 0 : value
    this.setState(previousState => ({
      ...previousState,
      [name]: type === `number` ? parseFloat(limitedValue) : limitedValue,
    }))
  }

  componentWillReceiveProps = nextProps => {
    !equal(this.props.audiotrack, nextProps.audiotrack) &&
      this.setState({
        id: nextProps.audiotrack.id,
        type: nextProps.audiotrack.type,
        recordingType: nextProps.audiotrack.recordingType,
        name: nextProps.audiotrack.name,
        sourceUrl: nextProps.audiotrack.sourceUrl,
        downloadUrl: nextProps.audiotrack.downloadUrl,
        size: nextProps.audiotrack.size,
        duration: nextProps.audiotrack.duration,
        audioLevel: nextProps.audiotrack.audioLevel,
        sortOrder: nextProps.audiotrack.sortOrder,
      })
  }
}

export default ({ match, history }) =>
  match.params.audioId !== `new` ? (
    <GetAudiotrack {...match.params}>
      {({ mode, payload }) => (
        <Panel
          title={`Audiotrack${ mode === `success` ? `: ${ payload.type }` : `` }`}
          onClose={() =>
            history.push(
              `/arrangements/${ match.params.arrangementId }/versions/${
                match.params.versionId
              }/audiotracks`
            )
          }
        >
          {mode === `loading` && (
            <Content>
              <Loader active inline="centered" content="Loading audiotrack" />
            </Content>
          )}
          {mode === `success` && (
            <Audiotrack
              audiotrack={payload}
              {...match.params}
              history={history}
            />
          )}
        </Panel>
      )}
    </GetAudiotrack>
  ) : (
    <Panel
      title={`New audiotrack`}
      onClose={() =>
        history.push(
          `/arrangements/${ match.params.arrangementId }/versions/${
            match.params.versionId
          }/audiotracks`
        )
      }
    >
      <Audiotrack {...match.params} history={history} match={match} />
    </Panel>
  )
