import notifyListeners from "./notify-listeners"

const createActivityContainer = (
  activitiesById = {},
  subscriptions = {},
  listeners = []
) => {
  const api = {
    add: (...activities) => {
      activities.map(activity => {
        const existingActivity = activitiesById[activity.id]
        activitiesById[activity.id] = activity

        if (activity.subscribe && !existingActivity) {
          subscriptions[activity.id] = activity.subscribe(state => {
            notifyListeners(listeners, api, state)
          })
        } else {
          notifyListeners(listeners, api, activity)
        }
      })
    },

    remove: ({ id }) => {
      const unsubscribe = subscriptions[id]

      if (unsubscribe) {
        unsubscribe()
        delete subscriptions[id]
      }

      const activity = activitiesById[id]

      if (activity) {
        delete activitiesById[id]
      }

      notifyListeners(listeners, api)

      return activity
    },

    getActivityById: id => activitiesById[id],

    getState: () =>
      Object.values(activitiesById).map(
        activity => (activity.getState ? activity.getState() : activity)
      ),

    clear: filter => {
      let removed = []

      activitiesById = Object.entries(activitiesById).reduce(
        (activities, [ id, activity ]) => {
          const status = activity.status || activity.getState().status
          if (status === filter) {
            removed = removed.concat(activity)
            return activities
          } else {
            return Object.assign(activities, { [id]: activity })
          }
        },
        {}
      )

      notifyListeners(listeners, api)

      return removed
    },

    subscribe: listener => {
      listeners = [ ...listeners, listener ]
      return () => {
        listeners = listeners.filter(l => l !== listener)

        return listener
      }
    },
  }

  return api
}

export default createActivityContainer
