import React from "react"
import { CommandBar, Command, Content } from "../Panel"
import { Form, Image } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { UserContainer } from "./UserContainer"
import { UsersContainer } from "./UsersContainer"
import { root } from "../services/Globals"

export default props => (
  <Subscribe to={[ UserContainer, UsersContainer ]}>
    {(userStore, usersStore) => (
      <React.Fragment>
        <CommandBar>
          <Command.Delete
            onClick={() => {
              const deleteNotification = new root.wav.Notification({
                text: `<h4 class="ui header"><i aria-hidden="true" class="trash icon"></i><div class="content">You are about to delete this user permanently, do you want to continue?</div></h4>`,
                buttons: [
                  root.wav.Notification.button(
                    `Yes`,
                    `ui compact button`,
                    () => {
                      userStore.remove().then(removedId => {
                        usersStore.removeUser(removedId)
                        props.history.push(
                          props.returnTo ||
                            `/users${
                              props.location.search ? props.location.search : ``
                            }`
                        )
                        deleteNotification.close()
                      })
                    }
                  ),
                  root.wav.Notification.button(
                    `No`,
                    `ui compact button`,
                    () => {
                      deleteNotification.close()
                    }
                  ),
                ],
                theme: `semanticui`,
                modal: true,
              }).show()
            }}
          />
        </CommandBar>
        <Content loading={userStore.state.isFetching}>
          <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
            <Form.Field>
              <label>Display Name</label>
              <p>{userStore.state.details.displayName}</p>
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <p>
                {userStore.state.details.profile &&
                  userStore.state.details.profile.email}
              </p>
            </Form.Field>
            <Form.Field>
              <label>Voice</label>
              <p>
                {userStore.state.details.profile &&
                  userStore.state.details.profile.voice}
              </p>
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <p>
                {(userStore.state.details.profile &&
                  userStore.state.details.profile.description) || (
                  <span>&mdash;</span>
                )}
              </p>
            </Form.Field>

            {userStore.state.details.profileImage && (
              <Form.Field>
                <label>Profile Image</label>
                <Image
                  src={userStore.state.details.profileImage.url + `?width=300`}
                  size="medium"
                />
              </Form.Field>
            )}
            {userStore.state.details.profile &&
              userStore.state.details.profile.place && (
                <Form.Field>
                  <label>Place</label>
                  <a
                    href={`https://www.google.com/maps/?q=${
                      userStore.state.details.profile.place.latitude
                    },${ userStore.state.details.profile.place.longitude }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {userStore.state.details.profile.place.name}
                  </a>
                </Form.Field>
              )}
            <Form.Field>
              <label>Created</label>
              <p>
                {userStore.state.details.createdUtc &&
                  new Date(userStore.state.details.createdUtc).toLocaleString(
                    navigator.language
                  )}
              </p>
            </Form.Field>
            <Form.Field>
              <label>Updated</label>
              <p>
                {userStore.state.details.updatedUtc &&
                  new Date(userStore.state.details.updatedUtc).toLocaleString(
                    navigator.language
                  )}
              </p>
            </Form.Field>
            {usersStore.state.data.externalUserId && (
              <Form.Field>
                <label>External User ID</label>
                <p>{userStore.state.details.externalUserId}</p>
              </Form.Field>
            )}
          </Form>
        </Content>
      </React.Fragment>
    )}
  </Subscribe>
)
