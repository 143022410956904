import React from "react"
import styled from "styled-components"
import get from "lodash.get"
import { Subscribe } from "unstated"
import { ActivityContainer } from "../ActivityContainer"
import { Progress as UIProgress, Icon } from "semantic-ui-react"
import createActivity from "../utils/activities/create-activity"
import STATUS from "../utils/activities/status"

const ACTIVITY_TYPES = {
  MediaProcessing: `MediaProcessing`,
  ScoreGenerating: `ScoreGenerating`,
  FileUpload: `FileUpload`,
}

const Message = ({ message, ...props }) =>
  message ? (
    <React.Fragment>
      <dt>Message</dt>
      <dd style={{ fontSize: `12px` }}>{message}</dd>
    </React.Fragment>
  ) : null

const Progress = ({ percent, hidden, ...props }) =>
  !!percent && !hidden ? (
    <React.Fragment>
      <dt>Progress</dt>
      <dd>
        <UIProgress
          percent={percent}
          inverted
          color="black"
          progress
          size="small"
          style={{ marginBottom: 0, marginTop: `6px` }}
          {...props}
        />
      </dd>
    </React.Fragment>
  ) : null

const Status = props => (
  <React.Fragment>
    <dt>Status</dt>
    <dd>
      <Icon
        {...get(
          {
            [STATUS.Created]: {
              name: `pause circle outline`,
              color: `yellow`,
            },
            [STATUS.Running]: {
              name: `play circle outline`,
              color: `blue`,
            },
            [STATUS.Completed]: {
              name: `check circle outline`,
              color: `green`,
            },
            [STATUS.Canceled]: { name: `ban`, color: `grey` },
            [STATUS.Error]: { name: `bug`, color: `red` },
            [STATUS.CompletedWithError]: {
              name: `ban`,
              color: `red`,
            },
          },
          props.status,
          STATUS.Created
        )}
      />
    </dd>
  </React.Fragment>
)

const formatDateTime = utcTime =>
  new Date(utcTime).toLocaleString(navigator.language)

const DateTime = styled.dd`
  font-size: 12px;
  color: #ccc;
`

const FormattedDateTime = props => (
  <React.Fragment>
    <dt>{props.label}</dt>
    <DateTime>{formatDateTime(props.children)}</DateTime>
  </React.Fragment>
)

const humanizeActivityType = type =>
  get(
    {
      MediaProcessing: `Media processing`,
      ScoreGenerating: `Score generation`,
      FileUpload: `File upload`,
    },
    type,
    type
  )

const Title = props => (
  <React.Fragment>
    <dt>Title</dt>
    <dd>{humanizeActivityType(props.children)}</dd>
  </React.Fragment>
)

const Details = styled.dl`
  padding: 0;
  position: relative;
  margin: 0;

  dt {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px; }
  }

  dd {
    margin-left: 0;
  }
`

const ActivityItem = styled.li`
  cursor: ${ props => (props.onClick ? `pointer` : `auto`) };

  :hover {
    background: ${ props => (props.onClick ? `rgb(53, 53, 54)` : `transparent`) };
  }
`

const Activity = ({ activity, ...props }) => (
  <ActivityItem
    {...(get(activity, `metadata.redirectUri`)
      ? {
          onClick: () => {
            props.history.push(activity.metadata.redirectUri)
          },
        }
      : {})}
  >
    {activity ? (
      <Details>
        <div
          style={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <div>
            <Title>{get(activity, `metadata.name`, activity.type)}</Title>
            <FormattedDateTime label="Created">
              {activity.createdUtc}
            </FormattedDateTime>
          </div>
          <Status status={activity.status} />
        </div>
        <Message
          message={get(activity, `metadata.message`, activity.message)}
        />
        <Progress
          key={activity.id}
          percent={activity.progress}
          hidden={activity.status !== STATUS.Running}
        />
      </Details>
    ) : null}
  </ActivityItem>
)

const Activities = styled.ul`
  list-style-type: none;
  padding: 0;

  li:not(:last-child) {
    border-bottom: 1px solid rgb(52, 53, 52);
  }

  li {
    padding: 1rem 1.618rem;
  }

  li:first-child {
    margin-top: -1rem;
  }
`

const Button = styled.a`
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #aaaaaa;
  padding: 3px 4px;
  user-select: none;
  cursor: pointer;

  :hover {
    color: #ffffff;
  }
`

export default props => (
  <Subscribe to={[ ActivityContainer ]}>
    {activities => (
      <div>
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `space-between`,
            marginLeft: `1.618rem`,
            marginRight: `1.618rem`,
          }}
        >
          <h1
            onClick={() => {
              const activity = createActivity(
                { name: `an activity`, type: ACTIVITY_TYPES.FileUpload },
                actions => {
                  const promise = new Promise(resolve => {
                    setTimeout(resolve, 2000)

                    setTimeout(
                      () =>
                        actions.onProgress({
                          loaded: 250,
                          total: 1000,
                          lengthComputable: true,
                        }),
                      500
                    )

                    setTimeout(
                      () =>
                        actions.onProgress({
                          loaded: 600,
                          total: 1000,
                          lengthComputable: true,
                        }),
                      800
                    )

                    setTimeout(
                      () =>
                        actions.onProgress({
                          loaded: 1000,
                          total: 1000,
                          lengthComputable: true,
                        }),
                      1900
                    )
                  })

                  return promise.then(() => actions.resolve())
                }
              )

              activities.add(activity)

              activity.start()
            }}
          >
            Activity
          </h1>
          <Button
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              activities.clear(STATUS.Completed)
            }}
            style={{
              display:
                activities.state.activities.filter(
                  activity => activity.status === STATUS.Completed
                ).length > 0
                  ? `block`
                  : `none`,
            }}
          >
            Clear Completed
          </Button>
        </div>
        <Activities>
          {activities.state.activities
            .filter(activity => activity.status !== STATUS.Completed)
            .sort((a, b) => new Date(a.createdUtc) - new Date(b.createdUtc))
            .map(activity => (
              <Activity key={activity.id} activity={activity} {...props} />
            ))}
          {activities.state.activities
            .filter(activity => activity.status === STATUS.Completed)
            .sort((a, b) => new Date(b.updatedUtc) - new Date(a.updatedUtc))
            .map(activity => (
              <Activity key={activity.id} activity={activity} {...props} />
            ))}
        </Activities>
      </div>
    )}
  </Subscribe>
)
