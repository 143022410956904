import { Component } from "react"
import dispatchEvent from "../../helpers/dispatch-event"
import { root } from "../../services/Globals"

const noop = () => {}

export default class DeleteArrangement extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `idle`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
    } = this

    return this.props.children({
      mode,
      payload,
      delete: this.delete,
    })
  }

  delete = () => {
    const {
      arrangementId,
      onSuccess = noop,
      onError = noop,
      onLoading = noop,
    } = this.props

    this.setState(
      previousState => ({
        ...previousState,
        mode: `loading`,
      }),
      onLoading
    )

    return root.wav
      .api({
        method: `delete`,
        url: `/arrangements/${ arrangementId }`,
      })
      .then(({ data }) => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `success`,
            payload: data,
          }),
          () => {
            onSuccess(data)
            dispatchEvent(`delete:${ arrangementId }`)
            dispatchEvent(`delete:arrangement`)
          }
        )
      })
      .catch(error => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `error`,
            payload: error,
          }),
          () => onError(error)
        )
      })
  }
}
