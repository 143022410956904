import React, { Component } from "react"
import { Container, Input, Button, Message, Progress } from "semantic-ui-react"
import { root } from "./services/Globals"
import { Subscribe } from "unstated"
import { ActivityContainer } from "./ActivityContainer"
import get from "lodash.get"
import createActivity from "./utils/activities/create-activity"

class FileUpload extends Component {
  constructor(props) {
    super(props)

    this.url = props.url || `/assets`
    this.method = this.props.method || `post`

    this.inputId = `${ Math.floor(
      Math.random() * Number.MAX_SAFE_INTEGER
    ).toString(16) }-FileUpload`

    this.initialState = {
      file: undefined,
      uploading: false,
      error: false,
      progress: undefined,
      _isMounted: false,
    }

    this._isMounted = false

    this.state = { ...this.initialState }
  }

  componentDidMount = () => {
    this._isMounted = true
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleChange = event => {
    const file = event.target.files[0]
    if (/&/.test(file.name)) {
      this.setState(previousState => ({
        error: `Filename cannot contain the character '&'`,
      }))
    } else {
      this.setState(previousState => ({
        file,
        error: false,
      }))
    }
  }

  upload = () => {
    this.setState(previousState => ({
      ...previousState,
      uploading: true,
      error: false,
    }))

    const data = new FormData()
    data.append(`file`, this.state.file)

    this.props.data &&
      Object.keys(this.props.data).map(key =>
        data.append(key, this.props.data[key])
      )

    const { arrangementId, versionId, audioId } = this.props.metadata || {}

    const uploadActivity = createActivity(
      {
        type: `FileUpload`,
        message:
          this.props.metadata && this.props.metadata.audioId
            ? `Uploading audiotrack`
            : null,
        metadata: {
          redirectUri:
            arrangementId && versionId
              ? `/arrangements/${ arrangementId }/versions/${ versionId }${
                  audioId ? `/audiotracks` : ``
                }`
              : undefined,
        },
      },
      ({ onProgress, resolve, reject }) =>
        root.wav
          .api({
            method: this.method,
            url: this.url,
            data,
            onUploadProgress: progressEvent => {
              onProgress(progressEvent)

              this._isMounted &&
                (progressEvent => {
                  this.setState(previousState => ({
                    ...previousState,
                    progress: progressEvent,
                  }))

                  this.props.onUploadProgress &&
                    this.props.onUploadProgress(progressEvent)
                })(progressEvent)
            },
          })
          .then(response => {
            const metadata = {
              name:
                get(
                  {
                    post: `Added: `,
                    put: `Updated: `,
                  },
                  this.method,
                  ``
                ) +
                (response.data.name ||
                  (audioId
                    ? `Audiotrack`
                    : versionId
                    ? `Version`
                    : arrangementId
                    ? `Arrangement ${ response.data.name }`
                    : ``)),
              redirectUri:
                arrangementId && versionId
                  ? `/arrangements/${ arrangementId }/versions/${ versionId }${
                      audioId ? `/audiotracks` : ``
                    }`
                  : response.config.url ===
                    `${ response.config.baseURL }/arrangements/musicxml`
                  ? `/arrangements/${ response.data.id }`
                  : undefined,
              message: `Upload completed`,
            }

            return {
              ...response.data,
              metadata: {
                ...(response.data.metadata || {}),
                ...metadata,
              },
            }
          }, reject)
          .then(resolve)
    )

    this.props.activities.add(uploadActivity)

    uploadActivity
      .start()
      .then(data => this._isMounted && this.successHandler(data))
      .catch(error => this._isMounted && this.errorHandler(error))
  }

  errorHandler = error => {
    this._isMounted &&
      this.setState(previousState => ({
        ...previousState,
        uploading: false,
        error: error.toString(),
      }))

    this._isMounted && this.props.errorHandler && this.props.errorHandler(error)
  }

  successHandler = response => {
    this._isMounted &&
      this.setState(previousState => ({
        ...previousState,
        ...this.initialState,
      }))

    this._isMounted &&
      this.props.successHandler &&
      this.props.successHandler(response)
  }

  render = () => {
    const buttonColor = this.state.error
      ? `red`
      : this.state.uploading
      ? `yellow`
      : `green`
    const content = this.state.error ? `Retry` : `Upload`

    const { accept } = this.props

    return (
      <Container fluid>
        <Input
          fluid
          readOnly
          placeholder="No file selected"
          value={(this.state.file && this.state.file.name) || ``}
          action
        >
          <input
            onKeyDown={event => {
              if (event.key === ` ` || event.key === `Enter`) {
                document.getElementById(this.inputId).click()
              }
            }}
          />
          <label
            htmlFor={this.inputId}
            className={
              this.state.uploading ? `ui button disabled` : `ui button`
            }
            style={{
              display: `inline`,
              ...(this.props.disabled
                ? { pointerEvents: `none`, opacity: `.4` }
                : {}),
            }}
          >
            <input
              id={this.inputId}
              disabled={this.state.uploading}
              type="file"
              value=""
              style={{ display: `none` }}
              onChange={this.handleChange}
              accept={accept}
            />
            Browse
          </label>
          {this.state.file ? (
            <Button
              color={buttonColor}
              loading={this.state.uploading}
              content={content}
              onClick={this.upload}
            />
          ) : null}
        </Input>
        {this.props.showProgress &&
          this.state.progress &&
          this.state.progress.lengthComputable && (
            <Progress
              style={{ marginTop: `1rem` }}
              progress="percent"
              precision={0}
              total={this.state.progress.total}
              value={this.state.progress.loaded}
              error={!!this.state.error}
            />
          )}
        {this.state.error && this.props.showError ? (
          <Message negative>
            <Message.Header>There was an error</Message.Header>
            <pre>
              <code>{this.state.error}</code>
            </pre>
          </Message>
        ) : null}
      </Container>
    )
  }
}

export default props => (
  <Subscribe to={[ ActivityContainer ]}>
    {activities => <FileUpload activities={activities} {...props} />}
  </Subscribe>
)
