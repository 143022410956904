import React, { Component } from "react"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { Form, Header, Checkbox, Table, Dropdown } from "semantic-ui-react"
import ImageAsset from "../ImageAsset"
import { root } from "../services/Globals"
import uploadAsset from "../api/upload-asset"
import { Subscribe } from "unstated"
import { ChoirsContainer } from "./ChoirsContainer"

class CreateChoir extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      name: (props.location.state && props.location.state.name) || ``,
      description: undefined,
      image: undefined,
      place: {
        name: undefined,
        latitude: undefined,
        longitude: undefined,
      },
      settings: {
        disableFeedPost: false,
        hideFromListings: false,
        access: `Private`,
      },
      isSaving: false,
      progress: undefined,
    }
    this.state = { ...this.initialState }
  }

  render = () => {
    return (
      <Panel
        title="Create a choir"
        onClose={() =>
          this.props.history.push(
            this.props.returnTo ||
              `/choirs${
                this.props.location.search ? this.props.location.search : ``
              }`
          )
        }
      >
        <CommandBar>
          <Command.Save
            disabled={this.state.isSaving}
            onClick={this.handleSave}
          />
        </CommandBar>
        <Content
          loading={this.state.isSaving}
          loadingText={
            this.state.progress
              ? `Uploading image ${ this.state.progress }%`
              : `Creating choir`
          }
        >
          <Form autoComplete="off" style={{ marginBottom: `20px` }}>
            <Form.Input
              label="Name"
              name="name"
              value={this.state.name || ``}
              onChange={this.handleBasicsChange}
              required
            />
            <Form.TextArea
              label="Description"
              name="description"
              value={this.state.description || ``}
              onChange={this.handleBasicsChange}
              required
            />

            <Form.Field>
              <label>Image</label>
              <ImageAsset
                name="image"
                {...(this.state.image
                  ? { initialValue: this.state.image.url }
                  : {})}
                onChange={this.handleBasicsChange}
              />
            </Form.Field>
            <Header as="h3">Place</Header>
            <Form.Input
              label="Name"
              name="place.name"
              value={this.state.place.name || ``}
              onChange={this.handlePlaceChange}
              required
            />
            <div style={{ display: `flex` }}>
              <div style={{ marginRight: `8px`, flexGrow: 1 }}>
                <Form.Input
                  type="number"
                  label="Latitude"
                  name="place.latitude"
                  value={this.state.place.latitude || 0}
                  onChange={this.handlePlaceChange}
                  required
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                <Form.Input
                  type="number"
                  label="Longitude"
                  name="place.longitude"
                  value={this.state.place.longitude || 0}
                  onChange={this.handlePlaceChange}
                  required
                />
              </div>
            </div>
            <Header as="h3">Settings</Header>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Disable feed post</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Checkbox
                      name="disableFeedPost"
                      toggle
                      checked={this.state.settings.disableFeedPost}
                      onChange={this.handleSettingsChange}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Hide in listings</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Checkbox
                      name="hideFromListings"
                      toggle
                      checked={this.state.settings.hideFromListings}
                      onChange={this.handleSettingsChange}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Access</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      name="access"
                      options={[
                        {
                          text: `Private`,
                          value: `Private`,
                        },
                        {
                          text: `Public`,
                          value: `Public`,
                        },
                      ]}
                      value={this.state.settings.access}
                      onChange={this.handleSettingsChange}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Form>
        </Content>
      </Panel>
    )
  }

  handleBasicsChange = ({ target: { name, value } }) => {
    this.setState(previousState => ({
      ...previousState,
      [name]: value,
    }))
  }

  handlePlaceChange = ({ target }) => {
    const name = target.name.split(`.`)[1]
    const value = target.value
    this.setState(previousState => ({
      ...previousState,
      place: {
        ...previousState.place,
        [name]: value,
      },
    }))
  }

  handleSettingsChange = (event, { name, value, checked }) => {
    console.log(name, value, checked)
    this.setState(previousState => ({
      ...previousState,
      settings: {
        ...previousState.settings,
        [name]: value || !previousState.settings[name],
      },
    }))
  }

  handleUploadProgress = ({ total, loaded }) => {
    this.setState(previousState => ({
      ...previousState,
      progress:
        total === loaded ? undefined : Math.ceil((loaded / total) * 100),
    }))
  }

  handleSave = () => {
    this.setState(
      previousState => ({
        ...previousState,
        isSaving: true,
      }),
      () => {
        const { name, description, image, place, settings } = this.state

        Promise.resolve(
          image
            ? uploadAsset(image, this.handleUploadProgress).then(({ id }) => id)
            : undefined
        )
          .then(imageId =>
            root.wav.api({
              url: `/choirs`,
              method: `post`,
              data: {
                name,
                description,
                ...(imageId ? { imageId } : {}),
                ...(place.name && place.latitude && place.longitude
                  ? { place }
                  : {}),
                settings,
              },
            })
          )
          .then(({ data: { id } }) => {
            this.props.choirsStore.updateId(id)
            this.props.history.replace(
              `/choirs/${ id }${
                this.props.location.search ? this.props.location.search : ``
              }`
            )
          })
          .catch(error => {
            console.error(error)
            this.setState(previousState => ({
              ...previousState,
              isSaving: false,
              progress: undefined,
            }))
          })
      }
    )
  }
}

export default props => (
  <Subscribe to={[ ChoirsContainer ]}>
    {choirsStore => <CreateChoir choirsStore={choirsStore} {...props} />}
  </Subscribe>
)
