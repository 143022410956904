import { Component } from "react"
import { root } from "../../services/Globals"

export default class GetVersion extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `loading`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
      reload,
    } = this

    return this.props.children({
      mode,
      payload,
      reload,
    })
  }

  componentDidMount = () => {
    ;[ this.props.arrangementId, this.props.versionId ].forEach(id => {
      window.addEventListener(`update:${ id }`, this.getData)
    })
    return this.getData()
  }

  componentWillUnmount = () => {
    ;[ this.props.arrangementId, this.props.versionId ].forEach(id => {
      window.removeEventListener(`update:${ id }`, this.getData)
    })
  }

  componentWillReceiveProps = nextProps => {
    this.props.versionId !== nextProps.versionId && setTimeout(this.getData, 0)
  }

  getData = () => {
    this.setState(previousState => ({
      ...previousState,
      mode: `loading`,
    }))

    const arrangementId = this.props.arrangementId
    const versionId = this.props.versionId

    return root.wav
      .api(`/arrangements/${ arrangementId }/versions/${ versionId }`)
      .then(({ data }) => {
        this.setState(previousState => ({
          ...previousState,
          mode: `success`,
          payload: data,
        }))
      })
      .catch(error => {
        this.setState(previousState => ({
          ...previousState,
          mode: `error`,
          payload: error,
        }))
      })
  }

  reload = event => {
    event && event.preventDefault && event.preventDefault()
    this.setState(() => this.initialState)
    return this.getData()
  }
}
