import React, { Component } from "react"
import * as FilePond from "filepond"
import "filepond/dist/filepond.min.css"
import ImagePreviewPlugin from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FileTypePlugin from "filepond-plugin-file-validate-type"
import OrientationPlugin from "filepond-plugin-image-exif-orientation"
import "./ImageAsset.css"

FilePond.registerPlugin(ImagePreviewPlugin, FileTypePlugin, OrientationPlugin)

class ImagePreview extends Component {
  constructor(props) {
    super(props)
    this.wrapperElement = undefined
    this.state = {
      url: props.url,
      imageLoaded: false,
    }
  }

  render = () => {
    const width = this.wrapperElement && this.wrapperElement.offsetWidth

    return this.state.url ? (
      <div
        className="image-preview--wrapper"
        ref={element => (this.wrapperElement = element)}
      >
        <img
          src={`${ this.state.url }${ width ? `?width=${ width }` : `?width=518` }`}
          width={width ? `${ width }px` : `100%`}
          alt="preview"
          onLoad={this.handleLoad}
        />
        {!this.state.imageLoaded && (
          <div
            style={{
              width: `100%`,
              height: `71px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            <p>Loading image...</p>
          </div>
        )}
        <div
          className="image-preview--delete"
          onClick={this.props.handleRemovePreview}
          style={{ display: this.state.imageLoaded ? `flex` : `none` }}
        >
          Delete
        </div>
      </div>
    ) : null
  }

  componentWillReceiveProps = nextProps => {
    this.props.url !== nextProps.url &&
      this.setState(
        {
          url: ``,
          imageLoaded: false,
        },
        () => this.setState({ url: nextProps.url })
      )
  }

  handleLoad = () => {
    this.setState({ imageLoaded: true })
  }
}

export default class ImagePicker extends Component {
  constructor(props) {
    super(props)
    this.inputElement = undefined
    this.pond = undefined
    this.state = {
      initialValueDiscarded: false,
      initialValue: undefined,
    }
  }

  render = () => {
    const previewDiscarded =
      this.state.initialValue && this.state.initialValueDiscarded
    return (
      <React.Fragment>
        <div
          className="filepond--wrapper"
          style={{
            ...(this.state.initialValue && !previewDiscarded
              ? { display: `none` }
              : {}),
          }}
        >
          <input
            type="file"
            accept="image/*"
            ref={inputElement => {
              this.inputElement = inputElement
            }}
          />
        </div>
        {this.state.initialValue && !previewDiscarded && (
          <ImagePreview
            url={this.state.initialValue}
            handleRemovePreview={this.handleRemovePreview}
          />
        )}
      </React.Fragment>
    )
  }

  componentDidMount = () => {
    this.initPond()
  }

  componentDidUpdate = (prevProps, prevState) => {
    prevProps.initialValue !== this.props.initialValue &&
      this.setState(
        {
          initialValueDiscarded: false,
          ...(this.props.initialValue
            ? { initialValue: this.props.initialValue }
            : { initialValue: undefined }),
        },
        () => {
          this.handleRemoveFile(`suppress`)
        }
      )
  }

  initPond = () => {
    if (this.inputElement) {
      this.pond = FilePond.create(this.inputElement)
      this.pond.setOptions({
        allowMultiple: false,
        onaddfile: this.handleAddFile,
        onremovefile: this.handleRemoveFile,
      })
    }
    this.props.initialValue &&
      this.setState({ initialValue: this.props.initialValue })
  }

  componentWillUnmount = () => {
    this.pond && this.inputElement && this.pond.destroy()
  }

  handleAddFile = () => {
    this.props.onChange &&
      this.props.onChange({
        target: {
          ...(this.props.name ? { name: this.props.name } : {}),
          value: this.pond.getFile(0).file,
        },
      })
  }

  handleRemoveFile = data => {
    if (data === `suppress`) {
      this.setState(
        {
          suppressRemoval: true,
        },
        this.pond.removeFile
      )
    } else {
      this.state.suppressRemoval
        ? this.setState({ suppressRemoval: false })
        : this.onChange()
    }
  }

  handleRemovePreview = () => {
    this.props.initialValue &&
      !this.state.initialValueDiscarded &&
      this.setState(
        {
          initialValueDiscarded: true,
        },
        () => {
          this.pond.removeFile()
          this.handleRemoveFile()
          this.onChange()
        }
      )
  }

  onChange = () => {
    this.props.onChange &&
      this.props.onChange({
        target: {
          ...(this.props.name ? { name: this.props.name } : {}),
          value: undefined,
        },
      })
  }
}
