import { Component } from "react"
import dispatchEvent from "../../helpers/dispatch-event"
import { root } from "../../services/Globals"

export default class CopyVersion extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `idle`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
      copy,
    } = this

    return this.props.children({
      mode,
      payload,
      copy,
    })
  }

  copy = options => {
    const { arrangementId, versionId, onSuccess, onError, onLoading } =
      options || this.props

    this.setState(
      previousState => ({
        ...previousState,
        mode: `loading`,
      }),
      () => onLoading && onLoading()
    )

    return root.wav
      .api({
        method: `post`,
        url: `/arrangements/${ arrangementId }/versions/${ versionId }/copy`,
      })
      .then(response => {
        this.setState({
          mode: `idle`,
          payload: response.data,
        })
        onSuccess && onSuccess(response.data)
        dispatchEvent(`update:${ arrangementId }`)
        return response
      })
      .catch(error => {
        this.setState(
          previousState => ({
            ...previousState,
            mode: `error`,
            payload: error,
          }),
          () => onError && onError(error)
        )
      })
  }
}
