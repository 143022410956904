import React, { Component } from "react"
import { root } from "../services/Globals"
import { Subscribe } from "unstated"
import { PlanContainer } from "./PlanContainer"
import { PlansContainer } from "./PlansContainer"
import isEqual from "deep-equal"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { Form } from "semantic-ui-react"
import Select from "../Select"

const INTERVAL = {
  Day: `Day`,
  Month: `Month`,
  Year: `Year`,
  Week: `Week`,
  "Every 3rd month": `Every3Month`,
  "Every 6th month": `Every6Month`,
}

const FEATURES = {
  None: `None`,
  MyPlayer: `MyPlayer`,
  Player: `Player`,
  Premium: `Premium`,
  Playlist: `Playlist`,
  ManageRepertoire: `ManageRepertoire`,
}

const CURRENCY = {
  sek: `sek`,
}

const STATUS = {
  Public: `Public`,
  Private: `Private`,
  Draft: `Draft`,
}

const TYPE = {
  Individual: `Individual`,
  Group: `Group`,
}

const initialState = {
  type: TYPE.Individual,
  name: undefined,
  title: undefined,
  description: undefined,
  currency: CURRENCY.sek,
  amount: undefined,
  interval: INTERVAL.Month,
  features: [ FEATURES.None ],
  roles: [],
  trialPeriodDays: 0,
  stripePlanId: undefined,
  stripeCouponId: undefined,
  status: undefined,
}

class Plan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
      name: (props.location.state && props.location.state.name) || ``,
    }
  }

  render = () => {
    const plan = this.props.plan || {}

    return (
      <Panel
        title={
          !this.props.planId
            ? `New plan`
            : this.props.isFetching
            ? `Loading plan...`
            : this.state.name
            ? `Edit plan: ${ this.state.name }`
            : `Plan`
        }
        onClose={() =>
          this.props.history.push(
            this.props.returnTo ||
              `/plans${
                this.props.location.search ? this.props.location.search : ``
              }`
          )
        }
      >
        <CommandBar>
          {this.props.planId && (
            <Command.Delete
              onClick={() => {
                const deleteNotification = new root.wav.Notification({
                  text: `<h4 class="ui header"><i aria-hidden="true" class="trash icon"></i><div class="content">You are about to delete this plan permanently, delete this plan?</div></h4>`,
                  buttons: [
                    root.wav.Notification.button(
                      `Yes`,
                      `ui compact button`,
                      () => {
                        this.props.deletePlan(this.state.id).then(id => {
                          deleteNotification.close()
                          this.props.removePlanFromPlans(id)
                          this.props.history.push(
                            this.props.returnTo ||
                              `/plans${
                                this.props.location.search
                                  ? this.props.location.search
                                  : ``
                              }`
                          )
                        })
                      }
                    ),
                    root.wav.Notification.button(
                      `No`,
                      `ui compact button`,
                      () => {
                        deleteNotification.close()
                      }
                    ),
                  ],
                  theme: `semanticui`,
                  modal: true,
                }).show()
              }}
            />
          )}
          <Command.Save
            disabled={isEqual(this.state, plan)}
            onClick={() => {
              this.props[this.state.id ? `updatePlan` : `createPlan`]({
                ...this.state,
                ...(this.state.amount
                  ? {
                      amount: parseFloat(
                        this.state.amount.toString().replace(/,/g, `.`),
                        10
                      ),
                    }
                  : {}),
              }).then(plan => {
                this.props.updatePlanInPlans(plan)
                this.props.planId
                  ? this.props.fetchPlan(plan.id)
                  : this.props.history.push(
                      `/plans/${ plan.id }` + this.props.location.search
                    )
              })
            }}
          />
        </CommandBar>
        <Content
          loading={
            this.props.isFetching ||
            this.props.isDeleting ||
            this.props.isSaving
          }
        >
          <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
            <Form.Select
              label="Type"
              name="type"
              options={Object.entries(TYPE).map(([ key, value ]) => ({
                value,
                text: key,
              }))}
              value={this.state.type || ``}
              onChange={this.handleSelectChange}
            />

            <Form.Input
              label="Name"
              name="name"
              value={this.state.name || ``}
              onChange={this.handleInputChange}
            />

            <Form.Input
              label="Title"
              name="title"
              value={this.state.title || ``}
              onChange={this.handleInputChange}
            />

            <Form.TextArea
              label="Description"
              name="description"
              value={this.state.description || ``}
              onChange={this.handleInputChange}
            />

            <Form.Group>
              <Form.Input
                label="Amount"
                name="amount"
                type="text"
                value={this.state.amount || ``}
                onChange={this.handleInputChange}
              />
              <Form.Select
                fluid
                label="Currency"
                name="currency"
                options={Object.entries(CURRENCY).map(([ key, value ]) => ({
                  value,
                  text: key,
                }))}
                value={this.state.currency || ``}
                onChange={this.handleSelectChange}
              />
            </Form.Group>

            <Form.Select
              label="Billing interval"
              name="interval"
              options={Object.entries(INTERVAL).map(([ key, value ]) => ({
                value,
                text: key,
              }))}
              value={this.state.interval || ``}
              onChange={this.handleSelectChange}
            />

            <Form.Select
              label="Features"
              name="features"
              multiple
              options={Object.entries(FEATURES).map(([ key, value ]) => ({
                value,
                text: key,
              }))}
              value={this.state.features}
              onChange={this.handleSelectChange}
            />

            <Form.Field>
              <label>Roles</label>
              <Select
                url="/roles"
                value={this.state.roles}
                onChange={this.handleRolesChange}
                fluid
                multiple
              />
            </Form.Field>

            <Form.Input
              label="Trial period (days)"
              name="trialPeriodDays"
              type="number"
              value={this.state.trialPeriodDays || 0}
              onChange={this.handleInputChange}
            />

            <Form.Input
              label="Stripe price plan ID"
              name="stripePlanId"
              value={this.state.stripePlanId || ``}
              onChange={this.handleInputChange}
            />

            <Form.Input
              label="Stripe coupon ID"
              name="stripeCouponId"
              value={this.state.stripeCouponId || ``}
              onChange={this.handleInputChange}
            />

            {this.state.id && (
              <Form.Select
                label="Status"
                name="status"
                options={Object.entries(STATUS).map(([ key, value ]) => ({
                  value,
                  text: key,
                }))}
                value={this.state.status || ``}
                onChange={this.handleSelectChange}
              />
            )}

            {this.state.createdUtc && (
              <Form.Field>
                <label>Created</label>
                <p>
                  {new Date(this.state.createdUtc).toLocaleString(
                    navigator.language
                  )}
                </p>
              </Form.Field>
            )}

            {this.state.updatedUtc && (
              <Form.Field>
                <label>Updated</label>
                <p>
                  {new Date(this.state.updatedUtc).toLocaleString(
                    navigator.language
                  )}
                </p>
              </Form.Field>
            )}
          </Form>
        </Content>
      </Panel>
    )
  }

  componentDidMount = () => {
    this.props.match.params.planId &&
      this.props
        .fetchPlan(this.props.match.params.planId)
        .then(data => this.setState(data))
  }

  componentDidUpdate = previousProps => {
    if (
      previousProps.plan.id !== this.props.plan.id ||
      previousProps.plan.updatedUtc !== this.props.plan.updatedUtc
    ) {
      this.setState({
        ...this.props.plan,
      })
    }
  }

  handleInputChange = ({ target: { name, value } }) => {
    process.env.NODE_ENV === `development` &&
      console.log(`Plan:handleInputChange`, name, value)

    this.setState(previousState => ({
      ...previousState,
      [name]: value,
    }))
  }

  handleSelectChange = (event, { name, value }) => {
    process.env.NODE_ENV === `development` &&
      console.log(`Plan:handleSelectChange`, name, value)

    this.setState(previousState => ({
      ...previousState,
      [name]: value,
    }))
  }

  handleRolesChange = roles => {
    this.setState(previousState => ({
      ...previousState,
      roles,
    }))
  }
}

export default props => {
  const planId = props.match.params.planId

  return (
    <Subscribe to={[ PlanContainer, PlansContainer ]}>
      {({ state, ...actions }, { state: plansState, ...plansActions }) => {
        console.log(state)
        return (
          <Plan
            key={planId}
            planId={planId}
            {...plansActions}
            {...props}
            {...state}
            {...actions}
          />
        )
      }}
    </Subscribe>
  )
}
