import React from "react"
import Panel, { Content } from "../Panel"
import FileUpload from "../FileUpload"

export default ({
  history,
  match: {
    params: { arrangementId, versionId, audioId },
    url,
  },
}) => (
  <Panel
    title="Upload audio file"
    onClose={() => {
      history.push(
        url.slice(0, url.indexOf(`/audiotracks`) + `/audiotracks`.length)
      )
    }}
    key={url}
  >
    <Content>
      <FileUpload
        showProgress
        url={`/arrangements/${ arrangementId }/versions/${ versionId }/audiotracks/${ audioId }/upload`}
        successHandler={() =>
          history.push(
            `/arrangements/${ arrangementId }/versions/${ versionId }/audiotracks/${ audioId }`
          )
        }
        accept="audio/*,video/*"
        metadata={{
          arrangementId,
          versionId,
          audioId,
        }}
      />
    </Content>
  </Panel>
)
