import React, { Component } from "react"
import { Content } from "../Panel"
import Select from "../Select"
import { Form, Table, Header, Label } from "semantic-ui-react"
import { Subscribe } from "unstated"
import { UserContainer } from "./UserContainer"

class Organizations extends Component {
  render = () => {
    return (
      <Subscribe to={[ UserContainer ]}>
        {userStore => (
          <Content loading={userStore.state.isFetching}>
            <Header as="h3">
              Organizations
              {userStore.state.details.organizationalUnits &&
                userStore.state.details.organizationalUnits.length > 0 && (
                  <Label size="tiny" circular>
                    {userStore.state.details.organizationalUnits.length}
                  </Label>
                )}
            </Header>
            {userStore.state.details.organizationalUnits && userStore.state.details.organizationalUnits.length > 0 && (
              <Table basic selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {userStore.state.details.organizationalUnits &&
                  userStore.state.details.organizationalUnits.length > 0 && (
                    <Table.Body>
                      {userStore.state.details.organizationalUnits.map(
                        x => (
                          <Table.Row
                            key={x.id}
                            style={{ cursor: `pointer` }}
                            onClick={() =>
                              this.props.history.push(
                                `/organizations/${ x.id }`
                              )
                            }
                          >
                            <Table.Cell>{x.name}</Table.Cell>
                            <Table.Cell>{x.type}</Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  )}
              </Table>
            )}
            <Form autoComplete="off">
              <Form.Field>
                <label
                  style={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    userSelect: `none`,
                  }}
                >
                  Edit organizations
                </label>
                <Select
                  url="/organizations/ou"
                  value={userStore.state.organizations}
                  onChange={newOrganizations => {
                    userStore.updateOrganizations(newOrganizations)
                  }}
                  fluid
                  multiple
                />
              </Form.Field>
            </Form>
          </Content>
        )}
      </Subscribe>
    )
  }
}

export default props => (
  <Subscribe to={[ UserContainer ]}>
    {userStore => <Organizations userStore={userStore} {...props} />}
  </Subscribe>
)
