import React from "react"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { Dropdown } from "semantic-ui-react"
import { Form } from "semantic-ui-react"
import { Link } from "react-router-dom"
import get from "lodash.get"
import ReportContainer from "./ReportContainer"
import Draft from "../Draft"
import merge from "lodash.merge"
import { push } from "../history"

const STATUS = {
  Open: 'Open',
  'In progress': 'InProgress',
  Closed: 'Closed',
  Ignored: 'Ignored',
}

export default ({ onUpdate, ...props }) => (
  <ReportContainer
    id={props.match.params.reportId !== `new` && props.match.params.reportId}
    onUpdate={onUpdate}
  >
    {report => (
      <Draft
        key={get(report, `data.id`)}
        original={merge(
          {},
          report.data
        )}
      >
        {draft => (
          <Panel
            title={
              report.loading
                ? `Loading report...`
                : 'Arrangement feedback report'
            }
            onClose={() => push(props.returnTo || `/reports`)}
          >
            <CommandBar>
              <Command
                save
                disabled={
                  (get(draft, `state.id`) && !draft.dirty)
                }
                onClick={() => report.save(draft.state)}
              />
            </CommandBar>
            <Content
              loading={report.loading}
              loadingText={report.loadingMessage}
            >
              <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
                <Form.Field>
                  <label>Status</label>
                  <Dropdown
                    name="status"
                    value={draft.state.status}
                    options={Object.entries(STATUS).map(([key, value]) => ({
                      text: key,
                      value: value,
                    }))}
                    onChange={(_, data) => draft.handleChange({ target: { name: 'status', value: data.value }})}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Type</label>
                  <p>
                    {draft.state.type}
                  </p>
                </Form.Field>

                <Form.Field>
                  <label>Comments</label>
                  <p>
                    {draft.state.comments || '-'}
                  </p>
                </Form.Field>

                <Form.Field>
                  <label>Arrangement</label>
                  <div>{draft.state.arrangementTitle || '-'}</div>
                  <Link to={`/arrangements/${draft.state.arrangementId}`}>{draft.state.arrangementId}</Link>
                </Form.Field>

                <Form.Field>
                  <label>Arrangement version</label>
                  {draft.state.arrangementVersionId
                    ? <Link to={`/arrangements/${draft.state.arrangementId}/versions/${draft.state.arrangementVersionId}`}>{draft.state.arrangementVersionId}</Link>
                    : '-'}
                </Form.Field>

                <Form.Field>
                  <label>Arrangement play mode</label>
                  {draft.state.arrangementVersionId && draft.state.playModeId
                    ? <Link to={`/arrangements/${draft.state.arrangementId}/versions/${draft.state.arrangementVersionId}/playmodes/${draft.state.playModeId}`}>{draft.state.playModeId}</Link>
                    : draft.state.playModeId || '-'}
                </Form.Field>

                <Form.Field>
                  <label>Owner (client service user)</label>
                  <Link to={`/users/${draft.state.ownerClientUserId}`}>{draft.state.ownerClientUserDisplayName || draft.state.ownerClientUserId}</Link>
                </Form.Field>

                <Form.Field>
                  <label>Reporter (end-user ID in client service)</label>
                  <p>
                    {draft.state.reporterUserId || '-'}
                  </p>
                </Form.Field>

                <Form.Field>
                  <label>Metadata</label>
                  {draft.state.metadata && Object.keys(draft.state.metadata).length ? <pre>
                    {JSON.stringify(draft.state.metadata, (key, val) => {
                      if (typeof val === "string") {
                        try{
                          return JSON.parse(val);
                        } catch {
                          // Ignore
                        }
                      }
                      return val
                    }, 2)}
                  </pre> : '-'}
                </Form.Field>

                <Form.Field>
                  <label>Created</label>
                  <p>
                    {draft.state.createdUtc && new Date(draft.state.createdUtc).toLocaleString(navigator.language)}
                  </p>
                </Form.Field>
                <Form.Field>
                  <label>Updated</label>
                  <p>
                    {draft.state.updatedUtc && new Date(draft.state.updatedUtc).toLocaleString(navigator.language)}
                  </p>
                </Form.Field>
              </Form>
            </Content>
          </Panel>
        )}
      </Draft>
    )}
  </ReportContainer>
)
