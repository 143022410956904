import React, { Component } from "react"
import styled from "styled-components"
import GetScore from "../api/containers/GetScore"
import { Loader, Form, TextArea } from "semantic-ui-react"
import PutScore from "../api/containers/PutScore"
import PostScore from "../api/containers/PostScore"
import isEqual from "deep-equal"
import { CommandBar, Command, Content } from "../Panel"
import Config from "../Config"
import get from "lodash.get"

const prettyPrintJson = json => {
  try {
    return JSON.stringify(
      json.constructor.name === `String` ? JSON.parse(json) : json,
      null,
      2
    )
  } catch (error) {
    return json
  }
}

const parseJson = data => {
  if (data) {
    try {
      return JSON.parse(data)
    } catch (error) {
      return data
    }
  } else {
    return data
  }
}

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Score = ({
  data: {
    introDelay,
    timeMapping,
    noteSheet: { url: noteSheetUrl },
    noteSheetVertical: { url: noteSheetVerticalUrl },
    noteSheetPdf: { url: noteSheetPdfUrl },
  },
  onChange = () => {},
  versionId,
  arrangementId,
  advancedMode,
}) => (
  <Form>
    <Form.Field
      control="input"
      type="number"
      label="Intro delay"
      name="introDelay"
      value={introDelay || 0}
      onChange={onChange}
    />
    {advancedMode && (
      <Config>
        {config => (
          <React.Fragment>
            <Form.Field>
              <LabelContainer>
                <label>Horizontal score SVG</label>
                {noteSheetUrl && (
                  <a
                    href={
                      get(config, `asset.url`, ``).replace(/^\//, () => ``) +
                      noteSheetUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open
                  </a>
                )}
              </LabelContainer>
              <Form.Input
                type="text"
                name="noteSheetUrl"
                value={noteSheetUrl || ``}
                onChange={onChange}
              />
            </Form.Field>

            <Form.Field>
              <LabelContainer>
                <label>Vertical score SVG</label>
                {noteSheetVerticalUrl && (
                  <a
                    href={
                      get(config, `asset.url`, ``).replace(/^\//, () => ``) +
                      noteSheetVerticalUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open
                  </a>
                )}
              </LabelContainer>
              <Form.Input
                type="text"
                name="noteSheetVerticalUrl"
                value={noteSheetVerticalUrl || ``}
                onChange={onChange}
              />
            </Form.Field>

            <Form.Field>
              <LabelContainer>
                <label>PDF</label>
                {noteSheetPdfUrl && (
                  <a
                    href={
                      get(config, `asset.url`, ``).replace(/^\//, () => ``) +
                      noteSheetPdfUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open
                  </a>
                )}
              </LabelContainer>
              <Form.Input
                type="text"
                name="noteSheetPdfUrl"
                value={noteSheetPdfUrl || ``}
                onChange={onChange}
              />
            </Form.Field>

            <Form.Field
              control={TextArea}
              label="Time mapping"
              name="timeMapping"
              value={prettyPrintJson(timeMapping) || timeMapping}
              onChange={onChange}
              rows={20}
              style={{ marginBottom: `20px` }}
            />
          </React.Fragment>
        )}
      </Config>
    )}
  </Form>
)

Score.displayName = `Score`

class CreateScore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      score: {
        introDelay: undefined,
        noteSheetUrl: undefined,
        noteSheetVerticalUrl: undefined,
        noteSheetPdfUrl: undefined,
        timeMapping: undefined,
      },
    }
  }

  render = () => {
    return (
      <div style={{ height: `100%` }}>
        <CommandBar>
          <PostScore
            arrangementId={this.props.arrangementId}
            versionId={this.props.versionId}
            data={{
              ...this.state.score,
              ...(this.state.score.timeMapping
                ? { timeMapping: parseJson(this.state.score.timeMapping) }
                : {}),
            }}
          >
            {score => (
              <Command
                disabled={
                  isEqual(this.state, this.initialState) ||
                  score.mode === `loading`
                }
                onClick={() => score.create()}
              >
                <span>
                  {/* <Icon name="cloud upload" /> */}
                  {score.mode === `error`
                    ? `Retry`
                    : score.mode === `idle`
                    ? `Save`
                    : `Saving`}
                </span>
              </Command>
            )}
          </PostScore>
        </CommandBar>
        <Content disabled={this.props.disabled}>
          <Score
            data={{
              ...this.state.score,
              noteSheet: {
                url: this.state.score.noteSheetUrl,
              },
              noteSheetVertical: {
                url: this.state.score.noteSheetVerticalUrl,
              },
              noteSheetPdf: {
                url: this.state.score.noteSheetPdfUrl,
              },
            }}
            onChange={this.handleChange}
          />
        </Content>
      </div>
    )
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState(previousState => ({
      ...previousState,
      score: {
        ...previousState.score,
        [name]: value,
      },
    }))
  }
}

class UpdateScore extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      score: {
        introDelay: this.props.data.introDelay,
        noteSheetUrl:
          this.props.data.noteSheet && this.props.data.noteSheet.absolutePath,
        noteSheetVerticalUrl:
          this.props.data.noteSheetVertical &&
          this.props.data.noteSheetVertical.absolutePath,
        noteSheetPdfUrl:
          this.props.data.noteSheetPdfUrl &&
          this.props.data.noteSheetPdfUrl.absolutePath,
        timeMapping: this.props.data.timeMapping,
      },
      advancedMode: false,
    }
    this.state = { ...this.initialState }
  }

  render = () => {
    return (
      <div style={{ height: `100%` }}>
        <CommandBar>
          <Command.Advanced
            toggled={this.state.advancedMode}
            onClick={this.toggleAdvancedMode}
          />
          <PutScore
            arrangementId={this.props.arrangementId}
            versionId={this.props.versionId}
            data={{
              ...this.state.score,
              ...(this.state.score.timeMapping
                ? { timeMapping: parseJson(this.state.score.timeMapping) }
                : {}),
            }}
          >
            {score => (
              <Command
                disabled={
                  isEqual(this.state.score, this.initialState.score) ||
                  score.mode === `loading`
                }
                onClick={() => score.update()}
              >
                <span>
                  {/* <Icon name="cloud upload" /> */}
                  {score.mode === `error`
                    ? `Retry`
                    : score.mode === `idle`
                    ? `Save`
                    : `Saving`}
                </span>
              </Command>
            )}
          </PutScore>
        </CommandBar>
        <Content disabled={this.props.disabled}>
          <Score
            data={{
              ...this.state.score,
              noteSheet: {
                url: this.state.score.noteSheetUrl,
              },
              noteSheetVertical: {
                url: this.state.score.noteSheetVerticalUrl,
              },
              noteSheetPdf: {
                url: this.state.score.noteSheetPdfUrl,
              },
            }}
            onChange={this.handleChange}
            advancedMode={this.state.advancedMode}
          />
        </Content>
      </div>
    )
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState(previousState => ({
      ...previousState,
      score: {
        ...previousState.score,
        [name]: value,
      },
    }))
  }

  toggleAdvancedMode = () => {
    this.setState(previousState => ({
      ...previousState,
      advancedMode: !previousState.advancedMode,
    }))
  }
}

export default ({ arrangementId, versionId, version: { status } }) => (
  <GetScore arrangementId={arrangementId} versionId={versionId}>
    {({ mode, payload, reload }) => (
      <div style={{ height: `100%` }}>
        {mode === `loading` && (
          <Content>
            <Loader active inline="centered" content="Loading score" />
          </Content>
        )}
        {mode === `success` && (
          <UpdateScore
            data={payload}
            arrangementId={arrangementId}
            versionId={versionId}
            disabled={status !== `Draft`}
          />
        )}
        {mode === `error` &&
          payload.response &&
          payload.response.data &&
          payload.response.data.statusCode === `NotFound` && (
            <CreateScore
              arrangementId={arrangementId}
              versionId={versionId}
              disabled={status !== `Draft`}
            />
          )}
      </div>
    )}
  </GetScore>
)
