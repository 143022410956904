import React, { Component } from "react"
import { Subscribe } from "unstated"
import { OrderContainer } from "./OrderContainer"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { Form } from "semantic-ui-react"
import get from "lodash.get"

class CreateInvoice extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render = () => (
    <Panel
      title="Create invoice"
      onClose={() =>
        this.props.history.push(
          this.props.returnTo ||
            `/orders/${ this.props.orderId }${
              this.props.location.search ? this.props.location.search : ``
            }`
        )
      }
    >
      <CommandBar>
        <Command onClick={this.create}>Create invoice</Command>
      </CommandBar>
      <Content
        loading={this.props.isFetchingOrder || this.props.sendingInvoice}
        loadingText={this.props.sendingInvoice ? `Creating invoice...` : ``}
      >
        <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
          <Form.Input
            label="Email"
            name="email"
            value={this.state.email || ``}
            onChange={this.handleInputChange}
          />
          <Form.Input
            label="Name"
            name="name"
            value={this.state.name || ``}
            onChange={this.handleInputChange}
          />
          <Form.Input
            label="Address"
            name="line1"
            value={this.state.line1 || ``}
            onChange={this.handleInputChange}
          />
          <Form.Input
            name="line2"
            value={this.state.line2 || ``}
            onChange={this.handleInputChange}
          />
          <Form.Input
            label="Postal code"
            name="postalCode"
            value={this.state.postalCode || ``}
            onChange={this.handleInputChange}
          />
          <Form.Input
            label="City"
            name="city"
            value={this.state.city || ``}
            onChange={this.handleInputChange}
          />
          <Form.Input
            label="Country"
            name="country"
            value={this.state.country || ``}
            onChange={this.handleInputChange}
          />
        </Form>
      </Content>
    </Panel>
  )

  handleInputChange = ({ target: { name, value } }) => {
    this.setState(previousState => ({
      ...previousState,
      [name]: value,
    }))
  }

  create = () => {
    this.props
      .createInvoice({
        orderId: this.props.match.params.orderId,
        ...this.state,
      })
      .then(() => {
        this.props.history.push(`/orders`)
      })
  }

  componentDidMount = () => {
    const order = get(this.props, `order`, {})
    const billing = get(order, `billing`, undefined)

    if (order) {
      this.setState({
        ...(billing
          ? billing
          : {
              name: get(order, `user.displayName`, ``),
              email: get(order, `user.profile.email`, ``),
            }),
      })
    } else {
      this.props.fetchOrder(this.props.orderId).then(order => {
        console.log(order)
        this.setState({
          ...get(order, `billing`, {
            name: get(order, `user.displayName`, ``),
            email: get(order, `user.profile.email`, ``),
          }),
        })
      })
    }
  }
}

export default props => (
  <Subscribe to={[ OrderContainer ]}>
    {orderStore => (
      <CreateInvoice
        key={orderStore.state.order.id + `:create-invoice`}
        orderId={props.match.params.orderId}
        order={orderStore.state.order}
        isFetchingOrder={orderStore.isFetching}
        sendingInvoice={orderStore.state.sendingInvoice}
        fetchOrder={orderStore.fetchOrder}
        createInvoice={orderStore.createInvoice}
        {...props}
      />
    )}
  </Subscribe>
)
