import styled from "styled-components"

const Details = styled.details`
  background: #f9fafb;
  border-radius: 3px;

  span {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  span:not(:first-child) {
    margin-top: 0.5rem;
  }
`

Details.Summary = styled.summary`
  background: #e0e1e2;
  padding: 0.2em 1em;
  border-radius: 3px;
`

export default Details
