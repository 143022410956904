import { root } from "../services/Globals"

export default async (file, onUploadProgress) => {
  const data = new FormData()
  data.append(`file`, file)

  try {
    const response = await root.wav.api({
      url: `/assets`,
      method: `post`,
      data,
      ...(onUploadProgress ? { onUploadProgress } : {}),
    })

    return response.data
  } catch (error) {
    console.error(error)
    return error
  }
}
