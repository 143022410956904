import React from "react"
import GetVersion from "../api/containers/GetVersion"
import Panel, { Menu, MenuItem /* Content */ } from "../Panel"
// import { Loader } from "semantic-ui-react"
import { Link, Route, Switch } from "react-router-dom"
import Details from "./Details"
import Score from "./Score"
import Audiotracks from "./Audiotracks"
import Overview from "./VersionOverview"
import Status from "./Status"
import Jobs from "../api/containers/Jobs"
import Validate from "../api/containers/Validate"
import Notes from "./VersionNotes"
import PlayModes from "./PlayModes"

const Version = ({
  match: {
    params: { arrangementId, versionId },
    url,
    path,
  },
  history,
  location: { pathname },
  jobs,
}) => (
  <Jobs arrangementVersionId={versionId}>
    {jobs => (
      <Panel
        title="Version"
        onClose={() => history.push(`/arrangements/${ arrangementId }/versions`)}
        menu={
          <Menu>
            <MenuItem
              active={
                pathname.indexOf(`/details`) === -1 &&
                pathname.indexOf(`/score`) === -1 &&
                pathname.indexOf(`/audiotracks`) === -1 &&
                pathname.indexOf(`/playmodes`) === -1 &&
                pathname.indexOf(`/status`) === -1 &&
                pathname.indexOf(`/notes`) === -1
              }
            >
              <Link to={url}>Overview</Link>
            </MenuItem>
            <MenuItem active={pathname.indexOf(`/details`) >= 0}>
              <Link to={`${ url }/details`}>Details</Link>
            </MenuItem>
            <MenuItem
              active={pathname.indexOf(`/score`) >= 0}
              disabled={
                jobs.running.filter(({ type }) => type === `ScoreGenerating`)
                  .length > 0
              }
            >
              <Link to={`${ url }/score`}>
                Score
                {jobs.running.filter(({ type }) => type === `ScoreGenerating`)
                  .length > 0 && <small>{` `}(Building...)</small>}
              </Link>
            </MenuItem>
            <MenuItem active={pathname.indexOf(`/audiotracks`) >= 0}>
              <Link to={`${ url }/audiotracks`}>Audio tracks</Link>
            </MenuItem>
            <MenuItem active={pathname.indexOf(`/playmodes`) >= 0}>
              <Link to={`${ url }/playmodes`}>Play modes</Link>
            </MenuItem>
            <MenuItem active={pathname.indexOf(`/status`) >= 0}>
              <Link to={`${ url }/status`}>Status</Link>
            </MenuItem>
            <MenuItem active={pathname.indexOf(`/notes`) >= 0}>
              <Link to={`${ url }/notes`}>Notes</Link>
            </MenuItem>
          </Menu>
        }
      >
        <GetVersion arrangementId={arrangementId} versionId={versionId}>
          {({ mode, payload = {}, reload }) => (
            <React.Fragment>
              <Switch>
                <Route
                  path={`${ url }/details`}
                  render={props => (
                    <Details
                      version={payload}
                      arrangementId={arrangementId}
                      url={url}
                      reloadVersion={reload}
                    />
                  )}
                />
                <Route
                  path={`${ url }/score`}
                  render={props => (
                    <Score
                      arrangementId={arrangementId}
                      versionId={versionId}
                      version={payload}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${ url }/audiotracks`}
                  render={props => (
                    <Audiotracks
                      arrangementId={arrangementId}
                      versionId={versionId}
                      jobs={jobs}
                      version={payload}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${ url }/playmodes`}
                  render={props => (
                    <PlayModes
                      arrangementId={arrangementId}
                      versionId={versionId}
                      version={payload}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${ path }/status`}
                  render={props => <Status jobs={jobs} {...props} />}
                />
                <Route
                  path={`${ path }/notes`}
                  render={props => (
                    <Notes
                      key={payload.id}
                      arrangementId={arrangementId}
                      version={payload}
                      loading={mode === `loading`}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${ path }`}
                  render={props => (
                    <Validate
                      arrangementId={arrangementId}
                      versionId={versionId}
                    >
                      {validation => (
                        <Overview
                          jobs={jobs}
                          version={payload}
                          validation={validation}
                          {...props}
                        />
                      )}
                    </Validate>
                  )}
                />
              </Switch>
            </React.Fragment>
          )}
        </GetVersion>
      </Panel>
    )}
  </Jobs>
)

Version.displayName = `Version`

export default Version
