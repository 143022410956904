import { root } from "../services/Globals"

export default (options = {}) =>
  new Promise((resolve, reject) => {
    try {
      const deleteNotification = new root.wav.Notification({
        text: `<h4 class="ui header"><i aria-hidden="true" class="trash icon"></i><div class="content">You are about to delete this${ ` ${
          options.type
        }` || `` } permanently, do you want to continue?</div></h4>`,
        buttons: [
          root.wav.Notification.button(`Yes`, `ui compact button`, () => {
            Promise.resolve(options.deleteFunction()).then(() => {
              deleteNotification.close()
            })
          }),
          root.wav.Notification.button(`No`, `ui compact button`, () => {
            deleteNotification.close()
          }),
        ],
        theme: `semanticui`,
        modal: true,
        callbacks: {
          afterClose: () => resolve(),
        },
      }).show()
    } catch (error) {
      reject(error)
    }
  })
