import React, { Component, createContext } from "react"
import styled from "styled-components"
import "./Panels.css"
import { Subscribe } from "unstated"
import { SidebarContainer } from "./Sidebar/SidebarContainer"

const { Provider, Consumer } = createContext()

export { Consumer as PanelsConsumer }

const FillerSpace = styled.div`
  height: 100%;
  min-width: ${ props => props.sidebarWidth }px;
`

class Panels extends Component {
  constructor(props) {
    super(props)

    this.element = undefined
    this.observer = undefined
    this.portalContainer = undefined
  }

  render = () => {
    return (
      <Provider value={this.portalContainer}>
        <div
          className="wav-panel-manager"
          ref={element => {
            this.element = element
          }}
        >
          {this.props.children}
          <div
            className="portal-container"
            ref={element => {
              this.portalContainer = element
            }}
            style={{ marginLeft: `-1px`, display: `flex` }}
          />
          <FillerSpace sidebarWidth={Math.floor(this.props.sidebarWidth)} />
        </div>
      </Provider>
    )
  }

  componentDidMount = () => {
    let previousPanelCount = 0

    this.observer = new MutationObserver(mutations => {
      this.element.scrollLeft =
        this.element.scrollWidth -
        this.element.offsetWidth +
        this.portalContainer.offsetWidth

      if (!this.props.sidebarToggled) {
        this.element.scrollLeft =
          this.element.scrollLeft -
          (this.props.sidebarWidth && Math.ceil(this.props.sidebarWidth))
      }

      const openPanels = document.querySelectorAll(`.wav-panel-next`)

      if (openPanels.length !== previousPanelCount) {
        openPanels.length && openPanels[openPanels.length - 1].focus()

        previousPanelCount = openPanels.length
      }
    })

    this.observer.observe(this.element, { childList: true, subtree: true })

    this.element.scrollLeft =
      this.element.scrollWidth -
      this.element.offsetWidth +
      this.portalContainer.offsetWidth -
      (this.props.sidebarWidth && Math.ceil(this.props.sidebarWidth))
  }

  componentWillUnmount = () => {
    this.observer.disconnect()
  }
}

export default props => (
  <Subscribe to={[ SidebarContainer ]}>
    {sidebar => (
      <Panels
        sidebarWidth={sidebar.state.metrics.width}
        sidebarToggled={sidebar.state.toggled}
        {...props}
      />
    )}
  </Subscribe>
)
