import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Input, Loader } from "semantic-ui-react";
import { CommandBar, Content } from "../Panel";
import "./Catalog.css";
import { getCatalogInfo, getClients, moveToMyTitlesCatalog, moveToPublicCatalog } from "../api/catalog-api";

const PUBLIC_CATALOG = "WeAreVoice";
const MY_TITLES_CATALOG = "MyPlayer";

export default function Catalog({ arrangementId }) {
  const [catalog, setCatalog] = useState(PUBLIC_CATALOG);
  const [client, setClient] = useState();
  const [availableClients, setAvailableClients] = useState([])
  const [userId, setUserId] = useState();
  const [isLoadingMove, setIsLoadingMove] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setSuccess(false)
  }, [catalog, client, userId])

  useEffect(() => {
    if (arrangementId) {
        setIsLoading(true)
        getCatalogInfo({ arrangementId }).then(res => {
            setCatalog(res.catalog)
            setClient(res.ownerClientUserId)
            setUserId(res.userId)

            getClients().then((res) => {
                setAvailableClients(res)
                setIsLoading(false)
            })

        })
    }

  }, [arrangementId])

  const handleMoveArrangement = useCallback(() => {
    if (!arrangementId) {
        return
    }


    if (catalog === PUBLIC_CATALOG) {
        setIsLoadingMove(true)
        moveToPublicCatalog({arrangementId}).then(() => {
            setIsLoadingMove(false)
            setSuccess(true)
        }).catch(() => {
            setIsLoadingMove(false)
        })

    }

    else if (catalog === MY_TITLES_CATALOG) {
        setIsLoadingMove(true)
        moveToMyTitlesCatalog({arrangementId, ownerClientUserId: client, userId }).then(() => {
            setIsLoadingMove(false)
            setSuccess(true)
        }).catch(() => {
            setIsLoadingMove(false)
        })
    }
  }, [arrangementId, catalog, client, userId])

  const catalogOptions = useMemo(
    () => [
      { value: PUBLIC_CATALOG, text: "Public" },
      { value: MY_TITLES_CATALOG, text: "My Titles" },
    ],
    []
  );

  const clientOptions = useMemo(
    () => availableClients.map(c => ({
        value: c.id,
        text: c.displayName
    })),
    [availableClients]
  );

  if (isLoading) {
    return <Content>
    <Loader active content="Loading" inline="centered" />
  </Content>
  }

  return (
    <div>
      <CommandBar />

      <div className="wrapper">
        <div className="field">
          <p>Catalog</p>
          <Dropdown
            onChange={(_, v) => setCatalog(v.value)}
            value={catalog}
            options={catalogOptions}
          />
        </div>

        {catalog === MY_TITLES_CATALOG && (
            <>
          <div className="field">
            <p>Client</p>
            <Dropdown
              onChange={(_, v) => setClient(v.value)}
              value={client}
              options={clientOptions}
            />
          </div>

          <div className="field">
            <p>User ID</p>
            <Input
              onChange={(_, v) => setUserId(v.value)}
              value={userId}
            />
          </div>

          </>
        )}

        <Button loading={isLoadingMove} onClick={handleMoveArrangement}>
            Move arrangement to {catalogOptions.find(c => c.value === catalog)?.text} catalog
        </Button>

        {success && <p className='success'>Successfully moved arrangement to {catalog} catalog</p>}
      </div>
    </div>
  );
}
