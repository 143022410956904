import React from "react"
import styled from "styled-components"
import { push } from "../history"
import { QueryParams } from "../QueryParamsContainer"
import Config from "../Config"
import { AzureSearchContainer } from "../AzureSearchContainer"
import Panels from "../FlexiblePanels"
import Panel, { Content } from "../FlexiblePanel"
import { Input, Table, Button } from "semantic-ui-react"
import Observer from "react-intersection-observer"
import { FIELDS } from "./constants"
import getFriendlyName from "./get-friendly-name"
import getFieldSettings from "./get-field-settings"
import Field from "./Field"
import Filters from "./Filters"
import Columns from "./Columns"

const TableWrapper = styled.div`
  overflow: auto;
  margin-top: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;

  table {
    display: inline-table !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
`

const Container = styled.div`
  padding: 1rem;
`

export default props => (
  <Panels>
    <QueryParams>
      {queryParams => (
        <Config>
          {({
            search: { serviceName, readIndex: index, queryApiKey: apiKey },
            asset: { url: assetUrl },
          }) => (
            <AzureSearchContainer
              serviceName={serviceName}
              index={index}
              apiKey={apiKey}
              parameters={{
                ...queryParams.params,
                filter: (queryParams.params.filter || ``).concat(
                  !(queryParams.params.filter || ``).includes(
                    `type eq 'WeAreVoice.Core.Search.Models.ArrangementSearchModel'`
                  )
                    ? `${
                        queryParams.params.filter &&
                        queryParams.params.filter.length
                          ? ` and `
                          : ``
                      }type eq 'WeAreVoice.Core.Search.Models.ArrangementSearchModel'`
                    : ``
                ),
              }}
            >
              {({ loading, value, nextPageParameters, count }) => {
                const getHeaderCellProps = (
                  fieldName,
                  { sortable, showName = true } = {}
                ) => ({
                  children: showName ? getFriendlyName(FIELDS, fieldName) : ``,

                  style: {
                    pointerEvents: sortable ? `auto` : `none`,
                  },

                  ...(sortable
                    ? {
                        onClick: () => {
                          const current = queryParams.params.orderby || ``

                          queryParams.update({
                            orderby: !current.includes(fieldName)
                              ? `${ fieldName } asc`
                              : current.indexOf(`asc`) > -1
                              ? `${ fieldName } desc`
                              : `${ fieldName } asc`,
                          })
                        },
                      }
                    : {}),

                  ...((queryParams.params.orderby || ``).includes(fieldName)
                    ? {
                        sorted: queryParams.params.orderby.includes(`asc`)
                          ? `ascending`
                          : `descending`,
                      }
                    : {}),
                })

                const selectedFields = ((queryParams.params.select || ``).length
                  ? queryParams.params.select.split(`,`)
                  : Object.keys(FIELDS)
                ).filter(
                  name => !name.match(/Id$/) && name !== `id` && name !== `type`
                )

                const toggleFilter = (type, fieldName, id) => () => {
                  const current = (queryParams.params.filter || ``)
                    .split(/(\sand\s|\sor\s)/)
                    .map(x => x.trim())

                  let next

                  if (type === `collection`) {
                    next = current.find(x => x.includes(id))
                      ? current.filter(x => !x.includes(id))
                      : current.concat([
                          ` and `,
                          `${ fieldName }/any(x:x eq '${ id }')`,
                        ])
                  } else if (type === `entity`) {
                    next = current.find(x => x.includes(id))
                      ? current.filter(x => !x.includes(id))
                      : current.concat([ ` and `, `${ fieldName } eq '${ id }'` ])
                  } else {
                    next = current.filter(x => !x.includes(id))
                  }

                  queryParams.update({
                    filter: next
                      .join(` `)
                      .replace(/\s*(and|or)\s*$/, ``)
                      .replace(/^\s*(and|or)\s*/, ``)
                      .replace(/\sand\sand\s/, ` and `)
                      .replace(/\sor\sor\s/, ` or `),
                    skip: 0,
                  })
                }

                return (
                  <Panel
                    title="Catalog"
                    active={props.match.isExact}
                    onClose={() => push(`/`)}
                    loading={loading}
                  >
                    <Content initialWidth="max">
                      <Container>
                        <div style={{ display: `flex` }}>
                          <Input
                            fluid
                            icon="search"
                            iconPosition="left"
                            placeholder="Search..."
                            value={queryParams.params.search || ``}
                            onChange={(event, { value }) => {
                              queryParams.update({ search: value })
                            }}
                            style={{
                              flexGrow: 1,
                              marginRight: `1rem`,
                              maxWidth: `576px`,
                            }}
                          />

                          <Columns
                            selected={queryParams.params.select}
                            selectedFields={selectedFields}
                            update={queryParams.update}
                          />
                        </div>

                        <Filters
                          toggleFilter={toggleFilter}
                          currentFilters={(queryParams.params.filter || ``)
                            .split(/(\sand\s|\sor\s)/)
                            .map(x => x.trim())
                            .filter(
                              x =>
                                x !==
                                `type eq 'WeAreVoice.Core.Search.Models.ArrangementSearchModel'`
                            )
                            .filter(x => x.length > 0)}
                          searchResults={value}
                        />

                        <div>
                          <h4
                            style={{
                              marginTop: `1rem`,
                              marginBottom: `0.2rem`,
                            }}
                          >
                            Results:{` `}
                          </h4>
                          <span style={{ fontWeight: `normal` }}>
                            {!loading && count ? count : <span>&mdash;</span>}
                          </span>
                        </div>

                        <TableWrapper>
                          <Table celled striped selectable sortable singleLine>
                            <Table.Header>
                              <Table.Row>
                                {selectedFields.map(name => (
                                  <Table.HeaderCell
                                    key={name}
                                    {...getHeaderCellProps(
                                      name,
                                      getFieldSettings(name)
                                    )}
                                  />
                                ))}
                              </Table.Row>
                            </Table.Header>

                            <Table.Body>
                              {value.map((item, index) => (
                                <Table.Row key={index}>
                                  {selectedFields.map(name => (
                                    <Field
                                      key={name}
                                      type={name}
                                      item={item}
                                      toggleFilter={toggleFilter}
                                      assetUrl={assetUrl}
                                      currentFilter={
                                        queryParams.params.filter || ``
                                      }
                                    />
                                  ))}
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </TableWrapper>
                      </Container>
                      {nextPageParameters && (
                        <Observer
                          onChange={inView =>
                            inView &&
                            !loading &&
                            queryParams.update(nextPageParameters)
                          }
                        >
                          <div
                            style={{
                              width: `100%`,
                              display: `flex`,
                              justifyContent: `center`,
                              padding: `3em`,
                            }}
                          >
                            <Button
                              basic
                              content={loading ? `Loading...` : `Load more`}
                              onClick={() =>
                                queryParams.update(nextPageParameters)
                              }
                              loading={loading}
                              disabled={loading}
                            />
                          </div>
                        </Observer>
                      )}
                    </Content>
                  </Panel>
                )
              }}
            </AzureSearchContainer>
          )}
        </Config>
      )}
    </QueryParams>
  </Panels>
)
