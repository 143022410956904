import React, { Component } from "react"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { Subscribe } from "unstated"
import { LicensesContainer } from "./LicensesContainer"
import { LicenseContainer } from "./LicenseContainer"
import { Form, Dropdown, Table, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"
import get from "lodash.get"
import isEqual from "deep-equal"
import { root } from "../services/Globals"
// import concatEntityIfMissing from "../concat-entity-if-missing"

class Member extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false

    this.initialState = {
      isFetching: false,
      member: {},
    }

    this.state = {
      ...this.initialState,
    }
  }

  render = () => {
    return (
      <Panel
        title={
          this.state.isFetching
            ? `Loading member...`
            : `Member: ${ get(
                this.state,
                `member.user.displayName`,
                this.props.location.state.member.user.displayName
              ) }`
        }
        onClose={() =>
          this.props.history.push(
            this.props.returnTo ||
              `/choirs/${ this.props.match.params.choirId }/members${
                this.props.location.search ? this.props.location.search : ``
              }`
          )
        }
      >
        <CommandBar>
          <Command.Save
            disabled={isEqual(
              this.state.initialLicences,
              get(this.state, `member.licences`)
            )}
            onClick={() => {
              const currentMemberLicenseIds = get(
                this.state,
                `initialLicences`,
                []
              ).map(({ id }) => id)

              const licenseIdsToAdd = get(this.state, `member.licences`, [])
                .map(({ id }) => id)
                .filter(id => !currentMemberLicenseIds.includes(id))

              const licenseIdsToRemove = currentMemberLicenseIds.filter(
                id =>
                  !get(this.state, `member.licences`, [])
                    .map(({ id }) => id)
                    .includes(id)
              )

              Promise.all([
                Promise.all(
                  licenseIdsToAdd.map(licenseId =>
                    this.props
                      .assignLicenseToUser({
                        licenseId,
                        userId: this.props.match.params.userId,
                      })
                      .then(({ data }) => data)
                  )
                ),
                Promise.all(
                  licenseIdsToRemove.map(licenseId =>
                    this.props
                      .assignLicenseToUser({
                        licenseId,
                        userId: null,
                      })
                      .then(({ data }) => data)
                  )
                ),
              ]).then(([ added, removed ]) =>
                Promise.all(
                  [ ...added, ...removed ]
                    .map(license => this.props.updateLicenses(license))
                    .concat(this.fetchMember())
                )
              )
            }}
          />
        </CommandBar>
        <Content loading={this.state.isFetching} loadingText="Loading member">
          <Form autoComplete="off">
            <Form.Field>
              <label>Member</label>
              <Link to={`/users/${ get(this.state, `member.user.id`) }`}>
                {get(this.state, `member.user.displayName`)}
              </Link>
            </Form.Field>

            <Form.Field>
              <label>Type</label>
              {get(this.state, `member.memberType`)}
            </Form.Field>

            <Form.Field>
              <label>Member since</label>
              {new Date(
                get(this.state, `member.memberSinceUtc`)
              ).toLocaleString(navigator.language)}
            </Form.Field>

            <Form.Field>
              <label>Voice</label>
              {get(this.state, `member.user.profile.voice`, null)}
            </Form.Field>

            {/* <Form.Field>
              <label>E-mail</label>
              {get(this.state, `member.user.profile.email`, null)}
            </Form.Field> */}

            <Form.Field>
              <label>Licenses</label>
              <Table celled>
                <Table.Body>
                  {get(this.state, `member.licences`, []).map(license => (
                    <Table.Row key={license.id}>
                      <Table.Cell>
                        <div
                          style={{
                            display: `flex`,
                            justifyContent: `space-between`,
                            paddingRight: `6px`,
                          }}
                        >
                          <span>{license.plan.name}</span>
                          <Icon
                            name="minus circle"
                            color="red"
                            style={{ paddingLeft: `11px` }}
                            onClick={() => {
                              this.setState(previousState => ({
                                ...previousState,
                                member: {
                                  ...previousState.member,
                                  licences: get(
                                    previousState,
                                    `member.licences`,
                                    []
                                  ).filter(
                                    prevLicense => prevLicense.id !== license.id
                                  ),
                                },
                              }))
                            }}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>

                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell
                      style={
                        // get(this.state, `member.licences`, []).length === 0
                        !get(this.state, `member.licences.length`)
                          ? { borderTopColor: `transparent` }
                          : {}
                      }
                    >
                      <div
                        style={{
                          display: `flex`,
                          alignItems: `center`,
                        }}
                      >
                        <Icon name="plus" style={{ marginRight: `11px` }} />
                        <Dropdown
                          fluid
                          selection
                          search
                          deburr
                          options={this.props.licenses
                            .filter(license => !license.subscription)
                            .filter(
                              license =>
                                !get(this.state, `member.licences`, []).find(
                                  ({ id }) => id === license.id
                                )
                              // !get(this.state, `member.licences`, [])
                              //   .map(({ id }) => id)
                              //   .includes(license.id)
                            )
                            .map(license => ({
                              key: license.id,
                              text: license.plan.name,
                              value: license.id,
                              content: (
                                <div
                                  style={{
                                    display: `flex`,
                                    justifyContent: `space-between`,
                                  }}
                                >
                                  <div>{license.plan.name}</div>
                                  {get(this.state, `member.licences`, []).find(
                                    ({ id }) => id === license.id
                                  ) && (
                                    <div
                                      style={{
                                        color: `rgba(120,120,120,1)`,
                                        fontSize: `12px`,
                                        paddingLeft: `12px`,
                                      }}
                                    >
                                      (Current)
                                    </div>
                                  )}
                                </div>
                              ),
                            }))
                            .concat(
                              this.props.next
                                ? {
                                    key: `next`,
                                    text: `Too many to display, start typing to initiate a search...`,
                                    disabled: true,
                                  }
                                : []
                            )}
                          value={undefined}
                          onChange={(event, { value }) => {
                            this.setState(previousState => ({
                              ...previousState,
                              member: {
                                ...previousState.member,
                                licences: [
                                  ...this.state.member.licences,
                                  this.props.licenses.find(l => l.id === value),
                                ],
                              },
                            }))
                          }}
                          attached="left"
                        />
                      </div>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Form.Field>
          </Form>
        </Content>
      </Panel>
    )
  }

  getMemberLicenses = (licenses = this.props.licenses) => {
    // return concatEntityIfMissing({
    //   entities: licenses.filter(
    //     license =>
    //       get(license, `subscription.user.id`) ===
    //       this.props.match.params.userId
    //   ),
    //   entity: get(this.props, `location.state.license`)
    // })
    return licenses.filter(
      license =>
        get(license, `subscription.user.id`) === this.props.match.params.userId
    )
  }

  componentDidMount = () => {
    this._isMounted = true
    this.fetchMember()
  }

  componentDidUpdate = previousProps => {
    // this.props.licenses !== previousProps.licenses &&
    //   this.setState({
    //     memberLicenses: this.getMemberLicenses(),
    //   })

    get(previousProps, `match.params.userId`) !==
      get(this.props, `match.params.userId`) && this.fetchMember()
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  fetchMember = (userId = this.props.match.params.userId) => {
    const { choirId } = this.props.match.params

    return (
      choirId &&
      userId &&
      new Promise((resolve, reject) => {
        this._isMounted &&
          this.setState({ isFetching: true }, () => {
            root.wav
              .api({
                url: `/choirs/${ choirId }/members/${ userId }`,
              })
              .then(({ data }) => {
                this._isMounted &&
                  this.setState(
                    {
                      isFetching: false,
                      initialLicences: data.licences || [],
                      member: data,
                    },
                    () => resolve(data)
                  )
              })
              .catch(error => {
                this._isMounted &&
                  this.setState({ isFetching: false, member: {} })
                reject(error)
              })
          })
      })
    )
  }
}

export default props => (
  <Subscribe to={[ LicensesContainer, LicenseContainer ]}>
    {(
      { state: licensesState, fetch, updateLicenses },
      { assignLicenseToUser, state: licenseState }
    ) => (
      <Member
        licenses={licensesState.data}
        next={licensesState.next}
        fetchLicenses={() => fetch(props.match.params.choirId)}
        assignLicenseToUser={assignLicenseToUser}
        updateLicenses={updateLicenses}
        isSaving={licenseState.isSaving}
        {...props}
      />
    )}
  </Subscribe>
)
