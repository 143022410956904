import React from 'react'
import { Form } from 'semantic-ui-react'

export default function RevenueOverview({revenue}) {
    if (!revenue) {
        return null;
    }
  return (
    <Form>
        <Form.Field>
          <label>Start date</label>
          <p>{new Date(revenue.startUtc).toLocaleDateString()}</p>
        </Form.Field>
        <Form.Field>
          <label>End date</label>
          <p>{new Date(revenue.endUtc).toLocaleDateString()}</p>
        </Form.Field>
        <Form.Field>
          <label>Revenue</label>
          <p>{revenue.revenueRaw.toFixed(2)} £$€</p>
        </Form.Field>
        <Form.Field>
          <label>Client</label>
          <p>{revenue.clientUserName ?? 'All'}</p>
        </Form.Field>
        {revenue.includeOtherUsersInWav && (<Form.Field>
          <label>Include Other users in WAV</label>
          <p>✓</p>
        </Form.Field>)}
        <Form.Field>
          <label>Total time</label>
          <p>{Math.round(revenue.totalTime)} s</p>
        </Form.Field>
        <Form.Field>
          <label>Value per second</label>
          <p>{revenue.valuePerSecondRaw} £$€</p>
        </Form.Field>
        {!!revenue.organizationCount && (
          <Form.Field>
            <label>Organization count</label>
            <p>{revenue.organizationCount}</p>
          </Form.Field>
        )}
        {!!revenue.arrangementCount && (
          <Form.Field>
            <label>Arrangement count</label>
            <p>{revenue.arrangementCount}</p>
          </Form.Field>
        )}
        {!!revenue.userCount && (
          <Form.Field>
            <label>User count</label>
            <p>{revenue.userCount}</p>
          </Form.Field>
        )}
      </Form>
  )
}
