import React, { Component } from "react"
import { createPortal } from "react-dom"
import Panel, { Menu, MenuItem, CommandBar, Command, Content } from "../Panel"
import GetArrangementDetails from "../api/containers/GetArrangementDetails"
import { Loader, Form, Icon } from "semantic-ui-react"
import { Link, Switch, Route } from "react-router-dom"
import Versions from "./Versions"
import PutArrangement from "../api/containers/PutArrangement"
import isEqual from "deep-equal"
import DeleteArrangement from "../api/containers/DeleteArrangement"
import { root } from "../services/Globals"
import Select from "../Select"
import ImageAsset from "../ImageAsset"
import { PanelsConsumer } from "../Panels"
import OrganizationContainer from "../Organizations/OrganizationContainer"
import Organization from "../Organizations/Organization"
import Category from "../Categories/Category"
import AccessControl from "./AccessControl"
import Notes from "./Notes"
import FocusElement from "../FocusElement"
import CreateSong from "./CreateSong"
import Button from "../TextButton"
import Catalog from "./Catalog"

class Arrangement extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      draft: {
        id: props.arrangement.id,
        name: props.arrangement.name,
        title: props.arrangement.title,
        subtitle: props.arrangement.subtitle,
        publicDomain: props.arrangement.publicDomain,
        externalId: props.arrangement.externalId,
        publisherUrl: props.arrangement.publisherUrl,
        description: props.arrangement.description,
        ice: props.arrangement.ice,
        iswc: props.arrangement.iswc,
        contentProvider1Id:
          props.arrangement.contentProvider1 &&
          props.arrangement.contentProvider1.id,
        contentProvider2Id:
          props.arrangement.contentProvider2 &&
          props.arrangement.contentProvider2.id,
        arrangerId: props.arrangement.arranger && props.arrangement.arranger.id,
        publisherTempId:
          props.arrangement.publisherTemp && props.arrangement.publisherTemp.id,
        songId: props.arrangement.song && props.arrangement.song.id,
        composition: props.arrangement.composition,
        genresId: props.arrangement.genres.map(({ id }) => id),
        image: props.arrangement.image,
        notes: props.arrangement.notes,
      }
    }
    this.state = this.initialState
  }

  render = () => {
    const { handleChange, state, initialState } = this

    const unchanged = isEqual(state.draft, initialState.draft)

    return (
      <FocusElement>
        {({ elementRef, focusElement }) => (
          <React.Fragment>
            <CommandBar>
              <DeleteArrangement
                arrangementId={state.draft.id}
                onSuccess={() => this.props.history.push(`/arrangements`)}
              >
                {arrangement => (
                  <Command
                    disabled={arrangement.mode === `loading`}
                    onClick={() => {
                      const deleteNotification = new root.wav.Notification({
                        text: `<h4 class="ui header"><i aria-hidden="true" class="trash icon"></i><div class="content">You are about to delete this arrangement permanently, do you want to continue?</div></h4>`,
                        buttons: [
                          root.wav.Notification.button(
                            `Yes`,
                            `ui compact button`,
                            () => {
                              arrangement.delete().then(() => {
                                deleteNotification.close()
                              })
                            }
                          ),
                          root.wav.Notification.button(
                            `No`,
                            `ui compact button`,
                            () => {
                              deleteNotification.close()
                            }
                          ),
                        ],
                        theme: `semanticui`,
                        modal: true,
                      }).show()
                    }}
                  >
                    <span>
                      <Icon name="trash" />
                      {arrangement.mode === `error`
                        ? `Retry`
                        : arrangement.mode === `idle`
                        ? `Delete`
                        : `Deleting`}
                    </span>
                  </Command>
                )}
              </DeleteArrangement>
              <PutArrangement data={state.draft}>
                {arrangement => (
                  <Command
                    disabled={unchanged || arrangement.mode === `loading`}
                    onClick={() => arrangement.update()}
                    ref={elementRef}
                  >
                    <span>
                      {/* <Icon name="cloud upload" /> */}
                      {arrangement.mode === `error`
                        ? `Retry`
                        : arrangement.mode === `idle`
                        ? `Save`
                        : `Saving`}
                    </span>
                  </Command>
                )}
              </PutArrangement>
            </CommandBar>
            <Content>
              <Form autoComplete="off">
                <Form.Field
                  control="input"
                  type="text"
                  label="Title"
                  name="title"
                  value={state.draft.title || ``}
                  onChange={handleChange}
                />
                <Form.Field
                  control="input"
                  type="text"
                  label="Subtitle"
                  name="subtitle"
                  value={state.draft.subtitle || ``}
                  onChange={handleChange}
                />
                <Form.TextArea
                  label="Description"
                  name="description"
                  value={state.draft.description || ``}
                  onChange={handleChange}
                />
                <div className="field">
                <label
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      userSelect: `none`,
                    }}>
                      Interested Parties
                      <small>Managed in upload-backoffice</small>
                    </label>
                  <div>
                    {this.props.arrangement.interestedParties.map(x => `${x.type}: ${x.name}`).join(', ') || '-'}
                  </div>
                </div>
                <div className="field">
                  <label
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      userSelect: `none`,
                    }}>
                      Metadata: Composition
                      <small>Managed in upload-backoffice</small>
                    </label>
                  <div>
                    {this.props.arrangement.metadata.filter(x => x.type === 'Composition').map(x => x.labelEn || x.labelSv || x.name).join(', ') || '-'}
                  </div>
                </div>
                <div className="field">
                  <label
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      userSelect: `none`,
                    }}>
                      Metadata: Content Type
                      <small>Managed in upload-backoffice</small>
                    </label>
                  <div>
                    {this.props.arrangement.metadata.filter(x => x.type === 'ContentType').map(x => x.labelEn || x.labelSv || x.name).join(', ') || '-'}
                  </div>
                </div>
                <div className="field">
                  <label
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      userSelect: `none`,
                    }}>
                      Metadata: Other
                      <small>Managed in upload-backoffice</small>
                    </label>
                  <div>
                    {this.props.arrangement.metadata.filter(x => x.type !== 'Composition' && x.type !== 'ContentType').map(x => x.labelEn || x.labelSv || x.name).join(', ') || '-'}
                  </div>
                </div>
                <Form.Field>
                  <label
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      userSelect: `none`,
                    }}
                  >
                    Content Provider 1
                    {state.draft.contentProvider1Id && (
                      <Button
                        onClick={event => {
                          event.preventDefault()
                          this.handleChange(undefined, {
                            name: `contentProvider1Id`,
                            value: undefined,
                          })
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </label>
                  <Select
                    url="/organizations/ou"
                    value={state.draft.contentProvider1Id}
                    onChange={item =>
                      handleChange(undefined, {
                        name: `contentProvider1Id`,
                        value: item.id,
                      })
                    }
                    fluid
                    allowAdditions
                    onAddItem={({ value }) => {
                      this.props.history.push(
                        this.props.match.url + `/arranger`,
                        {
                          name: value,
                        }
                      )
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      userSelect: `none`,
                    }}
                  >
                    Content Provider 2
                    {state.draft.contentProvider2Id && (
                      <Button
                        onClick={event => {
                          event.preventDefault()
                          this.handleChange(undefined, {
                            name: `contentProvider2Id`,
                            value: undefined,
                          })
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </label>
                  <Select
                    url="/organizations/ou"
                    value={state.draft.contentProvider2Id}
                    onChange={item =>
                      handleChange(undefined, {
                        name: `contentProvider2Id`,
                        value: item.id,
                      })
                    }
                    fluid
                    allowAdditions
                    onAddItem={({ value }) => {
                      this.props.history.push(
                        this.props.match.url + `/arranger`,
                        {
                          name: value,
                        }
                      )
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      userSelect: `none`,
                    }}
                  >
                    Distributing Publisher
                    {state.draft.publisherTempId && (
                      <Button
                        onClick={event => {
                          event.preventDefault()
                          this.handleChange(undefined, {
                            name: `publisherTempId`,
                            value: undefined,
                          })
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </label>
                  <Select
                    url="/organizations/ou"
                    value={state.draft.publisherTempId}
                    onChange={item =>
                      handleChange(undefined, {
                        name: `publisherTempId`,
                        value: item.id,
                      })
                    }
                    fluid
                    allowAdditions
                    onAddItem={({ value }) => {
                      this.props.history.push(
                        this.props.match.url + `/publisher`,
                        {
                          name: value,
                        }
                      )
                    }}
                  />
                </Form.Field>
                <Form.Field
                  control="input"
                  type="checkbox"
                  label="Public Domain"
                  name="publicDomain"
                  checked={state.draft.publicDomain}
                  value={state.draft.publicDomain}
                  onChange={this.handleBoolChange}
                />
                <Form.Field
                  control="input"
                  type="text"
                  label="External ID"
                  name="externalId"
                  value={state.draft.externalId || ``}
                  onChange={handleChange}
                />
                <Form.Field
                  control="input"
                  type="text"
                  label="ICE"
                  name="ice"
                  value={state.draft.ice || ``}
                  onChange={handleChange}
                />
                <Form.Field
                  control="input"
                  type="text"
                  label="ISWC"
                  name="iswc"
                  value={state.draft.iswc || ``}
                  onChange={handleChange}
                  onKeyDown={focusElement}
                />
                <Form.Field
                  control="input"
                  type="text"
                  label="Publisher URL"
                  name="publisherUrl"
                  value={state.draft.publisherUrl || ``}
                  onChange={handleChange}
                />
                <Form.Field>
                  <label
                    style={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      userSelect: `none`,
                    }}
                  >
                    Song
                    {state.draft.songId && (
                      <Button
                        onClick={event => {
                          event.preventDefault()
                          this.handleChange(undefined, {
                            name: `songId`,
                            value: undefined,
                          })
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </label>
                  <Select
                    url="/songs"
                    value={state.draft.songId}
                    onChange={item =>
                      handleChange(undefined, {
                        name: `songId`,
                        value: item.id,
                      })
                    }
                    fluid
                    allowAdditions
                    onAddItem={({ value }) => {
                      this.props.history.push(this.props.match.url + `/song`, {
                        name: value,
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Categories</label>
                  <Select
                    url="/genres"
                    value={state.draft.genresId.map(id => ({ id }))}
                    onChange={items =>
                      handleChange(undefined, {
                        name: `genresId`,
                        value: items.map(({ id }) => id),
                      })
                    }
                    fluid
                    multiple
                    allowAdditions
                    onAddItem={({ value }) => {
                      this.props.history.push(
                        this.props.match.url + `/category`,
                        {
                          name: value,
                        }
                      )
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Image</label>
                  <ImageAsset
                    name="image"
                    onChange={handleChange}
                    {...(state.draft.image && state.draft.image.url
                      ? { initialValue: state.draft.image.url }
                      : {})}
                  />
                </Form.Field>
              </Form>
            </Content>

            <PanelsConsumer>
              {panelsElement => (
                <React.Fragment>
                  <Route
                    path={this.props.match.url + `/arranger`}
                    render={props =>
                      createPortal(
                        <OrganizationContainer
                          onCreate={this.handleArrangerAddition}
                        >
                          {organization => (
                            <Organization {...props} {...organization} />
                          )}
                        </OrganizationContainer>,
                        panelsElement
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + `/publisher`}
                    render={props =>
                      createPortal(
                        <OrganizationContainer
                          onCreate={this.handlePublisherAddition}
                        >
                          {organization => (
                            <Organization {...props} {...organization} />
                          )}
                        </OrganizationContainer>,
                        panelsElement
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + `/song`}
                    render={props =>
                      createPortal(
                        // <Song
                        //   {...props}
                        //   returnTo={this.props.match.url}
                        //   onSave={this.handleSongAddition}
                        // />,
                        <CreateSong
                          returnTo={this.props.match.url}
                          onCreate={this.handleSongAddition}
                          {...props}
                        />,
                        panelsElement
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + `/category`}
                    render={props =>
                      createPortal(
                        // <Genre
                        //   {...props}
                        //   returnTo={this.props.match.url}
                        //   onSave={this.handleCategoryAddition}
                        // />,
                        <Category
                          returnTo={this.props.match.url}
                          onCreate={this.handleCategoryAddition}
                          {...props}
                        />,
                        panelsElement
                      )
                    }
                  />
                </React.Fragment>
              )}
            </PanelsConsumer>
          </React.Fragment>
        )}
      </FocusElement>
    )
  }

  handleBoolChange = (event, data) => {
    const { name, checked } =
      data ||
      (event && event.target) ||
      event.target.type ||
      event.target.checked
    this.setState(previousState => ({
      ...previousState,
      draft: {
        ...previousState.draft,
        [name]: checked,
      },
    }))
  }

  handleChange = (event, data) => {
    const { name, value } = data || (event && event.target)
    this.setState(previousState => ({
      ...previousState,
      draft: {
        ...previousState.draft,
        [name]: value,
      },
    }))
  }

  handleArrangerAddition = response => {
    this.props.history.replace(
      this.props.match.url + this.props.location.search
    )
    this.handleChange(undefined, {
      name: `arrangerId`,
      value: response.data.id,
    })
  }

  handlePublisherAddition = response => {
    this.props.history.replace(
      this.props.match.url + this.props.location.search
    )
    this.handleChange(undefined, {
      name: `publisherTempId`,
      value: response.data.id,
    })
  }

  handleSongAddition = ({ data: { id } }) => {
    this.props.history.replace(
      this.props.match.url + this.props.location.search
    )
    this.handleChange(undefined, { name: `songId`, value: id })
  }

  handleCategoryAddition = ({ data: { id } }) => {
    this.props.history.replace(
      this.props.match.url + this.props.location.search
    )
    this.handleChange(undefined, {
      name: `genresId`,
      value: [...(this.state.genresId || []), id],
    })
  }
}

export default ({ match, history, location }) => (
  <GetArrangementDetails arrangementId={match.params.arrangementId}>
    {({ mode, payload }) => (
      <Panel
        title={
          {
            loading: `Loading arrangement...`,
            success: `Arrangement`,
            error: `Error loading arrangement`,
          }[mode || `loading`]
        }
        onClose={() => history.push(`/arrangements`)}
        menu={
          <Menu>
            <MenuItem
              active={
                location.pathname.indexOf(match.url) >= 0 &&
                location.pathname.indexOf(`${ match.url }/versions`) === -1 &&
                location.pathname.indexOf(`${ match.url }/acl`) === -1 &&
                location.pathname.indexOf(`${ match.url }/notes`) === -1 && 
                location.pathname.indexOf(`${ match.url }/catalog`) === -1
              }
            >
              <Link to={`${ match.url }`}>Details</Link>
            </MenuItem>
            <MenuItem
              active={location.pathname.indexOf(`${ match.url }/versions`) >= 0}
            >
              <Link to={`${ match.url }/versions`}>Versions</Link>
            </MenuItem>
            <MenuItem
              active={location.pathname.indexOf(`${ match.url }/acl`) >= 0}
            >
              <Link to={`${ match.url }/acl`}>Permissions</Link>
            </MenuItem>
            <MenuItem
              active={location.pathname.indexOf(`${ match.url }/notes`) >= 0}
            >
              <Link to={`${ match.url }/notes`}>Notes</Link>
            </MenuItem>
            <MenuItem
              active={location.pathname.indexOf(`${ match.url }/catalog`) >= 0}
            >
              <Link to={`${ match.url }/catalog`}>Catalog</Link>
            </MenuItem>
          </Menu>
        }
      >
        {mode === `loading` && (
          <Content>
            <Loader active content="Loading arrangement" inline="centered" />
          </Content>
        )}
        {mode === `success` && (
          <Switch>
            <Route
              path={`${ match.path }/catalog`}
              render={props => (
                <Catalog arrangementId={match.params.arrangementId} />
              )}
            />
            <Route
              path={`${ match.path }/notes`}
              render={props => (
                <Notes
                  key={payload.id + payload.updatedUtc}
                  arrangement={payload}
                  loading={mode === `loading`}
                  {...props}
                />
              )}
            />
            <Route
              path={`${ match.path }/acl`}
              render={props => <AccessControl {...props} />}
            />
            <Route
              path={`${ match.url }/versions`}
              render={props => (
                <Versions
                  arrangementId={match.params.arrangementId}
                  {...props}
                />
              )}
            />
            <Route
              path={`${ match.url }`}
              render={props => (
                <Arrangement
                  {...props}
                  arrangement={payload}
                  history={history}
                />
              )}
            />
          </Switch>
        )}
      </Panel>
    )}
  </GetArrangementDetails>
)
