import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Subscribe } from "unstated"
import { SidebarContainer } from "../Sidebar/SidebarContainer"
import { ActivityContainer } from "../ActivityContainer"
import { Activity as ActivityIcon, LogOut } from "react-feather"
import { withLock } from "../services/Lock"
import Logo from "../Logo"
import STATUS from "../utils/activities/status"

const Title = styled(Link).attrs({
  to: `/`,
})`
  color: white;
  font-family: inherit;
  font-size: 16px;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-left: 0.8rem;

  :hover {
    color: white;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`

const ActivityIconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  z-index: 0;
  cursor: ${ props => (props.onClick ? `pointer` : `auto`) };
  box-sizing: border-box;

  ::before {
    content: '${ props =>
      props.activityCount && props.activityCount < 100
        ? props.activityCount
        : `` }';
    position: absolute;
    top: -.9em;
    right: -.9em;
    width: 2em;
    height: 2em;
    color: white;
    background: ${ props =>
      props.activityCount && props.activityCount < 100 ? `#db2828` : `white` };
    border: ${ props =>
      props.activityCount && props.activityCount < 100
        ? `none`
        : `0.7em solid #db2828` };
    border-radius: 50%;
    font-size: 0.4em;
    line-height: 0.4em;
    display: ${ props => (props.activityCount ? `flex` : `none`) };
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 5;
  }

  ::after {
    content: '';
    position: absolute;
    left: calc(50% - 8px);
    bottom: -6px;
    width: 16px;
    height: 1px;
    border-radius: 50%;
    background: rgba(61, 181, 239, 1);
    box-shadow: 0 0 12px rgb(61, 181, 239);
    opacity: ${ props => (props.active ? 1 : 0) };
    transition: opacity 120ms;
  }
`

const Activity = props => (
  <ActivityIconContainer {...props}>
    <ActivityIcon color="white" size={22} />
  </ActivityIconContainer>
)

const ActivityBar = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 24px;
  }

  & > *:last-child {
    margin-right: 1.618rem;
  }
`

const Header = styled.header`
  min-height: 65px;
  width: 100%;
  background: rgb(33, 33, 34);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(52, 53, 52);
`

export default withLock(props => (
  <Header>
    <TitleContainer>
      <Logo size={1.618} />
      <Title>Catalog</Title>
    </TitleContainer>
    <ActivityBar>
      <Subscribe to={[ SidebarContainer, ActivityContainer ]}>
        {(sidebar, { state: { activities } }) => (
          <Activity
            active={sidebar.state.toggled}
            onClick={() => sidebar.togglePanel(`activity`)}
            activityCount={
              activities.filter(
                activity => activity.status !== STATUS.Completed
              ).length
            }
          />
        )}
      </Subscribe>
      <LogOut
        color="white"
        size={22}
        style={{ cursor: `pointer` }}
        onClick={() => props.lock.logout()}
      />
    </ActivityBar>
  </Header>
))
