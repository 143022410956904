import { Component } from "react"
import { root } from "../../services/Globals"

export default class GetArrangementDetails extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `loading`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
      reload,
    } = this

    return this.props.children({
      mode,
      payload,
      reload,
    })
  }

  componentDidMount = () => {
    window.addEventListener(`update:${this.props.arrangementId}`, this.getData)
    return this.getData()
  }

  componentWillUnmount = () => {
    window.removeEventListener(
      `update:${this.props.arrangementId}`,
      this.getData
    )
  }

  componentWillReceiveProps = nextProps => {
    this.props.arrangementId !== nextProps.arrangementId &&
      setTimeout(this.getData, 0)
  }

  getData = () => {
    this.setState(previousState => ({
      ...previousState,
      mode: `loading`,
    }))

    const arrangementId = this.props.arrangementId

    return root.wav
      .api(`/arrangements/${ arrangementId }/details`)
      .then(({ data }) => {
        this.setState(previousState => ({
          ...previousState,
          mode: `success`,
          payload: data,
        }))
      })
      .catch(error => {
        this.setState(previousState => ({
          ...previousState,
          mode: `error`,
          payload: error,
        }))
      })
  }

  reload = event => {
    event && event.preventDefault && event.preventDefault()
    this.setState(() => this.initialState)
    return this.getData()
  }
}
