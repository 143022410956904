import React, { Component } from "react"
import { Dropdown } from "semantic-ui-react"
import { root } from "../services/Globals"

export default class SelectTag extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [ { text: `one`, value: `one` }, { text: `two`, value: `two` } ],
      value: [],
      loading: false,
    }
  }

  render = () => {
    const {
      state,
      props: { onChange, ...props },
      handleChange,
    } = this

    return (
      <Dropdown
        selection
        multiple
        onChange={handleChange}
        {...state}
        {...props}
      />
    )
  }

  componentWillMount = () => {
    this.fetch()
  }

  handleChange = (event, data) => {
    const { value } = data
    this.setState(
      previousState => ({
        ...previousState,
        value,
        options: previousState.options
          .map(({ value }) => value)
          .concat(value)
          .reduce((acc, v) => (acc.indexOf(v) === -1 ? [ ...acc, v ] : acc), [])
          .map(value => ({ text: value, value })),
      }),
      () => {
        this.props.onChange && this.props.onChange({}, data)
      }
    )
  }

  fetch = next => {
    this.setState(
      previousState => ({
        ...previousState,
        loading: true,
      }),
      () => {
        root.wav
          .api(next || `/content/tags`)
          .then(({ data: { items = [], next } }) => {
            this.setState(
              previousState => ({
                ...previousState,
                options: items.map(value => ({ text: value, value })),
              }),
              () => {
                next
                  ? this.fetch(next)
                  : this.setState(previousState => ({
                      loading: false,
                    }))
              }
            )
          })
      }
    )
  }
}
