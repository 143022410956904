import { root } from "./services/Globals"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import isolateSearchQuery from "./helpers/isolate-search-query"
import equal from "deep-equal"
import { Loader, Button } from "semantic-ui-react"

const defaultItemRenderer = item => <div key={item.id}>{item.id}</div>

class Items extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      error: null,
      next: null,
      loading: true,
    }
  }
  render = () => {
    return (
      <div style={{ display: `flex`, flexDirection: `column` }}>
        {this.state.loading && (
          <Loader
            active
            content={
              `Loading ` +
              this.props.match.url.slice(
                this.props.match.url.lastIndexOf(`/`) + 1
              )
            }
            style={{ padding: `4rem` }}
          />
        )}
        {this.state.items.map(item =>
          (this.props.renderItem || defaultItemRenderer)(item, this.fetch)
        )}
        {this.state.next && (
          <Button
            basic
            compact
            style={{ margin: `auto` }}
            content="Load more..."
            onClick={this.fetch}
            loading={this.state.loading}
          />
        )}
      </div>
    )
  }

  componentDidMount = () => {
    this.fetch()

    const type = this.props.match.url.slice(
      this.props.match.url.lastIndexOf(`/`) + 1
    )

    root.addEventListener(`post:${ type }`, this.fetch)
    root.addEventListener(`put:${ type }`, this.fetch)
    root.addEventListener(`delete:${ type }`, this.reload)
  }

  componentWillUnmount = () => {
    const type = this.props.match.url.slice(
      this.props.match.url.lastIndexOf(`/`) + 1
    )

    root.removeEventListener(`post:${ type }`, this.fetch)
    root.removeEventListener(`put:${ type }`, this.fetch)
    root.removeEventListener(`delete:${ type }`, this.reload)
  }

  componentWillReceiveProps = nextProps => {
    !equal(
      queryString.parse(isolateSearchQuery(this.props.location.search)),
      queryString.parse(isolateSearchQuery(nextProps.location.search))
    ) && this.reload()

    this.props.url !== nextProps.url && this.reload()
  }

  fetch = () => {
    this.setState(
      previousState => ({
        ...previousState,
        loading: true,
      }),
      () => {
        Promise.resolve()
          .then(() =>
            root.wav.api(
              this.state.next
                ? {
                    url: this.state.next,
                  }
                : {
                    url: this.props.url || this.props.match.url,
                    params: {
                      ...(this.props.limit
                        ? { limit: this.props.limit }
                        : { limit: 50 }),
                      ...(this.props.offset
                        ? { offset: this.props.offset }
                        : { offset: 0 }),
                      ...queryString.parse(
                        isolateSearchQuery(this.props.location.search)
                      ),
                    },
                  }
            )
          )
          .then(({ data: { items, next } }) => {
            this.setState(previousState => ({
              ...previousState,
              items: Object.values(
                [ ...previousState.items, ...items ].reduce(
                  (itemsById, item) =>
                    Object.assign(itemsById, { [item.id]: item }),
                  {}
                )
              ),
              next,
              loading: false,
            }))
          })
      }
    )
  }

  reload = () => {
    this.setState(
      previousState => ({
        ...previousState,
        items: [],
        next: null,
      }),
      () => {
        this.fetch()
      }
    )
  }
}

export default withRouter(Items)
