import React, { Component } from "react"
import isEqual from "deep-equal"
import { Form } from "semantic-ui-react"
import { CommandBar, Command, Content } from "../Panel"
import PutVersion from "../api/containers/PutVersion"

class Details extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  render = () => {
    const {
      state,
      initialState,
      handleChange,
      toggleAdvancedMode,
      props: { arrangementId },
    } = this

    const unchanged = isEqual({ ...state, advancedMode: false }, initialState)
    return (
      <Form>
        <CommandBar>
          <Command.Advanced
            toggled={state.advancedMode}
            onClick={toggleAdvancedMode}
          />
          <PutVersion arrangementId={arrangementId} data={state}>
            {version => (
              <Command
                disabled={unchanged || version.mode === `loading`}
                onClick={() => version.update()}
              >
                <span>
                  {/* <Icon name="cloud upload" /> */}
                  {version.mode === `error`
                    ? `Retry`
                    : version.mode === `idle`
                    ? `Save`
                    : `Saving`}
                </span>
              </Command>
            )}
          </PutVersion>
        </CommandBar>
        <Content disabled={this.props.version.status !== `Draft`}>
          <Form.Field
            control="input"
            type="number"
            label="Duration"
            name="duration"
            value={state.duration || 0}
            onChange={handleChange}
          />
          <Form.Field
            control="input"
            type="number"
            label="BPM"
            name="bpm"
            value={state.bpm || 0}
            onChange={handleChange}
          />
          {state.advancedMode && (
            <Form.Field
              control="input"
              type="text"
              label="MusicXML source URL"
              name="musicXmlSourceUrl"
              value={state.musicXmlSourceUrl || ``}
              onChange={handleChange}
            />
          )}
        </Content>
      </Form>
    )
  }

  componentDidMount = () => {
    this.props.reloadVersion()
  }

  componentDidUpdate = previousProps => {
    !isEqual(previousProps.version, this.props.version) &&
      this.setState(this.getInitialState())
  }

  getInitialState = () => {
    return {
      id: this.props.version.id,
      duration: this.props.version.duration,
      bpm: this.props.version.bpm,
      musicXmlSourceUrl: this.props.version.musicXmlSourceUrl,
      notes: this.props.version.notes,
      advancedMode: false,
    }
  }

  handleChange = (event, data) => {
    const { name, value } = data || (event && event.target)
    this.setState(previousState => ({
      ...previousState,
      [name]: value,
    }))
  }

  toggleAdvancedMode = () => {
    this.setState(previousState => ({
      ...previousState,
      advancedMode: !previousState.advancedMode,
    }))
  }
}

export default Details
