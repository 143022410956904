import React from "react"
import pickBy from "lodash.pickby"
import identity from "lodash.identity"
import merge from "lodash.merge"
import get from "lodash.get"
import set from "lodash.set"
import isEqual from "deep-equal"

const truthy = source => pickBy(source, identity)

export default class Draft extends React.Component {
  constructor(props) {
    super(props)

    this.state = truthy(get(props, `original`, {}))
  }

  render = () =>
    this.props.children({
      state: this.state,
      handleChange: this.handleChange,
      dirty: !isEqual(this.props.original, this.state),
    })

  handleChange = ({ target: { name, value } }) => {
    this.setState(previousState => set(merge({}, previousState), name, value))
  }
}
