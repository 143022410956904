import React from "react"
import { Content, CommandBar, Command } from "../Panel"
import { Form } from "semantic-ui-react"
import Select from "../Select"
import ImageAsset from "../ImageAsset"
import FocusElement from "../FocusElement"
import { push } from "../history"
import get from "lodash.get"

export default props => (
  <FocusElement>
    {({ elementRef, focusElement }) => (
      <React.Fragment>
        <CommandBar>
          {get(props, `draft.id`) && <Command.Delete onClick={props.delete} />}
          <Command
            save
            ref={elementRef}
            disabled={
              (get(props, `draft.id`) && !props.dirty) ||
              !get(props, `draft.name`)
            }
            onClick={props.save}
          />
        </CommandBar>
        <Content loading={props.loading} loadingText={props.loadingMessage}>
          <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
            <Form.Input
              required
              name="name"
              label="Name"
              value={get(props, `draft.name`, ``)}
              onChange={props.onChange}
            />

            <Form.TextArea
              name="description"
              label="Description"
              value={get(props, `draft.description`, ``)}
              onChange={props.onChange}
            />

            <Form.Field>
              <label>Associated artists</label>
              <Select
                fluid
                allowAdditions
                multiple
                name="knownPerformingArtists"
                url="/artists"
                value={get(props, `draft.knownPerformingArtists`, [])}
                onChange={value =>
                  props.onChange({
                    target: {
                      name: `knownPerformingArtists`,
                      value,
                    },
                  })
                }
                onAddItem={({ value: name }) =>
                  push(props.match.url + `/artist`, {
                    name,
                  })
                }
              />
            </Form.Field>

            <Form.Field>
              <label>Songwriters</label>
              <Select
                fluid
                allowAdditions
                multiple
                name="songwriters"
                url="/songwriters"
                value={get(props, `draft.songwriters`, [])}
                onChange={value =>
                  props.onChange({
                    target: {
                      name: `songwriters`,
                      value,
                    },
                  })
                }
                onAddItem={({ value: name }) =>
                  push(props.match.url + `/songwriter`, { name })
                }
              />
            </Form.Field>

            <Form.Input
              name="ice"
              label="ICE"
              value={get(props, `draft.ice`, ``)}
              onChange={props.onChange}
            />

            <Form.Input
              name="iswc"
              label="ISWC"
              value={get(props, `draft.iswc`, ``)}
              onChange={props.onChange}
              onKeyDown={focusElement}
            />

            <Form.Field>
              <label>Image</label>
              <ImageAsset
                name="image"
                initialValue={get(props, `draft.image.url`)}
                onChange={props.onChange}
              />
            </Form.Field>

            {get(props, `draft.id`) && (
              <React.Fragment>
                <Form.Field>
                  <label>Created</label>
                  <p>
                    {props.draft.createdUtc &&
                      new Date(props.draft.createdUtc).toLocaleString(
                        navigator.language
                      )}
                  </p>
                </Form.Field>
                <Form.Field>
                  <label>Updated</label>
                  <p>
                    {props.draft.updatedUtc &&
                      new Date(props.draft.updatedUtc).toLocaleString(
                        navigator.language
                      )}
                  </p>
                </Form.Field>
              </React.Fragment>
            )}
          </Form>
        </Content>
      </React.Fragment>
    )}
  </FocusElement>
)
