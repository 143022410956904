import React from "react";
import { Button } from "semantic-ui-react";

export default function RevenueTableWrapper({ children, onDownload, isLoadingDownload }) {
  return (
    <div
      style={{
        padding: "1rem",
        gap: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {onDownload && <Button loading={isLoadingDownload} onClick={onDownload} style={{ alignSelf: "flex-end" }}>
        Download
      </Button>}
      <div style={{ overflow: "scroll", minWidth: '20rem' }}>{children}</div>
    </div>
  );
}
