import React from "react"
import ReactDOM from "react-dom"
import history from "./history"
import { Router } from "react-router-dom"
import { QueryParamsProvider } from "./QueryParamsContainer"
import Configuration from "./services/Configuration"
import Application from "./Application"
import { Loader } from "semantic-ui-react"
import CatastrophicError from "./CatastrophicError"
import Lock from "./services/Lock"
import Globals from "./services/Globals"
import notification from "./services/notification"
import api from "./services/api"
import registerServiceWorker from "./registerServiceWorker"
import { Provider } from "unstated"
import { ServiceWorkerContainer } from "./ServiceWorkerContainer"
import Config from "./Config"
import "intersection-observer"
import logo from "./images/wav-logo.png"
import "./index.css"
import "semantic-ui-css/semantic.min.css"

const serviceWorkerUpdateTrigger = ((flipped = false, listeners = []) => ({
  listen: fn => {
    listeners.push(fn)
    flipped && fn(flipped)
    return () => (listeners = listeners.filter(listener => listener !== fn))
  },
  trigger: () => {
    flipped = true
    listeners.forEach(listener => listener(flipped))
  },
}))()

const serviceWorkerUpdate = new ServiceWorkerContainer(
  serviceWorkerUpdateTrigger
)

ReactDOM.render(
  <Configuration url="/appsettings.json">
    {({ configuration, error }) => {
      if (error) {
        return <CatastrophicError error={error} />
      } else if (configuration) {
        return (
          <Lock
            clientID={configuration.auth0.clientID}
            domain={configuration.auth0.domain}
            options={{
              closable: true,
              auth: {
                redirect: true,
                autoParseHash: true,
                audience: configuration.auth0.audience,
                responseType: configuration.auth0.responseType,
                redirectUrl: configuration.auth0.redirectUri,
                params: {
                  scope: configuration.auth0.scope,
                },
              },
              theme: {
                logo,
                primaryColor: `#2e318c`,
              },
              languageDictionary: {
                title: `WAV - Catalog`,
              },
            }}
          >
            {({ isAuthenticated, show, authResult }) => {
              if (isAuthenticated) {
                return (
                  <Globals
                    namespace="wav"
                    attach={[ api({ configuration, authResult }), notification ]}
                  >
                    {() => (
                      <Provider inject={[ serviceWorkerUpdate ]}>
                        <Config>
                          <Router history={history}>
                            <QueryParamsProvider>
                              <Application />
                            </QueryParamsProvider>
                          </Router>
                        </Config>
                      </Provider>
                    )}
                  </Globals>
                )
              } else {
                show()
                return null
              }
            }}
          </Lock>
        )
      } else {
        return <Loader size="massive" active />
      }
    }}
  </Configuration>,
  document.getElementById(`root`)
)

registerServiceWorker(serviceWorkerUpdateTrigger)

console.log(`We Are Voice - Catalog`)
