import React from "react"
import styled from "styled-components"
import { push } from "../history"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { Form, Dropdown, Table, Input } from "semantic-ui-react"
import Select from "../Select"
import get from "lodash.get"
import { CONTRACT_TYPES } from "./constants"

const Organization = styled.p`
  font-size: 1em;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em 0.67857143em 0em;
  display: flex;
  align-items: center;

  button {
    visibility: hidden;
    border: 1px solid #db2828;
    border-radius: 3px;
    color: #db2828;
    text-transform: uppercase;
    margin-left: 5px;
    font-size: 10px;
    line-height: 10px;
    padding: 2px 7px;
    letter-spacing: 0.02em;
  }

  :hover button {
    visibility: visible;
  }
`

export default props => (
  <Panel
    title={props.loading ? `Loading contract...` : `Contract`}
    onClose={() =>
      push(props.match.url.slice(0, props.match.url.lastIndexOf(`/`)))
    }
  >
    <CommandBar>
      <Command
        save
        onClick={props.save}
        disabled={(get(props, `draft.id`) && !props.dirty) || props.loading}
      />
    </CommandBar>
    <Content loading={props.loading} loadingText={props.loadingText}>
      <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
        {!get(props, `draft.id`) && (
          <Form.Field>
            <label>Type</label>
            <Dropdown
              selection
              deburr
              search
              name="contractType"
              options={Object.entries(CONTRACT_TYPES).map(([ value, text ]) => ({
                value,
                text,
              }))}
              value={props.draft.contractType}
              onChange={(event, { value }) =>
                props.onChange({
                  target: {
                    name: `contractType`,
                    value,
                  },
                })
              }
            />
          </Form.Field>
        )}

        <Form.Group style={{ display: `flex` }}>
          <Form.Field style={{ flexGrow: 1 }}>
            <label>Region</label>
            <Select
              fluid
              name="region"
              url="/regions"
              value={get(props, `draft.region.id`)}
              onChange={value =>
                props.onChange({
                  target: {
                    name: `region`,
                    value,
                  },
                })
              }
            />
          </Form.Field>
          {` `}
          <Form.Field>
            <label>Kickback (total)</label>
            <Input
              name="kickback"
              label="%"
              labelPosition="right"
              value={get(props, `draft.kickback`, ``)}
              onChange={props.onChange}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <label>Holders</label>

          <Table celled style={{ marginTop: 0 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    fontSize: `.92857143em`,
                    fontWeight: 700,
                  }}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    fontSize: `.92857143em`,
                    fontWeight: 700,
                  }}
                >
                  Kickback
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!get(props, `draft.contractHolders`, []).length && (
                <Table.Row>
                  <Table.Cell colSpan={2} textAlign="center" disabled>
                    None
                  </Table.Cell>
                </Table.Row>
              )}
              {get(props, `draft.contractHolders`, []).map(contract => (
                <Table.Row key={contract.holder.id}>
                  <Table.Cell>
                    <Organization>
                      {contract.holder.name}
                      <button
                        onClick={event => {
                          event.preventDefault()
                          event.stopPropagation()

                          props.onChange({
                            target: {
                              name: `contractHolders`,
                              value: get(
                                props,
                                `draft.contractHolders`,
                                []
                              ).filter(c => c.holder.id !== contract.holder.id),
                            },
                          })
                        }}
                      >
                        remove
                      </button>
                    </Organization>
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      name="kickback"
                      label="%"
                      labelPosition="right"
                      fluid
                      value={contract.kickback || 0}
                      onChange={event => {
                        props.onChange({
                          target: {
                            name: `contractHolders`,
                            value: get(props, `draft.contractHolders`).map(c =>
                              c.holder.id === contract.holder.id
                                ? {
                                    ...c,
                                    kickback: event.target.value,
                                  }
                                : c
                            ),
                          },
                        })
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  <Select
                    fluid
                    allowAdditions
                    key={`organizations-${
                      get(props, `draft.contractHolders`, []).length
                    }`}
                    itemFilter={item =>
                      !get(props, `draft.contractHolders`, [])
                        .map(({ holder }) => holder.id)
                        .includes(item.id)
                    }
                    name="contractHolders"
                    url="/organizations/ou"
                    onChange={value =>
                      props.onChange({
                        target: {
                          name: `contractHolders`,
                          value: get(props, `draft.contractHolders`, []).concat(
                            {
                              kickback: 0,
                              holder: value,
                            }
                          ),
                        },
                      })
                    }
                    onAddItem={({ value: name }) =>
                      push(props.match.url + `/organization`, {
                        name,
                      })
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Form.Field>
      </Form>
    </Content>
  </Panel>
)
