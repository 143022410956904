import React, { Component } from "react"
import {
  Icon,
  Input,
  Loader as SUILoader,
  Dropdown,
  Dimmer,
} from "semantic-ui-react"
import "./Panel.css"
import { Subject } from "rxjs"
import { map, distinctUntilChanged } from "rxjs/operators"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import equal from "deep-equal"
import isolateSearchQuery from "./helpers/isolate-search-query"
import styled from "styled-components"
import history, { push } from "./history"

const LoaderContainer = styled.div`
  position: absolute;
  margin-top: 2rem;
  padding-top: 2.1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  box-shadow: 0 4px 20px rgba(40, 40, 40, 0.3);
  border-radius: 6px;

  ::after {
    content: "";
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  .loader {
    color: white;
    padding: 2rem 3.5rem;
  }

  .loader::before {
    border-color: rgb(140, 140, 140) !important;
  }

  .loader::after {
    border-color: rgb(220, 220, 220) transparent transparent !important;
  }
`

const Loader = props => (
  <LoaderContainer>
    <SUILoader {...props} />
  </LoaderContainer>
)

const Close = styled.div`
  width: 1rem;
  height: 1rem;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 1em;
    height: 2px;
    background: white;
    transform: translateY(-50%) rotate(45deg);
  }

  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 1em;
    height: 2px;
    background: white;
    transform: translateY(-50%) rotate(-45deg);
  }
`

class Search extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: ``,
    }

    this.subject = new Subject(``).pipe(
      map(value => value.trim()),
      distinctUntilChanged()
    )
  }

  render = () => {
    return (
      <span style={{ display: `flex`, width: `100%` }}>
        <Input
          style={{ flexGrow: `1` }}
          placeholder={this.props.placeholder || "Search..."}
          icon="search"
          iconPosition="left"
          transparent
          value={this.props.value || this.state.value}
          onChange={this.handleChange}
          loading={this.props.loading}
          {...(this.props.innerRef ? { ref: this.props.innerRef } : {})}
        />
        <Icon
          fitted
          style={{
            visibility:
              (this.props.value && this.props.value.length !== 0) ||
              this.state.value.length !== 0
                ? `visible`
                : `hidden`,
            fontSize: `1.1rem`,
          }}
          name="remove circle"
          onClick={event => this.handleChange(event, { value: `` })}
        />
      </span>
    )
  }

  componentWillMount = () => {
    this.subscription = this.subject.subscribe({
      next: value => this.props.onChange(value),
      error: error => console.error(error),
    })
  }

  componentWillUnmount = () => {
    this.subscription.unsubscribe()
  }

  handleChange = (event, { value }) => {
    if (this.props.hasOwnProperty(`value`)) {
      this.props.onChange(value)
    } else {
      this.subject.next(value)
      this.setState({ value })
    }
  }
}

export { Search }

const PersistentSearch = withRouter(
  ({ location, history, match, onChange, value = ``, ...props }) => {
    return (
      <Search
        onChange={value => {
          const { search, ...remainingQueryParams } = queryString.parse(
            location.search
          )
          const newQueryString = queryString.stringify({
            ...remainingQueryParams,
            ...(value.length !== 0 ? { search: value } : {}),
          })
          history.replace(location.pathname + `?${ newQueryString }`)
          onChange && onChange(value)
        }}
        value={value}
        {...props}
      />
    )
  }
)

export { PersistentSearch }

class FilterComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { value: `` }
  }

  render = () => {
    return (
      <span style={{ display: `flex`, width: `100%` }}>
        <Input
          icon={this.props.icon || `filter`}
          iconPosition="left"
          transparent
          placeholder={this.props.name || `Filter`}
          onChange={this.handleChange}
          value={this.state.value}
        />
      </span>
    )
  }

  componentDidMount = () => {
    const query = queryString.parse(
      isolateSearchQuery(this.props.location.search)
    )[this.props.name.toLowerCase()]

    this.setState({
      value: Array.isArray(query) ? query.join(`, `).trim() : query || ``,
    })
  }

  componentWillReceiveProps = nextProps => {
    !queryString.parse(isolateSearchQuery(nextProps.location.search))[
      this.props.name.toLowerCase()
    ] && this.setState({ value: `` })
  }

  handleChange = (event, { value }) => {
    this.setState({ value }, () => {
      const parsed = queryString.parse(
        isolateSearchQuery(this.props.location.search)
      )

      const trimmedValue = this.state.value
        .split(`,`)
        .map(x => x.trim())
        .filter(x => x.length > 0)

      if (trimmedValue.length > 0) {
        if (!equal(parsed[this.props.name.toLowerCase()], trimmedValue)) {
          const query = {
            ...parsed,
            [this.props.name.toLowerCase()]: trimmedValue,
          }

          this.props.location.search.indexOf(`/`) === -1
            ? this.props.history.push(
                this.props.match.url + `?${ queryString.stringify(query) }`
              )
            : this.props.history.push(
                this.props.match.url +
                  `?${ queryString.stringify(
                    query
                  ) }${ this.props.location.search.slice(
                    this.props.location.search.indexOf(`/`)
                  ) }`
              )
        }
      } else {
        const query = Object.keys(parsed)
          .filter(k => k !== this.props.name.toLowerCase())
          .reduce(
            (acc, k) => ({
              ...acc,
              [k]: parsed[k],
            }),
            {}
          )

        this.props.location.search.indexOf(`/`) === -1
          ? this.props.history.push(
              this.props.match.url + `?${ queryString.stringify(query) }`
            )
          : this.props.history.push(
              this.props.match.url +
                `?${ queryString.stringify(
                  query
                ) }${ this.props.location.search.slice(
                  this.props.location.search.indexOf(`/`)
                ) }`
            )
      }
    })
  }
}

const Filter = withRouter(FilterComponent)

export { Filter }

class SelectComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ``,
    }
  }

  render = () => {
    const {
      name,
      icon = `filter`,
      header,
      options = [],
      children,
      multiple,
      staticContext,
      clearable = true,
      ...props
    } = this.props

    return (
      <span>
        <Icon name={icon} />
        <Dropdown text={name} {...props}>
          <Dropdown.Menu>
            {header && <Dropdown.Header content={header} />}
            {header && <Dropdown.Divider />}
            {options.map((option, index) => (
              <Dropdown.Item
                key={index}
                onClick={event => this.handleItemClick(option.value)}
                active={this.props.location.search.indexOf(option.value) > -1}
              >
                {option.content}
              </Dropdown.Item>
            ))}
            {clearable && (
              <Dropdown.Item onClick={event => this.handleItemClick(``)}>
                <div style={{ textAlign: `center`, fontWeight: `bold` }}>
                  Clear
                </div>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </span>
    )
  }

  handleItemClick = item => {
    if (item.length > 0) {
      this.props.history.push(
        this.props.match.url +
          `?` +
          queryString.stringify({
            ...queryString.parse(
              isolateSearchQuery(this.props.location.search)
            ),
            [this.props.name.toLowerCase()]: item,
          })
      )
    } else {
      const search = queryString.parse(
        isolateSearchQuery(this.props.location.search)
      )

      const cleanedSearch = Object.keys(search)
        .filter(k => k !== this.props.name.toLowerCase())
        .reduce((acc, k) => ({ ...acc, [k]: search[k] }), {})

      Object.keys(cleanedSearch).length > 0
        ? this.props.history.push(
            this.props.match.url + `?` + queryString.stringify(cleanedSearch)
          )
        : this.props.history.push(this.props.match.url)
    }
  }
}

const Select = withRouter(SelectComponent)

export { Select }

const Content = ({
  children,
  loading,
  loadingText = `Loading...`,
  disabled,
  ...props
}) => (
  <div
    className={[
      `wav-panel-next-content`,
      ...(disabled ? [ `wav-panel-next-content-disabled` ] : []),
    ].join(` `)}
    {...props}
  >
    {/* <Dimmer.Dimmable style={{ ...(loading ? { paddingTop: `4rem` } : {}) }}>
      <Dimmer active={loading} inverted>
        <Loader content={loadingText} />
      </Dimmer> */}
    <Loader
      inline
      active={loading}
      content={loadingText}
      // style={{
      //   position: `absolute`,
      //   top: `2rem`,
      //   left: `50%`,
      //   transform: `translateX(-50%)`,
      // }}
    />
    <Dimmer.Dimmable dimmed={loading}>
      {children}
      {/* <Dimmer active={loading} inverted /> */}
    </Dimmer.Dimmable>
    {/* </Dimmer.Dimmable> */}
  </div>
)

Content.displayName = `Content`
export { Content }

const Menu = ({ children, ...props }) => (
  <div className="wav-panel-next-menu" {...props}>
    {children}
  </div>
)

Menu.displayName = `Menu`
export { Menu }

const MenuItem = ({ children, active, disabled, ...props }) => (
  <div
    className={`wav-panel-next-menu-item ${
      active ? `wav-panel-next-menu-item-active` : ``
    } ${ disabled ? `wav-panel-next-menu-item-disabled` : `` }`}
    {...props}
  >
    {children}
  </div>
)

MenuItem.displayName = `MenuItem`
export { MenuItem }

const CommandBar = ({ children, ...props }) => (
  <div className="wav-panel-next-command-bar" {...props}>
    {children}
  </div>
)
CommandBar.displayName = `CommandBar`

const Command = React.forwardRef(
  ({ children, onClick, disabled, fluid, save, ...props }, ref) => (
    <div
      role="button"
      tabIndex={0}
      ref={ref}
      onClick={event => {
        event.preventDefault()
        onClick && onClick()
      }}
      onKeyPress={({ key }) => {
        if (key === `Enter`) {
          onClick && onClick()
        }
      }}
      className={[
        `wav-panel-next-command-bar-command`,
        disabled ? `wav-panel-next-command-bar-command-disabled` : ``,
        fluid ? `wav-panel-next-command-bar-command-fluid` : ``,
      ].join(` `)}
      {...props}
    >
      {save ? (
        <span>
          {/* <Icon name="cloud upload" /> */}
          Save
        </span>
      ) : (
        children
      )}
    </div>
  )
)

const Save = props => (
  <Command {...props}>
    <span>
      {/* <Icon name="cloud upload" /> */}
      Save
    </span>
  </Command>
)

const Delete = props => (
  <Command {...props}>
    <span>
      <Icon name="trash" />
      Delete
    </span>
  </Command>
)

const New = props => (
  <Command {...props}>
    <span>
      <Icon name="add" />
      New
    </span>
  </Command>
)

const Edit = props => (
  <Command {...props}>
    <span>
      <Icon name="edit" />
      Edit
    </span>
  </Command>
)

const Advanced = ({ toggled, ...props }) =>
  toggled ? (
    <Command {...props}>
      <span>
        <Icon.Group>
          <Icon name="sliders" />
          <Icon name="dont" corner />
        </Icon.Group>
        {` `}Hide advanced
      </span>
    </Command>
  ) : (
    <Command {...props}>
      <span>
        <Icon name="sliders" />
        Show advanced
      </span>
    </Command>
  )

Object.assign(Command, { Save, Delete, New, Edit, Advanced })

export { CommandBar, Command }

class Panel extends Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef()
  }

  render = () => {
    const {
      title = ``,
      onClose,
      menu,
      commandBar,
      children,
      attached,
      extra,
      ...props
    } = this.props

    return (
      <div
        className={`wav-panel-next ${ !!menu ? `wav-panel-next-wide` : `` } ${
          attached ? `wav-panel-next-attached` : ``
        } ${ extra ? `wav-panel-next-extra` : `` }`}
        tabIndex={-1}
        ref={this.ref}
        onKeyDown={event => {
          event.target === this.ref.current &&
            event.key === `Escape` &&
            push(
              history.location.pathname.slice(
                0,
                history.location.pathname.lastIndexOf(`/`)
              )
            )
        }}
        {...props}
      >
        <div className="wav-panel-next-title">
          <span className="wav-panel-next-title-text">{title}</span>
          <span
            className="wav-panel-next-title-back"
            onClick={onClose}
            style={{ ...(onClose ? {} : { display: `none` }) }}
          >
            <Close />
          </span>
        </div>
        {!!menu ? (
          <div className="wav-panel-next-with-menu">
            {menu}
            <div className="wav-panel-next-with-menu-content">{children}</div>
          </div>
        ) : (
          children
        )}
      </div>
    )
  }

  componentDidMount = () => {
    setTimeout(() => this.ref.current && this.ref.current.focus(), 0)
  }
}

export default Panel
