// semantic ui "size" attribute to pixels
export default {
  mini: 35,
  tiny: 80,
  small: 150,
  medium: 300,
  large: 450,
  big: 600,
  huge: 800,
  massive: 960,
}
