import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import RevenueTableWrapper from './RevenueTableWrapper'

const ARRANGEMENT_LIMIT = 5;

export default function UserRevenueTable({ revenue, clientOptions }) {
  return (
    <RevenueTableWrapper>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Arrangement</Table.HeaderCell>
            <Table.HeaderCell>Total time</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {revenue.userIds.map((userId) => (
            <Fragment key={userId}>
              <Table.Row>
                <Table.Cell>
                  <Link to={`users/${userId}`}>
                    {revenue.users[userId].name || clientOptions.find(x => x.key === userId)?.text || 'Other'}
                  </Link>
                </Table.Cell>
                <Table.Cell />
                <Table.Cell>
                  <p style={{fontWeight: 'bold'}}>{Math.round(revenue.users[userId].totalTime)} s</p>
                </Table.Cell>
                <Table.Cell>
                  <p style={{fontWeight: 'bold'}}>{revenue.users[userId].revenueRaw.toFixed(2)} £$€</p>
                </Table.Cell>
              </Table.Row>

              {revenue.users[userId].arrangementIds.slice(0, ARRANGEMENT_LIMIT).map((arrId) => (
                <Table.Row key={`${revenue.users[userId].id}-${arrId}`}>
                  <Table.Cell />

                  <Table.Cell>
                    <Link to={`arrangements/${arrId}`}>{revenue.users[userId].arrangements[arrId].title}</Link>
                  </Table.Cell>

                  <Table.Cell>
                    <p>{Math.round(revenue.users[userId].arrangements[arrId].totalTime)} s</p>
                  </Table.Cell>

                  <Table.Cell>
                    <p>{revenue.users[userId].arrangements[arrId].revenueRaw.toFixed(2)} £$€</p>
                  </Table.Cell>
                </Table.Row>
              ))}
            {revenue.users[userId].arrangementCount > ARRANGEMENT_LIMIT && (
              <Table.Row key={`${userId}-more`}>
                <Table.Cell />

                <Table.Cell>
                  {`+${revenue.users[userId].arrangementCount - ARRANGEMENT_LIMIT} more`}
                </Table.Cell>

                <Table.Cell>
                  ...
                </Table.Cell>

                <Table.Cell>
                  ...
                </Table.Cell>
              </Table.Row>
            )}
            </Fragment>
          ))}
        </Table.Body>
      </Table>
    </RevenueTableWrapper>
  );
}
