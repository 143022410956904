import React from "react"
import Panel, { CommandBar, Command, Content } from "../Panel"
import { Form } from "semantic-ui-react"
import FileInput from "../FileInput"
import { root } from "../services/Globals"
import { push } from "../history"
import dispatchEvent from "../helpers/dispatch-event"

export default class UploadPdf extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      file: undefined,
      loading: false,
      loadingMessage: undefined,
    }
  }

  render = () => (
    <Panel
      title="Upload PDF"
      onClose={() =>
        push(
          this.props.match.url.slice(0, this.props.match.url.lastIndexOf(`/`))
        )
      }
    >
      <CommandBar>
        <Command save disabled={!this.state.file} onClick={this.save} />
      </CommandBar>
      <Content
        loading={this.state.loading}
        loadingText={this.state.loadingMessage}
      >
        <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
          <Form.Field>
            <label>PDF</label>
            <FileInput
              name="pdf"
              value={this.state.file}
              icon="file pdf"
              accept="application/pdf"
              onChange={this.handleFileChange}
            />
          </Form.Field>
        </Form>
      </Content>
    </Panel>
  )

  handleFileChange = event => {
    const {
      files: [ file ],
    } = event.target

    this.setState({ file })
  }

  save = () => {
    const {
      match: {
        params: { arrangementId, versionId },
        url,
      },
    } = this.props
    const { file } = this.state

    const data = new FormData()
    data.append(`file`, file)

    this.setState(
      {
        loading: true,
        loadingMessage: `Uploading pdf...`,
      },
      () => {
        root.wav
          .api({
            url: `/arrangements/${ arrangementId }/versions/${ versionId }/upload/pdf`,
            method: `post`,
            data,
            onUploadProgress: ({ total, loaded }) => {
              this.setState(previousState => ({
                ...previousState,
                loadingMessage: `Uploading pdf ${ Math.ceil(
                  (loaded / total) * 100
                ) }%`,
              }))
            },
          })
          .then(() => {
            dispatchEvent(`update:${ versionId }`)
            push(url.slice(0, url.lastIndexOf(`/`)))
          })
          .catch(error => {
            this.setState({ loading: false, loadingMessage: undefined })
          })
      }
    )
  }
}
