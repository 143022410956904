import React from "react"
import { Image } from "semantic-ui-react"
import sizes from "../helpers/semantic-ui-sizes"

export default props =>
  props.image ? (
    <Image
      size="mini"
      src={props.image.url + `?width=${ sizes.mini }`}
      style={{
        marginTop: `auto`,
        marginBottom: `auto`,
      }}
    />
  ) : (
    <div
      className="ui tiny image"
      style={{
        width: `${ sizes.mini }px`,
        height: `${ sizes.mini }px`,
        border: `dashed 2px rgb(140,140,140)`,
      }}
    />
  )
