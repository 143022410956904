import { Container } from "unstated"
import { root } from "../services/Globals"

const initialState = {
  isSaving: false,
}

class LicenseContainer extends Container {
  constructor() {
    super()

    this.state = {
      ...initialState,
    }
  }

  assignLicenseToUser = ({ licenseId, userId }) => {
    return new Promise((resolve, reject) => {
      this.setState({
        isSaving: true,
      })

      root.wav
        .api({
          url: `/licences/${ licenseId }/${ userId ? `assign` : `unassign` }`,
          method: `put`,
          ...(userId ? { data: { userId } } : {}),
        })
        .then(response => {
          this.setState({
            isSaving: false,
          })

          resolve(response)
        })
        .catch(error => {
          this.setState({ isSaving: false })
          reject(error)
        })
    })
  }

  assignAll = ({ choirId, planId }) => {
    this.setState({
      isSaving: true,
    })

    return new Promise((resolve, reject) => {
      root.wav
        .api({
          url: `/licences/assignall`,
          method: `post`,
          data: {
            choirId,
            planId,
          },
        })
        .then(response => {
          this.setState({ isSaving: false })

          resolve(response)
        })
        .catch(error => {
          this.setState({ isSaving: false })
          reject(error)
        })
    })
  }
}

export { LicenseContainer }
