import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"

const routes = [
  { name: `Arrangements`, path: `/arrangements` },
  { name: `Reports`, path: `/reports` },
  // { name: `Artists`, path: `/artists` },
  { name: `Catalog`, path: `/catalog` },
  // { name: `Categories`, path: `/categories` },
  { name: `Organizations`, path: `/organizations` },
  { name: `Users`, path: `/users` },
  { name: `Revenue`, path: `/revenue` },
  // { name: `Songs`, path: `/songs` },
  // { name: `Songwriters`, path: `/songwriters` }
]

const Nav = styled.nav`
  background: rgb(33, 33, 34);
  border-right: 1px solid rgb(52, 53, 52);
  overflow-y: scroll;
  min-width: 220px;
`

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  padding-left: 1.618rem;
  padding-right: 6rem;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  height: 40px;
  color: #999999;
  position: relative;
  white-space: nowrap;
  user-select: none;

  :hover {
    color: #fff;
  }

  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0.2em;
    height: 100%;
    background: rgb(61, 181, 239);
    transform: translateX(-100%);
    transition: transform 250ms;
  }

  &.active {
    color: #fff;
    &::before {
      transform: translateX(0%);
    }
  }
`

const LinkContainer = styled.li``

const Links = styled.ul`
  list-style-type: none;
  padding: 40px 0 40px 0;
  margin: 0;
`

export default props => (
  <Nav>
    <Links>
      {routes.map(({ name, path }, index) => (
        <LinkContainer key={index}>
          <Link to={path}>{name}</Link>
        </LinkContainer>
      ))}
    </Links>
  </Nav>
)
