import React from "react"
import axios from "axios"
import { Subject, from } from "rxjs"
import { switchMap, tap, debounceTime } from "rxjs/operators"
import isEqual from "deep-equal"

class AzureSearchContainer extends React.Component {
  constructor(props) {
    super(props)

    this.search$ = new Subject().pipe(
      debounceTime(400),
      tap(() => {
        this.setState({ loading: true })
      }),
      switchMap(configuration => from(axios.request(configuration)))
    )

    this.state = {
      loading: false,
      nextPageParameters: undefined,
      value: [],
      count: undefined,
    }
  }

  render = () => this.props.children({ ...this.state })

  componentDidMount = () => {
    this.searchSubscription = this.search$.subscribe(response => {
      this.setState(previousState => ({
        loading: false,
        value: response.config.data.includes(`"skip":`)
          ? previousState.value.concat(response.data.value)
          : response.data.value,
        nextPageParameters: response.data[`@search.nextPageParameters`],
        count: response.data[`@odata.count`],
      }))
    })

    this.search({ initial: true })
  }

  componentDidUpdate = previousProps => {
    const propNames = [
      `serviceName`,
      `index`,
      `apiVersion`,
      `apiKey`,
      `parameters`,
    ]

    propNames.reduce(
      (hasChanged, key) =>
        hasChanged || !isEqual(previousProps[key], this.props[key]),
      false
    ) && this.search()
  }

  componentWillUnmount = () => {
    this.searchSubscription.unsubscribe()
  }

  search = ({ initial } = {}) => {
    const {
      serviceName = `dev-search-wav`,
      index = `dev`,
      apiVersion = `2017-11-11`,
      apiKey = `4EF8FB5E46D6E724C533FACBE2B785A4`,
      parameters = {
        search: `*`,
        select: `*`,
      },
    } = this.props

    if (initial && parameters.skip) {
      delete parameters.skip
    }

    this.search$.next({
      method: `POST`,
      url: `https://${ serviceName }.search.windows.net/indexes/${ index }/docs/search`,
      params: {
        "api-version": apiVersion,
        "api-key": apiKey,
      },
      data: Object.assign({}, parameters, { count: true }),
    })
  }
}

export { AzureSearchContainer }
