import styled from "styled-components"

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li:first-child {
    margin-bottom: 3px;
  }

  li:last-child {
    margin-top: 3px;
  }

  li:not(:first-child),
  li:not(:last-child) {
    margin-bottom: 3px;
    margin-top: 3px;
  }
`

List.Item = styled.li`
  padding: 0;
  margin: 0;
`

export default List
