import { root } from "../services/Globals"

export const exportRevenue = ({ source, startDate, endDate, revenue, clientUserId, includeOtherUsersInWav }) => {
  return root.wav.api
    .request({
      url: `statistics/aggregated/${source === 'user' ? 'users' : 'arrangements'}/revenue/organizations/csv`,
      params: {
        ...(startDate ? { startDate } : {}),
        ...(endDate ? { endDate } : {}),
        ...(revenue ? { revenue } : {}),
        ...(clientUserId ? { clientUserId } : {}),
        ...(includeOtherUsersInWav ? { includeOtherUsersInWav } : {})
      },
      method: 'GET'
    }).then(({ data }) => data)
}

export const readRevenuePerArrangement = ({ source, startDate, endDate, revenue, clientUserId, includeOtherUsersInWav }) => {
    return root.wav.api
    .request({
      url: `statistics/aggregated/${source === 'user' ? 'users' : 'arrangements'}/revenue/arrangements`,
      params: {
        ...(startDate ? { startDate } : {}),
        ...(endDate ? { endDate } : {}),
        ...(revenue ? { revenue } : {}),
        ...(clientUserId ? { clientUserId } : {}),
        ...(includeOtherUsersInWav ? { includeOtherUsersInWav } : {})
      },
      method: 'GET'
    }).then(({ data }) => data) 
}

export const readRevenuePerOrganization = ({ source, startDate, endDate, revenue, clientUserId, includeOtherUsersInWav }) => {
    return root.wav.api
    .request({
      url: `statistics/aggregated/${source === 'user' ? 'users' : 'arrangements'}/revenue/organizations`,
      params: {
        ...(startDate ? { startDate } : {}),
        ...(endDate ? { endDate } : {}),
        ...(revenue ? { revenue } : {}),
        ...(clientUserId ? { clientUserId } : {}),
        ...(includeOtherUsersInWav ? { includeOtherUsersInWav } : {})
      },
      method: 'GET'
    }).then(({ data }) => data) 
}

export const readClients = () => {
    return root.wav.api
    .request({
      url: 'users/clients',
      method: 'GET'
    }).then(({ data }) => data) 
}