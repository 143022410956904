import { Container } from "unstated"
import { root } from "../services/Globals"

const initialState = {
  isFetching: false,
  isRemoving: false,
  details: {},
  auth0: {},
  organizations: [],
  roles: []
}

const user = (() => {
  const details = id =>
    root.wav.api({ url: `/users/${ id }/details` }).then(({ data }) => data)

  const auth0 = id =>
    root.wav
      .api({
        url: `/users/${ id }/auth0`,
      })
      .then(({ data }) => data.data)

  const deleteUser = id =>
    root.wav.api({
      url: `/users/${ id }`,
      method: `delete`,
    })

  const addUserToUnit = (userId, ouId, type) => {
    console.log(`addUserToUnit`, userId, ouId, type);
    return root.wav.api({
      url: `/organizations/ou/${ ouId }/users/${ userId }`,
      method: `post`,
      data: {
        type,
      }
    })
  }

  const removeUserFromUnit = (userId, ouId) => {
    console.log(`removeUserFromUnit`, userId, ouId);
    return root.wav.api({
      url: `/organizations/ou/${ ouId }/users/${ userId }`,
      method: `delete`,
    })
  }

  const addRoleToUser = (userId, roleId) => {
    console.log(`addRoleToUser`, userId, roleId);
    return root.wav.api({
      url: `/roles/${ roleId }/users`,
      method: `post`,
      data: {
        userId
      }
    })
  }

  const removeRoleFromUser = (userId, roleId) => {
    console.log(`removeRoleFromUser`, userId, roleId);
    return root.wav.api({
      url: `/roles/${ roleId }/users`,
      method: `delete`,
      data: {
        userId
      }
    })
  }


  return {
    read: {
      details,
      auth0,
    },
    delete: deleteUser,
    organizations: {
      addUserToUnit,
      removeUserFromUnit
    },
    roles: {
      addRoleToUser,
      removeRoleFromUser
    }
  }
})()

class UserContainer extends Container {
  state = {
    ...initialState,
  }

  fetchDetails = async id => {
    this.setState({
      isFetching: true,
    })

    try {
      const data = await user.read.details(id)

      this.setState({
        isFetching: false,
        details: data,
        organizations: (data && data.organizationalUnits) || [],
        roles: (data && data.roles) || []
      })

      return data
    } catch (error) {
      console.error(error)

      this.setState({
        isFetching: false,
      })
    }
  }

  fetchAuth0 = async (id = this.state.details.id) => {
    await new Promise(resolve =>
      this.setState(
        previousState => ({
          previousState,
          auth0: { ...initialState.auth0 },
          isFetching: true,
        }),
        resolve
      )
    )

    try {
      const data = await user.read.auth0(id)

      await new Promise(resolve =>
        this.setState(
          previousState => ({
            ...previousState,
            isFetching: false,
            auth0: data,
          }),
          resolve
        )
      )

      return data
    } catch (error) {
      console.error(error)

      this.setState({
        ...initialState,
      })
    }
  }

  remove = async () => {
    this.setState({
      isRemoving: true,
    })

    try {
      const id = this.state.details.id
      await user.delete(id)
      this.setState({
        ...initialState,
      })
      return id
    } catch (error) {
      this.setState({
        isRemoving: false,
      })
    }
  }

  updateRoles = async (newRoles = []) => {
    const addedRoles = newRoles.filter(role => role.id && !this.state.roles.some(o => o.id === role.id));
    const removedRoles = this.state.roles.filter(o => o.id && !newRoles.some(no => no.id === o.id));
    console.log(`updateRoles`, this.state.roles, newRoles, addedRoles, removedRoles);
    if (!this.state.details || !this.state.details.id || (removedRoles.length <= 0 && addedRoles <= 0)) {
      return;
    }
    this.setState({
      isFetching: true,
    })

    for (let i = 0; i < addedRoles.length; i++) {
      const role = addedRoles[i];
      try {
        await user.roles.addRoleToUser(this.state.details.id, role.id);
        this.setState((s) => ({
          ...s,
          roles: [ ...s.roles, role ]
        }));
      } catch (error) {
        console.error(error);
      }
    }

    for (let i = 0; i < removedRoles.length; i++) {
      const role = removedRoles[i];
      try {
        await user.roles.removeRoleFromUser(this.state.details.id, role.id);
        this.setState((s) => ({
          ...s,
          roles: s.roles.filter(r => r.id !== role.id),
        }));
      } catch (error) {
        console.error(error);
      }
    }

    await this.fetchDetails(this.state.details.id);
  }

  updateOrganizations = async (newOrginizations = []) => {
    const addedOrganizations = newOrginizations.filter(no => no.id && !this.state.organizations.some(o => o.id === no.id));
    const removedOrganizations = this.state.organizations.filter(o => o.id && !newOrginizations.some(no => no.id === o.id));
    console.log(`updateOrganizations`, this.state.organizations, newOrginizations, addedOrganizations, removedOrganizations);
    if (!this.state.details || !this.state.details.id || (removedOrganizations.length <= 0 && addedOrganizations <= 0)) {
      return;
    }
    this.setState({
      isFetching: true,
    })

    for (let i = 0; i < addedOrganizations.length; i++) {
      const ou = addedOrganizations[i];
      try {
        await user.organizations.addUserToUnit(this.state.details.id, ou.id, `Unknown`);
        this.setState((s) => ({
          ...s,
          organizations: [ ...s.organizations, ou ]
        }));
      } catch (error) {
        console.error(error);
      }
    }

    for (let i = 0; i < removedOrganizations.length; i++) {
      const ou = removedOrganizations[i];
      try {
        await user.organizations.removeUserFromUnit(this.state.details.id, ou.id);
        this.setState((s) => ({
          ...s,
          organizations: s.organizations.filter(o => o.id !== ou.id),
        }));
      } catch (error) {
        console.error(error);
      }
    }

    await this.fetchDetails(this.state.details.id);
  }

  resetState = () => {
    this.setState({ ...initialState })
  }
}

export { UserContainer }
