import { Container } from "unstated"
import createActivityContainer from "./utils/activities/create-activity-container"

const activityContainer = createActivityContainer()

class ActivityContainer extends Container {
  constructor() {
    super()

    this.state = {
      activities: [],
    }

    activityContainer.subscribe(({ getState }) => {
      this.setState({
        activities: getState(),
      })
    })
  }

  add = activityContainer.add
  clear = activityContainer.clear
  remove = activityContainer.remove
}

export { ActivityContainer, activityContainer }
