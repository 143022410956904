import React from "react"
import Panel, { Content, CommandBar, Command, Search } from "../Panel"
import { QueryParams } from "../QueryParamsContainer"
import { Table, Image, Button, Label } from "semantic-ui-react"
import Observer from "react-intersection-observer"
import sizes from "../helpers/semantic-ui-sizes"
import { push } from "../history"

const Organizations = props => (
  <QueryParams>
    {queryParams => (
      <Panel title="Organizations">
        <CommandBar>
          <Command fluid>
            <Search
              value={queryParams.params.organizationsQuery || ``}
              onChange={organizationsQuery =>
                queryParams.update({ organizationsQuery })
              }
            />
          </Command>
        </CommandBar>
        <Content
          style={{ padding: 0 }}
          loading={props.organizations.isFetching}
          loadingText="Loading organizations"
        >
          <Table basic="very" selectable style={{ marginTop: 0 }}>
            <Table.Body>
              {props.organizations.data.map(item => (
                <Table.Row
                  key={item.id}
                  active={props.location.pathname.indexOf(item.id) !== -1}
                  onClick={() => {
                    push(`${ props.match.path }/${ item.id }`)
                  }}
                  style={{ cursor: `pointer` }}
                >
                  <Table.Cell
                    textAlign="left"
                    style={{
                      paddingLeft: `16px`,
                      width: `1%`,
                      whiteSpace: `nowrap`,
                      verticalAlign: `top`,
                    }}
                  >
                    {item.image ? (
                      <Image
                        size="mini"
                        src={item.image.url + `?width=${ sizes.mini }`}
                        style={{
                          marginTop: `auto`,
                          marginBottom: `auto`,
                        }}
                      />
                    ) : (
                      <div
                        className="ui tiny image"
                        style={{
                          width: `${ sizes.mini }px`,
                          height: `${ sizes.mini }px`,
                          border: `dashed 2px rgb(140,140,140)`,
                        }}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell style={{ paddingLeft: `16px` }}>
                    <div
                      style={{
                        minHeight: `40px`,
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `center`,
                      }}
                    >
                      <span>{item.name}</span>
                      <small>{item.description}</small>
                    </div>
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    style={{
                      paddingRight: `16px`,
                      width: `1%`,
                      whiteSpace: `nowrap`,
                    }}
                  >
                    {item.featured ? <Label>Featured</Label> : null}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {props.organizations.next && (
            <Observer
              onChange={inView =>
                inView &&
                !props.organizations.isFetching &&
                props.organizations.fetch(true)
              }
              rootMargin="610px"
            >
              <div
                style={{
                  width: `100%`,
                  display: `flex`,
                  justifyContent: `center`,
                  padding: `3em`,
                }}
              >
                <Button
                  basic
                  content="Load more..."
                  onClick={() => props.organizations.fetch(true)}
                  loading={props.organizations.isFetchingNext}
                />
              </div>
            </Observer>
          )}
          {!props.organizations.next && !props.organizations.isFetching && (
            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                minHeight: `122px`,
              }}
            >
              <Button
                basic
                onClick={() =>
                  push(
                    `/organizations/new`,
                    queryParams.params.organizationsQuery &&
                      queryParams.params.organizationsQuery.length > 0
                      ? { name: queryParams.params.organizationsQuery }
                      : {}
                  )
                }
              >
                {queryParams.params.organizationsQuery &&
                queryParams.params.organizationsQuery.length > 0 ? (
                  <span>
                    Add{` `}
                    <strong>{queryParams.params.organizationsQuery}</strong>
                  </span>
                ) : (
                  `Add missing organization`
                )}
              </Button>
            </div>
          )}
        </Content>
      </Panel>
    )}
  </QueryParams>
)

export default Organizations
