import { Container } from "unstated"
import Activity from "./Activity"
import get from "lodash.get"

class SidebarContainer extends Container {
  constructor() {
    super()

    this.state = {
      ref: undefined,
      toggled: false,
      metrics: { width: 100 },
      component: Activity,
    }
  }

  togglePanel = componentName => {
    this.setState(previousState => ({
      ...previousState,
      toggled: !previousState.toggled,
      component: !previousState.toggled
        ? get({ activity: Activity }, componentName, null)
        : null,
    }))
  }

  setPanelRef = ref => {
    if (ref) {
      const elementMetrics = ref.getBoundingClientRect()

      this.setState(previousState => ({
        ...previousState,
        ref,
        metrics: elementMetrics,
      }))
    }
  }
}

export { SidebarContainer }
