import { Component } from "react"
import equal from "deep-equal"
import { root } from "../../services/Globals"

export default class GetAudiotrack extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `loading`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
      reload,
    } = this

    return this.props.children({
      mode,
      payload,
      reload,
    })
  }

  componentDidMount = () => {
    const { arrangementId, versionId, audioId } = this.props
    window.addEventListener(`update:${ arrangementId }`, this.getData)
    window.addEventListener(`update:${ versionId }`, this.getData)
    window.addEventListener(`update:${ audioId }`, this.getData)
    return this.getData()
  }

  componentWillUnmount = () => {
    const { arrangementId, versionId, audioId } = this.props
    window.removeEventListener(`update:${ arrangementId }`, this.getData)
    window.removeEventListener(`update:${ versionId }`, this.getData)
    window.removeEventListener(`update:${ audioId }`, this.getData)
  }

  componentWillReceiveProps = nextProps => {
    !equal(this.props, nextProps) && setTimeout(this.getData, 0)
  }

  getData = () => {
    this.setState(previousState => ({
      ...previousState,
      loading: true,
    }))

    const arrangementId = this.props.arrangementId
    const versionId = this.props.versionId
    const audioId = this.props.audioId

    return root.wav
      .api({
        url: `/arrangements/${ arrangementId }/versions/${ versionId }/audiotracks/${ audioId }`,
      })
      .then(({ data }) => {
        this.setState(previousState => ({
          ...previousState,
          mode: `success`,
          payload: data,
        }))
      })
      .catch(error => {
        this.setState(previousState => ({
          ...previousState,
          mode: `error`,
          payload: error,
        }))
      })
  }

  reload = event => {
    event && event.preventDefault && event.preventDefault()
    this.setState(() => this.initialState)
    return this.getData()
  }
}
