import React, { Component } from "react"
import Panel, { Content, CommandBar, Command } from "../Panel"
import { root } from "../services/Globals"
import { Form, Icon } from "semantic-ui-react"
import SelectTag from "./SelectTag"
import SelectLocale from "./SelectLocale"
import deleteModal from "../helpers/delete-modal"
import ImageAsset from "../ImageAsset"
import * as MarkdownEditor from "react-mde"
import "react-mde/lib/styles/css/react-mde.css"
import "react-mde/lib/styles/css/react-mde-preview.css"
import "react-mde/lib/styles/css/react-mde-toolbar.css"
import "../../node_modules/font-awesome/css/font-awesome.css"
import uploadAsset from "../api/upload-asset"

class Entry extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      entry: {
        locale: ``,
        tags: [],
        name: ``,
        title: ``,
        text: ``,
        preamble: ``,
        imageId: ``,
        image: {
          url: ''
        }
      },
      editor: {
        value: { text: ``, selection: null },
      },
      loading: false,
      showPreview: true,
    }
    this.state = { ...this.initialState }
  }

  render = () => {
    const {
      handleChange,
      handleImageChange,
      save,
      deleteEntry,
      handleEditorChange,
      togglePreview,
    } = this
    const { entry, editor, loading, showPreview } = this.state

    return [
      <Panel
        key="entry"
        title={
          entry.id ? `Entry${entry.name ? `: ${entry.name}` : ``}` : `New entry`
        }
        onClose={
          showPreview ? null : () => this.props.history.push(`/content/entries`)
        }
        attached
      >
        <CommandBar>
          {!showPreview && (
            <React.Fragment>
              <Command onClick={togglePreview}>
                <span>
                  <Icon name="unhide" />
                  Show preview
                </span>
              </Command>
              <Command.Delete
                disabled={!entry.id}
                onClick={() => {
                  deleteModal({ type: `entry`, deleteFunction: deleteEntry })
                }}
              />
              <Command.Save
                disabled={entry.name.length === 0 || entry.locale.length === 0}
                onClick={save}
              />
            </React.Fragment>
          )}
        </CommandBar>
        <Content loading={loading} loadingText="Loading entry...">
          <Form autoComplete="off" style={{ height: `100%` }}>
            <Form.Field required>
              <label>Name</label>
              <input name="name" value={entry.name} onChange={handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Tags</label>
              <SelectTag
                fluid
                search
                allowAdditions
                name="tags"
                value={entry.tags}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field required>
              <label>Locale</label>
              <SelectLocale
                name="locale"
                value={entry.locale}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Title</label>
              <input name="title" value={entry.title} onChange={handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Preamble</label>
              <input name="preamble" value={entry.preamble} onChange={handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Image</label>
              <ImageAsset
                name="image"
                initialValue={entry.image.url}
                onChange={handleImageChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Text</label>
              <MarkdownEditor.ReactMde
                value={editor.value}
                onChange={handleEditorChange}
                commands={MarkdownEditor.ReactMdeCommands.getDefaultCommands()}
                visibility={{
                  preview: false,
                }}
                textAreaProps={{
                  rows: 15,
                  style: { fontFamily: `monospace` },
                }}
              />
            </Form.Field>
          </Form>
        </Content>
      </Panel>,
      showPreview ? (
        <Panel
          key="markdown-preview"
          onClose={
            showPreview
              ? () => this.props.history.push(`/content/entries`)
              : null
          }
        >
          <CommandBar>
            {showPreview && (
              <React.Fragment>
                <Command onClick={togglePreview}>
                  <span>
                    <Icon name="hide" />
                    Hide preview
                  </span>
                </Command>
                <Command.Delete
                  disabled={!entry.id}
                  onClick={() => {
                    deleteModal({ type: `entry`, deleteFunction: deleteEntry })
                  }}
                />
                <Command.Save
                  disabled={
                    entry.name.length === 0 || entry.locale.length === 0
                  }
                  onClick={save}
                />
              </React.Fragment>
            )}
          </CommandBar>
          <Content>
            <Form>
              <Form.Field>
                <label>Preview</label>
                <MarkdownEditor.ReactMdePreview
                  markdown={editor.value.text}
                  helpVisible={false}
                />
              </Form.Field>
            </Form>
          </Content>
        </Panel>
      ) : null,
    ]
  }

  componentDidMount = () => {
    this.props.match.params.entryId !== `create` && this.fetch()
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.match.params.entryId === `create`) {
      this.setState(this.initialState)
    } else {
      if (!(this.props.match.url === nextProps.match.url)) {
        this.fetch()
      }
    }
  }

  fetch = () => {
    this.setState(
      previousState => ({
        ...previousState,
        loading: true,
      }),
      () => {
        Promise.resolve()
          .then(() => root.wav.api(this.props.match.url))
          .then(({ data: entry }) => {
            if (entry.preamble == null) {
              entry.preamble = ''; // because entries uploaded before preamble will be null
            }

            if(entry.image == null) {
              entry.image = {}
            }

            if(entry.image) {
              entry.imageId = entry.image.id;
            }

            
            this.setState(previousState => ({
              ...previousState,
              entry,
              editor: {
                value: { text: entry.text, selection: null },
              },
              loading: false,
            }))
          })
      }
    )
  }

  save = () => {
    const { id, createdUtc, updatedUtc, ...data } = this.state.entry
    console.log(data)

    this.setState(
      previousState => ({
        ...previousState,
        loading: true,
      }),
      () => {
        root.wav
          .api({
            url: this.props.match.url.replace(`/create`, ``),
            method:
              this.props.match.params.entryId !== `create` ? `put` : `post`,
            data,
          })
          .then(({ data: { id } }) => {
            this.props.match.params.entryId === `create`
              ? this.props.history.push(`/content/entries/${id}`)
              : this.fetch()
          })
      }
    )
  }

  deleteEntry = () => {
    this.setState(
      previousState => ({
        ...previousState,
        loading: true,
      }),
      () => {
        root.wav
          .api({
            url: this.props.match.url,
            method: `delete`,
          })
          .then(() => {
            this.props.history.push(`/content/entries`)
          })
      }
    )
  }

  handleChange = ({ target }, data) => {
    const { name, value } = data || target
    this.setState(previousState => ({
      ...previousState,
      entry: {
        ...previousState.entry,
        [name]: value,
      },
    }))
  }

  handleImageChange = ({ target }, data) => {
    const { name, value } = data || target
    console.log(name, value)
    if(value) {
    uploadAsset(value).then((res) => {
      this.setState(previousState => ({
        ...previousState,
        entry: {
          ...previousState.entry,
          imageId: res.id,
        },
      }))
    })
  } else {
    this.setState(previousState => ({
      ...previousState,
      entry: {
        ...previousState.entry,
        imageId: null,
      },
    }))
  }
    
  }

  handleEditorChange = value => {
    this.setState(previousState => ({
      ...previousState,
      entry: {
        ...previousState.entry,
        text: value.text,
      },
      editor: { value },
    }))
  }

  togglePreview = () => {
    this.setState(previousState => ({
      ...previousState,
      showPreview: !previousState.showPreview,
    }))
  }
}

Entry.displayName = `Entry`

export default Entry
