import React from "react"
import { Route } from "react-router-dom"
import { QueryParams } from "../QueryParamsContainer"
import { CollectionProvider } from "../CollectionContainer"
import Reports from "./Reports"
import Report from "./Report"

export default props => (
  <QueryParams>
    {queryParams => (
      <CollectionProvider
        url="/arrangements/feedback"
        params={{ search: queryParams.params.reportsQuery }}
      >
        {reports => (
          <React.Fragment>
            <Route
              path={props.match.url}
              render={props => <Reports reports={reports} {...props} />}
            />

            <Route
              path="/reports/:reportId"
              render={props => (
                <Report
                  onUpdate={({ data: { id } }) => reports.refresh(id)}
                  {...props}
                />
              )}
            />
          </React.Fragment>
        )}
      </CollectionProvider>
    )}
  </QueryParams>
)
