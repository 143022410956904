import React from "react"
import Panel, { CommandBar, Command, Content } from "../Panel"
import { Form, Header } from "semantic-ui-react"
import { push } from "../history"
import isEqual from "deep-equal"
import ImageAsset from "../ImageAsset"
import set from "lodash.set"
import merge from "lodash.merge"
import FocusElement from "../FocusElement"

class Organization extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      draft: {
        name: (props.location.state || {}).name || ``,
        address: {},
      },
    }
  }

  render = () => {
    const { draft } = this.state

    return (
      <FocusElement>
        {({ elementRef, focusElement }) => (
          <Panel
            title={
              !this.props.match.params.organizationId
                ? `New organization`
                : this.props.isFetching
                ? `Loading organization...`
                : draft && draft.name
                ? `Edit organization: ${ draft.name }`
                : `Organization`
            }
            onClose={() => push(this.props.returnTo || `/organizations`)}
          >
            <CommandBar>
              {this.props.match.params.organizationId && (
                <Command.Delete
                  disabled={this.props.loading}
                  onClick={this.props.delete}
                />
              )}
              <Command
                save
                disabled={isEqual(this.props.data, draft) || this.props.loading}
                onClick={() => {
                  this.props.save(draft)
                }}
                ref={elementRef}
              />
            </CommandBar>
            <Content
              loading={this.props.loading}
              loadingText={this.props.loadingMessage}
            >
              <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
                <Form.Input
                  label="Name"
                  name="name"
                  value={draft.name || ``}
                  onChange={this.handleChange}
                />

                <Form.TextArea
                  label="Description"
                  name="description"
                  value={draft.description || ``}
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Email"
                  name="email"
                  value={draft.email || ``}
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Legal ID"
                  name="legalIdentity"
                  value={draft.legalIdentity || ``}
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="VAT"
                  name="vat"
                  value={draft.vat || ``}
                  onChange={this.handleChange}
                />

                <Header as="h3">Address</Header>

                <Form.Input
                  label="Line 1"
                  name="address.line1"
                  value={
                    draft.address && draft.address.line1
                      ? draft.address.line1
                      : ``
                  }
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Line 2"
                  name="address.line2"
                  value={
                    draft.address && draft.address.line2
                      ? draft.address.line2
                      : ``
                  }
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Postal code"
                  name="address.postalCode"
                  value={
                    draft.address && draft.address.postalCode
                      ? draft.address.postalCode
                      : ``
                  }
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="City"
                  name="address.city"
                  value={
                    draft.address && draft.address.city
                      ? draft.address.city
                      : ``
                  }
                  onChange={this.handleChange}
                />

                <Form.Input
                  label="Country"
                  name="address.country"
                  value={
                    draft.address && draft.address.country
                      ? draft.address.country
                      : ``
                  }
                  onChange={this.handleChange}
                  onKeyDown={focusElement}
                />

                <Form.Field>
                  <Header as="h3">Image</Header>
                  <ImageAsset
                    name="image"
                    onChange={this.handleImageChange}
                    {...(draft.image && draft.image.url
                      ? { initialValue: draft.image.url }
                      : {})}
                  />
                </Form.Field>

                {draft.createdUtc && (
                  <Form.Field>
                    <label>Created</label>
                    <p>
                      {new Date(draft.createdUtc).toLocaleString(
                        navigator.language
                      )}
                    </p>
                  </Form.Field>
                )}

                {draft.updatedUtc && (
                  <Form.Field>
                    <label>Updated</label>
                    <p>
                      {new Date(draft.updatedUtc).toLocaleString(
                        navigator.language
                      )}
                    </p>
                  </Form.Field>
                )}
              </Form>
            </Content>
          </Panel>
        )}
      </FocusElement>
    )
  }

  componentDidMount = () => {
    this.props.data &&
      this.setState(previousState => ({
        ...previousState,
        draft: merge(
          {},
          {
            name: (this.props.location.state || {}).name || ``,
            address: {},
          },
          this.props.data
        ),
      }))
  }

  componentDidUpdate = previousProps => {
    !isEqual(previousProps.data, this.props.data) &&
      this.setState(previousState => ({
        ...previousState,
        draft: merge(
          {},
          {
            name: (this.props.location.state || {}).name || ``,
            address: {},
          },
          this.props.data
        ),
      }))
  }

  handleChange = (event, { name, value }) => {
    this.setState(previousState => ({
      ...previousState,
      draft: set(merge({}, previousState.draft), name, value),
    }))
  }

  handleImageChange = ({ target: { value } }) => {
    this.setState(previousState => ({
      ...previousState,
      draft: {
        ...previousState.draft,
        image: value,
      },
    }))
  }
}

export default Organization
