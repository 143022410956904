import { Component } from "react"
import dispatchEvent from "../../helpers/dispatch-event"
import { root } from "../../services/Globals"
import uploadAsset from "../upload-asset"

const noop = () => {}

export default class PutArrangement extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `idle`,
      payload: undefined,
    }
    this.state = this.initialState
  }

  render = () => {
    const {
      state: { mode, payload },
      update,
    } = this

    return this.props.children({
      mode,
      payload,
      update,
    })
  }

  update = async () => {
    const {
      data: {
        id,
        image,
        arranger,
        song,
        genres,
        publisherTemp,
        ...arrangement
      },
      onSuccess = noop,
      onError = noop,
      onLoading = noop,
    } = this.props

    this.setState(
      previousState => ({
        ...previousState,
        mode: `loading`,
      }),
      onLoading
    )

    const { id: imageId } = image
      ? image.constructor.name === `File`
        ? await uploadAsset(image)
        : image
      : {}

    try {
      console.log({...arrangement})
      const { data } = await root.wav.api({
        method: `put`,
        url: `/arrangements/${ id }`,
        data: {
          ...arrangement,
          ...(imageId ? { imageId } : {}),
          ...(arranger ? { arrangerId: arranger.id } : {}),
          ...(publisherTemp ? { publisherTempId: publisherTemp.id } : {}),
          ...(song ? { songId: song.id } : {}),
          ...(genres ? { genresId: genres.map(({ id }) => id) } : {}),
          ...(arrangement.contentProvider1 ? { contentProvider1Id: arrangement.contentProvider1.id } : {} ),
          ...(arrangement.contentProvider2 ? { contentProvider2Id: arrangement.contentProvider2.id } : {} )
        },
        // arrangement = {
        //   name,
        //   description,
        //   iswc,
        //   arrangerId,
        //   songId,
        //   genresId
        // }
      })

      this.setState(
        previousState => ({
          ...previousState,
          mode: `idle`,
          payload: data,
        }),
        () => {
          onSuccess(data)
          dispatchEvent(`update:arrangement`)
          dispatchEvent(`update:${ id }`)
        }
      )

      return data
    } catch (error) {
      this.setState(
        previousState => ({
          ...previousState,
          mode: `error`,
          payload: error,
        }),
        () => onError(error)
      )
    }
  }
}
