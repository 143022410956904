import React, { Component } from "react"
import { Subscribe } from "unstated"
import { OrderContainer } from "./OrderContainer"
import Panel, { Content, CommandBar, Command } from "../Panel"
// import JSONTree from "react-json-tree"
import { Form, Header } from "semantic-ui-react"
import get from "lodash.get"
import { Link } from "react-router-dom"

class Order extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render = () => (
    <Panel
      title={
        this.props.isFetching
          ? `Loading order...`
          : this.state.id
          ? `Order: ${ this.state.id }`
          : `Order`
      }
      onClose={() =>
        this.props.history.push(
          this.props.returnTo ||
            `/orders${
              this.props.location.search ? this.props.location.search : ``
            }`
        )
      }
    >
      <CommandBar>
        <Command
          disabled={this.state.status !== `InProgress`}
          onClick={() =>
            this.props.history.push(
              `/orders/${ this.props.orderId }/create-invoice${
                this.props.location.search ? this.props.location.search : ``
              }`
            )
          }
        >
          Invoicing
        </Command>
      </CommandBar>
      <Content loading={this.props.isFetching}>
        <Form autoComplete="off" style={{ marginBottom: `2rem` }}>
          <Form.Field>
            <label>User</label>
            <Link to={`/users/${ get(this.state, `user.id`) }`}>
              {get(this.state, `user.displayName`)} (
              {get(this.state, `user.profile.email`)})
            </Link>
          </Form.Field>
          {this.state.choir && (
            <Form.Field>
              <label>Group</label>
              <Link to={`/choirs/${ get(this.state, `choir.id`) }`}>
                {get(this.state, `choir.name`)}
              </Link>
            </Form.Field>
          )}
          <Form.Field>
            <label>Plan</label>
            <Link to={`/plans/${ get(this.state, `plan.id`) }`}>
              {get(this.state, `plan.name`)}
            </Link>
          </Form.Field>
          {this.state.campaignId && (
            <Form.Field>
              <label>Campaign ID</label>
              <Link to={`/campaigns/${ get(this.state, `campaignId`) }`}>
                {get(this.state, `campaignId`)}
              </Link>
            </Form.Field>
          )}
          <Form.Field>
            <label>Quantity</label>
            <p>{get(this.state, `quantity`)}</p>
          </Form.Field>
          {this.state.billing && (
            <React.Fragment>
              <Header as="h3">Billing</Header>
              <Form.Field>
                <label>Email</label>
                <p>{get(this.state, `billing.email`)}</p>
              </Form.Field>
              <Form.Field>
                <label>Name</label>
                <p>{get(this.state, `billing.name`)}</p>
              </Form.Field>
              <Form.Field>
                <label>Address</label>
                <p>{get(this.state, `billing.line1`)}</p>
              </Form.Field>
              <Form.Field>
                <p>{get(this.state, `billing.line2`)}</p>
              </Form.Field>
              <Form.Field>
                <label>Postal code</label>
                <p>{get(this.state, `billing.postalCode`)}</p>
              </Form.Field>
              <Form.Field>
                <label>City</label>
                <p>{get(this.state, `billing.city`)}</p>
              </Form.Field>
              <Form.Field>
                <label>Country</label>
                <p>{get(this.state, `billing.country`)}</p>
              </Form.Field>
            </React.Fragment>
          )}
        </Form>
        {/* <JSONTree
          data={this.state}
          theme={{
            scheme: `chalk`,
            base00: `#151515`,
            base01: `#202020`,
            base02: `#303030`,
            base03: `#505050`,
            base04: `#b0b0b0`,
            base05: `#d0d0d0`,
            base06: `#e0e0e0`,
            base07: `#f5f5f5`,
            base08: `#fb9fb1`,
            base09: `#eda987`,
            base0A: `#ddb26f`,
            base0B: `#acc267`,
            base0C: `#12cfc0`,
            base0D: `#6fc2ef`,
            base0E: `#e1a3ee`,
            base0F: `#deaf8f`
          }}
        /> */}
      </Content>
    </Panel>
  )

  componentDidMount = () => {
    this.props.match.params.orderId &&
      this.props
        .fetchOrder(this.props.match.params.orderId)
        .then(data => this.setState(data))
  }

  componentDidUpdate = (previousProps, previousState) => {
    if (previousProps.orderId !== this.props.orderId) {
      this.setState({
        ...this.props.order,
      })
    }
  }
}

export default props => (
  <Subscribe to={[ OrderContainer ]}>
    {({ state, ...actions }) => (
      <Order
        key={props.match.params.orderId}
        orderId={props.match.params.orderId}
        {...props}
        {...actions}
        {...state}
      />
    )}
  </Subscribe>
)
