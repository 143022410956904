import axios from "axios"
import queryString from "query-string"

export default ({ configuration, authResult }) => root =>
  new Promise((resolve, reject) => {
    try {
      root.api = axios.create({
        baseURL: configuration.managementApiUrl,
        headers: {
          Authorization: `Bearer ${ authResult.idToken }`,
        },
        paramsSerializer: queryString.stringify,
      })

      root.api.interceptors.response.use(
        response => {
          if (
            response.config.method === `post` ||
            response.config.method === `put` ||
            response.config.method === `delete`
          ) {
            response.config.url
              .replace(response.config.baseURL, ``)
              .split(`/`)
              .filter(part => part.length > 0)
              .filter(part => part === `entries`)
              .forEach(part => {
                window &&
                  window.dispatchEvent(
                    new Event(`${ response.config.method }:${ part }`)
                  )
              })
          }

          return response
        },
        error => {
          if (
            error &&
            error.response &&
            error.response.config &&
            !error.response.config.suppressError
          ) {
            const message = error.response && error.response.data && (error.response.data.message || error.response.data.title)
            const header = message
              ? `<strong>${ message }</strong>`
              : error.toString()

            const errors = error.response
              && error.response.data
              && (
                (error.response.data.innerErrors && error.response.data.innerErrors.map(ie => ie.message))
                || (error.response.data.errors && Array.from(Object.entries(error.response.data.errors)).map(([k,v]) => `${k}: ${v}`))
              )
            const messages = errors
                ? `<ul>${errors
                    .map(message => `<li>${ message }</li>`)
                    .join(``) }</ul>`
                : undefined

            new root.Notification({
              type: `error`,
              timeout: 3000,
              layout: `topRight`,
              theme: `semanticui`,
              text: messages
                ? `<div>${ header + messages }</div>`
                : `<div>${ header }</div>`,
            }).show()
          }
          return Promise.reject(error)
        }
      )
      resolve(root.api)
    } catch (error) {
      reject(error)
    }
  })
