import React, { Component } from "react"
import Panel, { Menu, MenuItem } from "../Panel"
import { Subscribe } from "unstated"
import { ChoirContainer } from "./ChoirContainer"
import { Route, Link, Switch } from "react-router-dom"
import Overview from "./Overview"
import Members from "./Members"
import Settings from "./Settings"
import Update from "./Update"
import Licenses from "./Licenses"

class Choir extends Component {
  render = () => {
    return (
      <Subscribe to={[ ChoirContainer ]}>
        {choirStore => (
          <Panel
            title={
              choirStore.state.isFetching
                ? `Loading choir...`
                : choirStore.state.details
                ? `Choir: ${ choirStore.state.details.name }`
                : `Choir`
            }
            onClose={() =>
              this.props.history.push(
                this.props.returnTo ||
                  `/choirs${
                    this.props.location.search ? this.props.location.search : ``
                  }`
              )
            }
            menu={
              <Menu>
                <MenuItem
                  active={this.props.location.pathname === this.props.match.url}
                >
                  <Link to={this.props.match.url + this.props.location.search}>
                    Overview
                  </Link>
                </MenuItem>
                <MenuItem
                  active={
                    this.props.location.pathname.indexOf(
                      this.props.match.url + `/members`
                    ) > -1
                  }
                >
                  <Link
                    to={
                      this.props.match.url +
                      `/members` +
                      this.props.location.search
                    }
                  >
                    Members
                  </Link>
                </MenuItem>
                <MenuItem
                  active={
                    this.props.location.pathname.indexOf(
                      this.props.match.url + `/edit`
                    ) > -1
                  }
                >
                  <Link
                    to={
                      this.props.match.url +
                      `/edit` +
                      this.props.location.search
                    }
                  >
                    Properties
                  </Link>
                </MenuItem>
                <MenuItem
                  active={
                    this.props.location.pathname.indexOf(
                      this.props.match.url + `/settings`
                    ) > -1
                  }
                >
                  <Link
                    to={
                      this.props.match.url +
                      `/settings` +
                      this.props.location.search
                    }
                  >
                    Settings
                  </Link>
                </MenuItem>
                <MenuItem
                  active={
                    this.props.location.pathname.indexOf(
                      this.props.match.url + `/licenses`
                    ) > -1
                  }
                >
                  <Link
                    to={
                      this.props.match.url +
                      `/licenses` +
                      this.props.location.search
                    }
                  >
                    Licenses
                  </Link>
                </MenuItem>
              </Menu>
            }
          >
            <Switch>
              <Route
                path={this.props.match.path + `/licenses/:licenceId`}
                component={Licenses}
              />
              <Route
                path={this.props.match.path + `/licenses`}
                component={Licenses}
              />
              <Route
                path={this.props.match.path + `/members`}
                component={Members}
              />
              <Route
                path={this.props.match.path + `/settings`}
                component={Settings}
              />
              <Route
                path={this.props.match.path + `/edit`}
                component={Update}
              />
              <Route path={this.props.match.path} component={Overview} />
            </Switch>
          </Panel>
        )}
      </Subscribe>
    )
  }

  componentDidMount = () => {
    setTimeout(
      () => this.props.choirStore.fetchDetails(this.props.match.params.choirId),
      0
    )
  }

  componentWillReceiveProps = nextProps => {
    this.props.match.params.choirId !== nextProps.match.params.choirId &&
      this.props.choirStore.fetchDetails(nextProps.match.params.choirId)
  }

  componentWillUnmount = () => {
    this.props.choirStore.resetState()
  }
}

export default props => (
  <Subscribe to={[ ChoirContainer ]}>
    {choirStore => <Choir choirStore={choirStore} {...props} />}
  </Subscribe>
)
