import React, { Component } from "react"
import GetPlayMode from "../api/containers/GetPlayMode"
import Panel, { CommandBar, Command, Content } from "../Panel"
import { Loader, Form, Dropdown, Icon } from "semantic-ui-react"
import PutPlayMode from "../api/containers/PutPlayMode"
import isEqual from "deep-equal"
import DeletePlayMode from "../api/containers/DeletePlayMode"
import { root } from "../services/Globals"
import equal from "deep-equal"

import PostPlayModes from "../api/containers/PostPlayModes"
import GetPlayModeTypes from "../api/containers/GetPlayModeTypes"

const prettyPrintJson = json => {
  try {
    return JSON.stringify(
      json.constructor.name === `String` ? JSON.parse(json) : json,
      null,
      2
    )
  } catch (error) {
    return json
  }
}

const parseJson = data => {
  if (data) {
    try {
      return JSON.parse(data)
    } catch (error) {
      return data
    }
  } else {
    return data
  }
}

class PlayMode extends Component {
  constructor(props) {
    super(props)
    this.initialState = props.playMode
      ? {
          id: props.playMode.id,
          playModeType: props.playMode.playModeType,
          name: props.playMode.name,
          enabled: props.playMode.enabled,
          default: props.playMode.default,
          data: props.playMode.data ? props.playMode.data : ``,
        }
      : {
          id: undefined,
          playModeType: undefined,
          name: undefined,
          default: false,
          enabled: false,
          data: undefined,
        }
    this.state = this.initialState
  }

  render = () => {
    const {
      handleChange,
      state,
      initialState,
      props: { arrangementId, versionId, history, match },
    } = this

    const unchanged = isEqual(state, initialState)

    return (
      <Form>
        <CommandBar>
          {state.id && (
            <React.Fragment>
              <DeletePlayMode
                arrangementId={arrangementId}
                versionId={versionId}
                playModeId={state.id}
              >
                {playMode => (
                  <Command
                    disabled={playMode.mode === `loading`}
                    onClick={() => {
                      const deleteNotification = new root.wav.Notification({
                        text: `<h4 class="ui header"><i aria-hidden="true" class="trash icon"></i><div class="content">You are about to delete this play mode permanently, do you want to continue?</div></h4>`,
                        buttons: [
                          root.wav.Notification.button(
                            `Yes`,
                            `ui compact button`,
                            () => {
                              playMode.delete().then(() => {
                                deleteNotification.close()
                              })
                            }
                          ),
                          root.wav.Notification.button(
                            `No`,
                            `ui compact button`,
                            () => {
                              deleteNotification.close()
                            }
                          ),
                        ],
                        theme: `semanticui`,
                        modal: true,
                        callbacks: {
                          afterClose: () => {
                            history.push(
                              `/arrangements/${ arrangementId }/versions/${ versionId }/playmodes`
                            )
                          },
                        },
                      }).show()
                    }}
                  >
                    <span>
                      <Icon name="trash" />
                      {playMode.mode === `error`
                        ? `Retry`
                        : playMode.mode === `idle`
                        ? `Delete`
                        : `Deleting`}
                    </span>
                  </Command>
                )}
              </DeletePlayMode>
              <PutPlayMode
                data={state}
                arrangementId={arrangementId}
                versionId={versionId}
              >
                {playMode => (
                  <Command
                    disabled={unchanged || playMode.mode === `loading`}
                    onClick={() => playMode.update()}
                  >
                    <span>
                      {/* <Icon name="cloud upload" /> */}
                      {playMode.mode === `error`
                        ? `Retry`
                        : playMode.mode === `loading`
                        ? `Saving...`
                        : `Save`}
                    </span>
                  </Command>
                )}
              </PutPlayMode>
            </React.Fragment>
          )}
          {!state.id && (
            <PostPlayModes>
              {playModes => (
                <PutPlayMode>
                  {playMode => (
                    <Command
                      disabled={
                        unchanged ||
                        playModes.mode === `loading` ||
                        playMode.mode === `loading`
                      }
                      onClick={() => {
                        playModes
                          .create({
                            arrangementId,
                            versionId,
                            data: {
                              playModeType: state.playModeType,
                              enabled: state.enabled,
                              default: state.default,
                              name: state.name,
                              data: state.data ? parseJson(state.data) : null,
                            },
                          })
                          // .then(({ data }) => {
                          //   playMode.update({
                          //     arrangementId,
                          //     versionId,
                          //     data: {
                          //       ...state,
                          //       id: data.id,
                          //       enabled: state.enabled,
                          //       data: (state.data ? parseJson(state.data) : {})
                          //     },
                          //   })
                          // })
                          .then((res) => {
                            history.push(
                              match.url.slice(
                                0,
                                match.url.indexOf(`playmodes`) +
                                  `playmodes`.length
                              ) + '/' + res.data.id
                            )
                          })
                      }}
                    >
                      <span>
                        {/* <Icon name="cloud upload" /> */}
                        {` `}Save
                      </span>
                    </Command>
                  )}
                </PutPlayMode>
              )}
            </PostPlayModes>
          )}
        </CommandBar>
        <Content>
          <GetPlayModeTypes>
            {({ mode, payload }) => (
              <Form.Field required>
                <label>Play Mode</label>
                <Dropdown
                  name="playModeType"
                  options={
                    mode === `success`
                      ? payload.map(({ name, value }) => ({
                          key: name,
                          text: name,
                          value: name,
                        }))
                      : []
                  }
                  items={mode === `success` ? payload : []}
                  loading={mode === `loading`}
                  error={mode === `error`}
                  value={state.playModeType}
                  fluid
                  selection
                  search
                  onChange={handleChange}
                />
              </Form.Field>
            )}
          </GetPlayModeTypes>
          <Form.Field
            control="input"
            type="text"
            label="Name"
            name="name"
            value={state.name}
            onChange={handleChange}
          />
           <Form.Field
            control="input"
            type="checkbox"
            label="Default"
            name="default"
            checked={state.default}
            value={state.default}
            onChange={this.handleBoolChange}
          />
          <Form.Field
            control="input"
            type="checkbox"
            label="Enabled"
            name="enabled"
            checked={state.enabled}
            value={state.enabled}
            onChange={this.handleBoolChange}
          />
          <Form.TextArea
            label="Data"
            name="data"
            value={prettyPrintJson(state.data) || state.data}
            onChange={this.handleJsonChange}
            autoHeight
            rows={20}
          />
        </Content>
      </Form>
    )
  }

  handleChange = (event, data) => {
    const { name, value, type } =
      data || (event && event.target) || event.target.type
    this.setState(previousState => ({
      ...previousState,
      [name]: type === `number` ? parseInt(value) : value,
    }))
  }

  handleBoolChange = (event, data) => {
    const { name, checked } =
      data ||
      (event && event.target) ||
      event.target.type ||
      event.target.checked
    this.setState(previousState => ({
      ...previousState,
      [name]: checked,
    }))
  }

  handleJsonChange = (event, data) => {
    const { name, value } =
      data ||
      (event && event.target) ||
      event.target.type ||
      event.target.checked
    this.setState(previousState => ({
      ...previousState,
      [name]: value ? parseJson(value) : ``,
    }))
  }

  componentWillReceiveProps = nextProps => {
    !equal(this.props.playMode, nextProps.playMode) &&
      this.setState({
        id: nextProps.playMode.id,
        playModeType: nextProps.playMode.playModeType,
        enabled: nextProps.playMode.enabled,
        name: nextProps.playMode.name,
        default: nextProps.playMode.default,
        data: nextProps.playMode.data ? nextProps.playMode.data : ``,
      })
  }
}

export default ({ match, history }) =>
  match.params.playModeId !== `new` ? (
    <GetPlayMode {...match.params}>
      {({ mode, payload }) => (
        <Panel
          title={`Play Mode${
            mode === `success` ? `: ${ payload.playModeType }` : ``
          }`}
          onClose={() =>
            history.push(
              `/arrangements/${ match.params.arrangementId }/versions/${
                match.params.versionId
              }/playmodes`
            )
          }
        >
          {mode === `loading` && (
            <Content>
              <Loader active inline="centered" content="Loading play mode" />
            </Content>
          )}
          {mode === `success` && (
            <PlayMode playMode={payload} {...match.params} history={history} />
          )}
        </Panel>
      )}
    </GetPlayMode>
  ) : (
    <Panel
      title={`New play mode`}
      onClose={() =>
        history.push(
          `/arrangements/${ match.params.arrangementId }/versions/${
            match.params.versionId
          }/playmodes`
        )
      }
    >
      <PlayMode {...match.params} history={history} match={match} />
    </Panel>
  )
