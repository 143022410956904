import { Component } from "react"
import { root } from "../../services/Globals"

export default class GetVersions extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      mode: `loading`,
      payload: undefined,
      next: undefined,
    }
    this.state = this.initialState
    this.updateEvent = `update:${ this.props.arrangementId }`
  }

  render = () => {
    const { mode, payload, reload, next } = this.state
    return this.props.children({
      mode,
      payload,
      reload,
      ...(next ? { paginate: this.paginate } : {}),
    })
  }

  componentDidMount = () => {
    window.addEventListener(this.updateEvent, this.getData)
    return this.getData()
  }

  componentWillUnmount = () => {
    window.removeEventListener(this.updateEvent, this.getData)
  }

  getData = () => {
    this.setState(previousState => ({
      ...previousState,
      loading: true,
    }))

    const { limit, offset, sort, arrangementId } = this.props

    const params = {
      ...(limit ? { limit } : {}),
      ...(offset ? { offset } : {}),
      ...(sort ? { sort } : {}),
    }

    return root.wav
      .api({
        url: `/arrangements/${ arrangementId }/versions`,
        params,
      })
      .then(({ data: { next, items } }) => {
        this.setState(previousState => ({
          ...previousState,
          mode: `success`,
          payload: items,
          next,
        }))
      })
      .catch(error => {
        this.setState(previousState => ({
          ...previousState,
          mode: `error`,
          payload: error,
        }))
      })
  }

  paginate = () => {
    this.setState(previousState => ({
      ...previousState,
      mode: `paginating`,
    }))

    return root.wav
      .api(this.state.next)
      .then(({ data: { items, next } }) => {
        this.setState(previousState => ({
          ...previousState,
          mode: `success`,
          payload: [ ...previousState.payload, ...items ],
          next,
        }))
      })
      .catch(error => {
        this.setState(previousState => ({
          ...previousState,
          mode: `error`,
          payload: error,
        }))
      })
  }

  reload = event => {
    event && event.preventDefault && event.preventDefault()
    this.setState(() => this.initialState)
    return this.getData()
  }
}
