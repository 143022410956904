import React, { Component } from "react"
import Panel, { Content } from "../Panel"
import FileUpload from "../FileUpload"
import Select from "../Select"
import { Form } from "semantic-ui-react"
import dispatchEvent from "../helpers/dispatch-event"
import { Route } from "react-router-dom"
import Song from "../Songs/Song"

export default class MusicXml extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false

    this.state = {
      songId: undefined,
      error: undefined,
    }

    const { arrangementId, versionId } =
      (props.location && props.location.state) ||
      (props.match && props.match.params)
    this.arrangementId = arrangementId

    this.url = [
      arrangementId ? `${ arrangementId }/versions` : undefined,
      versionId,
      `musicxml`,
    ].reduce((acc, part) => (part ? `${ acc }/${ part }` : acc), `/arrangements`)

    this.method = versionId ? `put` : `post`
  }

  render = () => {
    const {
      state,
      handleChange,
      successHandler,
      errorHandler,
      handleSongAddition,
    } = this

    const requiresSongId =
      this.props.location &&
      this.props.location.pathname.includes(`/arrangements/musicxml`)

    const returnTo = this.props.location.pathname.slice(
      0,
      this.props.location.pathname.lastIndexOf(`/`)
    )

    const { arrangementId, versionId, audioId } =
      (this.props.location && this.props.location.state) ||
      (this.props.match && this.props.match.params)

    return (
      <React.Fragment>
        <Panel
          key="upload-music-xml"
          title="Upload MusicXML"
          onClose={() => this.props.history.push(returnTo)}
        >
          <Content>
            <Form>
              {requiresSongId && (
                <Form.Field required>
                  <label>Song</label>
                  <Select
                    fluid
                    allowAdditions
                    url="/songs"
                    value={this.state.songId}
                    onChange={handleChange}
                    onAddItem={({ value: name }) =>
                      this.props.history.push(
                        this.props.match.url +
                          `/song` +
                          this.props.location.search,
                        { name }
                      )
                    }
                  />
                </Form.Field>
              )}
              <Form.Field required>
                <label>File</label>
                <FileUpload
                  url={this.url}
                  method={this.method}
                  successHandler={successHandler}
                  errorHandler={errorHandler}
                  {...(requiresSongId
                    ? {
                        data: {
                          songId: state.songId,
                        },
                        disabled: !state.songId,
                      }
                    : {})}
                  showProgress={true}
                  // accept="text/xml"
                  metadata={{
                    arrangementId,
                    versionId,
                    audioId,
                  }}
                />
              </Form.Field>
            </Form>
          </Content>
        </Panel>
        <Route
          path={`${ this.props.match.path }/song`}
          render={props => (
            <Song
              {...props}
              onSave={handleSongAddition}
              returnTo={this.props.match.url + this.props.location.search}
            />
          )}
        />
      </React.Fragment>
    )
  }

  componentDidMount = () => {
    this._isMounted = true
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleChange = item => {
    this.setState(previousState => ({
      ...previousState,
      songId: item.id,
    }))
  }

  successHandler = data => {
    this._isMounted && this.props.onUpdate && this.props.onUpdate(data)
    dispatchEvent(`create:arrangement`)
    dispatchEvent(`update:${ data.id }`)
    this.arrangementId && dispatchEvent(`update:${ this.arrangementId }`)
    this._isMounted &&
      this.props.history.push(
        `/arrangements/${
          this.arrangementId
            ? `${ this.arrangementId }/versions${ data.id ? `/${ data.id }` : `` }`
            : data.id
        }`
      )
  }

  errorHandler = error => {
    this._isMounted &&
      this.setState(previousState => ({
        ...previousState,
        error,
      }))
  }

  handleSongAddition = id => {
    this.props.history.push(this.props.match.url + this.props.location.search)
    this.handleChange({ id })
  }
}
