import React from "react"
import { root } from "../services/Globals"

export default class ContractContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      loadingMessage: ``,
      data: undefined,
    }
  }

  render = () =>
    this.props.children({
      ...this.state,
      save: this.save,
      delete: this.delete,
    })

  componentDidMount = () => {
    this.props.contractId && this.fetch()
  }

  componentDidUpdate = previousProps => {
    if (
      previousProps.contractId !== this.props.contractId &&
      !this.state.loading
    ) {
      this.props.contractId
        ? this.fetch()
        : this.setState({
            data: undefined,
          })
    }
  }

  fetch = () => {
    if (this.props.contractId && this.props.songId) {
      this.setState(
        { loading: true, loadingMessage: `Loading contract` },
        () => {
          root.wav
            .api({
              url: `/songs/${ this.props.songId }/contracts/${
                this.props.contractId
              }`,
            })
            .then(({ data }) => {
              this.setState({
                loading: false,
                loadingMessage: ``,
                data,
              })
            })
            .catch(error => {
              this.setState({
                loading: false,
                loadingMessage: ``,
                data: undefined,
              })
            })
        }
      )
    }
  }

  save = ({ id, contractType, kickback, region, contractHolders, ...data }) => {
    const method = id ? `put` : `post`

    const url = {
      put: `songs/${ this.props.songId }/contracts/${ id }`,
      post: `songs/${
        this.props.songId
      }/contracts/${ contractType
        .toLowerCase()
        .slice(0, contractType.indexOf(`Contract`)) }`,
    }[method]

    const loadingMessage =
      { put: `Saving`, post: `Creating` }[method] + ` contract...`

    this.setState(
      {
        loading: true,
        loadingMessage,
      },
      () => {
        root.wav
          .api({
            url,
            method,
            data: Object.entries({
              kickback: kickback ? parseInt(kickback, 10) : undefined,
              regionId: region ? region.id : undefined,
              contractHolders:
                contractHolders && contractHolders.length
                  ? contractHolders.map(c => ({
                      kickback: c.kickback ? parseInt(c.kickback, 10) : 0,
                      holderId: c.holder.id,
                    }))
                  : undefined,
              ...data,
            }).reduce(
              (data, [ key, value ]) =>
                !!value
                  ? Object.assign(data, {
                      [key]: value,
                    })
                  : data,
              {}
            ),
          })

          .then(response => {
            this.setState(
              {
                loading: false,
                loadingMessage: ``,
                data: response.data,
              },
              () => {
                method === `post` &&
                  this.props.onCreate &&
                  this.props.onCreate(response)
                method === `put` &&
                  this.props.onUpdate &&
                  this.props.onUpdate(response)
              }
            )
          })
          .catch(() => {
            this.setState({
              loading: false,
              loadingMessage: ``,
            })
          })
      }
    )
  }
}
