import { Container } from "unstated"
import { root } from "../services/Globals"
import { Subject, from } from "rxjs"
import { tap, map, switchMap, debounceTime } from "rxjs/operators"
import isEqual from "deep-equal"

const initialState = {
  isFetching: false,
  data: [],
  filters: {
    search: undefined,
    limit: 15,
    sort: undefined,
  },
  next: undefined,
}

class CampaignsContainer extends Container {
  constructor() {
    super()
    this.state = { ...initialState }

    this.fetch$ = new Subject()
    this.fetch$
      .pipe(
        tap(() => this.setState({ isFetching: true })),
        map(() => ({ ...this.state })),
        map(state =>
          state.next
            ? {
                url: state.next,
              }
            : {
                url: `/campaigns`,
                params: {
                  ...Object.keys(state.filters).reduce(
                    (params, filterName) =>
                      state.filters[filterName]
                        ? {
                            ...params,
                            [filterName]: state.filters[filterName],
                          }
                        : params,
                    {}
                  ),
                },
              }
        ),
        switchMap(configuration => from(root.wav.api(configuration)))
      )
      .subscribe(
        ({ data }) => {
          this.setState(previousState => ({
            ...previousState,
            isFetching: false,
            data: previousState.next
              ? [ ...previousState.data, ...data.items ]
              : data.items,
            next: data.next ? data.next : undefined,
          }))
        },
        error => process.env.NODE_ENV === `development` && console.error(error)
      )

    this.search$ = new Subject()
    this.search$.pipe(debounceTime(200)).subscribe(() => this.fetch$.next())

    this.fetch$.next()
  }

  fetch = () => {
    process.env.NODE_ENV === `development` &&
      console.log(`CampaignsContainer:fetch`)

    this.fetch$.next()
  }

  filter = filters => {
    this.setState({
      ...initialState,
      filters: {
        ...this.state.filters,
        ...filters,
      },
    })
    this.search$.next()
  }

  removeCampaignFromCampaigns = id => {
    this.setState({
      ...this.state,
      data: [ ...this.state.data.filter(campaign => campaign.id !== id) ],
    })
  }

  updateCampaignInCampaigns = ({ id, ...data }) => {
    const newState = {
      ...this.state,
      data: this.state.data.map(campaign =>
        campaign.id === id ? { id, ...data } : campaign
      ),
    }

    if (isEqual(this.state, newState)) {
      this.fetch()
    } else {
      this.setState(newState)
    }
  }

  resetState = () => {
    this.setState({ ...initialState })
  }
}

export { CampaignsContainer }
